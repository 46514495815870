export interface WrongChainModalProps {}

export const createWrongChainModalTitle = () =>
  "WARNING! DEX network and connected wallet network do not match.";

export const createWrongChainModalBody = (
  chainId: number | null,
  walletChainId: number | null
) => `DEX network chainID: ${chainId}, 
        
        Metamask network chainID: ${walletChainId} 

        Want to switch network and continue?`;
