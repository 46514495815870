import { IReactionDisposer, makeAutoObservable, reaction, toJS } from "mobx";
import { ColumnFiltersState, OnChangeFn, SortingState } from "@tanstack/react-table";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { showSuccessMsg } from "src/helpers/message";
import { roundSingleValue } from "src/helpers/rounding";
import { RangePickerStore } from "src/state/shared/RangePicker";
import { UrlSearchParamsStore } from "src/state/shared/UrlSearchParams";
import { IQueryHistory } from "src/state/shared/UrlSearchParams/types";
import { getDateTimeRangeFromUtc } from "src/helpers/dateUtils";
import { DEFAULT_FUNDING_RANGE, FundingStore, getDefaultFundingRefreshRange } from "..";
import {
  AggregatedFunding,
  AutoFunding,
  FundingMenuValue,
  ManualFunding,
  PartyFundingRange,
  TransfersFunding,
  UpdateFunding,
} from "../types";
import { AggregatedFundingStore } from "./AggregatedFundingStore";
import { AutoFundingStore } from "./AutoFundingStore";
import { ManualFundingStore } from "./ManualFundingStore";
import { TransfersFundingStore } from "./TransfersFundingStore";
import { UrlParams, searchParamsValidationSchema } from "./validationSchemes";

export class FundingManagementStore {
  mainState: FundingStore;

  rangeState: RangePickerStore;

  private _aggregatedFundingStore: AggregatedFundingStore;

  private _transfersFundingStore: TransfersFundingStore;

  private _manualFundingStore: ManualFundingStore;

  private _autoFundingStore: AutoFundingStore;

  private _activeFundingMenu: FundingMenuValue = FundingMenuValue.AGGREGATED;

  private _isLoading = false;

  private _urlSearchParamsState: UrlSearchParamsStore<UrlParams>;

  private _querySearchParamsReaction?: IReactionDisposer;

  private _currentFilters: ColumnFiltersState = [];

  private _currentSorting: SortingState = [];

  constructor(state: FundingStore, searchParamsProps: IQueryHistory) {
    this._aggregatedFundingStore = new AggregatedFundingStore();
    this._transfersFundingStore = new TransfersFundingStore();
    this._manualFundingStore = new ManualFundingStore();
    this._autoFundingStore = new AutoFundingStore();

    this.mainState = state;
    this.mainState.setUpdHandlers("updFundingList", this.loadData);

    this.rangeState = new RangePickerStore(
      this,
      DEFAULT_FUNDING_RANGE,
      getDefaultFundingRefreshRange
    );

    this._urlSearchParamsState = new UrlSearchParamsStore(this, {
      ...searchParamsProps,
      validationSchema: searchParamsValidationSchema,
    });

    makeAutoObservable(this);
  }

  get currentFilters() {
    return toJS(this._currentFilters);
  }

  get currentSorting() {
    return toJS(this._currentSorting);
  }

  get activeFundingMenu() {
    return this._activeFundingMenu;
  }

  get isLoading() {
    return this._isLoading;
  }

  get party() {
    return this.mainState.party;
  }

  get tableData() {
    return this._currentFundingStore.list.map((el) => ({
      ...el,
      amount: roundSingleValue(Number(el.amount)),
    }));
  }

  private get _currentFundingStore() {
    const store = {
      AGGREGATED: this._aggregatedFundingStore,
      TRANSFERS: this._transfersFundingStore,
      MANUAL: this._manualFundingStore,
      AUTO: this._autoFundingStore,
    };

    return store[this.activeFundingMenu];
  }

  private get _querySearchParams(): UrlParams {
    return { from: this.rangeState.start, to: this.rangeState.end };
  }

  setRange = (period: DateTimeRange) => this.rangeState.setRange(period);

  setActiveFundingMenu = (activeList: FundingMenuValue) => {
    this._activeFundingMenu = activeList;
  };

  setCurrentFilters: OnChangeFn<ColumnFiltersState> = (updaterOrValue) => {
    const newFilters =
      typeof updaterOrValue === "function" ? updaterOrValue(this._currentFilters) : updaterOrValue;
    this._currentFilters = newFilters;
  };

  setCurrentSorting: OnChangeFn<SortingState> = (updaterOrValue) => {
    const newSorting =
      typeof updaterOrValue === "function" ? updaterOrValue(this._currentSorting) : updaterOrValue;
    this._currentSorting = newSorting;
  };

  getSelectFunding = (id: number) => this._currentFundingStore.getSelectFunding(id);

  toggleFundingIgnore = (id: number, isIgnore: boolean) =>
    this.updateFunding(id, { skip: isIgnore });

  loadData = () => {
    if (this.rangeState.start && this.rangeState.end)
      this._getFundings({
        party: this.mainState.party,
        from: this.rangeState.start,
        to: this.rangeState.end,
      });
  };

  updateFunding = async (id: number, data: UpdateFunding, successCb?: () => void) => {
    this._setIsLoading(true);

    try {
      const resp = await this._currentFundingStore.updateFunding(id, data);

      if (resp && !resp.isError) {
        showSuccessMsg("Funding record successfully updated");

        successCb?.();
        this.mainState.updAllData();
      }
    } finally {
      this._setIsLoading(false);
    }
  };

  deleteFunding = (id: number) => {
    const deleteAction = async () => {
      this._setIsLoading(true);

      try {
        const resp = await this._currentFundingStore.deleteFundingAction(id);

        if (resp && !resp.isError) {
          showSuccessMsg("Funding record successfully deleted");

          this.mainState.updAllData();
        }
      } finally {
        this._setIsLoading(false);
      }
    };

    this._currentFundingStore.deleteFundingModal(id, deleteAction);
  };

  setInitialQueries = (queryObj: Partial<UrlParams>) => {
    const { from, to } = queryObj;

    if (from && to) this.rangeState.setStateRange(getDateTimeRangeFromUtc(from, to));
  };

  private _setActiveFundingList = (
    data: (AggregatedFunding | TransfersFunding | ManualFunding | AutoFunding)[]
  ) => {
    this._currentFundingStore.list = data;
  };

  private _getFundings = async (fundingParam: PartyFundingRange) => {
    this._setIsLoading(true);

    try {
      const { isError, data } = await this._currentFundingStore.loadFunding(fundingParam);

      if (!isError) this._setActiveFundingList(data);
      else this._setActiveFundingList([]);
    } catch {
      this._setActiveFundingList([]);
    } finally {
      this._setIsLoading(false);
    }
  };

  private _setIsLoading = (loading: boolean) => {
    this._isLoading = loading;
  };

  subscribe = () => {
    this._querySearchParamsReaction = reaction(
      () => this._querySearchParams,
      (query) => this._urlSearchParamsState.setAllQueryUrl(query),
      { fireImmediately: true }
    );
  };

  unsubscribe = () => this._querySearchParamsReaction?.();

  destroy = () => {};
}
