import { refreshTokenRequestConfig } from "src/helpers/auth";
import { getTemporaryToken } from "src/helpers/getToken";
import { CheckCode, Credentials } from "src/modules/auth";
import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from ".";

export const logIn = async (credentials: Credentials) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Empty, "/login"),
    method: "POST",
    data: credentials,
    auth: false,
  });

export const sendNewCode = async (login: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Empty, "/send-new-email"),
    method: "POST",
    data: { login },
    auth: {
      token: getTemporaryToken(),
    },
  });

export const checkCode = async (data: CheckCode) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Empty, "check-code"),
    method: "POST",
    data,
    auth: {
      token: getTemporaryToken(),
    },
  });

export const reqRefreshToken = async (token: string) =>
  ResponseHandler.handler(refreshTokenRequestConfig(token));
