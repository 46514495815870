import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { getTeams } from "src/api/userManager/userGroupsAPI";
import {
  getSelectorList,
  selectorValueToString,
  stringToSelectorValue,
} from "src/helpers/forms/selectors";
import { Comparators } from "src/helpers/sorting";
import { IDisposable } from "src/helpers/utils";
import { RequestMembers, RequestTeam, Team, TeamMember } from "src/modules/userManager";
import SearchStore, { ISearchStore } from "src/state/shared/SearchStore";
import { isDevEnv } from "../../../environment/env";
import { SelectorHandler } from "../UserGroups/AddUserGroupStore";

type TeamsMap = Map<string, Team>;

type PartiesSearchMemberMatch = Set<string>;

type PartiesSearchMatchMap = Map<string, Map<string, PartiesSearchMemberMatch>>;

const EMPTY_TEAM: Team = {
  name: "",
  head: "",
  headIsMember: false,
  index: 0,
  members: [],
};

export default class TeamsStore implements IDisposable {
  private _isLoading = false;

  private _teams: Team[] = [];

  private _teamsMap: TeamsMap = new Map();

  private _userGroupsFetchedReaction: IReactionDisposer;

  private _partiesSearch: ISearchStore;

  private _selectedTeam = "";

  private _teamSelectorActive = false;

  private _teamNamesChangedReaction: IReactionDisposer;

  constructor() {
    this._partiesSearch = new SearchStore({ waitTime: 300 });

    makeAutoObservable(this);

    this._userGroupsFetchedReaction = reaction(
      () => this._teams,
      (userGroups) => {
        this._setTeamsMap(userGroups);
      }
    );

    this._teamNamesChangedReaction = reaction(
      () => this._teamNames,
      (teamNames) => {
        this._initSelectedTeam(teamNames);
      }
    );
  }

  setPartiesFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    this._partiesSearch.onSearchTextChange(e);
  };

  private get _partiesSearchDebouncedText() {
    return this._partiesSearch.debouncedSearchText;
  }

  get partiesSearchText() {
    return this._partiesSearch.searchText;
  }

  private get _partiesFilterActive() {
    return Boolean(this._partiesSearchDebouncedText);
  }

  private get _teamsFilterActive() {
    return this._partiesFilterActive;
  }

  private _setTeamsMapTeam = ({ members, ...team }: Team) => {
    const sortedByPartiesTeam = {
      ...team,
      members: members
        .slice()
        .sort((a, b) => Comparators.Number.reverse(a.parties.length, b.parties.length))
        .sort((a, b) => Comparators.Boolean.reverse(a.head, b.head)),
    };
    this._teamsMap.set(sortedByPartiesTeam.name, sortedByPartiesTeam);
  };

  private _setTeamsMap = (teams: Team[]) => {
    this._teamsMap.clear();
    teams.forEach((team) => {
      this._setTeamsMapTeam(team);
    });
  };

  private get _teamNames(): string[] {
    return Array.from(this.teamsMap.keys());
  }

  get teamNames(): string[] {
    if (this._teamSelectorActive) {
      return this._selectedTeam ? [this._selectedTeam] : [];
    }
    return this._teamNames;
  }

  private _stringsSearchMatch = (str: string, search: string) =>
    str.toLowerCase().includes(search.toLowerCase());

  private _getPartySearchMatchesForTeam = (
    searchMatches: PartiesSearchMatchMap,
    team: string,
    members: TeamMember[],
    searchParty: string
  ) => {
    const partyFilter = (party: string) => this._stringsSearchMatch(party, searchParty);

    members.forEach(({ name: memberName, parties }) => {
      const matchedPartiesSet = new Set(parties.filter(partyFilter));

      if (matchedPartiesSet.size > 0) {
        const teamMatches = searchMatches.get(team);

        if (teamMatches) {
          teamMatches.set(memberName, matchedPartiesSet);
        } else {
          searchMatches.set(team, new Map([[memberName, matchedPartiesSet]]));
        }
      }
    });
  };

  private get _partiesSearchMatches() {
    const searchMatches: PartiesSearchMatchMap = new Map();

    if (!this._partiesFilterActive) return searchMatches;

    const searchParty = this._partiesSearchDebouncedText;

    this._teamsMap.forEach(({ name, members }) => {
      this._getPartySearchMatchesForTeam(searchMatches, name, members, searchParty);
    });

    return searchMatches;
  }

  partiesSearchMatchesForMember = (teamName: string, memberName: string) => {
    const matchedParties = this._partiesSearchMatches.get(teamName)?.get(memberName) ?? new Set();
    return matchedParties;
  };

  private _filterMembersByParty = (team: string, members: TeamMember[]): TeamMember[] => {
    if (!this._teamsFilterActive) return members;

    const teamMatches = this._partiesSearchMatches.get(team);
    if (!teamMatches) return [];

    return members.filter(({ name }) => teamMatches.has(name));
  };

  private get _filteredTeamsMap(): TeamsMap {
    const teams = Array.from(this._teamsMap.values());

    const filteredTeamsMapEntries = teams
      .map(({ members, ...team }) => {
        const filteredMembers = this._filterMembersByParty(team.name, members);
        return { ...team, members: filteredMembers };
      })
      .filter(({ members }) => members.length)
      .map((team) => [team.name, team] as const);

    const filteredTeamsMap = new Map(filteredTeamsMapEntries);
    return filteredTeamsMap;
  }

  private get teamsMap() {
    return this._filteredTeamsMap;
  }

  teamMembersByName = (name: string) => {
    const team = this.teamsMap.get(name);
    return team?.members ?? [];
  };

  teamByName = (name: string) => {
    const team = this.teamsMap.get(name);
    if (!team) {
      if (isDevEnv()) {
        throw Error(`team with ${name} not found`);
      }
      return EMPTY_TEAM;
    }
    return team;
  };

  setTeamSelectorActive = (active: boolean) => {
    this._teamSelectorActive = active;
    if (!this._teamSelectorActive) {
      this._initSelectedTeam(this._teamNames);
    }
  };

  private _setSelectedTeam = (name: string) => {
    this._selectedTeam = name;
  };

  private _initSelectedTeam = (teamNames: string[]) => {
    this._setSelectedTeam(teamNames[0] ?? "");
  };

  get selectedTeam() {
    return stringToSelectorValue(this._selectedTeam);
  }

  get selectableTeams() {
    return getSelectorList(this._teamNames);
  }

  onTeamSelected: SelectorHandler = (data) => {
    if (!data) return;
    this._setSelectedTeam(selectorValueToString(data));
  };

  private _setLoading = (loading: boolean) => {
    this._isLoading = loading;
  };

  get isLoading() {
    return this._isLoading;
  }

  private _setTeams(teams: Team[]) {
    this._teams = teams;
  }

  private _requestMembersToMembers = (reqMembers: RequestMembers, head: string): TeamMember[] =>
    Object.entries(reqMembers).map(([name, parties]) => ({
      name,
      parties,
      head: name === head,
    }));

  private _requestTeamsToTeams = (reqTeams: RequestTeam[]): Team[] =>
    reqTeams.map(({ name, head, users }, index) => {
      const members = this._requestMembersToMembers(users, head);
      return {
        name,
        head,
        headIsMember: Boolean(members.find((member) => member.name === head)),
        members,
        index,
      };
    });

  fetchTeams = async () => {
    this._setLoading(true);
    try {
      const { data, isError } = await getTeams();

      if (!isError) {
        const teams = this._requestTeamsToTeams(data);
        this._setTeams(teams);
      } else {
        this._setTeams([]);
      }
    } catch {
      this._setTeams([]);
    } finally {
      this._setLoading(false);
    }
  };

  destroy() {
    this._userGroupsFetchedReaction();
    this._teamNamesChangedReaction();
  }
}
