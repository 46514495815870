import { observer } from "mobx-react-lite";
import { FC } from "react";
import Select, { GroupBase, Props as SelectProps } from "react-select";
import * as styles from "src/components/shared/Forms/Selectors/shared/style";
import { SelectorValue } from "src/modules/shared";

export interface SelectorProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>>
  extends Omit<SelectProps<Option, IsMulti, Group>, "theme" | "classNamePrefix">,
    styles.StyledSelectProps {}

export const defaultSelectorProps = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  placeholder = "",
  isClearable = false,
  ...props
}: SelectorProps<Option, IsMulti, Group>): SelectorProps<Option, IsMulti, Group> => ({
  placeholder,
  isClearable,
  ...props,
});

export const Selector = observer(
  <
    Option = SelectorValue,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: SelectorProps<Option, IsMulti, Group>
  ) => {
    const propsWithDefaults = defaultSelectorProps(props);
    return (
      <styles.CustomSelect<FC<SelectProps<Option, IsMulti, Group>>>
        as={Select}
        {...propsWithDefaults}
      />
    );
  }
);
