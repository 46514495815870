import { createLateInitContext } from "src/hooks/useLateInitContext";
import { useAppState } from "src/state";
import { AllBotsStore } from "src/state/AllBots";

export const AllBotsContext = createLateInitContext<AllBotsStore>();

interface AllBotsProviderProps {
  children?: React.ReactNode;
}

export const AllBotsProvider = ({ children }: AllBotsProviderProps) => {
  const { allBotsState } = useAppState();

  return <AllBotsContext.Provider value={allBotsState}>{children}</AllBotsContext.Provider>;
};
