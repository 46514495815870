import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

interface IconChildrenProps
  extends Pick<ComponentPropsWithoutRef<"div">, "children" | "dangerouslySetInnerHTML"> {}

const iconMarkdownToHTML = (icon: string) => ({ __html: icon });

const useChainInfoIcon = (icon: string | JSX.Element): IconChildrenProps => {
  if (typeof icon === "string") {
    return { dangerouslySetInnerHTML: iconMarkdownToHTML(icon) };
  }

  return { children: icon };
};

export interface ChainInfoIconProps extends ComponentPropsWithoutRef<"span"> {
  icon: string | JSX.Element;
}

export const ChainInfoIcon = ({ icon, ...props }: ChainInfoIconProps) => {
  const iconProps = useChainInfoIcon(icon);

  return <styles.IconWrapper {...iconProps} {...props} />;
};
