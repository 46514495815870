import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Menu = styled.ul`
  z-index: 1000;

  width: 100px;
  max-height: 200px;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.selectMenuBg};

  padding: 0;
  margin: 0;

  border: none;
  border-radius: ${({ theme }) => theme.radii["4px"]};
  box-shadow:
    0px 20px 25px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  background: ${({ theme }) => theme.selectMenuBg};

  ${ScrollSize}
`;
