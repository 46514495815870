import { makeAutoObservable } from "mobx";
import { LabeledInputProps } from "src/components/shared/Forms/Inputs";
import { HashItem } from "src/components/shared/HashesList/HashesListItem/HashLabel";
import { FormFieldHandler } from "src/helpers/forms/types";
import { IArrayFormBinder } from "../FormBinder/types";
import { DEXNEWalletInputStore } from "./WalletInputStore";

export interface IDEXNESettingsWalletsState {
  addWallet: () => void;
  deleteWallet: (wallet: string) => void;
  setWallet: FormFieldHandler;
  get wallet(): string;
  get viewWallets(): HashItem[];
  get walletErrors(): string | undefined;
  get walletFieldProps(): Pick<LabeledInputProps, "value" | "onChange" | "errorHint">;
}

interface IDEXNESettingsWalletsParams {
  binder: IArrayFormBinder<string>;
  walletName?: string;
}

export class DEXNESettingsWalletsStore implements IDEXNESettingsWalletsState {
  private _walletStore: DEXNEWalletInputStore;

  private _walletsBinder: IArrayFormBinder<string>;

  private _walletName: string;

  constructor({ binder, walletName = "wallet" }: IDEXNESettingsWalletsParams) {
    makeAutoObservable(this);

    this._walletName = walletName;
    this._walletsBinder = binder;

    this._walletStore = new DEXNEWalletInputStore({
      binder,
      walletName: this._walletName,
    });
  }

  private get _walletInput() {
    return this._walletStore.wallet;
  }

  private get _wallets() {
    return this._walletsBinder.values;
  }

  get viewWallets(): HashItem[] {
    return this._wallets.map((address) => ({
      address,
    }));
  }

  addWallet = () => {
    this._walletInput.addToArray();
  };

  deleteWallet = (wallet: string) => {
    this._walletInput.deleteFromArray(wallet);
  };

  get wallet() {
    return this._walletInput.inputValue;
  }

  setWallet: FormFieldHandler = (e) => this._walletInput.getInputValueHandler(e);

  get walletErrors() {
    return this._walletInput.inputErrors;
  }

  get walletFieldProps() {
    return {
      value: this._walletInput.inputValue,
      onChange: this._walletInput.getInputValueHandler,
      errorHint: this._walletInput.inputErrors,
    };
  }
}
