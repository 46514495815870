import { ComponentPropsWithoutRef, forwardRef } from "react";
import * as styles from "./style";

export interface IconButtonProps
  extends Omit<ComponentPropsWithoutRef<"button">, keyof styles.IconButtonStyleProps>,
    Partial<styles.IconButtonStyleProps> {}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, size = "m", elevated = false, boxed = false, ...props }, ref) => (
    <styles.StyledIconButton ref={ref} $size={size} $elevated={elevated} $boxed={boxed} {...props}>
      {children}
    </styles.StyledIconButton>
  )
);
