import { IMarketInfo } from "src/api/arbitrage/types";
import { joinMarket } from "src/helpers/botName";
import { MarketMap } from "../types";
import { partyMarketListMapSchema } from "./validationSchemes";
import { PartyMarketListMap } from "./types";

export const marketInfoListToMap = (markets: IMarketInfo[]): MarketMap => {
  const marketMap = new Map<string, IMarketInfo>();

  markets.forEach((el) => {
    const marketID = joinMarket(el.market);
    marketMap.set(marketID, el);
  });

  return marketMap;
};

export const validationPartyMarketListMap = (partyMap: PartyMarketListMap) =>
  partyMarketListMapSchema.isValidSync(partyMap);
