import TTLCacheImpl from "@isaacs/ttlcache";
import { makeObservable, observable } from "mobx";
import { ICache, IObservableCache } from "../ICache";
import { BaseTTLCache } from "./BaseTTLCache";
import { TTLCacheOptions } from "./TTLCache";

export class ObservableTTLCache<T> implements IObservableCache<T> {
  private _cache: ICache<T>;

  constructor(options: TTLCacheOptions<T>) {
    const cacheImpl = new TTLCacheImpl(options);
    // make underlying data property in cache observable Map
    makeObservable<typeof cacheImpl, "data">(cacheImpl, {
      data: observable,
    });

    this._cache = new BaseTTLCache(cacheImpl);
  }

  get = (key: string) => this._cache.get(key);

  set = (key: string, value: T) => this._cache.set(key, value);

  has = (key: string) => this._cache.has(key);

  delete = (key: string) => this._cache.delete(key);

  clear = () => {
    this._cache.clear();
  };

  isObservable = true as const;
}
