import { useHistory, useRouteMatch } from "react-router-dom";
import { MainRoutes, PARTY_ROUTE } from "src/app/constants";
import { noOp } from "src/helpers/utils";
import { LayerType } from "..";
import { getPartySectionLink } from "../MenuContent/shared/MenuList/utils";

interface PartyQueryParam {
  party: string;
}

const useGetPartyDefaultPath = () => {
  const route = useRouteMatch<PartyQueryParam>({ path: PARTY_ROUTE });

  let partyURL = "";

  const party = route?.params.party;

  if (party) partyURL = getPartySectionLink({ party, partySection: "Bots" });

  return { partyURL };
};

export const useMoveLayer = (layer: LayerType) => {
  const history = useHistory();

  const { partyURL } = useGetPartyDefaultPath();

  switch (layer) {
    case "bot": {
      return () => history.push(partyURL);
    }
    case "party": {
      return () => history.push(MainRoutes.AllBotsPage);
    }

    default:
      return noOp;
  }
};
