import styled from "styled-components";
import { PanelContainer } from "../../shared";
import { Headline2 } from "../../Typography";

export interface ContainerProps {
  $orientation?: "row" | "column";
}

export const Container = styled(PanelContainer)<ContainerProps>`
  height: 100%;

  display: flex;
  flex-direction: ${({ $orientation = "column" }) => $orientation};
  align-items: center;
  justify-content: center;
  gap: 15px;

  padding: 5px;

  color: ${({ theme }) => theme.dangerColor};
  border: 1px solid currentColor;
`;

export interface TitleProps {
  $fontSize?: number;
}

const DEFAULT_TITLE_FONT_SIZE = "17px";
export const TITLE_FONT_SIZE_VARIABLE = "--title-font-size";

export const Title = styled(Headline2)<TitleProps>`
  font-size: min(
    var(${TITLE_FONT_SIZE_VARIABLE}, ${DEFAULT_TITLE_FONT_SIZE}),
    ${DEFAULT_TITLE_FONT_SIZE}
  );
  color: currentColor;

  text-align: center;
`;

const DEFAULT_ICON_WIDTH = "35px";
export const ICON_WIDTH_VARIABLE = "--icon-height";

export const IconWrapper = styled.div`
  width: min(var(${ICON_WIDTH_VARIABLE}, ${DEFAULT_ICON_WIDTH}), ${DEFAULT_ICON_WIDTH});

  height: auto;

  > svg {
    display: block;
    height: 100%;
    width: 100%;
  }
`;
