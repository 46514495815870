import { DateTimeInputPrompt } from "src/components/shared/Prompts/DateTimeInputPrompt";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 4px;
`;

const ClockTextMixin = css`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};

  white-space: nowrap;
  letter-spacing: -0.2px;
`;

export const ClockText = styled.span`
  color: ${({ theme }) => theme.dashboard.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};

  ${ClockTextMixin}
`;

export const UTCOffsetText = styled.span`
  color: ${({ theme }) => theme.dashboard.textDefault};

  ${ClockTextMixin}
`;

export const UTCInfoPrompt = styled(DateTimeInputPrompt)`
  width: 16px;
  height: 16px;
`;
