import { observer } from "mobx-react-lite";
import useElementSize from "src/hooks/useElementSize";
import { useAppState } from "src/state";
import { BreadCrumbs } from "./BreadCrumbs";
import { Logo } from "./Logo";
import { ProfileSection, ProfileSectionProps } from "./ProfileSection";
import { SideMenu } from "./SideMenu";
import * as styles from "./style";

export interface HeaderProps extends ProfileSectionProps {
  isMobile: boolean;
}

export const Header = observer(({ themeToggler, isMobile }: HeaderProps) => {
  const { userState } = useAppState();

  const [headerRef, headerSize] = useElementSize<HTMLDivElement>(250);

  if (!userState.isLoggedIn)
    return (
      <styles.HeaderContainer>
        <styles.HeaderBody>
          <Logo isMobile={isMobile} />
        </styles.HeaderBody>
      </styles.HeaderContainer>
    );

  return (
    <styles.HeaderContainer ref={headerRef}>
      <styles.HeaderBody>
        <styles.Wrapper>
          {isMobile ? <SideMenu isMobile={isMobile} headerHeight={headerSize.height} /> : null}

          <Logo isMobile={isMobile} />
        </styles.Wrapper>

        {!isMobile ? (
          <styles.NavigationWrapper>
            <BreadCrumbs />
          </styles.NavigationWrapper>
        ) : null}

        <ProfileSection themeToggler={themeToggler} />
      </styles.HeaderBody>
    </styles.HeaderContainer>
  );
});
