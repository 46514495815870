import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 999;
  background-color: ${({ theme }) => theme.loaderBg};
`;

export const LoaderAnim = styled.img`
  position: absolute;
  height: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
