import { ethers } from "ethers";
import { FieldPath, UseControllerProps } from "react-hook-form";
import { checkFutureDate } from "src/helpers/dateUtils";
import { getByKey, getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";
import { FormValidation, HookFormValidation, Validator } from "src/helpers/forms/types";
import { entries } from "src/helpers/utils";

export const EMAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

const durationRegex = /^(\d+d)?(\d+h)?(\d+m)?(\d+s)?$/;

const TIME_FRAME_UNITS = ["days", "hours", "minutes", "second"] as const;

type TimeFrameUnits = (typeof TIME_FRAME_UNITS)[number];

type TimeFrame = Partial<Record<TimeFrameUnits, number>>;

export const ERRORS_MSGS = {
  isRequired: "This field is required",
  isPositive: "The value must be positive",
  isNotZero: "Value cannot be 0",
  isContainsElements: "Must contain at least one value",
  isInteger: "The value must be an integer",
  graterThan: (min: number) => `Must be greater than ${min}`,
} as const;

export const validateData = (
  validation: any,
  data: any,
  erorrs: any,
  inValidateKeys: string[] | undefined
): boolean => {
  const validateKeys = inValidateKeys || Object.keys(validation);
  let valid = true;

  for (const key of validateKeys) {
    const validateArr = typeof validation[key] === "function" ? [validation[key]] : validation[key];

    for (const validate of validateArr) {
      const error = validate(getByKey(key, data), data);
      const [path, endKey] = getPathAndKey(key);
      const targetError = getTargetValueByPath(erorrs, path);
      targetError[endKey] = error;

      if (error) {
        valid = false;
        break;
      }
    }
  }

  return valid;
};

export const required =
  (errorMessage: string = ERRORS_MSGS.isRequired) =>
  (v: string | number): string => {
    if (!v && v !== 0) return errorMessage;
    return "";
  };

export const isNumber =
  (errorMessage: string = "Not a number") =>
  (v: number): string => {
    if (typeof v !== "number" && isNaN(v)) return errorMessage;
    return "";
  };

export const isInteger =
  (errorMessage: string = "The value must be an integer") =>
  (v: number | string): string => {
    if (!Number.isInteger(+v)) return errorMessage;
    return "";
  };

export const isMultipleOf =
  (multiple: number, errorMessage: string = `Value must be a multiple of ${multiple}`) =>
  (v: number | string): string => {
    if (+v % multiple !== 0) return errorMessage;
    return "";
  };

export const graterThan =
  (min: number, errorMessage: string = `Must be greater than ${min}`) =>
  (v: number | string): string => {
    if (+v < min) return errorMessage;
    return "";
  };

export const strictlyGreaterThan =
  (min: number, errorMessage: string = `Must be strictly greater than ${min}`) =>
  (v: number | string): string => {
    if (+v <= min) return errorMessage;
    return "";
  };

export const smallerThan =
  (max: number, errorMessage: string = `Should be less than ${max}`) =>
  (v: number | string): string => {
    if (+v > max) return errorMessage;
    return "";
  };

export const strictlySmallerThan =
  (max: number, errorMessage: string = `Should be strictly smaller than ${max}`) =>
  (v: number | string): string => {
    if (+v >= max) return errorMessage;
    return "";
  };

export const graterThanKey =
  (key: string, errorMessage: string = "Must be greater") =>
  (v: string | number, data: any): string => {
    if (+v && +v < getByKey<any>(key, data)) return errorMessage;
    return "";
  };

export const greaterThanNumberKey =
  (key: string, errorMessage: string = "Must be greater") =>
  (v: number, data: any): string => {
    if (+v < getByKey<any>(key, data)) return errorMessage;
    return "";
  };

export const strictlyGreaterThanKey =
  (key: string, errorMessage: string = "Must be strictly greater") =>
  (v: string | number, data: any): string => {
    if (+v && +v <= getByKey<any>(key, data)) return errorMessage;
    return "";
  };

export const smallerThanKey =
  (key: string, errorMessage: string = "Must be greater") =>
  (v: string, data: any): string => {
    if (+v && +v > getByKey<any>(key, data)) return errorMessage;
    return "";
  };

export const smallerThanNumberKey =
  (key: string, errorMessage: string = "Must be greater") =>
  (v: number, data: any): string => {
    if (+v > getByKey<any>(key, data)) return errorMessage;
    return "";
  };

export const strictlySmallerThanKey =
  (key: string, errorMessage: string = "Must be strictly smaller") =>
  (v: string, data: any): string => {
    if (+v && +v >= getByKey<any>(key, data)) return errorMessage;
    return "";
  };

export const isRequiredWhen =
  (whenKeys: string[], errorMessage: string = "Fill in the rest of the fields") =>
  (v: string | number, data: any): string => {
    if (whenKeys.some((key) => !!getByKey(key, data))) {
      if (!v) return errorMessage;
    }
    return "";
  };

export const notEqual =
  (min: number, errorMessage: string = "Should not be equal") =>
  (v: number | string): string => {
    if (+v === min) return errorMessage;
    return "";
  };

export const email =
  (errorMessage: string = "Email must be in the format ***@**.**") =>
  (v: string): string => {
    if (!EMAIL_REGEXP.test(v)) return errorMessage;
    return "";
  };

export const textFormat =
  (errorMessage: string = "Email must be in the format ***@**.**", regExp: RegExp) =>
  (v: string): string => {
    if (!regExp.test(v)) return errorMessage;
    return "";
  };

export const shortThan =
  (maxLength: number, errorMessage: string = `Must not exceed ${maxLength} characters`) =>
  (v: string): string => {
    if (!v) return "";
    if (v.length > maxLength) return errorMessage;
    return "";
  };

export const longerThan =
  (minLength: number, errorMessage: string = `Must be more than ${minLength} characters`) =>
  (v: string): string => {
    if (!v) return errorMessage;
    if (v.length < minLength) return errorMessage;
    return "";
  };

export const isEthAddress =
  (errorMessage: string = "Incorrect public address") =>
  (v: string) => {
    if (!ethers.utils.isAddress(v)) return errorMessage;

    return "";
  };

export const isContainsElements =
  (errorMessage: string = "Must contain at least one value", minLength: number = 1) =>
  (v: any[]) => {
    if (v.length < minLength) return errorMessage;
    return "";
  };

// callback for react-hook-form validation
export const hookFormIsFutureDate =
  (errorMessage: string = "Please choose future date") =>
  (v: string | number): string | undefined => {
    let time;

    if (typeof v === "number") {
      time = v * 1000;
    } else time = v;

    const isFuture = checkFutureDate(time);

    if (!isFuture) return errorMessage;
  };

// callback for react-hook-form validation
export const hookFormStrictlyGreaterThan =
  (min: number, errorMessage: string = "Must be strictly greater") =>
  (v: number | string): string | undefined => {
    if (+v <= min) return errorMessage;
  };

// wrapper for form validation using react-hook-forms
export const wrapHookFormValidation =
  <T>(validationCb: (v: T) => string) =>
  (v: T) => {
    const validationResult = validationCb(v);

    return validationResult || undefined;
  };

export const isTimeFrame =
  () =>
  (str: string): string | undefined => {
    const matches = str.match(durationRegex);
    const timeFrame: TimeFrame = {};

    if (!matches) {
      return "Invalid time frame format!";
    }

    TIME_FRAME_UNITS.forEach((unit, index) => {
      const match = matches[index + 1];
      if (match) {
        timeFrame[unit] = parseInt(match.slice(0, -1), 10);
      }
    });

    if (Object.keys(timeFrame).length === 0) {
      return "Time frame must contain at least one time interval";
    }
  };

export const toHookFormValidation = <T extends object, V extends FormValidation<T>>(
  validation: V
): HookFormValidation<T> =>
  entries(validation).reduce<HookFormValidation<T>>((acc, [key, rules]) => {
    if (!rules) return acc;
    const rulesArray: Validator[] = Array.isArray(rules) ? rules : [rules];

    const hookFormRules: UseControllerProps<T>["rules"] = {
      validate: (v) => {
        for (const rule of rulesArray) {
          const error = rule(v);
          if (error) return error;
        }
        return undefined;
      },
    };

    const hookFormKey = key as unknown as FieldPath<T>;

    acc[hookFormKey] = hookFormRules;

    return acc;
  }, {});
