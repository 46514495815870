import { IEqualsComparer, comparer } from "mobx";

export type CacheComparer<T> = IEqualsComparer<T | undefined>;

// comparer to filter out new undefined values upstream which corresponds to a cache miss
// enables caching of previous "old" value until new distinct item arrives
export const cacheComparer =
  <T>(defaultComparer?: IEqualsComparer<T | undefined>): CacheComparer<T> =>
  (a, b) => {
    if (b === undefined) return true;
    return defaultComparer ? defaultComparer(a, b) : comparer.default(a, b);
  };
