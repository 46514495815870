import { forwardRef } from "react";
import { DropdownButtonProps } from "src/components/shared/Buttons/v2/DropdownButton";
import useMediaQuery from "src/hooks/useMediaQuery";
import * as styles from "./style";

export interface BotTriggerButtonProps extends Omit<DropdownButtonProps, "size" | "as"> {}

const getButtonSize = (isMobile: boolean, isLaptop: boolean) => {
  if (isMobile) return "s";
  if (isLaptop) return "m";
  return "l";
};

export const BotTriggerButton = forwardRef<HTMLButtonElement, BotTriggerButtonProps>(
  ({ children, ...props }, ref) => {
    const isLaptop = useMediaQuery("(max-width: 1400px)");
    const isMobile = useMediaQuery("(max-width: 850px)");

    const text = !isLaptop ? children : null;
    const size = getButtonSize(isMobile, isLaptop);
    return (
      <styles.TriggerButton size={size} ref={ref} elevated={false} {...props}>
        {text}
      </styles.TriggerButton>
    );
  }
);
