import { observer } from "mobx-react-lite";
import { Popup } from "reactjs-popup";
import { PopupProps } from "reactjs-popup/dist/types";
import { usePopupState } from "../../../hooks/usePopupState";
import { MenuProps } from "./Menu";
import { MenuItem } from "./MenuItem";
import * as styles from "./style";

export interface PopoverMenuItem {
  label: string;
  onClick?: () => void;
}

interface PopoverMenuOwnProps extends Pick<PopupProps, "trigger" | "disabled"> {
  items?: PopoverMenuItem[];
  defaultOpen?: boolean;
}

export interface PopoverMenuProps extends PopoverMenuOwnProps {
  popupProps?: Omit<
    PopupProps,
    keyof PopoverMenuOwnProps | "open" | "onOpen" | "onClose" | "children"
  >;
  menuProps?: MenuProps;
}

export const PopoverMenu = observer(
  ({ trigger, disabled, defaultOpen, popupProps, menuProps, items = [] }: PopoverMenuProps) => {
    const [isOpen, { onClose, onOpen }] = usePopupState(defaultOpen);

    return (
      <Popup
        trigger={trigger}
        disabled={disabled}
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        on="click"
        closeOnDocumentClick
        arrow={false}
        {...popupProps}
      >
        <styles.Menu {...menuProps}>
          {items.map(({ label, onClick }) => {
            const onMenuClick = () => {
              onClick?.();
              onClose();
            };
            return (
              <MenuItem onClick={onMenuClick} key={label}>
                {label}
              </MenuItem>
            );
          })}
        </styles.Menu>
      </Popup>
    );
  }
);
