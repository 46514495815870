import { ApiModules, apiUrl } from "src/api";
import ResponseHandler from "src/state/network/ResponseHandler";
import { LimitMod } from "src/state/shared/DEX/types";

export type CreateDEXNEBotRequest = {
  base: string;
  bot_name: string;
  chain_name: string;
  dex_name: string;
  limit_executors_num: number;
  limit_settings: {
    gas_mult: number;
    gas_price_limit: number;
    settings: {
      lower_price: number;
      max_amount: number;
      min_amount: number;
      mod: LimitMod;
      period: number;
      status: boolean;
      upper_price: number;
    }[];
    slippage: number;
  };
  main_settings: {
    gas_limit: number;
    tt_buy_fee: number;
    tt_sell_fee: number;
    stable: string;
    stable_pool: string;
  };
  party_name: string;
  pool_address: string;
  quote: string;
  volume_executors_num: number;
  volume_settings: {
    buy_percent: number;
    gas_mult: number;
    gas_price_limit: number;
    max_amount: number;
    max_trades: number;
    min_amount: number;
    min_trades: number;
    period: number;
    slippage: number;
  };
  counter_executors_num: number;
  counter_settings: {
    black_listed_wallets: string[];
    gas_mult: number;
    gas_price_limit: number;
    settings: {
      input_event: string;
      input_lower_bound: number;
      input_upper_bound: number;
      lower_price: number;
      output_event: string;
      output_lower_bound_perc: number;
      output_upper_bound_perc: number;
      status: boolean;
      upper_price: number;
    }[];
    slippage: number;
  };
};

interface CreateDEXNEBotDTO {
  bot_id: string;
}

export const createBot = async (data: CreateDEXNEBotRequest) =>
  ResponseHandler.handler<CreateDEXNEBotDTO>({
    url: apiUrl(ApiModules.DEXNE, "bot/create"),
    method: "POST",
    data,
  });
