import { rgba } from "polished";
import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";
import { SwitchMenuDirection } from ".";
import { Label, RadioButton } from "./MenuItem/style";

export const Menu = styled.div<SwitchMenuDirection>`
  width: 100%;

  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-items: stretch;
  align-items: stretch;

  border-radius: ${({ theme }) => theme.radii["4px"]};
`;

const CheckedMenuItem = css`
  ${RadioButton}:checked + ${Label} {
    color: ${({ theme }) => theme.backgroundWhiteColor};
    background: ${({ theme }) => theme.primaryColor};
    box-shadow: ${({ theme: { shadows } }) => shadows.switchMenu};
  }
`;

export const MultipleActiveSwitchMenu = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-template-rows: auto;
  gap: 8px;

  ${Label} {
    height: 100%;

    color: ${({ theme }) => theme.primaryColor};
    background: ${({ theme }) => hexToRgb(theme.primaryColor, 0.1)};
  }

  ${CheckedMenuItem}
`;

export const SingleActiveSwitchMenu = css`
  height: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 2px;

  background: ${({ theme }) => rgba(theme.primaryColor, 0.1)};

  ${Label} {
    height: 100%;

    color: ${({ theme }) => theme.primaryColor};
  }

  ${CheckedMenuItem}
`;
