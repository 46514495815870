import { observer } from "mobx-react-lite";
import { GroupBase } from "react-select";
import { SelectorValue } from "src/modules/shared";
import {
  FormField,
  FormFieldControlProps,
  FormFieldOuterProps,
  FormFieldProps,
} from "../../../FormField";
import { CreatableSelector, CreatableSelectorProps } from "../CreatableSelector";

export interface LabeledCreatableSelectorOwnProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends Pick<
    CreatableSelectorProps<Option, IsMulti, Group>,
    | "placeholder"
    | "options"
    | "onChange"
    | "isMulti"
    | "isDisabled"
    | "isClearable"
    | "value"
    | "defaultValue"
    | "onCreateOption"
    | "onInputChange"
    | "menuPosition"
    | "menuShouldBlockScroll"
  > {}

export interface LabeledCreatableSelectorProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends LabeledCreatableSelectorOwnProps<Option, IsMulti, Group>,
    Pick<FormFieldProps, "label" | "errorHint" | "labelAfter">,
    Omit<FormFieldOuterProps, keyof LabeledCreatableSelectorOwnProps<Option, IsMulti, Group>> {}

export const LabeledCreatableSelector = observer(
  <
    Option = SelectorValue,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >({
    placeholder,
    options,
    onChange,
    isMulti,
    isDisabled,
    isClearable,
    value,
    defaultValue,
    onCreateOption,
    onInputChange,
    menuPosition,
    menuShouldBlockScroll,
    ...props
  }: LabeledCreatableSelectorProps<Option, IsMulti, Group>) => {
    const selectorProps = {
      placeholder,
      options,
      onChange,
      isMulti,
      isDisabled,
      isClearable,
      value,
      defaultValue,
      onCreateOption,
      onInputChange,
      menuPosition,
      menuShouldBlockScroll,
    };

    const FieldControl = ({ isError, id }: FormFieldControlProps) => (
      <CreatableSelector<Option, IsMulti, Group>
        inputId={id}
        error={isError}
        backspaceRemovesValue={false}
        menuPlacement="auto"
        {...selectorProps}
      />
    );

    return <FormField control={FieldControl} {...props} />;
  }
);
