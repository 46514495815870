import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

interface Props {
  check: boolean;
}

interface ResponsiveContainerProps {
  showSelectors: boolean;
}

export const Container = styled.div`
  position: relative;
  height: max-content;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding: 14px 15px;

  ${CardSurface}
`;

export const MainFilterContainer = styled(Container)<ResponsiveContainerProps>`
  min-height: ${({ showSelectors }) => (showSelectors ? "300px" : "185px")};
`;

export const MainFilterFallback = styled(CrashPanel)`
  height: 490px;

  @media (max-width: 650px) {
    height: 200px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 0px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 6px;

  transition: all 0.6s;

  @media (max-width: 650px) {
    height: max-content;
  }
`;

export const RealtimeUpdt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 0px;

  & > div:last-of-type {
    margin-left: 10px;
    color: ${({ theme }) => theme.textColor};
  }

  & > div > label {
    white-space: nowrap;
  }
`;

export const Arrow = styled.div<Props>`
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: ${({ check }) => (check ? "rotate(0)" : "rotate(90deg)")};

  ::before {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    left: 6.5px;
    top: 3.5px;
    border-bottom: solid;
    border-right: solid;
    border-width: 2.5px;
    border-color: #767676;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all 0.5s;
  }
`;
