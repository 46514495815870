import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 600px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 8px;

  ${CardSurface}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
