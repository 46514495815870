import { object, string, number } from "yup";
import { UrlParamsSchema } from "src/state/shared/UrlSearchParams/types";
import { UrlParams } from "./types";
import { DashboardV2View } from ".";

const DASHBOARD_VIEWS: DashboardV2View[] = ["balance-summary", "market-info", "pnl", "accounting"];

export const searchParamsValidationSchema: UrlParamsSchema<UrlParams> = object({
  view: string().oneOf(DASHBOARD_VIEWS),
  exchanges: string(),
  start: number().positive().integer(),
  end: number().positive().integer(),
});
