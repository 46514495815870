import { CSSProperties, ComponentPropsWithoutRef, RefObject, useCallback, useRef } from "react";
import { Options, VirtualItem, useVirtual } from "react-virtual";

type Virtualizer = ReturnType<typeof useVirtual>;

interface StyleProps extends Pick<ComponentPropsWithoutRef<"div">, "style" | "className"> {}

interface UseBotsVirtualizerParams extends Pick<Options<HTMLDivElement>, "overscan" | "size"> {
  heights: { mobile: number; desktop: number };
  isMobile: boolean;
  margin?: number;
}

interface UseBotsVirtualizerResponse {
  scrollParentRef: RefObject<HTMLDivElement>;
  virtualizer: Virtualizer;
  getVirtualRowStyles: (row: VirtualItem) => StyleProps;
}

export const useBotsVirtualizer = ({
  isMobile,
  heights: { mobile: mobileHeight, desktop: desktopHeight },
  margin = 10,
  size,
  overscan = 5,
}: UseBotsVirtualizerParams): UseBotsVirtualizerResponse => {
  const scrollParentRef = useRef<HTMLDivElement>(null);

  // use actual height + margin to imitate gaps in an absolutely positioned items list
  const botRowSize = useCallback(
    () => (isMobile ? mobileHeight + margin : desktopHeight + margin),
    [desktopHeight, isMobile, margin, mobileHeight]
  );

  const rowsVirtualizer = useVirtual({
    parentRef: scrollParentRef,
    estimateSize: botRowSize,
    size,
    overscan,
    // compensate for margin after last item with negative padding
    paddingEnd: -margin,
  });

  const getRowStyles = useCallback(
    ({ size, start }: VirtualItem): StyleProps => {
      // adjust for margins when creating row styles
      // so gap becomes visible
      const height = size - margin;
      const styles: CSSProperties = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: `${height}px`,
        transform: `translateY(${start}px)`,
      };
      return { style: styles };
    },
    [margin]
  );

  return {
    scrollParentRef,
    virtualizer: rowsVirtualizer,
    getVirtualRowStyles: getRowStyles,
  };
};
