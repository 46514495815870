import { ComponentPropsWithoutRef, forwardRef } from "react";
import Icons from "src/icons/Icons";
import * as styles from "./style";

export interface SelectorDropdownOwnProps extends styles.SelectorContainerProps {
  title?: string | null;
  open?: boolean;
}

export interface SelectorDropdownProps
  extends Omit<ComponentPropsWithoutRef<"div">, keyof SelectorDropdownOwnProps>,
    SelectorDropdownOwnProps {}

export const SelectorDropdown = forwardRef<HTMLDivElement, SelectorDropdownProps>(
  ({ size, title, open = false, ...props }, ref) => {
    const SelectorTitle =
      size === "small" ? Icons.schedule() : <styles.Title>{title}</styles.Title>;
    return (
      <styles.SelectorContainer size={size} {...props} ref={ref}>
        {SelectorTitle}
        <styles.DropdownIcon $open={open} />
      </styles.SelectorContainer>
    );
  }
);
