import { DropdownButton } from "src/components/shared/Buttons/v2/DropdownButton";
import styled, { css } from "styled-components";

export const BotActionButtonMixin = css`
  width: 120px;
  min-width: initial;

  @media (max-width: 1400px) {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    flex-shrink: 0;
  }
`;

export const TriggerButton = styled(DropdownButton)`
  ${BotActionButtonMixin}
`;
