import { BotStartButton } from "src/components/AllBots/Bots/shared/BotStartButton";
import styled, { css } from "styled-components";
import { ChainInfoChip } from "src/components/BotsContent/shared/Chain/ChainInfoChip";
import { IconWrapper } from "src/components/BotsContent/shared/Chain/ChainInfoChip/style";
import {
  MobFrame as BaseMobFrame,
  Favorite,
  Line,
  Time,
} from "src/components/AllBots/Bots/shared/shared";
import { DEXVersionChip } from "../shared/DEXVersionChip";
import { ModeChip } from "../shared/ModeChip";
import { BotPairButton } from "../../BotPairButton";
import { CellContent, DEXCommonExchange } from "../Bot/style";

export interface ContentProps {
  showDivider?: boolean;
}

const ContentGrid = css<ContentProps>`
  ${({ showDivider }) => {
    const dividerCol = showDivider ? "minmax(5px, 20px)" : "";
    return css`
      grid-template-columns:
        minmax(80px, 165px) minmax(70px, 120px) minmax(30px, 150px)
        minmax(45px, 90px) ${dividerCol} minmax(40px, 40px) auto;
    `;
  }}
`;

export const Content = styled.div<ContentProps>`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  ${ContentGrid}
  gap: 4px;

  grid-template-rows: 100%;

  color: currentColor;
`;

export const Row = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  padding: 10px 0px 0px 25px;
`;

export const MobChainChip = styled(ChainInfoChip)`
  width: 100%;
  height: 100%;

  ${IconWrapper} {
    width: 100%;
    height: 100%;
    > * {
      width: auto;
      height: 100%;
      max-height: 20px;
    }
  }
`;

export const TimeMob = styled(Time)`
  grid-area: auto;
  text-align: center;

  @media (max-width: 750px) {
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 3px;
`;

export const PairMob = styled(BotPairButton)`
  height: 25px;
  margin-bottom: auto;
`;

export const ModesWrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const MobCellContent = styled(CellContent)`
  padding: 0;
  color: unset;
`;

export const ButtonCellContent = styled(MobCellContent)`
  overflow: initial;
`;

export const MobExch = styled(DEXCommonExchange)`
  font-size: ${({ theme }) => theme.fontSizes["14px"]};

  @media (max-width: 750px) {
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["8px"]};
  }
`;

export const MobExchangeCellContent = styled(MobCellContent)`
  gap: 2px;
`;

export const MobDEXCommonVersion = styled(DEXVersionChip)`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};

  @media (max-width: 750px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["8px"]};
  }
`;

export interface MobModeChipProps {
  $isCompact?: boolean;
}

export const MobModeChip = styled(ModeChip).attrs<MobModeChipProps>(({ $isCompact }) => ({
  isMobile: true,
  iconSize: $isCompact ? 20 : 30,
}))<MobModeChipProps>`
  width: auto;
`;

export const Divider = styled(Line).attrs({
  $orientation: "vertical",
  $width: 75,
  $heightPx: 2,
})`
  justify-self: center;
`;

export const FavoriteButton = styled(Favorite)`
  width: 15px;
  height: 15px;

  bottom: 8px;
  left: 9px;
`;

export const MobFrame = styled(BaseMobFrame)`
  z-index: unset;
`;

export const MobStartButton = styled(BotStartButton)``;
