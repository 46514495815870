export type Comparator<T> = NonNullable<Parameters<T[]["sort"]>[0]>;

export type ReversibleComparator<T> = Comparator<T> & {
  reverse: Comparator<T>;
};

export const reverse =
  <T>(comparator: Comparator<T>): Comparator<T> =>
  (a, b) => {
    const result = comparator(a, b);
    return result ? -result : 0;
  };

const stringCompare: Comparator<string> = (a, b) => a.localeCompare(b);

const booleanCompare: Comparator<boolean> = (a, b) => +a - +b;

const strictNumberCompare: Comparator<number> = (a, b) => a - b;

const numberCompare: Comparator<number> = (a, b) =>
  strictNumberCompare(a, b) || +isNaN(a) - +isNaN(b);

const stringNumberCompare: Comparator<string> = (a, b) => numberCompare(+a, +b);

const reversibleComparator = <T>(comparator: Comparator<T>): ReversibleComparator<T> =>
  Object.assign(comparator, { reverse: reverse(comparator) });

export const Comparators = {
  String: reversibleComparator(stringCompare),
  Boolean: reversibleComparator(booleanCompare),
  Number: reversibleComparator(strictNumberCompare),
  StringNumber: reversibleComparator(stringNumberCompare),
} as const;
