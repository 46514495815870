import styled from "styled-components";

export const Container = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  height: 2em;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  :hover {
    border-left: 2px solid;
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;

export const Label = styled.label`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  padding-left: 1em;

  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-align: left;
  vertical-align: middle;
  color: ${({ theme }) => theme.textColor};
  box-sizing: border-box;
`;
