import { observer } from "mobx-react-lite";
import { FC } from "react";
import { GroupBase } from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import * as styles from "src/components/shared/Forms/Selectors/shared/style";
import { SelectorValue } from "src/modules/shared";

export interface CreatableSelectorProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends Omit<CreatableProps<Option, IsMulti, Group>, "theme" | "classNamePrefix">,
    styles.StyledSelectProps {}

export const defaultCreatableSelectorProps = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  placeholder = "",
  isClearable = false,
  ...props
}: CreatableSelectorProps<Option, IsMulti, Group>): CreatableSelectorProps<
  Option,
  IsMulti,
  Group
> => ({ placeholder, isClearable, ...props });

export const CreatableSelector = observer(
  <
    Option = SelectorValue,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: CreatableSelectorProps<Option, IsMulti, Group>
  ) => {
    const propsWithDefaults = defaultCreatableSelectorProps(props);
    return (
      <styles.CustomSelect<FC<CreatableProps<Option, IsMulti, Group>>>
        as={CreatableSelect}
        {...propsWithDefaults}
      />
    );
  }
);
