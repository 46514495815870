import { nanoid } from "nanoid";
import { useMemo } from "react";
import { ChildrenProps } from "src/helpers/utils";
import { MenuItem } from "../MenuItem";
import * as styles from "./style";

export interface SubMenuProps extends ChildrenProps {
  title: string;
}

export const SubMenu = ({ children, title }: SubMenuProps) => {
  const menuId = useMemo(() => {
    const id = nanoid(4);
    return `sub-menu-tooltip-${id}`;
  }, []);

  return (
    <>
      <MenuItem data-tooltip-id={menuId}>{title}</MenuItem>
      <styles.SubMenuContainer id={menuId}>
        <styles.SubMenuList>{children}</styles.SubMenuList>
      </styles.SubMenuContainer>
    </>
  );
};
