import { createContextProvider } from "src/context/utils/createContextProvider";
import useLocalStore from "src/hooks/useLocalStore";
import ExchangeAccountingStore from "src/state/CEX/accounting";
import { useQueryUrlParams } from "src/hooks/useQueryUrlParams";

const useExchangeAccountingStore = () => {
  const searchParamsProps = useQueryUrlParams();
  return useLocalStore(ExchangeAccountingStore, searchParamsProps);
};

export const ExchangeAccountingContext = createContextProvider({
  stateCreator: useExchangeAccountingStore,
});
