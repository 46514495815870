import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";

export interface ListItemProps extends SelectedProps {}

export type SelectedProps = {
  selected?: boolean;
};

const SelectedMixin = css<SelectedProps>`
  ${({ selected }) => {
    if (!selected)
      return css`
        border: 1px solid transparent;
      `;
    return css`
      color: ${({ theme }) => theme.primaryColor};
      background: ${({ theme }) => hexToRgb(theme.primaryColor, 0.1)};
      border: 1px solid ${({ theme }) => hexToRgb(theme.primaryColor, 0.13)};
    `;
  }}
`;

export const ListItem = styled.li<ListItemProps>`
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 6px;

  padding: 8px 13px 6px;

  border-radius: ${({ theme }) => theme.radii["4px"]};
  background: ${({ theme }) => theme.bgGrayBlack};
  color: ${({ theme }) => theme.inputTextColor};
  cursor: pointer;

  ${SelectedMixin}
`;

const EllipsisTextMixin = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Name = styled.span`
  margin: 0;
  padding: 0;

  max-width: 70%;

  flex-shrink: 0;

  color: currentColor;
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};

  ${EllipsisTextMixin}
`;

export interface CreatedAtProps extends SelectedProps {}

export const CreatedAt = styled.time<CreatedAtProps>`
  margin: 0;
  padding: 0;

  color: ${({ theme, selected }) => (selected ? "#7f95b3" : theme.disableInputColor)};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};

  ${EllipsisTextMixin}
`;
