import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from "src/api";
import { buildQueryUrl } from "src/helpers/url";
import { ICEXBot, RequestRecentParty } from "src/modules/bots";
import { BotsListSelectorValue } from "src/state/shared/types/botListsTypes";

interface CEXBotsDTO {
  allClients: BotsListSelectorValue[];
  allExchanges: BotsListSelectorValue[];
  arrayBots: ICEXBot[];
  isError: boolean;
  message: string;
}

export const getCEXBots = async (party?: string) => {
  const url = buildQueryUrl("get-bots", { party });

  return ResponseHandler.handler<CEXBotsDTO>({
    url: apiUrl(ApiModules.Empty, url),
    method: "GET",
  });
};

export const getCEXBot = async (botUUID: string) =>
  ResponseHandler.handler<ICEXBot>(
    {
      url: apiUrl(ApiModules.Bot, `${botUUID}/get-bot`),
      method: "GET",
    },
    "getCEXBot"
  );

export const getWatchList = async () =>
  ResponseHandler.handler(
    {
      url: apiUrl(ApiModules.WatchList, ""),
      method: "GET",
    },
    "getWatchList"
  );

export const getRecentParties = async () =>
  ResponseHandler.handler<RequestRecentParty[]>(
    {
      url: apiUrl(ApiModules.Empty, "get-bots-latest"),
      method: "GET",
    },
    "getRecentParties"
  );

export interface FloatingOrdersChartPoint {
  vl: number;
  vw: number;
}

export interface FloatingOrdersChartData {
  buy: FloatingOrdersChartPoint[];
  sell: FloatingOrdersChartPoint[];
}

export const getBotFloatingVWAP = async (botUUID: string) =>
  ResponseHandler.handler<FloatingOrdersChartData>({
    url: apiUrl(ApiModules.Bot, `/floating/${botUUID}/vwap`),
    method: "GET",
  });
