import { object, string } from "yup";
import { UrlParamsSchema, QueryParams } from "src/state/shared/UrlSearchParams/types";
import { TabsType } from "src/state/expertSystem";

export interface UrlParams extends QueryParams {
  currentTab: TabsType;
}

const TABS: TabsType[] = ["STRATEGIES", "MODULES"];

export const searchParamsValidationSchema: UrlParamsSchema<UrlParams> = object().shape({
  currentTab: string().oneOf(TABS),
});
