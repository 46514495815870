import { LinkProps, useLocation } from "react-router-dom";
import * as styles from "./style";

export interface BreadCrumbLinkProps extends Omit<LinkProps, "replace"> {
  children: string;
}

export const BreadCrumbLink = ({ children: name, to, ...props }: BreadCrumbLinkProps) => {
  const location = useLocation();

  const shouldReplace = location.pathname === to;

  // use same location if replace should occur to preserve current url with all params
  const toOrCurrentLocation = shouldReplace ? location : to;

  return (
    <styles.CrumbLink to={toOrCurrentLocation} replace={shouldReplace} {...props}>
      {name}
    </styles.CrumbLink>
  );
};
