import { Headline3 } from "src/components/shared/Typography";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";
import { ButtonsContainer } from "../DateTimeRangePickerButtons/style";
import { ShortcutsList } from "../PickerShortcuts/style";

export const Container = styled.div`
  width: 100%;
  max-width: 600px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 14px;

  padding: 8px;

  ${CardSurface}

  ${ButtonsContainer} {
    flex-direction: column;
  }

  ${ShortcutsList} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ShortcutsTitle = styled(Headline3)``;

export const ShortcutsTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
`;
