import { isEmptyOrUndefined } from "src/helpers/utils";
import { LabelErrorIcon, LabelErrorIconProps } from "./LabelErrorIcon";
import * as styles from "./style";

export type LabelOwnHtmlProps = Omit<
  React.ComponentPropsWithoutRef<"label">,
  keyof React.ComponentPropsWithoutRef<"div">
>;

export interface LabelProps
  extends React.ComponentPropsWithoutRef<"div">,
    LabelOwnHtmlProps,
    Partial<LabelErrorIconProps> {
  after?: JSX.Element;
}

export const labelErrorFromHint = (id: string, errorHint?: string) => {
  const isError = !isEmptyOrUndefined(errorHint);

  return isError ? { hint: errorHint, id } : undefined;
};

export const Label = ({ error, children, form, htmlFor, after, ...props }: LabelProps) => {
  const isError = error !== undefined;

  return (
    <styles.LabelWrapper {...props}>
      <styles.Label error={isError} form={form} htmlFor={htmlFor}>
        {children}
      </styles.Label>
      {after}
      {isError ? <LabelErrorIcon error={error} /> : null}
    </styles.LabelWrapper>
  );
};
