import dayjs, { ManipulateType } from "dayjs";
import { PickerShortcutsItem } from "../../DateTimeRangePicker/PickerShortcuts";
import { DateTimeRange } from "../models/dateTimeRange";

export const getShortcutInterval = (value: number, unit: ManipulateType): DateTimeRange => {
  const today = dayjs().utc();
  return [today.subtract(value, unit), today];
};

export const defaultShortcutsItems: PickerShortcutsItem[] = [
  {
    label: "Last 5 minutes",
    getValue: () => getShortcutInterval(5, "minutes"),
  },
  {
    label: "Last 15 minutes",
    getValue: () => getShortcutInterval(15, "minutes"),
  },
  {
    label: "Last 30 minutes",
    getValue: () => getShortcutInterval(30, "minutes"),
  },
  {
    label: "Last hour",
    getValue: () => getShortcutInterval(1, "hour"),
  },
  {
    label: "Last 12 hour",
    getValue: () => getShortcutInterval(12, "hour"),
  },
  {
    label: "Last 24 hour",
    getValue: () => getShortcutInterval(24, "hour"),
  },
  {
    label: "Last week",
    getValue: () => getShortcutInterval(1, "week"),
  },
  {
    label: "Last month",
    getValue: () => getShortcutInterval(1, "month"),
  },
];
