import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "../../hooks/useLateInitContext";

interface ProviderProps<T> {
  stateCreator: () => T;
}

export const createContextProvider = <T,>({ stateCreator }: ProviderProps<T>) => {
  const Context = createLateInitContext<T>();

  const Provider = ({ children }: ChildrenProps) => {
    const state = stateCreator();

    return <Context.Provider value={state}>{children}</Context.Provider>;
  };

  return { Context, Provider };
};
