import { SeparatorNum } from "src/components/shared/Separator";
import { BaseHeadline } from "src/components/shared/Typography";
import styled from "styled-components";

import { Tabs } from "src/components/shared/Tabs";
import { Panel } from "src/components/shared/Tabs/style";
import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { PageContainer } from "../../shared/shared";

interface Props {
  currentTab?: any;
}

export const AddressLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  min-width: 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;

  ${BaseHeadline}

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  font-family: "Roboto", monospace;
  font-weight: ${({ theme }) => theme.fontWeights.normal};

  color: #328ee3;

  cursor: pointer;
`;

export const BalancesCell = styled(SeparatorNum)`
  width: 100%;
  height: 100%;
  display: block;
`;

export const Content = styled(PageContainer)`
  flex-direction: ${({ currentTab }: Props) =>
    currentTab === "STATS" || currentTab === "EXCHANGE" ? "row" : "column"};
  background-color: inherit;
  align-items: center;
  flex: 1;

  @media (max-width: 376px) {
    height: 100%;
  }
`;

export const PanelTabs = styled(Tabs)`
  ${Panel} {
    margin-bottom: 10px;
  }
`;

export const NotesFallback = styled(CrashPanel)`
  grid-area: notes;
`;
