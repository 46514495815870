import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { checkAbility, checkParty } from "src/helpers/checkParty";
import { IAbilitiesProvider } from "src/state/User";
import { AbilityType, IAbilitiesParams, IAbilitiesVerifier } from "./types";

export class AbilitiesStore implements IAbilitiesVerifier {
  private _party: string | null = null;

  private _abilitiesProvider: IAbilitiesProvider;

  constructor({ abilitiesProvider }: IAbilitiesParams) {
    makeAutoObservable(this, { hasAbility: false });

    this._abilitiesProvider = abilitiesProvider;
  }

  get abilities() {
    return this._abilitiesProvider.abilities;
  }

  setParty = (party: string | null) => {
    this._party = party;
  };

  resetParty = () => {
    this._party = null;
  };

  get party() {
    return this._party || "";
  }

  private get _isPartyLayer() {
    return !!this._party;
  }

  hasAbility = computedFn((ability: AbilityType) => {
    if (!this._isPartyLayer) return checkAbility(this.abilities, ability);

    const { party } = this;

    if (!party) {
      return false;
    }

    return checkParty(this.abilities, ability, party);
  });
}
