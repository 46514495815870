import { makeAutoObservable } from "mobx";
import {
  GetBotModeStatus,
  GetBotStatusResponse,
  GetBotTotalStatus,
  getBotStatus,
} from "src/api/bots/DEXV2/bots";
import { startBot, stopBot } from "src/api/bots/DEXV2/settings";
import {
  DEXCommonBotsColorStatus,
  ListDEXCommonBot,
} from "src/components/AllBots/Bots/shared/DEXCommon";
import { toast } from "src/components/shared/Toaster";
import { toChainId } from "src/config/chains";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable, Mapper } from "src/helpers/utils";
import {
  DEXV2Bot,
  DEXCommonBotMode,
  DEXCommonBotModeStatus,
  DEXCommonBotStatus,
  DEXCommonCounterInfo,
  DEXCommonExchangeVersion,
  DEXCommonLimitInfo,
  DEXCommonVolumeInfo,
} from "src/modules/bots";
import WindowConsent from "src/state/WindowConsent";
import { ChainMetaMap, IChainMetaProvider } from "src/state/chain/ChainsInfo/ChainConfigs/types";

interface RawModeInfo {
  status?: GetBotModeStatus;
  nextTrade?: number;
  lastTrade?: number;
  message?: string;
}

export interface ModeInfo {
  status: GetBotModeStatus;
  nextTrade: number;
  lastTrade: number;
  message: string;
}

const parseRawTradeTime = (time: number) =>
  // ignore negative trade unix times from api
  Math.max(time, 0);
const parseRawModeInfo = ({
  status = "stopped",
  nextTrade = 0,
  lastTrade = 0,
  message = "",
}: RawModeInfo): ModeInfo => ({
  status,
  message,
  nextTrade,
  lastTrade,
});

const botModeStatusRespToStatus = (status: GetBotModeStatus): DEXCommonBotModeStatus => {
  switch (status) {
    case "error": {
      return DEXCommonBotModeStatus.Error;
    }
    case "running": {
      return DEXCommonBotModeStatus.Running;
    }
    case "stopped": {
      return DEXCommonBotModeStatus.Stopped;
    }
  }
};

const getLimitModeInfo = ({
  status: modeStatus,
  nextTrade,
  lastTrade,
  message,
}: ModeInfo): DEXCommonLimitInfo => {
  const status = botModeStatusRespToStatus(modeStatus);
  return {
    limitStatus: status,
    limitNextTrade: parseRawTradeTime(nextTrade),
    limitLastTrade: parseRawTradeTime(lastTrade),
    limitMessage: message,
  };
};

const getVolumeModeInfo = ({
  status: modeStatus,
  nextTrade,
  lastTrade,
  message,
}: ModeInfo): DEXCommonVolumeInfo => {
  const status = botModeStatusRespToStatus(modeStatus);
  return {
    volumeStatus: status,
    volumeNextTrade: parseRawTradeTime(nextTrade),
    volumeLastTrade: parseRawTradeTime(lastTrade),
    volumeMessage: message,
  };
};

const getCounterModeInfo = ({
  status: modeStatus,
  message,
  lastTrade,
}: ModeInfo): DEXCommonCounterInfo => {
  const status = botModeStatusRespToStatus(modeStatus);
  return {
    counterStatus: status,
    counterMessage: message,
    counterLastTrade: lastTrade ? parseRawTradeTime(lastTrade) : undefined,
  };
};

type DEXV2BotModeMap = {
  counter: DEXCommonCounterInfo;
  limit: DEXCommonLimitInfo;
  volume: DEXCommonVolumeInfo;
};

const getModeInfo = <M extends DEXCommonBotMode>(
  rawInfo: RawModeInfo,
  mode: M
): DEXV2BotModeMap[M] => {
  const info = parseRawModeInfo(rawInfo);

  let modeInfo: DEXV2BotModeMap[DEXCommonBotMode];
  switch (mode) {
    case "counter": {
      modeInfo = getCounterModeInfo(info);
      break;
    }
    case "limit": {
      modeInfo = getLimitModeInfo(info);
      break;
    }
    case "volume": {
      modeInfo = getVolumeModeInfo(info);
      break;
    }
    default: {
      throw Error("unknown mode type");
    }
  }

  return modeInfo as DEXV2BotModeMap[M];
};

const botTotalStatusRespToStatus = (status: GetBotTotalStatus): DEXCommonBotStatus => {
  switch (status) {
    case "warning": {
      return DEXCommonBotStatus.Warning;
    }
    case "error": {
      return DEXCommonBotStatus.Error;
    }
    case "running": {
      return DEXCommonBotStatus.Running;
    }
    case "stopped": {
      return DEXCommonBotStatus.Stopped;
    }
    case "stale": {
      return DEXCommonBotStatus.Stale;
    }
  }
};

export const botStatusResponseToDEXV2Bot: Mapper<GetBotStatusResponse, DEXV2Bot> = ({
  bot_id,
  exchange,
  party,
  chain_id,
  dex_version,

  volume_descr,
  volume_bot_last_trade_date_unix,
  volume_bot_last_trade_status,
  volume_bot_next_trade_date_unix,

  limit_descr,
  limit_bot_last_trade_date_unix,
  limit_bot_last_trade_status,
  limit_bot_next_trade_date_unix,

  counter_bot_last_trade_date_unix,
  counter_bot_last_trade_status,
  counter_bot_message,
  counter_descr,

  ref,
  quote_ticker,
  base_ticker,
  base_addr,
  quote_addr,
  pair_addr,
  bot_name,
  deployed,
  total_status,
}: GetBotStatusResponse) => {
  const limitModeInfo = getModeInfo(
    {
      status: limit_descr,
      nextTrade: limit_bot_next_trade_date_unix,
      lastTrade: limit_bot_last_trade_date_unix,
      message: limit_bot_last_trade_status,
    },
    "limit"
  );

  const volumeModeInfo = getModeInfo(
    {
      status: volume_descr,
      nextTrade: volume_bot_next_trade_date_unix,
      lastTrade: volume_bot_last_trade_date_unix,
      message: volume_bot_last_trade_status,
    },
    "volume"
  );

  const counterModeInfo = getModeInfo(
    {
      status: counter_descr,
      message: counter_bot_last_trade_status ?? counter_bot_message,
      lastTrade: counter_bot_last_trade_date_unix,
    },
    "counter"
  );

  const botStatus = botTotalStatusRespToStatus(total_status);

  return {
    name: bot_name,
    bot_uuid: bot_id,
    exchange,
    party,
    chain_id,
    link: ref,
    quote: quote_ticker,
    quote_addr,
    base: base_ticker,
    base_addr,
    pair_addr,
    dex_version,
    ...limitModeInfo,
    ...volumeModeInfo,
    ...counterModeInfo,
    isStopped: !deployed,
    status: botStatus,
  };
};

const getBotColorStatus = (status: DEXCommonBotStatus) => {
  switch (status) {
    case DEXCommonBotStatus.Stopped: {
      return DEXCommonBotsColorStatus.Gray;
    }
    case DEXCommonBotStatus.Error: {
      return DEXCommonBotsColorStatus.Red;
    }
    case DEXCommonBotStatus.Warning: {
      return DEXCommonBotsColorStatus.Yellow;
    }
    case DEXCommonBotStatus.Stale: {
      return DEXCommonBotsColorStatus.Blue;
    }
    case DEXCommonBotStatus.Running: {
      return DEXCommonBotsColorStatus.Green;
    }
  }
};

export const DEXV2BotToListDEXV2Bot = (
  bot: DEXV2Bot,
  chainMetaMap: ChainMetaMap
): ListDEXCommonBot => {
  const { chain_id, base, quote, status } = bot;
  const colorStatus = getBotColorStatus(status);
  const chainId = toChainId(chain_id);
  return {
    ...bot,
    colorStatus,
    pair: `${quote}_${base}`,
    chainMeta: chainMetaMap[chainId] ?? null,
    isWatched: false,
  };
};

export const INITIAL_DEX_V2_BOT: DEXV2Bot = {
  base: "",
  exchange: "",
  link: "",
  party: "",
  quote: "",
  bot_uuid: "",
  name: "",
  chain_id: 0,
  base_addr: "",
  quote_addr: "",
  pair_addr: "",
  isStopped: false,
  dex_version: DEXCommonExchangeVersion.V2,
  status: DEXCommonBotStatus.Stopped,

  limitStatus: DEXCommonBotModeStatus.Stopped,
  limitMessage: "",
  limitNextTrade: 0,
  limitLastTrade: 0,

  volumeStatus: DEXCommonBotModeStatus.Stopped,
  volumeMessage: "",
  volumeNextTrade: 0,
  volumeLastTrade: 0,

  counterStatus: DEXCommonBotModeStatus.Stopped,
  counterMessage: "",
  counterLastTrade: 0,
};

export const INITIAL_LIST_DEX_V2_BOT: ListDEXCommonBot = {
  ...INITIAL_DEX_V2_BOT,
  colorStatus: DEXCommonBotsColorStatus.Gray,
  pair: "",
  isWatched: false,
  chainMeta: null,
};

const BOT_FETCHING_INTERVAL = 5000;

export default class DEXV2BotInfoStore implements IDisposable {
  private _bot: ListDEXCommonBot = INITIAL_LIST_DEX_V2_BOT;

  private _botUUID = "";

  _intervalHandler?: ReturnType<typeof setInterval>;

  private _chainMetaProvider: IChainMetaProvider;

  constructor(chainMetaProvider: IChainMetaProvider) {
    makeAutoObservable(this);

    this._chainMetaProvider = chainMetaProvider;

    makeLoggable(this, { bot: true });
  }

  private _setBot(bot: DEXV2Bot) {
    this._bot = DEXV2BotToListDEXV2Bot(bot, this._chainMetaProvider.chainMetaMap);
  }

  get bot() {
    return this._bot;
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  _fetchBot = async () => {
    try {
      const { isError, data } = await getBotStatus(this._botUUID);

      if (!isError) {
        const bot = botStatusResponseToDEXV2Bot(data[0]);
        this._setBot(bot);
      }
    } catch (err) {
      logError(err);
    }
  };

  toggleBot = () => {
    if (this._bot.isStopped) {
      this.startBot();
    } else {
      this.stopBot();
    }
  };

  startBot = () => {
    WindowConsent.showWindow("Are you sure?", "This action will start the bot.", this._startBot);
  };

  private _startBot = async () => {
    try {
      const { isError } = await startBot(this._botUUID);

      if (!isError) {
        toast.success("Bot started");
      }
    } catch (err) {
      logError(err);
    }
  };

  stopBot = () => {
    WindowConsent.showWindow("Are you sure?", "This action will stop the bot.", this._stopBot);
  };

  private _stopBot = async () => {
    try {
      const { isError } = await stopBot(this._botUUID);

      if (!isError) {
        toast.success("Bot stopped");
      }
    } catch (err) {
      logError(err);
    }
  };

  resumeBotFetching = () => {
    this._fetchBot();
    this._intervalHandler = setInterval(() => this._fetchBot(), BOT_FETCHING_INTERVAL);
  };

  suspendBotFetching = () => {
    clearInterval(this._intervalHandler);
    this._intervalHandler = undefined;
  };

  destroy = () => {};
}
