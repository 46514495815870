import { LayerType } from "src/components/NavigationMenu";
import { MenuItem, MenuItemProps } from "./MenuItem";
import { IMenuItem } from "./MenuItem/types";
import * as styles from "./style";

interface MenuListProps<T extends LayerType> extends Omit<MenuItemProps<T>, "item"> {
  menuItems: IMenuItem<T>[];
  additionalItems?: IMenuItem<T>[];
}

export const MenuList = <T extends LayerType>({
  menuItems,
  additionalItems,
  ...props
}: MenuListProps<T>) => (
  <styles.MenuList>
    {menuItems.map((el) => (
      <MenuItem key={el.label} item={el} {...props} />
    ))}

    {additionalItems?.length ? (
      <styles.AdditionalSection>
        <styles.SectionTitle>Quick Access</styles.SectionTitle>
        {additionalItems.map((el) => (
          <MenuItem key={el.label} item={el} {...props} />
        ))}
      </styles.AdditionalSection>
    ) : null}
  </styles.MenuList>
);
