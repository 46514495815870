import { autorun, isComputedProp } from "mobx";
import { NoInfer } from "../utils";

const checkComputed = <Store extends object>(store: Store, prop: PropertyKey) => {
  const computed = isComputedProp(store, prop);
  if (!computed) {
    // eslint-disable-next-line no-console
    console.warn(
      `makeComputedCache: property ${String(prop)} is not computed in store: ${
        store.constructor.name
      }. Make it computed or exclude from keys.`
    );
  }

  return computed;
};

export type ComputedKeys<T extends object, AdditionalFields extends PropertyKey> = Array<
  keyof T | AdditionalFields
>;

/**
 * Make unobserved computeds in store cache values
 * @param store - MobX store
 * @param keys - list of unobserved computeds
 * @returns - disposer to stop observing computeds
 */
export const makeComputedCache = <Store extends object, AdditionalKeys extends PropertyKey = never>(
  store: Store,
  keys: ComputedKeys<Store, NoInfer<AdditionalKeys>>
) => {
  const computedKeys = keys.filter((key) => checkComputed(store, key));
  return autorun(() => {
    for (const key of computedKeys) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const touched = store[key as keyof Store];
    }
  });
};
