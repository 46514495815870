import { IMarketInfo } from "src/api/arbitrage/types";

export const enum ArbitrageTerminalModules {
  OrderBookPanel = "order-book-panel",
  OrdersTable = "orders-table",
  BuyBalancesTable = "buy-balances-table",
  SellBalancesTable = "sell-balances-table",
}

/** key - join string market (quote_base_exchange) */
export type MarketMap = Map<string, IMarketInfo>;

export interface SetOrderFromOrderBookProps {
  market: IMarketInfo;
  price: string;
  amount: string;
}

export type PlaceOrderSetter = (orderProps: SetOrderFromOrderBookProps) => void;
