import { makeAutoObservable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import { getAbilities } from "src/api/userManager/abilitiesAPI";
import { deleteHierarchy, getHierarchies } from "src/api/userManager/hierarchyAPI";
import { getParties, removeParty } from "src/api/userManager/partiesAPI";
import { deleteRole, getRoles } from "src/api/userManager/rolesAPI";
import { getScopes } from "src/api/userManager/scopesAPI";
import { deleteUserGroup, getUserGroups } from "src/api/userManager/userGroupsAPI";
import { getUsers, toggleUser } from "src/api/userManager/usersAPI";
import { toast } from "src/components/shared/Toaster";
import { getSelectorList } from "src/helpers/forms/selectors";
import { logError } from "src/helpers/network/logger";
import { IDisposable, arrToObj, filterCallback } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import {
  DeleteHierarchy,
  DeleteParty,
  GROUP_TYPES,
  GroupType,
  Hierarchie,
  Role,
  Scope,
  User,
  UserGroup,
} from "src/modules/userManager";
import SearchStore, { ISearchStore } from "../shared/SearchStore";
import { INITIAL_NEW_USER_GROUP } from "./UserGroups/AddUserGroupStore";

type Loaders =
  | "isLoadingParties"
  | "isLoadingAcc"
  | "isLoadingBot"
  | "isLoadingSwapBot"
  | "isLoadingRoles"
  | "isLoadingScopes"
  | "isLoadingUsers"
  | "isLoadingUserGroups"
  | "isLoadingHierarchie";

type SelectFilters = "_selectUsers" | "_selectRoles" | "_selectScopes";

export type ShowPanels = {
  showAddUserPanel: boolean;
  showAddRolesPanel: boolean;
  showAddHierarchyPanel: boolean;
  showEditRole: boolean;
  showEditHierarchy: boolean;
};

type TableHierarchy = {
  name: string;
  role: string;
  scope: string;
  id: number;
};

export interface ScopeSelection {
  selected: boolean;
  parties: {
    selected: boolean;
    name: string;
  }[];
  model: Scope;
}

export type UsersMap = Partial<Record<string, User>>;
export type UsersGroupsMap = Partial<Record<string, UserGroup>>;

export const INITIAL_USER: User = {
  name: "",
  email: "",
  is_active: false,
  group_type: "other",
  tg_handler: "",
  group_name: "",
};

export const INITIAL_USER_GROUP: UserGroup = {
  ...INITIAL_NEW_USER_GROUP,
  users: [],
  id: 0,
};

const ALL_SCOPES: Scope = {
  name: "All Scopes",
  parent_id: 0,
  scope_id: 0,
  parties: [],
  full_name: "All Scopes",
};

export class UserManagerStore implements IDisposable {
  newParty = "";

  bots = [];

  swapBots = [];

  private _roles: Role[] = [];

  private _parties: string[] = [];

  private _users: User[] = [];

  private _userGroups: UserGroup[] = [];

  private _scopes: Scope[] = [];

  private _hierarchies: Hierarchie[] = [];

  private _abilities: string[] = [];

  usersFilter = "";

  rolesFilter = "";

  userGroupsFilter = "";

  private _scopesSearch: ISearchStore;

  private _selectUsers: string[] = [];

  private _selectRoles: string[] = [];

  private _selectScopes: string[] = [];

  isError = false;

  name = "";

  partyErr = "";

  errors = {
    name: "",
    role: "",
  };

  isLoadingParties = false;

  isLoadingAcc = false;

  isLoadingBot = false;

  isLoadingSwapBot = false;

  isLoadingRoles = false;

  isLoadingScopes = false;

  isLoadingUsers = false;

  isLoadingUserGroups = false;

  isLoadingHierarchie = false;

  visiblePanel: ShowPanels = {
    showAddUserPanel: false,
    showAddRolesPanel: false,
    showAddHierarchyPanel: false,
    showEditRole: false,
    showEditHierarchy: false,
  };

  currentEditUser: string = "";

  editableRole: Role = {
    name: "",
    abilities: [],
  };

  constructor() {
    this._scopesSearch = new SearchStore({ waitTime: 300 });

    makeAutoObservable(this);
  }

  private _setUserGroups(userGroups: UserGroup[]) {
    this._userGroups = userGroups;
  }

  setPartyName = (value: string) => {
    this.name = value;
  };

  setCurrentEditUser = (userName: string) => {
    this.currentEditUser = userName;
  };

  setEditableRole = (role: Role) => {
    this.editableRole = role;
  };

  setLoading = (field: Loaders, value: boolean) => {
    this[field] = value;
  };

  loadData = () => {
    this.getPartiesList();
    this.getRolesList();
    this.getScopesList();
    this.getUsersList();
    this.getUserGroupsList();
    this.getHierarchieList();
    this.getAbilitiesList();
  };

  setUsersFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.usersFilter = e.target.value;
  };

  setRolesFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.rolesFilter = e.target.value;
  };

  setUserGroupsFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.userGroupsFilter = e.target.value;
  };

  setScopesFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    this._scopesSearch.onSearchTextChange(e);
  };

  get scopesSearchDebouncedText() {
    return this._scopesSearch.debouncedSearchText;
  }

  get scopesSearchText() {
    return this._scopesSearch.searchText;
  }

  setErrParty = (text: string) => {
    this.partyErr = text;
  };

  setSelectFilters = (key: SelectFilters, data: SelectorValue[]) => {
    this[key] = [];

    for (const el of data) {
      this[key].push(String(el.value));
    }
  };

  resetSelectFilters = () => {
    this._selectUsers = [];
    this._selectRoles = [];
    this._selectScopes = [];
  };

  isActiveSort = (userActive1: boolean, userActive2: boolean) => {
    if ((userActive1 && userActive2) || (!userActive1 && !userActive2)) {
      return 0;
    }
    if (userActive1 && !userActive2) {
      return -1;
    }
    if (!userActive1 && userActive2) {
      return 1;
    }

    return 0;
  };

  get parties() {
    return this._parties.map((el) => ({ value: el, label: el }));
  }

  get users() {
    return this._users
      .map(({ email, ...el }) => ({
        email: email.replace(/(,)/g, "$1 "),
        ...el,
      }))
      .filter(({ name }) => filterCallback(name, this.usersFilter))
      .sort((a, b) => this.isActiveSort(a.is_active, b.is_active));
  }

  get usersMap(): UsersMap {
    return arrToObj(this.users, (user) => user.name);
  }

  getUserByName(name: string): User {
    return this.usersMap[name] ?? INITIAL_USER;
  }

  usersByType = computedFn((type: GroupType) =>
    this._users.filter((user) => user.group_type === type)
  );

  usersByGroupType = computedFn((type: GroupType) => {
    switch (type) {
      case "admin":
      case "client": {
        return this.usersByType(type);
      }
      case "other": {
        return this._users;
      }
    }
  });

  get userGroups() {
    return this._userGroups.filter(({ name }) => filterCallback(name, this.userGroupsFilter));
  }

  get userGroupsMap(): UsersGroupsMap {
    return arrToObj(this._userGroups, (group) => group.name);
  }

  getUserGroupByName(name: string): UserGroup {
    return this.userGroupsMap[name] ?? INITIAL_USER_GROUP;
  }

  userGroupsByType = computedFn((type: GroupType) =>
    this._userGroups.filter((group) => group.type === type)
  );

  userGroupsByUserType = computedFn((type: GroupType) => {
    switch (type) {
      case "admin":
      case "client": {
        return this.userGroupsByType(type).concat(this.userGroupsByType("other"));
      }
      case "other": {
        return this.userGroupsByType(type);
      }
    }
  });

  get roles() {
    return this._roles
      .map(({ abilities, ...otherParams }) => ({
        amount: abilities ? abilities.length : 0,
        abilities,
        ...otherParams,
      }))
      .filter(({ name }) => filterCallback(name, this.rolesFilter));
  }

  get hierarchy(): TableHierarchy[] {
    return this._hierarchyFilters(
      this._hierarchies.map(({ user, scope, hierarchy }) => ({
        name: user.name,
        role: hierarchy.role,
        scope: scope.full_name,
        id: hierarchy.hierarchy_id,
      }))
    );
  }

  get usersSelect() {
    return this._users.map(({ name }) => ({ value: name, label: name }));
  }

  get rolesSelect() {
    return this._roles.map(({ name }) => ({
      value: name,
      label: name,
    }));
  }

  get scopesSelect() {
    return this._scopes.map(({ full_name, scope_id }) => ({
      value: full_name,
      label: full_name,
      id: scope_id,
    }));
  }

  get groupTypesSelect() {
    return getSelectorList([...GROUP_TYPES]);
  }

  get selectUsers() {
    return this._selectUsers.map((el) => ({ value: el, label: el }));
  }

  get selectRoles() {
    return this._selectRoles.map((el) => ({ value: el, label: el }));
  }

  get selectScopes() {
    return this._selectScopes.map((el) => ({ value: el, label: el }));
  }

  get selectAbilities() {
    return this._abilities.map((el) => ({ value: el, label: el }));
  }

  private _adjList = <T>(
    scopes: T[],
    getId: (scope: T) => number,
    getParentId: (scope: T) => number | null
  ) => {
    const adjList: Record<number, number[]> = { 0: [] };

    for (const scope of scopes) {
      adjList[getId(scope)] = [];
    }

    for (const scope of scopes) {
      const id = getId(scope);
      const parentId = getParentId(scope);

      if (parentId === null) {
        adjList[0].push(getId(scope));
        continue;
      }

      if (id !== parentId) {
        adjList[parentId].push(id);
      }
    }

    return adjList;
  };

  private _scopesMap = <T>(scopes: T[], getId: (scope: T) => number, rootScope: T) => {
    const graph: Record<number, T> = {};
    if (!scopes.length) return graph;

    graph[0] = rootScope;

    scopes.forEach((scope) => {
      graph[getId(scope)] = scope;
    });

    return graph;
  };

  private get _scopeAdjList() {
    return this._adjList(
      this._scopes,
      (scope) => scope.scope_id,
      (scope) => scope.parent_id
    );
  }

  private _scopeToScopeSelection = (
    scope: Scope,
    selectedFn: (name: string) => boolean = (name) =>
      filterCallback(name, this.scopesSearchDebouncedText)
  ): ScopeSelection => ({
    selected: selectedFn(scope.name),
    parties: scope.parties.map((party) => ({
      name: party,
      selected: selectedFn(party),
    })),
    model: scope,
  });

  private get _scopesSelection() {
    return this._scopes.map((scope) => this._scopeToScopeSelection(scope));
  }

  private get _matchedScopes() {
    return this._scopesSelection.filter(
      ({ selected, parties }) => selected || parties.some(({ selected }) => selected)
    );
  }

  private get _matchedScopesIds() {
    return new Set(this._matchedScopes.map(({ model: { scope_id } }) => scope_id));
  }

  private _dfs = (
    adjList: Record<number, number[]>,
    v: number,
    path: number[] = [],
    cbFn?: (v: number, path: number[]) => void
  ) => {
    const marked = new Set<number>();

    const dfs = (adjList: Record<number, number[]>, v: number, path: number[] = []) => {
      cbFn?.(v, path);

      marked.add(v);
      path.push(v);

      adjList[v].forEach((w) => {
        if (!marked.has(w)) {
          dfs(adjList, w, path);
        }
      });

      path.pop();
    };

    dfs(adjList, v, path);
  };

  private get _matchedScopesPathsIds() {
    const scopesPathsIds = new Set<number>();

    const currentPath: number[] = [];
    const root = 0;

    this._dfs(this._scopeAdjList, root, currentPath, (v, path) => {
      if (!this._matchedScopesIds.has(v)) {
        return;
      }

      path
        .filter((w) => w !== root)
        .forEach((w) => {
          scopesPathsIds.add(w);
        });
    });

    return scopesPathsIds;
  }

  private get _matchedScopesPaths() {
    return this._scopesSelection
      .filter(
        ({ model: { scope_id: id } }) =>
          this._matchedScopesIds.has(id) || this._matchedScopesPathsIds.has(id)
      )
      .map((scope) => {
        if (this.scopesSearchDebouncedText === "") {
          return this._scopeToScopeSelection(scope.model, () => false);
        }
        return scope;
      });
  }

  get scopeGraph() {
    return this._scopesMap(this._matchedScopesPaths, (scope) => scope.model.scope_id, {
      selected: false,
      parties: [],
      model: ALL_SCOPES,
    });
  }

  get scopeAdjList() {
    return this._adjList(
      this._matchedScopesPaths,
      (scope) => scope.model.scope_id,
      (scope) => scope.model.parent_id
    );
  }

  openPanel =
    <K extends keyof ShowPanels>(key: K) =>
    (bool: boolean) =>
      runInAction(() => {
        this.visiblePanel[key] = bool;
      });

  private _setParties = (parties: string[]) => {
    this._parties = parties;
  };

  getPartiesList = async () => {
    this.setLoading("isLoadingParties", true);
    try {
      const { data, isError } = await getParties();

      if (!isError) {
        this._setParties(data);

        return;
      }

      this._setParties([]);
    } catch {
      this._setParties([]);
    } finally {
      this.setLoading("isLoadingParties", false);
    }
  };

  getRolesList = async () => {
    this.setLoading("isLoadingRoles", true);

    runInAction(() => {
      this.visiblePanel.showAddRolesPanel = false;
    });
    runInAction(() => {
      this.visiblePanel.showEditRole = false;
    });

    try {
      const { data, isError } = await getRoles();

      if (!isError) {
        runInAction(() => {
          this._roles = data;
        });
        return;
      }

      this._roles = [];
    } catch {
      this._roles = [];
    } finally {
      this.setLoading("isLoadingRoles", false);
    }
  };

  getScopesList = async () => {
    this.setLoading("isLoadingScopes", true);
    try {
      const { data, isError } = await getScopes();
      if (!isError) {
        runInAction(() => {
          this._scopes = data;
        });
        return;
      }
      this._scopes = [];
    } catch {
      this._scopes = [];
    } finally {
      this.setLoading("isLoadingScopes", false);
    }
  };

  getUsersList = async () => {
    this.setLoading("isLoadingUsers", true);

    runInAction(() => {
      this.visiblePanel.showAddUserPanel = false;
    });

    try {
      const { data, isError } = await getUsers();

      if (!isError) {
        runInAction(() => {
          this._users = data;
        });

        return;
      }

      this._users = [];
    } catch {
      this._users = [];
    } finally {
      this.setLoading("isLoadingUsers", false);
    }
  };

  getUserGroupsList = async () => {
    this.setLoading("isLoadingUserGroups", true);

    try {
      const { data, isError } = await getUserGroups();

      if (!isError) {
        this._setUserGroups(data);
      } else {
        this._setUserGroups([]);
      }
    } catch {
      this._setUserGroups([]);
    } finally {
      this.setLoading("isLoadingUserGroups", false);
    }
  };

  getHierarchieList = async () => {
    this.setLoading("isLoadingHierarchie", true);

    try {
      const { data, isError } = await getHierarchies();

      if (!isError) {
        runInAction(() => {
          this._hierarchies = data;
        });
        return;
      }

      this._hierarchies = [];
    } catch {
      this._hierarchies = [];
    } finally {
      this.setLoading("isLoadingHierarchie", false);
    }
  };

  getAbilitiesList = async () => {
    try {
      const { data, isError } = await getAbilities();

      if (!isError) {
        runInAction(() => {
          this._abilities = data;
        });
        return;
      }

      this._abilities = [];
    } catch {
      this._abilities = [];
    }
  };

  private _toggleActiveUser = (user: User) => {
    runInAction(() => {
      // eslint-disable-next-line no-param-reassign
      user.is_active = !user.is_active;
    });
  };

  toggleActiveUser = async (user: User, active: boolean) => {
    this._toggleActiveUser(user);

    try {
      const { isError } = await toggleUser(user.name, active);

      if (!isError) {
        this.getUsersList();
        return;
      }

      this._toggleActiveUser(user);
    } catch {
      this._toggleActiveUser(user);
    }
  };

  removeUserGroup = async (groupName: string) => {
    try {
      const { isError } = await deleteUserGroup(groupName);

      if (!isError) {
        toast.success("User group deleted successfully");
        this.getUserGroupsList();
      }
    } catch (err) {
      logError(err);
    }
  };

  removeRole = async (nameRole: string) => {
    try {
      const { isError } = await deleteRole(nameRole);

      if (!isError) {
        this.getRolesList();
      }
    } catch (err) {
      logError(err);
    }
  };

  removeHierarchy = async (deleteData: DeleteHierarchy) => {
    try {
      const { isError } = await deleteHierarchy(deleteData);
      if (!isError) {
        this.getHierarchieList();
      }
    } catch (err) {
      logError(err);
    }
  };

  removeParty = async (party: DeleteParty) => {
    try {
      const { isError } = await removeParty(party);

      if (!isError) {
        toast.success("Party detached successfully");

        this.getScopesList();
      }
    } catch (err) {
      logError(err);
    }
  };

  private _hierarchyFilters = (hierarchies: TableHierarchy[]) =>
    hierarchies
      .filter(({ name }) => this._hierarchyFilter("_selectUsers", name))
      .filter(({ role }) => this._hierarchyFilter("_selectRoles", role))
      .filter(({ scope }) => this._hierarchyFilter("_selectScopes", scope));

  private _hierarchyFilter = (key: SelectFilters, value: string) => {
    if (!this[key].length) return true;
    switch (key) {
      case "_selectScopes": {
        return this._findScope(value);
      }
      default: {
        return this._findEl(key, value);
      }
    }
  };

  private _findEl = (key: SelectFilters, value: string) => this[key].indexOf(value) !== -1;

  private _findScope = (value: string) => {
    const searchEl = this._selectScopes.find((el) => this._checkEquals(value, el));

    if (searchEl) return true;

    return false;
  };

  private _checkEquals = (value: string, searchEl: string) =>
    // const arrStr = value.split(" ");

    // return value.includes(searchEl);
    value === searchEl;

  destroy = () => {};
}
