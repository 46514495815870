import { ButtonProps } from "src/components/shared/Buttons/v2/Button";
import useMediaQuery from "src/hooks/useMediaQuery";
import Icons from "src/icons/Icons";
import * as styles from "./style";

export interface BotMarkProps extends Omit<ButtonProps, "color"> {
  color?: string;
}

const getMarkSize = (isMobile: boolean, isLaptop: boolean) => {
  if (isMobile) return "s";
  if (isLaptop) return "m";
  return "l";
};

export const BotMark = ({ color, ...props }: BotMarkProps) => {
  const isLaptop = useMediaQuery("(max-width: 1400px)");
  const isMobile = useMediaQuery("(max-width: 850px)");

  const size = getMarkSize(isMobile, isLaptop);
  return (
    <styles.StyledMark
      $accentColor={color}
      endIcon={Icons.gotbit()}
      size={size}
      elevated={false}
      {...props}
    />
  );
};
