import { ApiModules, apiUrl } from "src/api";
import { StartCEXBot } from "src/modules/bots";
import { DecompressionPoint, PairDecimalsResponse } from "src/modules/settings";
import { CEXSettingsUpdateData } from "src/state/CEX/CEXSettings/types";
import ResponseHandler from "src/state/network/ResponseHandler";
import { CEXSettings } from "src/state/shared/CEX/types/settings";

export const createBot = async (data: StartCEXBot, party: string, startConfig: boolean) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `${party}/bot?start=${startConfig}`),
    method: "POST",
    data,
  });

export const getBotSettings = async (botUUID: string) =>
  ResponseHandler.handler<CEXSettings>({
    url: apiUrl(ApiModules.Bot, `${botUUID}/settings`),
    method: "GET",
  });

export const setBotSettings = async (botUUID: string, data: CEXSettingsUpdateData) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${botUUID}/settings`),
    method: "PUT",
    data,
  });

export const getDecimals = async (accountUUID: string, pair: string, exchange: string) =>
  ResponseHandler.handler<PairDecimalsResponse>({
    url: apiUrl(ApiModules.Terminal, "get-decimals"),
    method: "POST",
    data: {
      account_uuid: accountUUID,
      pair,
      exchange,
    },
  });

export const getConstraints = async (botUUID: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `/${botUUID}/rules`),
    method: "GET",
  });

export const getSpreadPeriods = async (botUUID: string, period: number) => {
  const stopPoint = Math.round(Date.now() / 1000);

  return ResponseHandler.handler<DecompressionPoint[]>({
    url: apiUrl(
      ApiModules.Bot,
      `${botUUID}/spread-decompressions?start=${stopPoint - period}&stop=${stopPoint}`
    ),
    method: "GET",
  });
};

export const getVerifyPeriods = async (botUUID: string, period: number) => {
  const stopPoint = Math.round(Date.now() / 1000);

  return ResponseHandler.handler<DecompressionPoint[]>({
    url: apiUrl(
      ApiModules.Bot,
      `${botUUID}/verify-orders?start=${stopPoint - period}&stop=${stopPoint}`
    ),
    method: "GET",
  });
};

export const startBot = async (botUUID: string): Promise<any> =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${botUUID}/start`),
    method: "POST",
  });

export const stopBot = async (botUUID: string): Promise<any> =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${botUUID}/stop`),
    method: "POST",
  });
