import { Fragment } from "react";
import * as styles from "./style";

export interface ChainMissingModalProps {}

export const createChainMissingModalTitle = (newChainId: number) =>
  `Error switching to chainID: ${newChainId}`;

export const toMultilineMessage = (lines: Array<JSX.Element | string>) => {
  const multilineMessage = lines.map((line, index) => {
    const lastLine = index === lines.length - 1;
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>
        {line}
        {lastLine ? null : "\n\n"}
      </Fragment>
    );
  });
  return <>{multilineMessage}</>;
};

export const createChainMissingModalBody = () => {
  const chainLink = (
    <styles.ChainListLink href="https://chainlist.org/">ChainList</styles.ChainListLink>
  );
  const messageLines = [
    "Make sure you have added this chain info to your wallet,",
    <>You can get required info at: {chainLink}</>,
    "Already added new chain, want to retry?",
  ];
  return toMultilineMessage(messageLines);
};
