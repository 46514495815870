import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable, OmitStrict } from "src/helpers/utils";
import { BotVersionStore, ILatestBotVersionProvider } from "./BotVersion";
import { ChainConfigsStore, IChainsConfigsProvider } from "./ChainConfigs";
import { ChainExchangesStore, IChainExchangesProvider } from "./ChainExchanges";
import { ChainExchangesMap } from "./ChainExchanges/types";
import { ChainQuotersStore, IChainQuotersProvider } from "./ChainQuoters";
import { ChainsStore, IChainsProvider } from "./Chains";
import { ChainInfosMap } from "./Chains/types";
import {
  chainRouterQuotersToChainQuoters,
  filterSupportedChainExchanges,
  filterSupportedChains,
} from "./mappers";
import { ChainQuoters } from "./types";

export interface IChainQuotersMapProvider {
  get chainQuotersMap(): ChainQuoters;
}

export interface IChainsInfoProvider
  extends OmitStrict<IChainsProvider, "getChains">,
    OmitStrict<IChainExchangesProvider, "getChainExchanges">,
    OmitStrict<IChainsConfigsProvider, "getChainConfigs">,
    IChainQuotersMapProvider {
  get supportedChainExchanges(): ChainExchangesMap;
  get supportedChains(): ChainInfosMap;
  getChainsInfo: () => Promise<void>;
}

export class ChainsInfoStore implements IChainsInfoProvider, IDisposable {
  private _chainsState: IChainsProvider;

  private _chainExchangesState: IChainExchangesProvider;

  private _chainQuotersState: IChainQuotersProvider;

  private _chainConfigsState: IChainsConfigsProvider;

  private _botVersionState: ILatestBotVersionProvider;

  private _disposers: IDisposable[];

  constructor() {
    makeAutoObservable(this);

    const chainsState = new ChainsStore();

    const chainExchangesState = new ChainExchangesStore();

    const chainQuotersState = new ChainQuotersStore();

    const chainConfigsState = new ChainConfigsStore();

    const botVersionState = new BotVersionStore();

    this._disposers = [
      chainsState,
      chainExchangesState,
      chainQuotersState,
      chainConfigsState,
      botVersionState,
    ];

    this._chainsState = chainsState;
    this._chainExchangesState = chainExchangesState;
    this._chainQuotersState = chainQuotersState;
    this._chainConfigsState = chainConfigsState;
    this._botVersionState = botVersionState;

    makeLoggable(this, { chainExchanges: true, chains: true, chainQuotersMap: true });
  }

  private get _latestBotVersion() {
    return this._botVersionState.latestBotVersion;
  }

  get chains() {
    return this._chainsState.chains;
  }

  get chainExchanges() {
    return this._chainExchangesState.chainExchanges;
  }

  get supportedChainExchanges() {
    const latestBotVersion = this._latestBotVersion;

    if (!latestBotVersion) {
      return {};
    }

    const supportedChainExchanges = filterSupportedChainExchanges(
      this.chainExchanges,
      latestBotVersion
    );

    return supportedChainExchanges;
  }

  get supportedChains() {
    const { chains, supportedChainExchanges } = this;
    if (!chains || !supportedChainExchanges) {
      return {};
    }

    const supportedChains = filterSupportedChains(chains, supportedChainExchanges);

    return supportedChains;
  }

  get chainConfigs() {
    return this._chainConfigsState.chainConfigs;
  }

  get chainMetaMap() {
    return this._chainConfigsState.chainMetaMap;
  }

  get nativePriceFeedMap() {
    return this._chainConfigsState.nativePriceFeedMap;
  }

  get dexLensMap() {
    return this._chainConfigsState.dexLensMap;
  }

  get balanceHelperMap() {
    return this._chainConfigsState.balanceHelperMap;
  }

  get addressHelperMap() {
    return this._chainConfigsState.addressHelperMap;
  }

  private get _chainRouterQuoters() {
    return this._chainQuotersState.chainQuoters;
  }

  get chainQuotersMap(): ChainQuoters {
    const chainRouterQuoters = this._chainRouterQuoters;
    const { chainExchanges } = this;

    if (!chainRouterQuoters || !chainExchanges) {
      return {};
    }

    return chainRouterQuotersToChainQuoters(chainRouterQuoters, chainExchanges);
  }

  getChainsInfo = async () => {
    try {
      await Promise.all([
        this._getChains(),
        this._getExchanges(),
        this._getChainsConfig(),
        this._getChainsQuoters(),
        this._getBotVersion(),
      ]);
    } catch (err) {
      logError(err);
    }
  };

  private _getChains = async () => {
    await this._chainsState.getChains();
  };

  private _getExchanges = async () => {
    await this._chainExchangesState.getChainExchanges();
  };

  private _getChainsConfig = async () => {
    await this._chainConfigsState.getChainConfigs();
  };

  private _getChainsQuoters = async () => {
    await this._chainQuotersState.getChainQuoters();
  };

  private _getBotVersion = async () => {
    await this._botVersionState.getLatestBotVersion();
  };

  destroy = () => {
    this._disposers.forEach((disposer) => disposer.destroy());
  };
}
