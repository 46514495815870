import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  height: 30px;
  display: grid;

  grid-template-columns:
    40px minmax(120px, 210px)
    minmax(75px, 135px) minmax(75px, 180px)
    minmax(75px, 130px) minmax(125px, 410px) minmax(74px, 150px) 35px minmax(100px, 1fr);
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes["20px"]};
  color: ${({ theme }) => theme.textColor};
  margin-right: 3px;

  @media (max-width: 1230px) {
    font-size: ${({ theme }) => theme.fontSizes["14px"]};
  }
`;
