import { ChangeEvent } from "react";
import { makeAutoObservable } from "mobx";
import { OpenLimitOrderTerminalProps, TradeSideType } from "src/api/bots/CEX/terminal/types";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { noOp } from "src/helpers/utils";
import { joinMarket } from "src/helpers/botName";
import { IMarketInfo } from "src/api/arbitrage/types";
import { PlaceOrderSetter, SetOrderFromOrderBookProps } from "../types";
import { createEmptyOrderProps, createEmptySelectOrders } from "./utils";
import { SelectedOrders } from "./types";

type OrdersSetters = Record<TradeSideType, PlaceOrderSetter>;
type PlaceOrderHandler = (order: OpenLimitOrderTerminalProps) => void;
/** key - market (quote_base_exchange), value - account uuid */
type MarketAccountMap = Record<string, string>;

export class ArbitrageModeStore {
  private _arbitrageMode = false;

  private _selectOrders: SelectedOrders = createEmptySelectOrders();

  private _ordersSetters: OrdersSetters = {
    buy: noOp,
    sell: noOp,
  };

  private _placeOrderHandlers: Record<TradeSideType, PlaceOrderHandler> = {
    buy: noOp,
    sell: noOp,
  };

  private _marketAccountMap: Record<TradeSideType, MarketAccountMap> = {
    buy: {},
    sell: {},
  };

  constructor() {
    makeAutoObservable(this);
  }

  get arbitrageMode() {
    return this._arbitrageMode;
  }

  get placeOrderHandler() {
    return this._placeOrderHandlers;
  }

  get marketAccountMap() {
    return this._marketAccountMap;
  }

  setArbitrageMode = (event: ChangeEvent<HTMLInputElement>) => {
    const value = getChangeEventValue(event) as boolean;

    this._arbitrageMode = value;

    this._resetSelectOrders();
  };

  setPlaceOrderSetter = (side: TradeSideType, setCb: PlaceOrderSetter) => {
    this._ordersSetters[side] = setCb;
  };

  orderSetter = (side: TradeSideType, props: SetOrderFromOrderBookProps) => {
    if (this._arbitrageMode) {
      this._setArbitrageOrder(side, props);
    } else {
      this._ordersSetters[side](props);
    }
  };

  manualResetForms = () => {
    this._resetSelectOrders();
    this._resetFormFills();
  };

  setPlaceOrderHandler = (side: TradeSideType, cbHandler: PlaceOrderHandler) => {
    this._placeOrderHandlers[side] = cbHandler;
  };

  private _resetSelectOrders = () => {
    this._selectOrders = createEmptySelectOrders();
  };

  private _resetFormFills = () => {
    this._ordersSetters.sell(createEmptyOrderProps());
    this._ordersSetters.buy(createEmptyOrderProps());
  };

  private _setArbitrageOrder = (side: TradeSideType, props: SetOrderFromOrderBookProps) => {
    const isRecurringMarket = this._checkRecurringMarket(side, props.market);

    if (isRecurringMarket) return;

    this._selectOrders[side] = props;

    if (!this._selectOrders.buy || !this._selectOrders.sell) {
      this._ordersSetters[side](props);
      return;
    }

    this._equalizeAmount();

    const { maxPriceOrder, minPriceOrder } = this._getMinMaxPriceOrders();

    if (!maxPriceOrder || !minPriceOrder) return;

    this._ordersSetters.sell(maxPriceOrder);
    this._ordersSetters.buy(minPriceOrder);
  };

  private _checkRecurringMarket = (side: TradeSideType, { market: newMarket }: IMarketInfo) => {
    const marketStr = joinMarket(newMarket);

    const checkSide: TradeSideType = side === "buy" ? "sell" : "buy";

    if (!this._selectOrders[checkSide]) return false;

    const oppositeMarket = joinMarket(this._selectOrders[checkSide]!.market.market);

    return oppositeMarket === marketStr;
  };

  private _getMinMaxPriceOrders = () => {
    if (!this._selectOrders.sell || !this._selectOrders.buy)
      return { minPriceOrder: null, maxPriceOrder: null };

    const orders = [this._selectOrders.sell, this._selectOrders.buy];

    const maxPriceOrder = orders.reduce((acc, curr) => (+acc.price > +curr.price ? acc : curr));

    const minPriceOrder = orders.reduce((acc, curr) => (+acc.price < +curr.price ? acc : curr));

    return { minPriceOrder, maxPriceOrder };
  };

  private _equalizeAmount = () => {
    if (!this._selectOrders.sell || !this._selectOrders.buy) return;

    const orders = [this._selectOrders.sell, this._selectOrders.buy];

    const amounts = orders.map(({ amount }) => +amount);

    const minAmount = Math.min(...amounts);

    this._selectOrders.sell.amount = String(minAmount);
    this._selectOrders.buy.amount = String(minAmount);
  };
}
