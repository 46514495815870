import { Calendar } from "react-calendar";
import { hexToRgb } from "src/helpers/colors";
import styled, { DefaultTheme, FlattenInterpolation, ThemeProps, css } from "styled-components";
import { CardSurface } from "../../shared";

export const Container = styled.div``;

const DATE_PICKER_CLASS_NAME = "react-calendar";

const getRootStyles: PickerStylesGetter = () => css`
  width: 350px;
  max-width: 100%;

  color: ${({ theme }) => theme.textColor};

  ${CardSurface};

  button {
    margin: 0;
    border: 0;
    outline: none;
  }

  button:enabled:hover {
    cursor: pointer;
  }

  abbr[title] {
    text-decoration: none;
  }
`;

type BasePickerColors = {
  primary: string;
  primaryBackground: string;
  hover: string;
};

type PickerStylesGetter = (
  rootClass: string,
  baseColors: BasePickerColors
) => FlattenInterpolation<ThemeProps<DefaultTheme>>;

const getNavigationStyles: PickerStylesGetter = (rootClass, baseColors) => {
  const { primary, hover } = baseColors;
  return css`
    .${rootClass}__navigation {
      display: flex;
      height: 44px;
      padding: 8px 0;

      button {
        color: ${primary};
        min-width: 30px;
        background: none;
        font-size: ${({ theme }) => theme.fontSizes["16px"]};
        border-radius: 100px;

        &:enabled:hover,
        &:enabled:focus {
          background-color: ${hover};
        }

        &:disabled {
          background-color: ${({ theme }) => theme.disableInputBgColor};
        }
      }

      &__label {
        order: -1;
        flex-grow: 0 !important;
        margin-right: auto;
        padding: 0 16px;
      }

      &__label__labelText {
        font-weight: ${({ theme }) => theme.fontWeights["600"]};
      }
    }
  `;
};

const getViewsStyles: PickerStylesGetter = (rootClass) => css`
  .${rootClass}__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.fontWeights["600"]};
      font-size: ${({ theme }) => theme.fontSizes["14px"]};

      &__weekday {
        padding: 8px;
      }
    }

    &__weekNumbers .${rootClass}__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: ${({ theme }) => theme.fontWeights["600"]};
      font-size: ${({ theme }) => theme.fontSizes["14px"]};
    }

    &__days__day {
      color: ${({ theme }) => theme.textColor};

      &--weekend {
        color: ${({ theme }) => theme.dangerColor};
      }
      &--neighboringMonth {
        color: ${({ theme }) => theme.disableInputColor};
      }
    }
  }

  .${rootClass}__year-view, .${rootClass}__decade-view, .${rootClass}__century-view {
    .${rootClass}__tile {
      padding: 16px;
      margin: 8px 0;
      color: ${({ theme }) => theme.textColor};
    }
  }
`;

const getTileStyles: PickerStylesGetter = (rootClass, baseColors) => {
  const { primary, primaryBackground, hover } = baseColors;
  // If a time range is part of only 1 day but does not encompass the whole day,
  // the class that react-calendar uses is '--hasActive' by itself (without being part of a '--range')
  const hasActiveSelector = "&--hasActive:not(&--range)";
  return css`
    &.${rootClass}--selectRange .${rootClass}__tile--hover {
      background-color: ${hover};
    }

    .${rootClass}__tile {
      max-width: 100%;
      padding: 8px 0px;
      margin: 2px 0;
      background: none;
      text-align: center;
      font-size: ${({ theme }) => theme.fontSizes["14px"]};

      &:not(&--hover, &--range, &:disabled) {
        border-radius: 100px;
      }

      &:disabled {
        background-color: ${({ theme }) => theme.disableInputBgColor};
      }

      &:enabled:hover,
      &:enabled:focus {
        background-color: ${hover};
        color: ${primary};
      }

      &--now,
      &--active,
      &--range {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
      }

      &--now {
        background: ${primaryBackground};
        color: ${primary};

        &:enabled:hover,
        &:enabled:focus {
          background: ${hexToRgb(primary, 0.2)};
          color: ${primary};
        }
      }

      ${hasActiveSelector},
      &--hasActive.${rootClass}__tile--rangeEnd,   
      &--active {
        background: ${primary};
        color: ${({ theme }) => theme.backgroundWhiteColor};

        &:enabled:hover,
        &:enabled:focus {
          background: ${hexToRgb(primary, 0.85)};
          color: ${({ theme }) => theme.backgroundWhiteColor};
        }
      }

      &--range {
        background: ${hover};
        color: ${primary};
      }

      &--rangeStart,
      &--rangeEnd {
        background: ${primary};
        color: ${({ theme }) => theme.backgroundWhiteColor};
      }

      &--rangeStart {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
      }
      &--rangeEnd {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
      }
    }
  `;
};

const getDatePickerStyles = (rootClass: string, theme: DefaultTheme) => {
  const { primaryColor } = theme;
  const hoverColor = theme.inputHoverBgColor;
  const primaryBackgroundColor = hexToRgb(primaryColor, 0.3);
  const baseColors: BasePickerColors = {
    primary: primaryColor,
    hover: hoverColor,
    primaryBackground: primaryBackgroundColor,
  };
  return css`
    ${getRootStyles(rootClass, baseColors)}
    ${getNavigationStyles(rootClass, baseColors)}
    ${getViewsStyles(rootClass, baseColors)}
    ${getTileStyles(rootClass, baseColors)}
  `;
};

export const DatePicker = styled(Calendar)`
  ${({ theme }) => getDatePickerStyles(DATE_PICKER_CLASS_NAME, theme)}
`;
