import styled from "styled-components";
import { CrashPanel } from "../shared/ErrorBoundary/CrashPanel";
import { PageContainer } from "../shared/shared";

export const Container = styled(PageContainer)`
  height: 0px;

  flex-direction: row-reverse;
  justify-content: center;
  flex: 1 1 auto;

  @media (max-width: 650px) {
    flex-direction: column;
    padding: 0px;
  }

  @media (max-width: 650px) {
    align-items: center;

    padding-top: 8px;
  }
`;

export const MainFallback = styled(CrashPanel)`
  margin: 40px 10px 0px;

  @media (max-width: 950px) {
    margin-top: 10px;
  }

  @media (max-width: 900px) {
    width: calc(100% - 20px);
  }
`;

export const Main = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  padding: 10px 15px 0px 0px;

  overflow-y: auto;

  @media (max-width: 900px) {
    padding: 0px 20px;
  }

  @media (max-width: 650px) {
    margin-top: 10px;
  }

  @media (max-width: 450px) {
    padding: 0px 5px;
  }

  @media (max-width: 380px) {
    padding: 0;
  }
`;

export const AllBotsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;
