import useElementSize from "src/hooks/useElementSize";
import Icons from "src/icons/Icons";
import * as styles from "./style";

type Orientation = styles.ContainerProps["$orientation"];

const ASPECT_RATIO_ORIENTATION_THRESHOLD = 1.5;

const getOrientation = (width: number, height: number): Orientation => {
  const isLandscapeLayout = width > ASPECT_RATIO_ORIENTATION_THRESHOLD * height;

  const orientation = isLandscapeLayout ? "row" : "column";
  return orientation;
};

const getSmallerSide = (
  width: number,
  height: number
): {
  side: Side;
  size: number;
} =>
  width < height
    ? {
        side: "horizontal",
        size: width,
      }
    : { side: "vertical", size: height };

const variablePxStyle = (name: string, value: number) =>
  ({
    [name]: `${value}px`,
  }) as React.CSSProperties;

const TARGET_TITLE_SIZE_HORIZONTAL_RATIO = 0.3;
const TARGET_TITLE_SIZE_VERTICAL_RATIO = 0.5;

type Side = "horizontal" | "vertical";

const ERROR_TITLE = "Something went wrong...";

const getTitleStyles = (side: Side, size: number) => {
  const sideRatio =
    side === "horizontal" ? TARGET_TITLE_SIZE_HORIZONTAL_RATIO : TARGET_TITLE_SIZE_VERTICAL_RATIO;

  const widthPenalty = side === "horizontal" && size < 150 ? 2 : 1;
  const heightPenalty = side === "vertical" && size < 150 ? 1.5 : 1;

  const fontSize = Math.round((size * sideRatio) / widthPenalty / heightPenalty);
  return fontSize ? variablePxStyle(styles.TITLE_FONT_SIZE_VARIABLE, fontSize) : undefined;
};

const TARGET_ICON_SIZE_HORIZONTAL_RATIO = 0.3;
const TARGET_ICON_SIZE_VERTICAL_RATIO = 0.3;

const getIconStyles = (side: Side, size: number) => {
  const sideRatio =
    side === "horizontal" ? TARGET_ICON_SIZE_HORIZONTAL_RATIO : TARGET_ICON_SIZE_VERTICAL_RATIO;

  const iconHeight = Math.round(size * sideRatio);
  return iconHeight ? variablePxStyle(styles.ICON_WIDTH_VARIABLE, iconHeight) : undefined;
};

export interface CrashPanelProps extends Pick<React.ComponentPropsWithoutRef<"div">, "style"> {}

export const CrashPanel = ({ ...props }: CrashPanelProps) => {
  const [contentRef, { width, height }] = useElementSize(50);

  const { side, size } = getSmallerSide(width, height);

  const orientation = getOrientation(width, height);

  return (
    <styles.Container ref={contentRef} $orientation={orientation} {...props}>
      <styles.IconWrapper style={getIconStyles(side, size)}>
        <Icons.Error />
      </styles.IconWrapper>

      <styles.Title style={getTitleStyles(side, size)}>{ERROR_TITLE}</styles.Title>
    </styles.Container>
  );
};
