import { ApiModules, apiUrl } from "src/api";
import {
  AccountBinding,
  AccountType,
  BotAccountName,
  LiquidityAccountBinding,
  LiquidityAccountName,
  VolumeAccountBinding,
  VolumeAccountName,
} from "src/modules/accounts";
import { Account } from "src/modules/userManager";
import ResponseHandler from "src/state/network/ResponseHandler";

type GetLiquidityApiKeysResponse = Partial<Record<LiquidityAccountName, LiquidityAccountBinding[]>>;

type GetVolumeApiKeysResponse = Partial<Record<VolumeAccountName, VolumeAccountBinding[]>>;

export type GetApiKeysResponse<T extends AccountType> = T extends "liquidity"
  ? GetLiquidityApiKeysResponse
  : GetVolumeApiKeysResponse;

export const getApiKeys = async <T extends AccountType>(botUUID: string, type: T) =>
  ResponseHandler.handler<GetApiKeysResponse<T>>({
    url: apiUrl(ApiModules.Bot, `/${botUUID}/accounts?type=${type}`),
    method: "GET",
  });

export type GetAllApiKeysResponse = GetLiquidityApiKeysResponse & GetVolumeApiKeysResponse;

export const getAllApiKeys = async (botUUID: string) =>
  ResponseHandler.handler<GetAllApiKeysResponse>({
    url: apiUrl(ApiModules.Bot, `/${botUUID}/accounts`),
    method: "GET",
  });

export const addApiKey = async (
  botUUID: string,
  botAccountName: BotAccountName,
  accountUUID: string
) =>
  ResponseHandler.handler<AccountBinding>({
    url: apiUrl(ApiModules.Bot, `/${botUUID}/accounts`),
    method: "POST",
    data: {
      name: botAccountName,
      account: {
        uuid: accountUUID,
      },
    },
  });

export const updateApiKey = async (botUUID: string, botAccountUUID: string, accountUUID: string) =>
  ResponseHandler.handler<{}>({
    url: apiUrl(ApiModules.Bot, `/${botUUID}/accounts/${botAccountUUID}`),
    method: "PUT",
    data: {
      account: {
        uuid: accountUUID,
      },
    },
  });

export const deleteApiKey = async (botUUID: string, botAccountUUID: string) =>
  ResponseHandler.handler<{}>({
    url: apiUrl(ApiModules.Bot, `/${botUUID}/accounts/${botAccountUUID}`),
    method: "DELETE",
  });

export interface GetExchangeAccountsResponse extends Record<string, Account[]> {}

export const getExchangeAccounts = async (party: string, exchange: string) =>
  ResponseHandler.handler<GetExchangeAccountsResponse>({
    url: apiUrl(ApiModules.Parties, `${party}/accounts?exchange=${exchange}`),
    method: "GET",
  });
