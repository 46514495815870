import { makeAutoObservable } from "mobx";

const STORAGE_KEY = "google_access_token";

export class GoogleAuthStore {
  private _accessToken = "";

  constructor() {
    makeAutoObservable(this);

    this._deserializeAccessToken();
  }

  private _serializeAccessToken = (token: string) => {
    localStorage.setItem(STORAGE_KEY, token);
  };

  private _deserializeAccessToken = () => {
    const token = localStorage.getItem(STORAGE_KEY);
    if (token) {
      this._accessToken = token;
    }
  };

  setAccessToken = (token: string) => {
    this._accessToken = token;
    this._serializeAccessToken(token);
  };

  get accessToken() {
    return this._accessToken;
  }

  get isLoggedIn() {
    return this._accessToken !== "";
  }

  clearAccessToken = () => {
    this.setAccessToken("");
  };
}
