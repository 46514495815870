import styled from "styled-components";

export interface FormFieldContainerProps {
  gap?: number;
}

export const Container = styled.div.attrs<FormFieldContainerProps>(({ gap }) => ({
  gap: gap === undefined ? 6 : gap,
}))<FormFieldContainerProps>`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => `${gap}px`};
`;
