import jwtDecode from "jwt-decode";
import { createAtom, makeAutoObservable, reaction } from "mobx";
import { reqRefreshToken } from "src/api/auth";
import { toast } from "src/components/shared/Toaster";
import { updateRefreshTokenStorage } from "src/helpers/auth";
import { getCurrentUnix } from "src/helpers/dateUtils";
import {
  getAbilities,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "src/helpers/getToken";
import { Abilities } from "src/modules/abilities";
import { IDecodedToken } from "src/modules/auth";

const TOKEN_RECEIVING_INTERVAL = 60000;

export interface IAbilitiesProvider {
  get abilities(): Abilities;
}
export class UserStore implements IAbilitiesProvider {
  _token: string = "";

  _decodedToken: Partial<IDecodedToken> = {};

  _tokenIsExpired = false;

  _atom;

  _intervalHandler: NodeJS.Timer | null = null;

  _timeoutHandler: NodeJS.Timeout | null = null;

  constructor() {
    makeAutoObservable(this);

    this._atom = createAtom(
      "Token",
      () => this._startReceivingToken(),
      () => this._stopReceivingToken()
    );
  }

  _resetTimeoutHandler() {
    if (!this._timeoutHandler) {
      return;
    }
    clearTimeout(this._timeoutHandler);
    this._timeoutHandler = null;
  }

  _receiveTokenFromLocalStorage() {
    this._token = getRefreshToken();

    this._decodedToken = this._token ? jwtDecode(this._token) : {};

    const currentUnix = getCurrentUnix();

    if (this._decodedToken?.exp !== undefined)
      // eslint-disable-next-line no-unsafe-optional-chaining
      this._tokenIsExpired = this._decodedToken?.exp <= currentUnix;

    if (!this._tokenIsExpired && this._decodedToken?.exp !== undefined) {
      this._resetTimeoutHandler();
      this._timeoutHandler = setTimeout(
        () => {
          this._tokenIsExpired = true;
          this._atom.reportChanged();
        },
        // eslint-disable-next-line no-unsafe-optional-chaining
        (this._decodedToken?.exp - currentUnix) * 1000
      );
    }

    this._atom.reportChanged();
  }

  _startReceivingToken() {
    this._receiveTokenFromLocalStorage();
    this._intervalHandler = setInterval(() => {
      this._receiveTokenFromLocalStorage();
    }, TOKEN_RECEIVING_INTERVAL);
  }

  _stopReceivingToken() {
    if (this._intervalHandler) clearInterval(this._intervalHandler);
    this._intervalHandler = null;
    this._resetTimeoutHandler();
  }

  get token() {
    if (this._atom.reportObserved()) {
      return this._token;
    }

    return getRefreshToken();
  }

  get decodedToken(): Partial<IDecodedToken> {
    if (this._atom.reportObserved()) {
      return this._decodedToken;
    }

    return jwtDecode(this.token);
  }

  logIn = () => {
    this._receiveTokenFromLocalStorage();
  };

  logOut = () => {
    setAccessToken("");
    setRefreshToken("");
    this._receiveTokenFromLocalStorage();
  };

  async updAbilities() {
    const { isError, data } = await reqRefreshToken(getRefreshToken());

    if (!isError) {
      updateRefreshTokenStorage(data);

      toast.success("Abilities updated successfully");
    }
  }

  get tokenIsExpired() {
    if (this._atom.reportObserved()) {
      return this._tokenIsExpired;
    }

    if (this.decodedToken?.exp === undefined) return true;

    const currentUnix = getCurrentUnix();

    // eslint-disable-next-line no-unsafe-optional-chaining
    return this.decodedToken?.exp <= currentUnix;
  }

  get abilities() {
    return getAbilities();
  }

  get isLoggedIn() {
    return !!this.token && !this._tokenIsExpired;
  }

  get login() {
    return this._decodedToken?.name;
  }

  subscribe = () =>
    reaction(() => [this._tokenIsExpired], this.logOut, {
      fireImmediately: true,
    });
}
