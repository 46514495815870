import { makeAutoObservable } from "mobx";
import WindowConsent from "src/state/WindowConsent";
import { getManualFunding, deleteManualFunding, updateManualFunding } from "src/api/shared/funding";
import { ManualFunding, UpdateFunding, PartyFundingRange } from "../types";
import { IBaseFundingStore } from "./IBaseFundingStore";

export class ManualFundingStore implements IBaseFundingStore<ManualFunding> {
  private _list: ManualFunding[] = [];

  get list() {
    return this._list;
  }

  set list(data: ManualFunding[]) {
    this._list = data;
  }

  constructor() {
    makeAutoObservable(this);
  }

  loadFunding = async (fundingParam: PartyFundingRange) => await getManualFunding(fundingParam);

  getSelectFunding = (id: number) => this.list?.find((el) => el.id === id);

  updateFunding = async (id: number, data: UpdateFunding) => await updateManualFunding(id, data);

  deleteFundingModal = (id: number, deleteAction: () => Promise<void>) => {
    const selectFunding = this.getSelectFunding(id);

    if (!selectFunding) return;

    WindowConsent.showWindow(
      "Are you sure you want to delete the funding entry:",
      `${selectFunding.type.toUpperCase()} ${selectFunding.amount} ${selectFunding.currency}
        From the ${selectFunding.account_name} account on the ${selectFunding.exchange} exchange
        `,
      deleteAction,
      id
    );
  };

  deleteFundingAction = async (id: number) => await deleteManualFunding(id);
}
