import { useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { Theme, darkTheme, lightTheme } from "src/components/Themes";
import { CEXBotStatus } from "src/modules/bots";
import { isDefined, Nullable, Nullish } from "../utils";

export const getTheme = (): Theme =>
  localStorage.getItem("theme") === "light" ? lightTheme : darkTheme;

export type ColorStatusKey = keyof Theme["botColorStatus"];

const getColorName = (status: Nullable<CEXBotStatus>, timeDontWork: Nullish<number>) => {
  const colorStatusesMap: Record<CEXBotStatus, ColorStatusKey> = {
    alert: !isDefined(timeDontWork) || timeDontWork > 300 ? "darkRed" : "lightRed",
    warning: "yellow",
    paused: "blue",
    active: "green",
    stopped: "gray",
    initial: "gray",
    crash: "purple",
  } as const;

  if (!status || !colorStatusesMap[status]) return "gray";

  return colorStatusesMap[status];
};

export const useColorStatus = () => {
  const { botColorStatus } = useContext(ThemeContext);

  const colorMapper = useMemo(
    () =>
      (status: CEXBotStatus | null, timeDontWork?: number): string => {
        const colorName = getColorName(status, timeDontWork);
        return botColorStatus[colorName];
      },
    [botColorStatus]
  );

  return colorMapper;
};

export const useBgColorStatus = () => {
  const { botBgColorStatus } = useContext(ThemeContext);

  const colorMapper = useMemo(
    () =>
      (status: CEXBotStatus | null, timeDontWork?: number): string => {
        const colorName = getColorName(status, timeDontWork);
        return botBgColorStatus[colorName];
      },
    [botBgColorStatus]
  );

  return colorMapper;
};

export const getCEXBotStatusColor = (status: CEXBotStatus, timeDontWork: Nullish<number>) => {
  const { botColorStatus } = getTheme();

  const colorName = getColorName(status, timeDontWork);

  return botColorStatus[colorName];
};
