import { observer } from "mobx-react-lite";
import WindowConsent from "src/state/WindowConsent";
import { Button } from "../Buttons/v2/Button";
import { OutlineButton } from "../Buttons/v2/variants/OutlineButton";
import * as styles from "./style";

export const ModalConsent = observer(() => (
  <styles.Background style={WindowConsent.hide ? { display: "none" } : { display: "flex" }}>
    <styles.Container>
      <styles.TextWrapper>
        <styles.Title>{WindowConsent.component}</styles.Title>

        <styles.Message>{WindowConsent.message}</styles.Message>
      </styles.TextWrapper>

      <styles.ButtonsWrapper>
        <OutlineButton stretched onClick={() => WindowConsent.hideWindow()}>
          Disagree
        </OutlineButton>

        <Button stretched onClick={() => WindowConsent.sendData()}>
          Agree
        </Button>
      </styles.ButtonsWrapper>
    </styles.Container>
  </styles.Background>
));
