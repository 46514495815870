import { makeAutoObservable } from "mobx";
import { getTransfersFunding, updateTransfersFunding } from "src/api/shared/funding";
import { TransfersFunding, UpdateFunding, PartyFundingRange } from "../types";
import { IBaseFundingStore } from "./IBaseFundingStore";

export class TransfersFundingStore implements IBaseFundingStore<TransfersFunding> {
  private _list: TransfersFunding[] = [];

  get list() {
    return this._list;
  }

  set list(data: TransfersFunding[]) {
    this._list = data;
  }

  constructor() {
    makeAutoObservable(this);
  }

  loadFunding = async (fundingParam: PartyFundingRange) => await getTransfersFunding(fundingParam);

  getSelectFunding = () => {};

  updateFunding = async (id: number, data: UpdateFunding) => await updateTransfersFunding(id, data);

  deleteFundingModal = () => {};

  deleteFundingAction = async () => {};
}
