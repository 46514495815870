import styled from "styled-components";
import { ScrollSize } from "src/components/shared/shared";

export const FiltersContainer = styled.aside`
  width: 320px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 18px;

  padding: 40px 0px;

  overflow: auto;

  ${ScrollSize}

  @media (max-width: 950px) {
    padding: 10px 0px;
  }

  @media (max-width: 650px) {
    width: 100%;
    height: max-content;

    gap: 8px;

    padding: 8px 30px;
  }

  @media (max-width: 450px) {
    padding: 0 15px;
  }

  @media (max-width: 380px) {
    padding: 0 10px;
  }
`;
