import { makeAutoObservable } from "mobx";
import { dateDiff, getCurrentDayjs } from "src/helpers/dateUtils";

export const MAX_DAYS_AFTER_BOT_CREATED = 14;

export interface CreatedDate {
  created_at: number;
}

export default class RecentlyAddedFilterStore {
  _recentlyAdded = false;

  private _currentDate = getCurrentDayjs();

  private _maxDaysAfter: number;

  constructor(maxDaysAfter?: number) {
    this._maxDaysAfter = maxDaysAfter ?? MAX_DAYS_AFTER_BOT_CREATED;
    makeAutoObservable(this);
  }

  toggleEnabled = () => {
    this._recentlyAdded = !this._recentlyAdded;
    if (this._recentlyAdded) {
      this._currentDate = getCurrentDayjs();
    }
  };

  filter = ({ created_at }: CreatedDate) => {
    const daysAfterCreated = dateDiff(created_at, this._currentDate.unix());
    return daysAfterCreated < this._maxDaysAfter;
  };

  switchableFilter = (created: CreatedDate) => {
    if (!this.isEnabled) return true;
    return this.filter(created);
  };

  get isEnabled() {
    return this._recentlyAdded;
  }
}
