import { DEXCommonBotMode, DEXCommonBotModeStatus, DEXCommonModeInfo } from "src/modules/bots";
import { IDEXNEModeInfo } from "src/state/DEX_NE/bots";
import { DEXNEBotModeStatuses } from "src/state/DEX_NE/bots/types";

const statusMap: Record<DEXNEBotModeStatuses, DEXCommonBotModeStatus> = {
  stopped: DEXCommonBotModeStatus.Stopped,
  active: DEXCommonBotModeStatus.Running,
  alert: DEXCommonBotModeStatus.Error,
};

export const getDEXNEModeProps = (mode: DEXCommonBotMode, modeInfo: IDEXNEModeInfo) => {
  const { status, message, lastTrade, nextTrade } = modeInfo;

  return {
    [`${mode}Status`]: statusMap[status],
    [`${mode}Message`]: message,
    [`${mode}NextTrade`]: nextTrade,
    [`${mode}LastTrade`]: lastTrade,
  } as DEXCommonModeInfo<DEXCommonBotMode>;
};
