import { nanoid } from "nanoid";
import React, { ChangeEvent, useMemo } from "react";
import * as styles from "./style";

export interface CheckBoxProps {
  label?: string;
  defaultChecked?: boolean;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  value?: string | number;
  useHtmlFor?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const CheckBox = ({
  label,
  defaultChecked,
  checked,
  onChange,
  disabled,
  value,
  inputRef,
  useHtmlFor = true,
  ...props
}: CheckBoxProps) => {
  const id = useMemo(() => nanoid(), []);

  const htmlForProps = { htmlFor: useHtmlFor ? id : undefined };

  return (
    <styles.WrapperForCheckbox {...props}>
      <styles.CheckBox
        ref={inputRef}
        id={id}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      <styles.Label {...htmlForProps} disabled={disabled} />
      {label ? <styles.LabelText {...htmlForProps}>{label}</styles.LabelText> : null}
    </styles.WrapperForCheckbox>
  );
};
