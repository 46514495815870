import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";

export interface ModeIconWrapperProps {
  mainColor?: string;
  size?: number;
}

export const ModeIconColorMixin = css<ModeIconWrapperProps>`
  ${({ mainColor }) => {
    if (!mainColor) {
      return css`
        border-color: ${({ theme }) => theme.textColor};
      `;
    }
    return css`
      border-color: ${mainColor};
      background-color: ${hexToRgb(mainColor, 0.2)};

      & > svg * {
        stroke: ${mainColor};
      }
    `;
  }}
`;

export const ModeIconWrapper = styled.div<ModeIconWrapperProps>`
  ${({ size: iconSize = 40 }) => {
    const size = `${iconSize}px`;
    return css`
      width: ${size};
      height: ${size};
    `;
  }}

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radii["6px"]};
  border-width: 2px;
  border-style: solid;

  & > svg {
    width: 60%;
    height: auto;
  }

  ${ModeIconColorMixin}
`;
