import { makeAutoObservable } from "mobx";
import { Nullish, isDefined } from "src/helpers/utils";
import { BaseStrategy, IStrategies, IStrategiesProvider } from "./types";

export class StrategiesStore<T extends BaseStrategy> implements IStrategies<T> {
  private _strategiesProvider: IStrategiesProvider<T>;

  private _selectedStrategyIndex: Nullish<number> = null;

  constructor(strategiesProvider: IStrategiesProvider<T>) {
    makeAutoObservable(this);

    this._strategiesProvider = strategiesProvider;
  }

  get selectedStrategy() {
    const selectedIndex = this._selectedStrategyIndex;
    const strategy = this._getBotStrategy(selectedIndex);
    return strategy;
  }

  private get _botStrategies() {
    return this._strategiesProvider.strategies;
  }

  updateStrategy = async (strategy: T) => {
    const strategyIndex = this._selectedStrategyIndex;
    this._updateBotStrategies(strategy, strategyIndex);

    return await this._saveBotStrategies();
  };

  toggleActiveStrategy = async (index: number) => {
    this._toggleActiveBotStrategy(index);

    return await this._saveBotStrategies();
  };

  deleteStrategy = async (index: number) => {
    this._updateBotStrategies(undefined, index);

    return await this._saveBotStrategies();
  };

  setSelectedStrategy = (index: Nullish<number>) => {
    this._selectedStrategyIndex = index;
  };

  private _setBotStrategies = (strategies: T[]) => {
    this._strategiesProvider.setStrategies(strategies);
  };

  private _saveBotStrategies = async () => await this._strategiesProvider.saveStrategies();

  private _getBotStrategy = (index: Nullish<number>): T | null => {
    const strategies = this._botStrategies;
    if (!isDefined(index) || index >= strategies.length) return null;
    return strategies[index] ?? null;
  };

  private _addBotStrategy = (strategy: T) => {
    const newStrategies = [...this._botStrategies, strategy];
    this._setBotStrategies(newStrategies);
  };

  private _updateBotStrategy = (strategy: T, index: number) => {
    const newStrategies = this._botStrategies.map((currentStrategy, i) =>
      i === index ? strategy : currentStrategy
    );
    this._setBotStrategies(newStrategies);
  };

  private _deleteBotStrategy = (index: number) => {
    const newStrategies = this._botStrategies.filter((_, i) => i !== index);
    this._setBotStrategies(newStrategies);
  };

  private _updateBotStrategies = (strategy?: T, index?: Nullish<number>) => {
    if (isDefined(index)) {
      if (strategy) {
        this._updateBotStrategy(strategy, index);
      } else {
        this._deleteBotStrategy(index);
      }
    } else if (strategy) {
      this._addBotStrategy(strategy);
    }
  };

  private _toggleActiveBotStrategy = (index: Nullish<number>) => {
    const currentStrategy = this._getBotStrategy(index);
    if (!currentStrategy || !isDefined(index)) return;

    const newStrategy = { ...currentStrategy, status: !currentStrategy.status };
    this._updateBotStrategy(newStrategy, index);
  };
}
