import {
  ICreatedLimitOrderStatus,
  IExtendedLimitOrderStatusDTO,
} from "src/api/bots/CEX/terminal/types";
import { Mapper } from "src/helpers/utils";
import { LimitOrderRequestProps } from "src/api/bots/CEX/terminal";
import { IExtendedLimitOrderStatus, IExtendedLimitOrderStatusView } from "./types";

const placedLimitOrderMapper: Mapper<IExtendedLimitOrderStatusDTO, IExtendedLimitOrderStatus> = ({
  account_uuid,
  canceled_at,
  created_at,
  fee_currency,
  filled_at,
  order_type,
  partially_filled,
  pair: { min_amount_base, min_amount_quote, ...pairRest },
  updated_at,
  ...rest
}) => ({
  accountUUID: account_uuid,
  canceledAt: canceled_at,
  createdAt: created_at,
  feeCurrency: fee_currency,
  filledAt: filled_at,
  orderType: order_type,
  partiallyFilled: partially_filled,
  pair: { minAmountBase: min_amount_base, minAmountQuote: min_amount_quote, ...pairRest },
  updatedAt: updated_at,
  ...rest,
});

export const placedLimitOrderStatusMapper: Mapper<
  ICreatedLimitOrderStatus,
  IExtendedLimitOrderStatus
> = ({
  canceled_at,
  created_at,
  fee_currency,
  filled_at,
  order_type,
  partially_filled,
  pair: { min_amount_base, min_amount_quote, ...pairRest },
  updated_at,
  ...rest
}) => ({
  accountUUID: "",
  exchange: "",
  canceledAt: canceled_at,
  createdAt: created_at,
  feeCurrency: fee_currency,
  filledAt: filled_at,
  orderType: order_type,
  partiallyFilled: partially_filled,
  pair: { minAmountBase: min_amount_base, minAmountQuote: min_amount_quote, ...pairRest },
  updatedAt: updated_at,
  ...rest,
});

export const placedLimitOrdersMap: Mapper<
  IExtendedLimitOrderStatusDTO[],
  IExtendedLimitOrderStatus[]
> = (data: IExtendedLimitOrderStatusDTO[]) => data.map((order) => placedLimitOrderMapper(order));

export const getRequestPropsFromOrder = ({
  accountUUID,
  id,
  pair,
}: IExtendedLimitOrderStatusView): LimitOrderRequestProps => ({
  account_uuid: accountUUID,
  order_id: id,
  pair,
});
