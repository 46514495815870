import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { noOp } from "src/helpers/utils";
import styled from "styled-components";
import MenuItem, { MenuItemData } from "./MenuItem";
import * as styles from "./style";

export type OnMenuItemChange = (value: string) => void;

export type ContentDirection = "column" | "row";

export type SwitchMenuDirection = {
  direction: ContentDirection;
};

export interface BaseSwitchMenuProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "onClick" | "onChange">,
    Partial<SwitchMenuDirection> {
  menuItems: MenuItemData[];
  border?: boolean;
  selectedItem: string;
  onChange: OnMenuItemChange;
  onClickActive?: OnMenuItemChange;
}

export const BaseSwitchMenu = observer(
  ({
    menuItems = [],
    selectedItem,
    onChange,
    direction = "column",
    border = true,
    onClickActive = noOp,
    ...props
  }: BaseSwitchMenuProps) => {
    const onCheckChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      },
      [onChange]
    );
    return (
      <styles.Menu direction={direction} {...props}>
        {menuItems.map(({ label, value }) => {
          const isSelectedItem = value === selectedItem;
          const onActiveItemClick = () => isSelectedItem && onClickActive(value);
          return (
            <MenuItem
              key={value}
              label={label}
              value={value}
              checked={isSelectedItem}
              onChange={onCheckChange}
              onClick={onActiveItemClick}
              direction={direction}
              border={border}
            />
          );
        })}
      </styles.Menu>
    );
  }
);

export type SwitchMenuType = "segmented" | "spread";

export type SwitchMenuTypeProps = {
  type: SwitchMenuType;
};

const getStyledSwitchMenu = (type: SwitchMenuType) => {
  switch (type) {
    case "segmented": {
      return styles.SingleActiveSwitchMenu;
    }
    case "spread": {
      return styles.MultipleActiveSwitchMenu;
    }
  }
};

export const SwitchMenu = styled(BaseSwitchMenu)<Partial<SwitchMenuTypeProps>>`
  ${({ type = "segmented" }) => getStyledSwitchMenu(type)}
`;
