import { Dayjs } from "dayjs";
import { IReactionDisposer, makeAutoObservable, reaction, when } from "mobx";
import { computedFn } from "mobx-utils";
import {
  DashboardQueryParams,
  DashboardRangeParams,
  getPartiesMetrics,
} from "src/api/bots/CEX/dashboard";
import { getShortPartyAccounts } from "src/api/userManager/partiesAPI";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { SelectorProps } from "src/components/shared/Forms/Selectors";
import { SelectPanelProps } from "src/components/shared/Forms/Selectors/SelectionPanel/SelectPanel";
import { getCurrentDayjs, getDateTimeRangeFromUtc, getDayjsFromUnix } from "src/helpers/dateUtils";
import { getSelectorList } from "src/helpers/forms/selectors";
import { makeLoggable } from "src/helpers/logger";
import { joinStrings } from "src/helpers/string";
import { IDisposable, Nullish, SubscribableStore } from "src/helpers/utils";
import { StringSelectorValue } from "src/modules/shared";
import { Account } from "src/modules/userManager";
import {
  ISimpleInvalidationEmitter,
  SimpleInvalidationStore,
} from "src/state/shared/Emitter/SimpleEmitterStore";
import { IUseRangePicker, RangePickerStore } from "src/state/shared/RangePicker";
import { UrlSearchParamsStore } from "src/state/shared/UrlSearchParams";
import { IQueryHistory } from "src/state/shared/UrlSearchParams/types";
import { UrlParams } from "./types";
import { searchParamsValidationSchema } from "./validationSchemes";
import { DEFAULT_MIN_DATE } from "./constants";

type DashboardSelectors = "account" | "exchange";

export type DashboardV2View = "balance-summary" | "market-info" | "pnl" | "accounting";

interface CEXDashboardV2PartyParams {
  party: string;
}

const getMinLimitRange = (min: Dayjs, range: DateTimeRange): DateTimeRange | null => {
  const [start, end] = range;

  if (!start || !end) return null;

  if (end.isSameOrBefore(min, "second")) {
    return null;
  }

  const isMinInRange = min.isBetween(start, end, "second", "[)");

  return isMinInRange ? [min, end] : range;
};

const getMaxLimitRange = (max: Dayjs, range: DateTimeRange): DateTimeRange | null => {
  const [start, end] = range;

  if (!start || !end) return null;

  if (start.isSameOrAfter(max, "second")) {
    return null;
  }

  const isMaxInRange = max.isBetween(start, end, "second", "(]");

  return isMaxInRange ? [start, max] : range;
};

export interface DashboardV2RequestParams {
  queryParams: DashboardQueryParams;
  party: string;
}

export type GetDashboardRequestParams = (
  range?: Nullish<DateTimeRange>
) => DashboardV2RequestParams | null;

export interface IDashboardV2StateProvider {
  get party(): string;
  get selectedExchanges(): string[];

  getQueryParams: (range?: Nullish<DateTimeRange>) => DashboardQueryParams | null;
  getRequestParams: GetDashboardRequestParams;

  get selectedRange(): DateTimeRange;
  get previousMonth(): DateTimeRange | null;
  get currentMonth(): DateTimeRange | null;
  get previousMonthDisabled(): boolean;
  get nextMonthDisabled(): boolean;

  get minDate(): Dayjs | undefined;

  get updatesCount(): number;

  setRange: (range: DateTimeRange) => void;
}

interface IDashboardV2StateActions {
  setPartyParams: (botParams: CEXDashboardV2PartyParams) => void;
  getInitialData: () => Promise<void>;
  onCurrentMonth: () => void;
  onPreviousMonth: () => void;
  onNextMonth: () => void;
  setCurrentView: (view: string) => void;
}

interface IDashboardV2SelectorState {
  selectorValue: (key: DashboardSelectors) => StringSelectorValue[];
  selectorOnChange: (key: DashboardSelectors) => (data: readonly StringSelectorValue[]) => void;
  selectorOptions: (key: DashboardSelectors) => StringSelectorValue[];
  selectorProps: (
    key: DashboardSelectors
  ) => Pick<SelectorProps<StringSelectorValue, true, any>, "options" | "value" | "onChange">;
  onRemoveSelect: (key: DashboardSelectors) => (value: string) => void;
  selectProps: (key: DashboardSelectors) => Pick<SelectPanelProps, "selectItems" | "removeClick">;
}

export interface IDashboardV2State
  extends IDashboardV2StateProvider,
    IDashboardV2StateActions,
    IDashboardV2SelectorState,
    SubscribableStore {
  get currentView(): DashboardV2View;
  get initialLoading(): boolean;
}

export interface IDashboardV2Fetcher {
  getStats: () => Promise<void>;
}

const getDashboardRangeQueryParams = (range: DateTimeRange | null): DashboardRangeParams | null => {
  if (!range) return null;

  const [start, end] = range;
  if (!start || !end) return null;
  return { from: `${start.unix()}`, to: `${end.unix()}` };
};

const getDashboardQueryParams = (
  rangeParams: DashboardRangeParams | null,
  exchanges: string[]
): DashboardQueryParams | null => {
  if (!rangeParams) return null;
  return {
    ...rangeParams,
    exchanges: exchanges.length ? joinStrings(exchanges, ",") : undefined,
  };
};

export interface IBaseDashboardV2StoreParams {
  stateProvider: IDashboardV2StateProvider;
}

export interface IStatsV2StateFetcher {
  getStats: () => Promise<void>;
}

export interface ICEXDashboardV2ViewState<T extends Record<string, any>>
  extends IStatsV2StateFetcher,
    IDisposable {
  get widgetsStateMap(): T;
}

export class CEXDashboardV2Store implements IDisposable, IUseRangePicker, IDashboardV2State {
  private _initialLoading = false;

  private _botParams: CEXDashboardV2PartyParams = {
    party: "",
  };

  private _currentView: DashboardV2View = "balance-summary";

  private _exchanges: string[] = [];

  private _accounts: Account[] = [];

  private _selectedExchanges: string[] = [];

  private _selectedAccounts: Account[] = [];

  private _rangePickerState: RangePickerStore;

  private _initialStatsFetchReaction?: IReactionDisposer;

  private _exchangesSelectionReaction?: IReactionDisposer;

  private _querySearchParamsReaction?: IReactionDisposer;

  private _urlSearchParamsState: UrlSearchParamsStore<UrlParams>;

  private _workingSince: number | null = null;

  private _invalidationEmitter: ISimpleInvalidationEmitter;

  constructor(searchParamsProps: IQueryHistory) {
    makeAutoObservable<this, "_viewsState" | "_currentMonth">(this, {
      selectorValue: false,
      selectorOptions: false,
      selectorProps: false,
      _viewsState: false,
      _currentMonth: false,
    });

    this._rangePickerState = new RangePickerStore(this, undefined);

    this._invalidationEmitter = new SimpleInvalidationStore();

    this._urlSearchParamsState = new UrlSearchParamsStore(this, {
      ...searchParamsProps,
      validationSchema: searchParamsValidationSchema,
    });

    makeLoggable<any>(this, {
      _accounts: true,
      selectedRange: true,
      loading: true,
      _botParams: true,
      previousMonth: true,
      currentMonth: true,
      currentView: true,
      _workingSince: true,
      _fetchDepsReady: true,
    });
  }

  private get _fetchDepsReady() {
    const { party } = this._botParams;

    const workingSince = this._workingSince;

    return Boolean(party && workingSince);
  }

  private get _initialLoadReady() {
    return this._fetchDepsReady;
  }

  get initialLoading() {
    return this._initialLoading;
  }

  private _setInitialLoading = (loading: boolean) => {
    this._initialLoading = loading;
  };

  get updatesCount() {
    return this._invalidationEmitter.invalidations;
  }

  setPartyParams = (botParams: CEXDashboardV2PartyParams) => {
    this._botParams = botParams;
  };

  get botParams() {
    return this._botParams;
  }

  get party() {
    return this.botParams.party;
  }

  private _setWorkingSince = (workingSince: number | null) => {
    this._workingSince = workingSince;
  };

  get selectedRange() {
    return this._rangePickerState.range;
  }

  private get _querySearchParams() {
    const { start, end } = this._rangePickerState;
    const exchanges = this._selectedExchanges.length
      ? joinStrings(this._selectedExchanges, ",")
      : null;

    return { view: this._currentView, exchanges, start, end };
  }

  private getQueryRangeParams = (range?: Nullish<DateTimeRange>): DashboardRangeParams | null => {
    const currentRange = range ?? this.selectedRange;
    return getDashboardRangeQueryParams(currentRange);
  };

  getQueryParams = (range?: Nullish<DateTimeRange>): DashboardQueryParams | null => {
    const rangeParams = this.getQueryRangeParams(range);
    const exchanges = this._selectedExchanges;
    return getDashboardQueryParams(rangeParams, exchanges);
  };

  getRequestParams = (range?: Nullish<DateTimeRange>): DashboardV2RequestParams | null => {
    const query = this.getQueryParams(range);
    const { party } = this;
    if (!query || !party) return null;
    return { queryParams: query, party };
  };

  get minDate() {
    const workingSince = this._workingSince;
    return workingSince ? getDayjsFromUnix(workingSince) : undefined;
  }

  setRange = (range: DateTimeRange) => this._rangePickerState.setRange(range);

  setInitialQueries = (queryObj: Partial<UrlParams>) => {
    const { view, start, end, exchanges } = queryObj;

    if (view) this.setCurrentView(view);

    if (exchanges) this._selectedExchanges = exchanges.split(",");

    if (start && end) {
      this._rangePickerState.setStateRange(getDateTimeRangeFromUtc(start, end));
      this._setInitialStatsFetchReaction(this.loadData);
    } else this._setInitialStatsFetchReaction(this._getInitialStats);
  };

  private _setInitialStatsFetchReaction = (cbEffect: () => void) => {
    this._initialStatsFetchReaction = when(
      () => this._initialLoadReady,
      () => cbEffect()
    );
  };

  private get _currentSelectedEndDate() {
    const [, endDate] = this.selectedRange;
    return endDate;
  }

  private _getMinMaxMonthRange = (range: DateTimeRange): DateTimeRange | null => {
    const now = getCurrentDayjs();

    const maxLimitRange = getMaxLimitRange(now, range);

    if (!maxLimitRange) return null;

    const min = this.minDate;

    if (!min) return maxLimitRange;

    const minMaxLimitRange = getMinLimitRange(min, maxLimitRange);

    return minMaxLimitRange;
  };

  private _getMonthRange = (
    monthOffset: number,
    currentDate: Nullish<Dayjs> = this._currentSelectedEndDate
  ): DateTimeRange | null => {
    if (!currentDate) {
      return null;
    }

    const targetMonthFirstDay = currentDate.startOf("month").add(monthOffset, "month");

    const targetMonthLastDay = targetMonthFirstDay.endOf("month");

    const targetRange = this._getMinMaxMonthRange([targetMonthFirstDay, targetMonthLastDay]);

    return targetRange;
  };

  private get _previousMonth() {
    return this._getMonthRange(-1);
  }

  get previousMonth() {
    return this._previousMonth;
  }

  onPreviousMonth = () => {
    const previousMonth = this._previousMonth;
    if (previousMonth) {
      this.setRange(previousMonth);
    }
  };

  get previousMonthDisabled() {
    const previousMonth = this._previousMonth;
    return !previousMonth;
  }

  private get _currentMonth() {
    return this._getMonthRange(0, getCurrentDayjs());
  }

  get currentMonth() {
    return this._currentMonth;
  }

  onCurrentMonth = () => {
    const currentMonth = this._currentMonth;
    if (currentMonth) {
      this.setRange(currentMonth);
    }
  };

  private get _nextMonth() {
    return this._getMonthRange(1);
  }

  onNextMonth = () => {
    const nextMonth = this._nextMonth;
    if (nextMonth) {
      this.setRange(nextMonth);
    }
  };

  get nextMonthDisabled() {
    const nextMonth = this._nextMonth;
    return !nextMonth;
  }

  get currentView() {
    return this._currentView;
  }

  setCurrentView = (view: string) => {
    this._currentView = view as DashboardV2View;
  };

  private _setExchanges = (exchanges: string[]) => {
    this._exchanges = exchanges;
  };

  private _setAccounts = (accounts: Account[]) => {
    this._accounts = accounts;
  };

  private get _accountsOptions() {
    const selectedExchanges = this._selectedExchanges;

    return this._accounts
      .filter(({ exchange }) => selectedExchanges.includes(exchange))
      .map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      }));
  }

  private _onAccountSelected = (values: readonly StringSelectorValue[]) => {
    const newAccountsIds = values.map(({ value }) => String(value));

    this._selectedAccounts = this._accounts.filter(({ uuid }) => newAccountsIds.includes(uuid));
  };

  get selectedExchanges() {
    return this._selectedExchanges;
  }

  private get _selectedAccountsValue() {
    return this._selectedAccounts.map(({ name, uuid }) => ({
      label: name,
      value: uuid,
    }));
  }

  private get _exchangesOptions() {
    return getSelectorList(this._exchanges);
  }

  private _onExchangeSelected = (values: readonly StringSelectorValue[]) => {
    const newExchanges = values.map(({ value }) => String(value));
    this._selectedAccounts = this._accounts.filter(({ exchange }) =>
      newExchanges.includes(exchange)
    );

    this._selectedExchanges = newExchanges;
  };

  private get _selectedExchangesValue() {
    return getSelectorList(this._selectedExchanges);
  }

  selectorValue = computedFn((key: DashboardSelectors): StringSelectorValue[] => {
    switch (key) {
      case "exchange": {
        return this._selectedExchangesValue;
      }
      case "account": {
        return this._selectedAccountsValue;
      }
    }
  });

  selectorOnChange = (
    key: DashboardSelectors
  ): ((data: readonly StringSelectorValue[]) => void) => {
    switch (key) {
      case "exchange": {
        return this._onExchangeSelected;
      }
      case "account": {
        return this._onAccountSelected;
      }
    }
  };

  selectorOptions = computedFn((key: DashboardSelectors): StringSelectorValue[] => {
    switch (key) {
      case "exchange": {
        return this._exchangesOptions;
      }
      case "account": {
        return this._accountsOptions;
      }
    }
  });

  selectorProps = (
    key: DashboardSelectors
  ): Pick<SelectorProps<StringSelectorValue, true, any>, "options" | "value" | "onChange"> => ({
    options: this.selectorOptions(key),
    value: this.selectorValue(key),
    onChange: this.selectorOnChange(key),
  });

  private _removeSelectedExchange = (exchange: string) => {
    const newExchanges = this._selectedExchanges.filter(
      (currentExchange) => currentExchange !== exchange
    );
    this._selectedExchanges = newExchanges;
    this._selectedAccounts = this._selectedAccounts.filter(({ exchange }) =>
      newExchanges.includes(exchange)
    );
  };

  private _removeSelectedAccount = (accountId: string) => {
    this._selectedAccounts = this._selectedAccounts.filter(({ uuid }) => uuid !== accountId);
  };

  onRemoveSelect = (key: DashboardSelectors): ((value: string) => void) => {
    switch (key) {
      case "exchange": {
        return this._removeSelectedExchange;
      }
      case "account": {
        return this._removeSelectedAccount;
      }
    }
  };

  selectProps = (
    key: DashboardSelectors
  ): Pick<SelectPanelProps, "selectItems" | "removeClick"> => ({
    selectItems: this.selectorValue(key),
    removeClick: this.onRemoveSelect(key),
  });

  private _getExchangesAccounts = async () => {
    try {
      const { data, isError } = await getShortPartyAccounts(this._botParams.party);

      if (!isError) {
        const exchanges = Object.keys(data);
        this._setExchanges(exchanges);

        const accounts = Object.values(data).flatMap((it) => it);
        this._setAccounts(accounts);
      }
    } catch {
      this._setExchanges([]);
      this._setAccounts([]);
    }
  };

  private _getWorkingSince = async () => {
    const { party } = this;
    if (!party) return null;

    try {
      const { isError, data } = await getPartiesMetrics(party);
      if (!isError) {
        this._setWorkingSince(data.created);
      } else this._setWorkingSince(DEFAULT_MIN_DATE);
    } catch {
      this._setWorkingSince(DEFAULT_MIN_DATE);
    }
  };

  getInitialData = async () => {
    try {
      this._setInitialLoading(true);
      await Promise.all([this._getExchangesAccounts(), this._getWorkingSince()]);
    } finally {
      this._setInitialLoading(false);
    }
  };

  private _getInitialStats = async () => {
    // auto refresh happens on new range
    const currentMonth = this._currentMonth!;
    this.setRange(currentMonth);
  };

  private _updateStats = () => {
    if (!this._fetchDepsReady) return;

    this._invalidationEmitter.invalidate();
  };

  loadData = () => {
    this._updateStats();
  };

  destroy = () => {};

  subscribe = () => {
    this._exchangesSelectionReaction = reaction(
      () => this._selectedExchanges.slice(),
      () => {
        this._updateStats();
      }
    );

    this._querySearchParamsReaction = reaction(
      () => this._querySearchParams,
      (query) => this._urlSearchParamsState.setAllQueryUrl(query)
    );
  };

  unsubscribe = () => {
    this._initialStatsFetchReaction?.();
    this._exchangesSelectionReaction?.();
    this._querySearchParamsReaction?.();
  };
}
