import { makeAutoObservable } from "mobx";
import { getPartyArbitrage } from "src/api/algoArbitrage";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { AccountManagerStore } from "src/state/shared/AccountManager";

export class AlgoArbitrageStore implements IDisposable {
  private _party: string;

  private _arbitrageUUID: string | null = null;

  private _accountsState: AccountManagerStore;

  private _isLoading = false;

  constructor(party: string) {
    makeAutoObservable(this);

    this._party = party;

    this._accountsState = new AccountManagerStore(this);
  }

  get party() {
    return this._party;
  }

  get arbitrageUUID() {
    return this._arbitrageUUID;
  }

  get accountsState() {
    return this._accountsState;
  }

  get isArbitrageCreated() {
    return Boolean(this._arbitrageUUID);
  }

  get isLoading() {
    return this._isLoading;
  }

  loadData = async () => {
    await Promise.all([this._getPartyArbitrage(this._party), this._accountsState.loadAccounts()]);
  };

  setArbitrageUUID = (uuid: string | null) => {
    this._arbitrageUUID = uuid;
  };

  private _setIsLoading = (value: boolean) => {
    this._isLoading = value;
  };

  private _getPartyArbitrage = async (party: string) => {
    this._setIsLoading(true);
    try {
      const { isError, data } = await getPartyArbitrage(party);

      if (!isError) {
        this.setArbitrageUUID(data[0] ?? null);
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setIsLoading(false);
    }
  };

  destroy = () => {};
}
