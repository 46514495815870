import { useCallback } from "react";
import { PrimaryColor, setPrimaryColor } from "src/helpers/theme";
import WindowConsent from "src/state/WindowConsent";
import { useTheme } from "styled-components";

const getNextPrimaryColor = (primaryColor: PrimaryColor): PrimaryColor => {
  switch (primaryColor) {
    case "blue": {
      return "orange";
    }
    case "orange": {
      return "blue";
    }
  }
};

export const useTogglePrimaryColor = () => {
  const currentTheme = useTheme();
  const primaryColor = currentTheme.primaryMode;

  const onTogglePrimaryColor = useCallback(() => {
    const nextPrimaryColor = getNextPrimaryColor(primaryColor);
    setPrimaryColor(nextPrimaryColor);
    window.location.reload();
  }, [primaryColor]);

  const togglePrimaryColor = useCallback(() => {
    WindowConsent.showWindow(
      "Are you sure?",
      "You are about to switch the primary color. This action will reload the window!",
      onTogglePrimaryColor
    );
  }, [onTogglePrimaryColor]);

  return togglePrimaryColor;
};
