import { observer } from "mobx-react-lite";
import Icons from "src/icons/Icons";
import { IconWrapper } from "../shared/shared";
import * as styles from "./style";

export interface HeaderCell {
  title: string;
  icon: JSX.Element | string;
}

interface Props {
  favorite?: boolean;
  toggleWatchList?: () => void;
  watchListEnabled?: boolean;
  cells?: HeaderCell[];
}

export const Header = observer(
  ({ favorite, toggleWatchList, watchListEnabled, cells = [], ...props }: Props) => (
    <styles.Header {...props}>
      {favorite ? (
        <IconWrapper onClick={toggleWatchList}>
          {watchListEnabled ? Icons.starTrue() : Icons.starFalse()}
        </IconWrapper>
      ) : null}

      {cells.map((el, index) => (
        <styles.Cell key={el.title}>
          <styles.Wrapper>
            <styles.Title>{el.title}</styles.Title>
            {el.icon}
          </styles.Wrapper>
        </styles.Cell>
      ))}
    </styles.Header>
  )
);
