import { observer } from "mobx-react-lite";
import { useHasAbilities } from "src/hooks/abilities/useHasAbilities";
import { AbilityType } from "src/state/shared/AbilitiesStore/types";
import { Loader } from "../Loader";
import * as styles from "./style";

export interface PermissionProps {
  abilityName: AbilityType;
}

const NO_PERMISSIONS_TEXT = "You don't have enough rights...";

export interface PermissionWrapperProps extends PermissionProps {
  children: JSX.Element;
  loading?: boolean;
  noPermissionsChildren?: JSX.Element;
  showInfoMsg?: boolean;
}

export const PermissionWrapper = observer(
  ({
    abilityName,
    children,
    loading,
    noPermissionsChildren,
    showInfoMsg = false,
  }: PermissionWrapperProps) => {
    const isHasAbility = useHasAbilities(abilityName);

    if (loading) return <Loader show={loading} />;

    if (isHasAbility) return children;

    if (noPermissionsChildren) return noPermissionsChildren;

    if (showInfoMsg) return <styles.Message>{NO_PERMISSIONS_TEXT}</styles.Message>;

    return null;
  }
);
