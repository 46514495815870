import { makeAutoObservable } from "mobx";
import { IDisposable } from "src/helpers/utils";
import { ArrayFormOnChange, IArrayFormBinder } from "./types";

export class ArrayFormBinderStore<T> implements IArrayFormBinder<T>, IDisposable {
  private _values: T[] = [];

  private _onStrategiesChange: ArrayFormOnChange<T> | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  private _unbindStrategies = () => {
    this._values = [];
    this._onStrategiesChange = null;
  };

  bindArray = (values: T[], onChange: ArrayFormOnChange<T> | null) => {
    this._values = values;
    this._onStrategiesChange = onChange;
    return this._unbindStrategies;
  };

  setArray = (strategies: T[]) => {
    this._onStrategiesChange?.(strategies);
  };

  get values() {
    return this._values;
  }

  destroy = () => {
    this._unbindStrategies();
  };
}
