import { LinkButton } from "src/components/shared/Buttons/v2/LinkButton";
import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";

export interface BotLinkButtonStyleProps {
  $accentColor?: string;
}

const BackgroundColorMixin = ({
  $accentColor,
}: Pick<BotLinkButtonStyleProps, "$accentColor">) => css`
  ${({ theme }) => {
    const mainColor = $accentColor ?? theme.botColorStatus.gray;

    const enabledAlpha = hexToRgb(mainColor, 0.2);
    const hoverAlpha = hexToRgb(mainColor, 0.4);
    const focusedAlpha = hexToRgb(mainColor, 0.6);
    const activeAlpha = hexToRgb(mainColor, 0.8);

    return css`
      background-color: ${enabledAlpha};

      :hover {
        background-color: ${hoverAlpha};
      }

      :active {
        background-color: ${activeAlpha};
      }

      :focus-visible {
        background-color: ${focusedAlpha};
      }
    `;
  }}
`;

export const BotButtonMixin = css<BotLinkButtonStyleProps>`
  font-weight: ${({ theme }) => theme.fontWeights["500"]};

  ${BackgroundColorMixin}
`;

export const StyledBotLinkButton = styled(LinkButton)<BotLinkButtonStyleProps>`
  min-width: auto;
  flex-grow: 0;
  color: ${({ theme }) => theme.bigTextColor};

  ${BotButtonMixin}
`;
