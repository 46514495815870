import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { Headline3 } from "src/components/shared/Typography";
import styled from "styled-components";
import { Container as BaseContainer } from "../MainFilter/style";

export const Container = styled(BaseContainer)`
  max-height: 244px;
  min-height: 244px;

  gap: 20px;

  @media (max-width: 650px) {
    max-height: 137px;
  }
`;

export const PartiesFilterFallback = styled(CrashPanel)`
  height: 244px;

  @media (max-width: 650px) {
    height: 137px;
  }
`;

export const Title = styled(Headline3)``;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
`;
