import { useMediaQuery } from "@mui/material";
import { ThemeContext } from "styled-components";
import { observer } from "mobx-react-lite";
import { useCallback, useContext, useRef } from "react";
import { getBotLink } from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { PermissionWrapper } from "src/components/shared/PermissionWrapper";
import { noOp } from "src/helpers/utils";
import Icons from "src/icons/Icons";
import { ChainInfoChip } from "src/components/BotsContent/shared/Chain/ChainInfoChip";
import { MobBotContainer, MobLine, Name } from "../../shared";
import { copyBotCredential } from "../../utils";
import { BotMark } from "../../BotMark";
import { useBaseModeChipProps } from "../hooks";
import { DEXCommonBotProps } from "../types";
import * as styles from "./style";

interface UseMobileChainInfoChipParams extends Pick<DEXCommonBotMobProps, "chainMeta"> {
  isCompact: boolean;
}

const useMobileChainInfoChip = ({ chainMeta, isCompact }: UseMobileChainInfoChipParams) => {
  if (chainMeta === undefined) return null;

  if (chainMeta === null) return <MobLine />;

  if (isCompact) return <styles.MobChainChip icon={chainMeta.icon} />;

  return <ChainInfoChip {...chainMeta} />;
};

export interface DEXCommonBotMobProps extends DEXCommonBotProps {
  compactQuery?: string;
}

const DEFAULT_COMPACT_QUERY = "(max-width: 650px)";

export const DEXCommonBotMob = observer(
  ({
    abilities,
    routes,
    botType,
    botTemplateColumnsStyle,

    favorite,
    toggleIsWatched,
    isWatched,
    botUUID,
    name,
    pair,
    exchange,
    dexVersion,
    link,
    colorStatus,
    showStartBtn,
    onStartClick = noOp,
    party,
    isStopped,
    chainMeta,

    limitMode,
    volumeMode,
    counterMode,

    compactQuery = DEFAULT_COMPACT_QUERY,
    getInfoContainer,
    showModesInfo = true,

    credential,
    afterContent,
    ...props
  }: DEXCommonBotMobProps) => {
    const { botColorStatus: colors, botBgColorStatus: bgColors } = useContext(ThemeContext);

    const containerRef = useRef<HTMLDivElement>(null);

    const isCompact = useMediaQuery(compactQuery);

    const ChainChip = useMobileChainInfoChip({ chainMeta, isCompact });

    const showStartButton = showStartBtn;

    const botLinkParams = { party, uuid: botUUID };

    const { limitLastTrade, limitMessage, limitNextTrade, limitStatus } = limitMode || {};
    const { volumeLastTrade, volumeMessage, volumeNextTrade, volumeStatus } = volumeMode || {};
    const { counterMessage, counterStatus, counterLastTrade } = counterMode || {};

    const toggleWatched = useCallback(() => {
      toggleIsWatched?.(botUUID);
    }, [botUUID, toggleIsWatched]);

    const baseModeChipProps = useBaseModeChipProps({
      getInfoContainer,
      showModesInfo,
      botUUID,
      containerRef,
    });

    const modeChipProps = {
      ...baseModeChipProps,
      $isCompact: isCompact,
    };

    return (
      <MobBotContainer {...props} ref={containerRef}>
        {favorite ? (
          <styles.FavoriteButton onClick={toggleWatched}>
            {isWatched ? Icons.startStatusTrue() : Icons.startStatusFalse()}
          </styles.FavoriteButton>
        ) : null}

        <styles.MobFrame
          color={colors[colorStatus]}
          style={{ backgroundColor: bgColors[colorStatus] }}
        >
          <styles.Content showDivider={isCompact}>
            <styles.ColumnWrapper>
              <styles.PairMob
                to={getBotLink({ botType, botTab: routes.Stats, ...botLinkParams })}
                color={colors[colorStatus]}
              >
                {pair?.split("_").join(" _ ")}
              </styles.PairMob>
              <Name>{name}</Name>
            </styles.ColumnWrapper>

            <styles.MobExchangeCellContent>
              <styles.MobExch href={link || "#"} target="_blank">
                {exchange}
              </styles.MobExch>
              <styles.MobDEXCommonVersion>{dexVersion}</styles.MobDEXCommonVersion>
            </styles.MobExchangeCellContent>

            {ChainChip}

            <styles.ModesWrapper>
              {limitMode && (
                <styles.MobModeChip
                  mode="limit"
                  nextTrade={limitNextTrade}
                  lastTrade={limitLastTrade}
                  message={limitMessage}
                  status={limitStatus}
                  {...modeChipProps}
                />
              )}

              {volumeMode && (
                <styles.MobModeChip
                  mode="volume"
                  nextTrade={volumeNextTrade}
                  lastTrade={volumeLastTrade}
                  message={volumeMessage}
                  status={volumeStatus}
                  {...modeChipProps}
                />
              )}

              {counterMode && (
                <styles.MobModeChip
                  mode="counter"
                  message={counterMessage}
                  lastTrade={counterLastTrade}
                  status={counterStatus}
                  {...modeChipProps}
                />
              )}
            </styles.ModesWrapper>

            {isCompact && <styles.Divider />}

            <styles.ButtonCellContent>
              {showStartButton ? (
                <PermissionWrapper abilityName={abilities.BotManage}>
                  <styles.MobStartButton
                    started={!isStopped}
                    onClick={onStartClick}
                    color={colors[colorStatus]}
                    showCompact={isCompact}
                  />
                </PermissionWrapper>
              ) : (
                <BotMark
                  onClick={() => credential && copyBotCredential(credential)}
                  disabled={!credential}
                  color={colors[colorStatus]}
                />
              )}
            </styles.ButtonCellContent>

            {afterContent}
          </styles.Content>
        </styles.MobFrame>
      </MobBotContainer>
    );
  }
);
