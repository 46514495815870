import { Props as SelectProps } from "react-select";
import styled, { css, DefaultTheme } from "styled-components";

export interface StyledSelectProps extends Pick<SelectProps, "isDisabled"> {
  error?: boolean;
}

interface StyledSelectChangedProps extends Pick<SelectProps, "classNamePrefix"> {}

interface TextColorProps extends Pick<StyledSelectProps, "isDisabled"> {
  theme: DefaultTheme;
}

const selectorTextColor = ({ theme, isDisabled }: TextColorProps) => {
  if (isDisabled) return theme.disableInputColor;
  return theme.inputTextColor;
};

const SelectorScrollBar = css`
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #edeadf;
    border-radius: ${({ theme }) => theme.radii["10px"]};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.radii["10px"]};
    background-color: rgba(180, 180, 180, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(180, 180, 180, 1);
  }
`;

export const SELECTOR_CLASSNAME = "Custom-select";

export const CustomSelect = styled.div.attrs<{}, StyledSelectChangedProps>({
  classNamePrefix: SELECTOR_CLASSNAME,
})<StyledSelectProps>`
  & {
    width: 100%;
  }

  & .Custom-select__container {
    width: 100%;
    display: inline-block;
    min-height: 1px;
    text-align: left;
    border: none;
  }

  & .Custom-select__control {
    height: 30px;
    border: none;
    cursor: pointer;
    min-height: 1px;
    background: ${({ isDisabled, theme }) => {
      if (isDisabled) return theme.selectDisabledBg;
      return theme.bgGrayBlack;
    }};

    box-shadow: none;

    box-shadow: ${({ theme, error }) =>
      error ? `0 0 0 1px ${theme.dangerColor} !important` : "none"};

    :hover {
      border: none !important;
    }
  }

  & .Custom-select__input {
    min-height: 1px;
    border: none;
  }
  & .Custom-select__input-container {
    margin: 0px;
    padding: 0px;

    overflow: inherit;
  }

  & .Custom-select__input-container {
    color: ${({ theme }) => theme.inputTextColor};
  }

  & .Custom-select__indicator-separator {
    display: none;
  }

  & .Custom-select__indicators-container {
    height: 100%;
  }

  & .Custom-select__clear-indicator {
    height: 100%;
    min-height: 1px;
    display: flex;
    align-items: center;
    padding: 0px;
  }

  & .Custom-select__multi-value {
    background: #fff;
    border: 1px solid black;
  }

  & .Custom-select__multi-value-remove {
    padding-left: 1px;
    padding-right: 2px;
  }

  & .Custom-select__multi-value-label {
    padding: 3px 2px 0px 3px;
  }

  & .Custom-select__value-container {
    min-height: 1px;
    height: 100%;
    font-size: 1em;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 13px;
    overflow-y: auto;
  }

  & .Custom-select__single-value {
    min-height: 1px;
    margin: 0px;
    color: ${({ theme }) => theme.inputTextColor};
    color: ${selectorTextColor};
  }

  & .Custom-select__placeholder {
    ${({ theme: { disableInputColor }, isDisabled }) => {
      if (isDisabled) {
        return `color: ${disableInputColor}`;
      }
    }};
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: 1/1/2/-1;
  }

  & .Custom-select__menu {
    box-shadow: none;
    z-index: 999;
    background: ${({ theme }) => theme.selectMenuBg};
  }

  & .Custom-select__menu-list {
    min-height: 80px;
    max-height: 200px;
    padding-bottom: 0px;
    padding-top: 0px;
    border-radius: ${({ theme }) => theme.radii["4px"]};
    box-shadow:
      0px 20px 25px rgba(0, 0, 0, 0.1),
      0px 10px 10px rgba(0, 0, 0, 0.04);
    border: none;
    background: ${({ theme }) => theme.selectMenuBg};
    z-index: 1000;

    ${SelectorScrollBar}
  }

  & .Custom-select__option {
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes["11px"]};
    color: ${({ theme }) => theme.inputTextColor};

    word-wrap: break-word;

    :hover {
      background: ${({ theme }) => theme.inputHoverBgColor};
      color: ${({ theme }) => theme.inputTextColor};
    }

    :last-of-type {
      border-radius: 0px 0px 4px 4px;
    }

    :first-of-type {
      border-radius: 4px 4px 0px 0px;
    }
  }

  & .Custom-select__option--is-selected {
    color: ${({ theme }) => `${theme.primaryColor}!important`};
    background: none;
  }

  & .Custom-select__option--is-focused {
    background: ${({ theme }) => theme.inputHoverBgColor};
    color: ${({ theme }) => theme.inputTextColor};
  }

  & .Custom-select__dropdown-indicator {
    position: relative;
    height: 100%;
    padding-right: 14px;
    min-height: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: #757575;

    ::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      left: 2.5px;
      top: 9px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      border-color: ${selectorTextColor};
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transition: all 0.1s;
    }

    & > svg {
      display: none;
    }
  }

  & .Custom-select__menu-notice--no-options {
    display: none;
  }

  & .Custom-select__clear-indicator {
    position: relative;
    padding-left: 10px;
    margin-right: 8px;

    ::before {
      position: absolute;
      content: "";
      width: 1.5px;
      height: 10px;
      background: #767676;
      left: 5px;
      top: 10px;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transition: all 0.1s;
    }
    ::after {
      position: absolute;
      content: "";
      width: 1.5px;
      height: 10px;
      background: #767676;
      left: 5px;
      top: 10px;
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      transition: all 0.1s;
    }

    & > svg {
      display: none;
    }
  }

  & .Custom-select__multi-value__label {
    font-size: 60%;
  }

  & .Custom-select__value-container--has-value {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    ${SelectorScrollBar}
  }

  & .Custom-select__multi-value {
    background: inherit;
    border: none;
    color: ${({ theme }) => theme.inputTextColor};

    ::after {
      content: ",";
    }
  }

  & .Custom-select__multi-value__label {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes["11px"]};
    color: currentColor;
  }

  & .Custom-select__multi-value__remove {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
