import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { AlgoArbitrageStore } from "src/state/party/algoArbitrage";
import { createContextProvider } from "src/context/utils/createContextProvider";
import { ActiveArbitrageStore } from "src/state/party/algoArbitrage/activeArbitrage";
import { ExchangeModuleListStore } from "src/state/party/algoArbitrage/activeArbitrage/exchangeModuleList";
import { CreateArbitrageStore } from "src/state/party/algoArbitrage/createArbitrage";
import { PartyPageProviderProps } from "../types";

type AlgoArbitrageProviderProps = PartyPageProviderProps;

export const AlgoArbitrageContext = createLateInitContext<AlgoArbitrageStore>();

export const AlgoArbitrageProvider = ({ party, children }: AlgoArbitrageProviderProps) => {
  const state = useLocalStore(AlgoArbitrageStore, party);

  return <AlgoArbitrageContext.Provider value={state}>{children}</AlgoArbitrageContext.Provider>;
};

export const useAlgoArbitrageState = () => useLateInitContext(AlgoArbitrageContext);

const CreateActiveArbitrageStore = () => {
  const state = useAlgoArbitrageState();
  return useLocalStore(ActiveArbitrageStore, state);
};

const { Context: ActiveArbitrageContext, Provider: ActiveArbitrageProvider } =
  createContextProvider<ActiveArbitrageStore>({
    stateCreator: CreateActiveArbitrageStore,
  });

export { ActiveArbitrageContext, ActiveArbitrageProvider };

export const useActiveArbitrageState = () => useLateInitContext(ActiveArbitrageContext);

const CreateExchangeModuleListStore = () => {
  const state = useActiveArbitrageState();
  return useLocalStore(ExchangeModuleListStore, state);
};

const { Context: ExchangeModuleListContext, Provider: ExchangeModuleListProvider } =
  createContextProvider<ExchangeModuleListStore>({
    stateCreator: CreateExchangeModuleListStore,
  });

export { ExchangeModuleListContext, ExchangeModuleListProvider };

export const useExchangeModuleListState = () => useLateInitContext(ExchangeModuleListContext);

const CreateArbitrageStoreCreator = () => {
  const state = useAlgoArbitrageState();
  return useLocalStore(CreateArbitrageStore, state);
};

const { Context: CreateArbitrageContext, Provider: CreateArbitrageProvider } =
  createContextProvider<CreateArbitrageStore>({
    stateCreator: CreateArbitrageStoreCreator,
  });

export { CreateArbitrageContext, CreateArbitrageProvider };

export const useCreateArbitrageState = () => useLateInitContext(CreateArbitrageContext);
