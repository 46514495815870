/* eslint-disable no-prototype-builtins */
import { toJS } from "mobx";
import { FormDataKeys } from "../types";
import { NestedObjectPaths, ObjectPathValue } from "../types/NestedObject";

export const getPathAndKey = (key: string): [string[], string] => {
  const path = key.split(".");

  const endKey = path.pop();
  if (endKey === undefined) throw Error("Invalid path");
  return [path, endKey];
};

export const getTargetValueByPath = (
  inObj: any,
  path: string[],
  createPath: boolean = true
): any => {
  let obj: any = inObj;
  for (let i = 0; i < path.length; i += 1) {
    const key = path[i];

    if (!obj[key]) {
      if (!createPath) return;
      obj[key] = {};
    }
    obj = obj[key];
  }

  return obj;
};

export const getValueByPath = <T>(
  obj: any,
  path: string[],
  endKey: string,
  defaultValue?: T
): T => {
  const data = getTargetValueByPath(obj, path);
  if (data[endKey] === undefined) {
    data[endKey] = defaultValue;
  }
  return data[endKey];
};

export const getByKey = <T>(key: string, obj: any, defaultValue?: T): T => {
  const [path, endKey] = getPathAndKey(key);
  return getValueByPath(obj, path, endKey, defaultValue);
};

export const copyDataByKey = <D, K extends NestedObjectPaths<D>>(target: D, source: D, key: K) => {
  const newData = getDataByKey(source, key);
  setData(target, key as FormDataKeys<D>, toJS(newData));
};

export const setData = <D, K extends FormDataKeys<D>>(
  data: D,
  key: K,
  value: ObjectPathValue<D, K>
) => {
  const [path, endKey] = getPathAndKey(key);
  const targetData = getTargetValueByPath(data, path);
  targetData[endKey] = value;
};

const getExistingValueByPath = (obj: any, path: string[], endKey: string) => {
  const data = getTargetValueByPath(obj, path, false);
  if (data === undefined || data[endKey] === undefined) {
    return undefined;
  }
  return data[endKey];
};

// allows all keys, not only leaves
export const getDataByKey = <D, K extends NestedObjectPaths<D>>(data: D, key: K) => {
  const [path, endKey] = getPathAndKey(key);
  return getExistingValueByPath(data, path, endKey);
};

export const getData = <D, K extends FormDataKeys<D>>(data: D, key: K): ObjectPathValue<D, K> =>
  getDataByKey(data, key as NestedObjectPaths<D>);

const transformCompare = (a: any, b: any, transformEq?: (val: any) => any) => {
  if (transformEq) {
    return [transformEq(a), transformEq(b)] as const;
  }
  return [a, b] as const;
};

export const objectEq = (x: any, y: any, transformEq?: (val: any) => any) => {
  // eslint-disable-next-line no-param-reassign
  [x, y] = transformCompare(x, y, transformEq);

  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  // eslint-disable-next-line no-restricted-syntax
  for (const p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    const [xp, yp] = transformCompare(x[p], y[p], transformEq);

    if (xp === yp) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof xp !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!objectEq(xp, yp, transformEq)) return false;
    // Objects and Arrays must be tested recursively
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
};

interface IEqualDataOptions {
  transformFn?: (val: any) => any;
}

export const equalData = <D, K extends NestedObjectPaths<D>>(
  a: D,
  b: D,
  startKey: K,
  { transformFn }: IEqualDataOptions = {}
) => {
  const startA = getDataByKey(a, startKey);
  const startB = getDataByKey(b, startKey);
  return objectEq(startA, startB, transformFn);
};
