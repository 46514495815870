import { ButtonColor } from "src/components/Themes";
import { StyledProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";
import { BaseButton } from "../shared/style";

export type ButtonVariant = "contained" | "outline" | "dashed";
export type ButtonSize = "s" | "m" | "l" | "xs";
export interface ButtonStyleProps {
  size: ButtonSize;
  variant: ButtonVariant;
  color: ButtonColor;
  stretched: boolean;
  elevated: boolean;
  singleIcon: boolean;
}

export interface ButtonStyledProps extends StyledProps<ButtonStyleProps> {}

export const BackgroundColorMixin = ({
  $color,
  $variant,
}: Pick<ButtonStyledProps, "$color" | "$variant">) => css`
  ${({ theme }) => {
    const stateColors = theme.buttonBackgroundStateColors[$color];
    const neutralStateColors = theme.buttonLightBackgroundStateColors[$color];

    const { enabled, active, hover, focused } =
      $variant === "contained" ? stateColors : neutralStateColors;

    return css`
      background-color: ${enabled};

      :hover {
        background-color: ${hover};
      }

      :active {
        background-color: ${active};
      }

      :focus-visible {
        background-color: ${focused};
      }
    `;
  }}
`;

const TextColorMixin = ({
  $color,
  $variant,
}: Pick<ButtonStyledProps, "$color" | "$variant">) => css`
  ${({ theme }) => {
    if ($variant === "contained") {
      switch ($color) {
        case "primary":
        case "danger":
        case "success": {
          return css`
            color: ${theme.backgroundWhiteColor};
          `;
        }
        case "secondary":
        case "neutral": {
          return css`
            color: ${theme.textColor};
          `;
        }
      }
    } else {
      switch ($color) {
        case "primary":
        case "danger":
        case "success": {
          const invertTextColor = theme.backgroundWhiteColor;
          const textColor = theme.buttonBackgroundStateColors[$color].enabled;
          return css`
            color: ${textColor};

            :active,
            :focus-visible {
              color: ${invertTextColor};
            }
          `;
        }
        case "secondary":
        case "neutral": {
          return css`
            color: ${theme.textColor};
          `;
        }
      }
    }
  }}
`;

const BorderMixin = ({ $color, $variant }: Pick<ButtonStyledProps, "$color" | "$variant">) => css`
  ${({ theme }) => {
    const borderStyle = $variant === "dashed" ? "dashed" : "solid";
    const baseBorderColor =
      $color !== "neutral" ? theme.buttonBackgroundStateColors[$color].enabled : theme.textColor;
    const borderColor = $variant === "contained" ? "transparent" : baseBorderColor;
    return css`
      border: 1.5px ${borderStyle} ${borderColor};
    `;
  }}
`;

const SizeMixin = ({ $size }: Pick<ButtonStyledProps, "$size">) => css`
  ${({ theme }) => {
    switch ($size) {
      case "l": {
        return css`
          min-width: 80px;
          min-height: 44px;
          font-size: ${theme.fontSizes["16px"]};
          line-height: ${theme.lineHeights["20px"]};
          padding: 12px 16px;
          border-radius: ${({ theme }) => theme.radii["8px"]};
        `;
      }
      case "m": {
        return css`
          min-width: 56px;
          min-height: 32px;
          font-size: ${theme.fontSizes["14px"]};
          line-height: ${theme.lineHeights["16px"]};
          padding: 8px 12px;
          border-radius: ${({ theme }) => theme.radii["6px"]};
        `;
      }
      case "s": {
        return css`
          min-height: 22px;
          font-size: ${theme.fontSizes["10px"]};
          line-height: ${theme.lineHeights["12px"]};
          padding: 4px 7px;
          border-radius: ${theme.radii["4px"]};
        `;
      }
      case "xs": {
        return css`
          min-height: 18px;
          font-size: ${theme.fontSizes["8px"]};
          line-height: ${theme.lineHeights["10px"]};
          padding: 2px 5px;
          border-radius: ${theme.radii["4px"]};
        `;
      }
    }
  }}
`;

const WidthMixin = ({ $stretched }: Pick<ButtonStyledProps, "$stretched">) => {
  if (!$stretched) {
    return;
  }
  return css`
    width: 100%;

    ${ButtonContent} {
      width: initial;
    }
  `;
};

const IconMixin = ({ $singleIcon }: Pick<ButtonStyledProps, "$singleIcon">) => {
  if (!$singleIcon) {
    return;
  }
  return css`
    min-width: initial;
    width: auto;
    aspect-ratio: 1;
  `;
};

export const ElevationMixin = ({
  $elevated,
  $variant,
}: Pick<ButtonStyledProps, "$elevated" | "$variant">) => {
  const shouldDisableElevation = $variant === "outline" || $variant === "dashed" || !$elevated;

  const disabledElevation =
    shouldDisableElevation &&
    css`
      box-shadow: none;
    `;

  const elevation = css`
    box-shadow: ${({ theme }) => theme.shadows.button};
  `;

  return css`
    ${elevation}

    :disabled, &[disabled] {
      box-shadow: none;
    }

    ${disabledElevation}
  `;
};

export const ButtonIcon = styled.span`
  display: inherit;
`;

export const ButtonContent = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(BaseButton)<ButtonStyledProps>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${({ theme }) => theme.fontWeights["600"]};
  gap: 8px;

  ${BackgroundColorMixin}
  ${TextColorMixin} 
  ${BorderMixin};
  ${ElevationMixin};
  ${SizeMixin};
  ${WidthMixin};
  ${IconMixin}
`;
