import { makeAutoObservable } from "mobx";
import { Pair, getPairs } from "src/api/dexScreener";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { IObservableCache } from "src/state/shared/Cache";
import { CacheQueryProvider } from "src/state/shared/Query/CacheQuery";
import {
  CacheQueryFetchOptions,
  ICacheQueryParams,
  ICacheQueryProvider,
} from "src/state/shared/Query/CacheQuery/types";
import { IScreenerNetworkProvider, ISwapPairAddressProvider } from "./types";

type GetTradePairQueryParams = {
  pairAddress: string;
  screenerNetwork: string;
} | null;

interface GetTradePairOptions extends CacheQueryFetchOptions {}

export interface IScreenerPairProvider {
  get pair(): Pair | undefined;
  getPair: (options?: GetTradePairOptions) => Promise<void>;
  get canQuery(): boolean;
  get loading(): boolean;
}

export interface IScreenerPairParams {
  networkProvider: IScreenerNetworkProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  pairCacheStore: IObservableCache<Pair>;
}

const getPriceCacheKey = (network: string, pairAddress: string) => `pair-${network}-${pairAddress}`;

export class ScreenerPairProvider
  implements IScreenerPairProvider, ICacheQueryParams<GetTradePairQueryParams, Pair>, IDisposable
{
  private _pairAddressProvider: ISwapPairAddressProvider;

  private _networkProvider: IScreenerNetworkProvider;

  private _cacheQueryState: ICacheQueryProvider<Pair> & IDisposable;

  private _pairCacheStore: IObservableCache<Pair>;

  constructor({ networkProvider, pairAddressProvider, pairCacheStore }: IScreenerPairParams) {
    makeAutoObservable(this, {});

    this._networkProvider = networkProvider;

    this._pairAddressProvider = pairAddressProvider;

    this._pairCacheStore = pairCacheStore;

    this._cacheQueryState = new CacheQueryProvider(this);

    makeLoggable<any>(this, {
      pair: true,
      queryParams: true,
      _pairAddress: true,
      _screenerNetwork: true,
    });
  }

  private get _pairAddress() {
    return this._pairAddressProvider.pairAddress;
  }

  private get _screenerNetwork() {
    return this._networkProvider.screenerNetwork;
  }

  get queryParams() {
    const pairAddress = this._pairAddress;
    const screenerNetwork = this._screenerNetwork;

    if (!pairAddress || !screenerNetwork) return null;
    return { pairAddress, screenerNetwork };
  }

  cacheKeyFn = (params: NonNullable<GetTradePairQueryParams>) => {
    const { pairAddress, screenerNetwork } = params;

    const key = getPriceCacheKey(screenerNetwork, pairAddress);

    return key;
  };

  queryFn = async (params: NonNullable<GetTradePairQueryParams>) => {
    const { pairAddress, screenerNetwork } = params;

    const { isError, data } = await getPairs(screenerNetwork, pairAddress);
    if (!isError) {
      const pair = data.pairs?.[0];

      return pair;
    }

    return undefined;
  };

  get cacheStore() {
    return this._pairCacheStore;
  }

  get canQuery() {
    return this._cacheQueryState.canQuery;
  }

  get loading() {
    return this._cacheQueryState.loading;
  }

  get pair() {
    return this._cacheQueryState.data;
  }

  getPair = async (options?: GetTradePairOptions) => {
    await this._cacheQueryState.getData(options);
  };

  destroy = () => {
    this._cacheQueryState.destroy();
  };
}
