import { IMarket, IMarketInfo } from "src/api/arbitrage/types";
import { decimalsValidSchema } from "src/state/expertSystem/strategies/changeSettingsModule/shared/validation";
import { array, object, ObjectSchema, string } from "yup";
import { PartyMarketListMap } from "./types";

const marketSchema: ObjectSchema<IMarket> = object().shape({
  quote: string().required(),
  base: string().required(),
  exchange: string().required(),
});

const marketInfoSchema: ObjectSchema<IMarketInfo> = object().shape({
  market: marketSchema,
  decimals: decimalsValidSchema,
});

export const marketListSchema = array().of(marketInfoSchema);

export const partyMarketListMapSchema: ObjectSchema<PartyMarketListMap> = object().test(
  "is-market-info-array",
  "Must be an array of IMarketInfo",
  (value) => {
    const entriesMap = Object.entries(value);

    for (const [key, value] of entriesMap) {
      const isValidKey = string().isValidSync(key);
      const isValidValue = marketListSchema.isValidSync(value);

      if (!isValidKey || !isValidValue) return false;
    }

    return true;
  }
);
