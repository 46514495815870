import styled from "styled-components";

export const IndicatePlate = styled.div`
  position: absolute;
  height: 100%;
  width: 8px;
  left: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.color};
`;

export const InfoIconWrapper = styled.div`
  color: white;

  svg {
    width: 12px;
    height: 12px;
  }
`;
