import { css } from "styled-components";
import { entries } from "../../utils";

export type Sides = "top" | "right" | "bottom" | "left";

type Spacing = Partial<Record<Sides, string | number>>;

export type Padding = Spacing;
export type Margin = Spacing;

const getSpacingBySide = (side: Sides, spacing?: Spacing) => {
  const value = spacing?.[side];
  if (!value) return undefined;
  if (typeof value === "number") return `${value}px`;
  return value;
};

const getSpacingRuleBySide = (prop: string, side: Sides, spacing?: Spacing) => {
  const value = getSpacingBySide(side, spacing);
  if (!value) return;
  return `${prop}: ${value};`;
};

const getSpacingStyles = (spacingStyles: SpacingStyleMap, spacing?: Spacing) =>
  entries(spacingStyles)
    .map(([side, style]) => getSpacingRuleBySide(style, side, spacing))
    .filter(Boolean)
    .join("");

type SpacingStyleMap = Record<Sides, string>;

const PADDING_SIDE_MAP: SpacingStyleMap = {
  top: "padding-top",
  right: "padding-right",
  bottom: "padding-bottom",
  left: "padding-left",
};

export interface PaddingProps {
  padding?: Padding;
}

export const PaddingMixin = css<PaddingProps>`
  ${({ padding }) => getSpacingStyles(PADDING_SIDE_MAP, padding)};
`;

const MARGIN_SIDE_MAP: SpacingStyleMap = {
  top: "margin-top",
  right: "margin-right",
  bottom: "margin-bottom",
  left: "margin-left",
};

export interface MarginProps {
  margin?: Margin;
}

export const MarginMixin = css<MarginProps>`
  ${({ margin }) => getSpacingStyles(MARGIN_SIDE_MAP, margin)};
`;
