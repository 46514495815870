import { makeAutoObservable } from "mobx";
import { BaseStrategy, IStrategiesProvider } from "src/state/shared/DEX/strategies/types";
import { IArrayFormBinder } from "../../shared/FormBinder/types";

export class StrategiesProvider<T extends BaseStrategy> implements IStrategiesProvider<T> {
  private _binder: IArrayFormBinder<T>;

  constructor(binder: IArrayFormBinder<T>) {
    makeAutoObservable(this);
    this._binder = binder;
  }

  get strategies() {
    return this._binder.values;
  }

  setStrategies = (strategies: T[]) => {
    this._binder.setArray(strategies);
  };

  saveStrategies = async () => true;
}
