import * as Sentry from "@sentry/react";
import { ComponentType, ErrorInfo } from "react";
import { toast } from "src/components/shared/Toaster";
import { CommonKeys } from "src/helpers/utils";
import { CrashPanel } from "./CrashPanel";

export interface ErrorBoundaryProps {
  fallback?: JSX.Element;
}

export interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Sentry.ErrorBoundary {
  static defaultProps: Sentry.ErrorBoundaryProps = {
    fallback: <CrashPanel />,
  };

  componentDidCatch(
    error: Error & {
      cause?: Error;
    },
    errorInfo: ErrorInfo
  ) {
    super.componentDidCatch(error, errorInfo);
    // You can also log the error to an error reporting service
    if (error instanceof Error) toast.error(error.message);
  }
}

export const withErrorBoundary = <P extends JSX.IntrinsicAttributes>(
  Component: CommonKeys<P, ErrorBoundaryProps> extends never ? ComponentType<P> : never
): ComponentType<P & ErrorBoundaryProps> => {
  const WrappedComponent: ComponentType<P & ErrorBoundaryProps> = ({ fallback, ...props }) => {
    const errorBoundaryProps = { fallback };

    const OriginalComponent = Component as ComponentType<P>;

    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <OriginalComponent {...(props as P)} />
      </ErrorBoundary>
    );
  };

  // Format for display in DevTools
  const name = Component.displayName || Component.name || "Unknown";
  WrappedComponent.displayName = `withErrorBoundary(${name})`;

  return WrappedComponent;
};
