import styled from "styled-components";
import { BotPairButton } from "../../shared/BotPairButton";
import { Exchange, Time } from "../../shared/shared";

export const Content = styled.div`
  width: 100%;
  height: 50%;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns:
    minmax(80px, 185px) minmax(50px, 90px) repeat(2, minmax(45px, 90px))
    40px;
  grid-template-rows: 100%;

  color: currentColor;
`;

export const Row = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  padding: 10px 0px 0px 25px;
`;

export const MobExch = styled(Exchange)`
  font-size: ${({ theme }) => theme.fontSizes["16px"]};

  @media (max-width: 750px) {
    font-size: ${({ theme }) => theme.fontSizes["14px"]};
  }

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const TimeMob = styled(Time)`
  grid-area: auto;

  @media (max-width: 750px) {
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const PairMob = styled(BotPairButton)`
  /* width: "100%", */
  margin-bottom: auto;
`;
