import { NavigationTabsIcons } from "src/icons/NavigationTabIcons";
import { LayerType } from "..";
import { useMoveLayer } from "./hooks";
import * as styles from "./styles";

interface BackLayerButtonProps {
  layer: LayerType;
}

export const BackLayerButton = ({ layer }: BackLayerButtonProps) => {
  const moveLayer = useMoveLayer(layer);

  return (
    <styles.NavLayerButton onClick={() => moveLayer()}>
      <NavigationTabsIcons.Row /> Back
    </styles.NavLayerButton>
  );
};
