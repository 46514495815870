import { makeAutoObservable } from "mobx";
import { getShortcutInterval } from "src/components/shared/DatePickers/shared/utils/shortcuts";
import { DurationWithMonth } from "src/helpers/dateUtils";
import { noOp } from "src/helpers/utils";
import { AccountManagerStore } from "src/state/shared/AccountManager";
import { NewFormManualFunding } from "./types";

type UpdCb = () => Promise<void> | void;

export const DEFAULT_FUNDING_RANGE: DurationWithMonth = { month: 1 };
export const getDefaultFundingRefreshRange = () => getShortcutInterval(1, "month");

interface FundingUpdHandlers {
  updFundingList: UpdCb;
  updPnL: UpdCb;
}

type UpdHandlersKeys = keyof FundingUpdHandlers;

type ArrUpdKeys = Array<keyof FundingUpdHandlers>;

type FundingSetCb = (funding: NewFormManualFunding) => void;

export class FundingStore {
  private _party = "";

  accountState: AccountManagerStore;

  updHandlers: FundingUpdHandlers = {
    updFundingList: noOp,
    updPnL: noOp,
  };

  fundingFormCb: FundingSetCb = noOp;

  constructor() {
    this.accountState = new AccountManagerStore(this);

    makeAutoObservable(this);
  }

  get party() {
    return this._party;
  }

  get updKeys() {
    return Object.keys(this.updHandlers) as ArrUpdKeys;
  }

  readonly setUpdHandlers = (key: UpdHandlersKeys, cb: () => void) => {
    this.updHandlers[key] = cb;
  };

  setParty = (party: string) => {
    this._party = party;
  };

  // the method is used in the FundingForm store initialization phase
  setFundingFormCb = (cb: FundingSetCb) => {
    this.fundingFormCb = cb;
  };

  loadData = () => {
    this.accountState.loadAccounts();
  };

  updAllData = () => {
    this.updKeys.forEach((key) => {
      this.updHandlers[key]();
    });
  };

  destroy = () => {};
}
