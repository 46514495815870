import { ICrumbItem } from "./hooks";

export const getReduceString = (pathNames: string[], separator?: string, initValue: string = "/") =>
  pathNames.reduce(
    (accumulator, currentValue) => `${accumulator}${accumulator ? separator : ""}${currentValue}`,
    initValue
  );

export const getPathNames = (url: string) => url.split("/").filter((x) => x);

export const getCrumb = (nameParts: string[], toParts: string[] | string): ICrumbItem => ({
  name: getReduceString(nameParts, " • ", ""),
  to: typeof toParts === "string" ? toParts : getReduceString(toParts, "/"),
});
