import { makeAutoObservable } from "mobx";
import { getLatestBotVersion } from "src/api/bots/DEXV2/chain";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { DEXV2BotVersion } from "src/modules/bots";

export interface ILatestBotVersionProvider {
  get latestBotVersion(): DEXV2BotVersion | null;
  getLatestBotVersion: () => Promise<void>;
}

export class BotVersionStore implements ILatestBotVersionProvider, IDisposable {
  private _botVersion: DEXV2BotVersion | null = null;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { latestBotVersion: true });
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get latestBotVersion() {
    return this._botVersion;
  }

  private _setBotVersion = (botVersion: DEXV2BotVersion) => {
    this._botVersion = botVersion;
  };

  getLatestBotVersion = async () => {
    await this._getLatestBotVersion();
  };

  private _getLatestBotVersion = async () => {
    if (this._loading || this._botVersion) return;

    this._setLoading(true);

    try {
      const { isError, data } = await getLatestBotVersion();
      if (!isError) {
        this._setBotVersion(data.version);
      }
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
