import styled, { css } from "styled-components";

export const BaseHeadline = css`
  display: block;

  margin: 0;

  font-family: inherit;
`;

export const Headline1 = styled.h1`
  ${BaseHeadline}
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.heading1};
  color: ${({ theme }) => theme.bigTextColor};
`;

export const Headline2 = styled.h2`
  ${BaseHeadline}
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes.heading2};
  color: ${({ theme }) => theme.textColor};
`;

export const Headline3 = styled.h3`
  ${BaseHeadline}
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.heading3};
  color: ${({ theme }) => theme.textColor};
`;

export const Headline4 = styled.h4`
  ${BaseHeadline}
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes.heading4};
  color: ${({ theme }) => theme.dashboard.textAdditional};
`;
