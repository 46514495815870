import { Mapper } from "src/helpers/utils";
import {
  ArbitrageExchangeModuleDTO,
  ArbitrageGeneralSettingsDTO,
} from "src/api/algoArbitrage/types";
import { ArbitrageExchangeModule, ArbitrageGeneralSettings } from "./types";

export const arbitrageSettingsMapper: Mapper<
  ArbitrageGeneralSettings,
  ArbitrageGeneralSettingsDTO
> = ({ arbitrageWindow, maxArbitrageAmount, maxOpenPosition, minProfit, fillPercent }) => ({
  arbitrage_window: String(arbitrageWindow),
  max_arbitrage_amount: String(maxArbitrageAmount),
  max_open_position: String(maxOpenPosition),
  min_profit: String(minProfit),
  fill_percent: String(fillPercent),
});

export const exchangeModuleMapper: Mapper<ArbitrageExchangeModule, ArbitrageExchangeModuleDTO> = ({
  accountUUID,
  amountDecimals,
  priceDecimals,
  takerFees,
  minExchangeAmount,
  ...rest
}) =>
  ({
    account_uuid: accountUUID,
    amount_decimals: amountDecimals,
    price_decimals: priceDecimals,
    taker_fees: takerFees,
    min_exchange_amount: minExchangeAmount,
    ...rest,
  }) as ArbitrageExchangeModuleDTO;

export const exchangeModulesMapper: Mapper<
  ArbitrageExchangeModule[],
  ArbitrageExchangeModuleDTO[]
> = (modules) => modules.map(exchangeModuleMapper);
