import { useMemo } from "react";
import { MenuItem } from "../shared/MenuList/MenuItem";
import { MenuList } from "../shared/MenuList/style";
import { PARTY_MENU_ITEMS, useGetPartyCurrentTab, useGetPathParams } from ".";
import { useLoadIndication } from "../shared/hooks";
import { filterWithAbilitiesMenu } from "../shared/utils";

export const PartyMenuList = () => {
  const { party } = useGetPathParams();
  const currentTab = useGetPartyCurrentTab();

  const menuList = useMemo(() => filterWithAbilitiesMenu(PARTY_MENU_ITEMS, party), [party]);

  useLoadIndication({ party, currentTab, menuList });

  return (
    <MenuList>
      {PARTY_MENU_ITEMS.map(({ value, ...other }) => (
        <MenuItem<"party">
          key={value}
          useGetCurrentTab={useGetPartyCurrentTab}
          useGetPathParams={useGetPathParams}
          item={{ value, ...other }}
        />
      ))}
    </MenuList>
  );
};
