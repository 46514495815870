import { makeAutoObservable } from "mobx";
import { getAggregatedFunding } from "src/api/shared/funding";
import { AggregatedFunding, PartyFundingRange } from "../types";
import { IBaseFundingStore } from "./IBaseFundingStore";

export class AggregatedFundingStore implements IBaseFundingStore<AggregatedFunding> {
  private _list: AggregatedFunding[] = [];

  get list() {
    return this._list;
  }

  set list(data: AggregatedFunding[]) {
    this._list = data;
  }

  constructor() {
    makeAutoObservable(this);
  }

  loadFunding = async (fundingParam: PartyFundingRange) => await getAggregatedFunding(fundingParam);

  getSelectFunding = () => {};

  updateFunding = async () => {};

  deleteFundingModal = () => {};

  deleteFundingAction = async () => {};
}
