import styled from "styled-components";
import { TabsContentDirection } from ".";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Panel = styled.div`
  display: flex;
  gap: 5px;

  margin-bottom: 10px;
`;

export const Content = styled.div<Required<TabsContentDirection>>`
  height: calc(100% - 70px);

  display: flex;
  align-items: center;
  flex-direction: ${({ contentDirection }) => contentDirection};
`;
