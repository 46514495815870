import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, { useMemo } from "react";
import { SwitchMenuDirection } from "..";
import * as styles from "./style";

export type MenuItemData = {
  value: string | number;
  label: string;
};

export interface MenuItemProps
  extends Omit<React.ComponentPropsWithoutRef<"input">, "type" | "value">,
    SwitchMenuDirection,
    MenuItemData {
  border: boolean;
}

const MenuItem = observer(({ label, ...props }: MenuItemProps) => {
  const id = useMemo(() => nanoid(), []);
  return (
    <styles.Container>
      <styles.RadioButton {...props} id={id} />
      <styles.Label htmlFor={id}>{label}</styles.Label>
    </styles.Container>
  );
});

export default MenuItem;
