import { GetSupportedExchangesResponse, SupportedExchange } from "src/api/bots/DEXV2/chain";
import { ChainId } from "src/config/chains";
import { Mapper, entries } from "src/helpers/utils";
import { ChainExchangesMap, ExchangeInfo, ExchangeInfosMap } from "./types";

const supportedExchangeRespToExchangeInfo = (
  chainId: number,
  name: string,
  { dex_version, pool_percent, dex_id, version, router, factory }: SupportedExchange
): ExchangeInfo => ({
  name,
  chainId: `${chainId}` as ChainId,
  dexVersion: dex_version,
  botVersion: version,
  poolPercents: pool_percent,
  dexId: dex_id,
  router,
  factory,
});

const supportedExchangesToExchangeInfos = (
  chainId: number,
  exchanges: Record<string, SupportedExchange>
): ExchangeInfosMap => {
  const exchangesEntries = entries(exchanges);

  const dexExchanges: ExchangeInfosMap = Object.fromEntries(
    exchangesEntries.map(([name, exchange]) => [
      name,
      supportedExchangeRespToExchangeInfo(chainId, name, exchange),
    ])
  );
  return dexExchanges;
};

export const supportedExchangesRespToChainsExchanges: Mapper<
  GetSupportedExchangesResponse,
  ChainExchangesMap
> = (chainExchanges) => {
  const chainDexMapsEntries = entries(chainExchanges).map(([chainId, exchanges]) => {
    const exchangesInfo = supportedExchangesToExchangeInfos(chainId, exchanges);
    return [chainId, exchangesInfo] as const;
  });
  const chainDexMaps = Object.fromEntries(chainDexMapsEntries);

  return chainDexMaps;
};
