import dayjs from "dayjs";
import { ChangeEventHandler, useCallback, useState } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { LabeledInputProps } from "src/components/shared/Forms/Inputs";
import { SuggestionLabeledInput } from "src/components/shared/Forms/Variants";
import { DayjsDuration } from "src/config/dayjsConfig";
import { PickerShortcutsProps } from "..";
import { DateTimeRange } from "../../../shared/models/dateTimeRange";
import { DurationFormatError, parseDuration } from "../../../shared/utils/parse-utils";
import * as styles from "./style";

export type DurationState = {
  duration: DayjsDuration;
  error: DurationFormatError;
};

const EMPTY_DURATION = dayjs.duration({});

const EMPTY_DURATION_STATE = {
  duration: EMPTY_DURATION,
  error: null,
};

const useDurationState = () => {
  const [durationState, setDurationState] = useState<DurationState>(EMPTY_DURATION_STATE);

  const onUpdateDuration = useCallback((durationStr: string) => {
    if (!durationStr) {
      setDurationState(EMPTY_DURATION_STATE);
      return;
    }
    const { duration, error } = parseDuration(durationStr);
    if (error) {
      setDurationState({ error, duration: EMPTY_DURATION });
    } else {
      const dayjsDuration = dayjs.duration(duration);
      setDurationState({ error: null, duration: dayjsDuration });
    }
  }, []);

  return [durationState, onUpdateDuration] as const;
};

export interface UseShortcutsInputStateResult {
  input: {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
  };
  duration: DurationState;
  updateState: (value: string) => void;
}

const useShortcutsInputState = (): UseShortcutsInputStateResult => {
  const [inputState, setInputState] = useState("");

  const [durationState, onUpdateDuration] = useDurationState();

  const updateState = useCallback(
    (value: string) => {
      setInputState(value);
      onUpdateDuration(value);
    },
    [onUpdateDuration]
  );

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const newValue = event.target.value;
      updateState(newValue);
    },
    [updateState]
  );

  return {
    input: { value: inputState, onChange: onInputChange },
    duration: durationState,
    updateState,
  };
};

export interface PickerShortcutsInputProps extends Pick<PickerShortcutsProps, "onChange"> {}

export const PickerShortcutsInput = ({ onChange }: PickerShortcutsInputProps) => {
  const { input: inputState, duration: durationState, updateState } = useShortcutsInputState();

  const inputProps: LabeledInputProps = {
    ...inputState,
    errorHint: durationState.error ?? undefined,
    label: "Shortcut",
  };

  const onUpdateRange = useCallback(() => {
    const { duration, error } = durationState;
    if (!error) {
      const updateRange = (): DateTimeRange => {
        const now = dayjs().utc();
        const newDate = now.subtract(duration);
        return [newDate, now];
      };

      const newValue = updateRange();

      onChange?.(newValue, { value: inputState.value, type: "input", resolve: updateRange });
      updateState("");
    }
  }, [durationState, inputState.value, onChange, updateState]);

  return (
    <styles.Container>
      <SuggestionLabeledInput<HTMLButtonElement>
        getSuggestion={(getRef) =>
          inputState.value ? (
            <Button
              size="s"
              ref={getRef}
              onClick={onUpdateRange}
              style={{ position: "absolute", right: "3px" }}
            >
              Save
            </Button>
          ) : null
        }
        onKeyUp={(e) => (e.code === "Enter" ? onUpdateRange() : null)}
        suggestionGap={6}
        {...inputProps}
      />
    </styles.Container>
  );
};
