import { makeAutoObservable } from "mobx";
import { getSelectorList } from "src/helpers/forms/selectors";
import { StringSelectorValue } from "src/state/UserManager/Scopes/EditScopeStore";

export interface IStringSelectorDataProvider {
  get data(): string[];
}

export interface IStringSelectorStore {
  get selectedData(): string[];
  get options(): StringSelectorValue[];
  onChange: (values: readonly StringSelectorValue[]) => void;
  get value(): StringSelectorValue[];
  reset: () => void;
}

export default class StringSelectorStore implements IStringSelectorStore {
  private _provider: IStringSelectorDataProvider;

  private _selectedData: string[] = [];

  constructor(provider: IStringSelectorDataProvider) {
    makeAutoObservable(this);

    this._provider = provider;
  }

  get selectedData() {
    return this._selectedData;
  }

  get data() {
    return this._provider.data;
  }

  get options() {
    return getSelectorList(this._provider.data);
  }

  onChange = (values: readonly StringSelectorValue[]) => {
    const newData = values.map(({ value }) => String(value));
    this._selectedData = newData;
  };

  get value() {
    return getSelectorList(this._selectedData);
  }

  reset = () => {
    this._selectedData = [];
  };
}
