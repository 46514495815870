import styled from "styled-components";

export interface StyledInputProps {
  error?: boolean;
}

export const InputWrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  white-space: nowrap;
`;

export const Input = styled.input<StyledInputProps>`
  width: 100%;
  height: 30px;

  padding: 9px 13px;

  font-size: 1em;
  outline: none;
  border: none;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  background-color: ${({ theme, readOnly }) => {
    if (readOnly) {
      return theme.disableInputBgColor;
    }
    return theme.bgGrayBlack;
  }};

  box-shadow: ${({ theme, error }) => (error ? `0 0 0 1px ${theme.errorLabelColor}` : "none")};

  color: ${({ theme, readOnly }) => {
    if (readOnly) return theme.disableInputColor;

    return theme.inputTextColor;
  }};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  :focus {
    box-shadow: ${({ theme: { errorLabelColor, inputBorderColor }, error }) =>
      `0 0 0 1px ${error ? errorLabelColor : inputBorderColor}`};
  }
`;
