import { useMediaQuery } from "@mui/material";
import { ButtonProps } from "src/components/shared/Buttons/v2/Button";
import Icons from "src/icons/Icons";
import { useTheme } from "styled-components";
import * as styles from "./style";

export interface BotStartButtonProps extends Omit<ButtonProps, "color"> {
  started?: boolean;
  color?: string;
  showCompact?: boolean;
}

const getButtonSize = (isMobile: boolean, isLaptop: boolean) => {
  if (isMobile) return "s";
  if (isLaptop) return "m";
  return "l";
};

export const BotStartButton = ({ started, color, showCompact, ...props }: BotStartButtonProps) => {
  const theme = useTheme();
  const isLaptop = useMediaQuery("(max-width: 1400px)");
  const isMobile = useMediaQuery("(max-width: 850px)");

  const size = getButtonSize(isMobile, isLaptop);

  const statusText = started ? "Stop" : "Start";

  const StatusIcon = started ? <Icons.Pause /> : <Icons.Play />;

  return (
    <styles.StartButton
      $accentColor={started ? theme.botColorStatus.gray : color}
      endIcon={isLaptop && StatusIcon}
      size={showCompact ? "xs" : size}
      elevated={false}
      {...props}
    >
      {!isLaptop && statusText}
    </styles.StartButton>
  );
};
