import { makeAutoObservable } from "mobx";
import ArrayInputStore, {
  ArrayInputFormValidators,
  IArrayInputFormProvider,
  IArrayInputState,
  createArrayInputValidator,
} from "src/state/DEXV2/shared/ArrayInputStore";
import { IArrayFormBinder } from "../FormBinder/types";
import { DEXNEWalletsInputFormAdapter } from "./WalletsInputFormAdapter";

interface IDEXNEWalletInputParams {
  binder: IArrayFormBinder<string>;
  walletName: string;
}

export class DEXNEWalletInputStore {
  private _adapter: IArrayInputFormProvider<string>;

  private _wallet: IArrayInputState<string>;

  constructor({ binder, walletName }: IDEXNEWalletInputParams) {
    makeAutoObservable(this);

    this._adapter = new DEXNEWalletsInputFormAdapter(binder);

    const walletValidators: ArrayInputFormValidators<string> = [
      createArrayInputValidator<string>((wallet, wallets) => {
        const isDuplicate = wallets.includes(wallet);
        if (isDuplicate) {
          return `Duplicate ${walletName}!`;
        }
        return "";
      }),
    ];

    this._wallet = new ArrayInputStore(this._adapter, walletValidators);
  }

  get wallet() {
    return this._wallet;
  }
}
