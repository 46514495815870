import { useEffect } from "react";
import { useAppState } from "src/state";
import ChainProviderStore, { IChainProvider } from "src/state/chain/ChainProviderStore";
import useLocalStore from "./useLocalStore";

export const useChainProvider = () => {
  const { chainsInfoState } = useAppState();
  const provider: IChainProvider = useLocalStore(ChainProviderStore, chainsInfoState);
  useEffect(() => {
    provider.getChainsInfo();
  }, [provider]);
  return provider;
};
