import { DEXV2Ability, DEXV2Routes } from "src/app/constants";
import { NavigationTabsIcons } from "src/icons/NavigationTabIcons";
import { BotContentMenuItem } from "..";
import { BotQueryParams } from "../../../shared/MenuList/MenuItem/types";
import { DEXV2Tabs, getBotLink } from "../../../shared/MenuList/utils";

type DEXV2MenuItem<T extends DEXV2Tabs = DEXV2Tabs> = T extends DEXV2Tabs
  ? BotContentMenuItem<T>
  : never;

const BOT_TYPE = "DEX_V2";

const stats = {
  abilityName: DEXV2Ability.StatsView,
  label: "Stats",
  value: DEXV2Routes.Stats,
  icon: NavigationTabsIcons.Stats,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: DEXV2Routes.Stats, ...pathParam }),
} as DEXV2MenuItem;

const settings = {
  abilityName: DEXV2Ability.SettingsView,
  label: "Settings",
  value: DEXV2Routes.Settings,
  icon: NavigationTabsIcons.Settings,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: DEXV2Routes.Settings, ...pathParam }),
} as DEXV2MenuItem;

const swap = {
  abilityName: DEXV2Ability.ExchangeView,
  label: "Swap",
  value: DEXV2Routes.Swap,
  icon: NavigationTabsIcons.Swap,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: DEXV2Routes.Swap, ...pathParam }),
} as DEXV2MenuItem;

export const DEXV2MenuItems: DEXV2MenuItem[] = [stats, settings, swap];
