import { ConnectStatusKeys } from "src/components/shared/Indicators";
import { getDayjsFromMs } from "src/helpers/dateUtils";

export const calcDelayExchangeResponse = (tsExchange: number, tsGateway: number) => {
  if (tsExchange && tsGateway) {
    const tsExchangeDayjs = getDayjsFromMs(tsExchange);
    const tsGatewayDayjs = getDayjsFromMs(tsGateway);

    return tsGatewayDayjs.diff(tsExchangeDayjs, "millisecond");
  }

  return null;
};

export const getStatusConnection = (delay: number | null): ConnectStatusKeys => {
  if (!delay) return "noConnection";

  if (delay > 225) {
    return "bad";
  }
  if (delay > 120 && delay <= 225) {
    return "normal";
  }
  if (delay > 75 && delay <= 120) {
    return "good";
  }
  if (delay <= 75) {
    return "great";
  }

  return "noConnection";
};
