import { makeAutoObservable } from "mobx";
import { getChainsQuoters } from "src/api/bots/DEXV2/chain";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { chainsQuotersRespToChainRouterQuoters } from "./mappers";
import { ChainRouterQuoters } from "./types";

export interface IChainQuotersProvider {
  get chainQuoters(): ChainRouterQuoters;
  getChainQuoters: () => Promise<void>;
}

export class ChainQuotersStore implements IChainQuotersProvider, IDisposable {
  private _chainRouterQuoters: ChainRouterQuoters | null = null;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { chainQuoters: true });
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _setChainQuoters = (quoters: ChainRouterQuoters) => {
    this._chainRouterQuoters = quoters;
  };

  get chainQuoters(): ChainRouterQuoters {
    return this._chainRouterQuoters ?? {};
  }

  getChainsInfo = async () => {
    try {
      await this._getChainsQuoters();
    } catch (err) {
      logError(err);
    }
  };

  getChainQuoters = async () => {
    await this._getChainsQuoters();
  };

  private _getChainsQuoters = async () => {
    if (this._loading || this._chainRouterQuoters) return;

    this._setLoading(true);
    try {
      const { isError, data } = await getChainsQuoters();
      if (!isError) {
        const chainQuoters = chainsQuotersRespToChainRouterQuoters(data);
        this._setChainQuoters(chainQuoters);
      }
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
