import { BotButtonMixin } from "src/components/AllBots/Bots/shared/BotLinkButton/style";
import { Button } from "src/components/shared/Buttons/v2/Button";
import styled from "styled-components";
import { BotActionButtonMixin } from "../BotTriggerButton/style";

export const StartButton = styled(Button)`
  color: ${({ theme }) => theme.backgroundWhiteColor};

  ${BotButtonMixin}

  ${BotActionButtonMixin}
`;
