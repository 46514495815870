import { PaddingMixin, PaddingProps } from "src/helpers/styled/spacing";
import styled from "styled-components";
import { LabeledInput } from "../../../Inputs";
import { Input } from "../../../Inputs/Input/Input/style";

interface PaddedLabeledInputProps extends PaddingProps {}

export const PaddedLabeledInput = styled(LabeledInput)<PaddedLabeledInputProps>`
  ${Input} {
    ${PaddingMixin}
  }
`;
