import { entries, keys } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { ChainExchangesMap } from "./ChainExchanges/types";
import { ChainRouterQuoters } from "./ChainQuoters/types";
import { ChainInfosMap } from "./Chains/types";
import { ChainQuoters, ExchangeQuoterMap } from "./types";

export const chainRouterQuotersToChainQuoters = (
  chainRouterQuoters: ChainRouterQuoters,
  chainExchanges: ChainExchangesMap
): ChainQuoters =>
  entries(chainExchanges).reduce((acc, entry) => {
    if (!entry) {
      return acc;
    }

    const [chainId, exchanges] = entry;

    const routerQuoters = chainRouterQuoters[chainId];
    if (!exchanges || !routerQuoters) {
      return acc;
    }

    const exchangeQuoterMap = entries(exchanges).reduce((acc, [exchangeName, exchangeInfo]) => {
      if (!exchangeInfo) {
        return acc;
      }

      const quoter = routerQuoters[exchangeInfo.router];
      if (quoter) {
        acc[exchangeName] = quoter;
      }
      return acc;
    }, {} as ExchangeQuoterMap);
    acc[chainId] = exchangeQuoterMap;
    return acc;
  }, {} as ChainQuoters);

export const filterSupportedChainExchanges = (
  chainExchanges: ChainExchangesMap,
  latestBotVersion: string
): ChainExchangesMap =>
  filterBoolean(entries(chainExchanges)).reduce((acc, [chainId, exchanges]) => {
    if (!exchanges) {
      return acc;
    }

    const supportedExchanges = Object.fromEntries(
      filterBoolean(entries(exchanges)).filter(
        ([_, exchange]) => exchange?.botVersion === latestBotVersion
      )
    );

    if (keys(supportedExchanges).length > 0) {
      acc[chainId] = supportedExchanges;
    }

    return acc;
  }, {} as ChainExchangesMap);

export const filterSupportedChains = (
  chains: ChainInfosMap,
  supportedChainExchanges: ChainExchangesMap
): ChainInfosMap =>
  filterBoolean(entries(chains)).reduce((acc, [chainId, chain]) => {
    if (supportedChainExchanges?.[chainId]) {
      acc[chainId] = chain;
    }
    return acc;
  }, {} as ChainInfosMap);
