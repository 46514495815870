import { DEXAbilities, DEXRoutes } from "src/app/constants";
import { NavigationTabsIcons } from "src/icons/NavigationTabIcons";
import { BotContentMenuItem } from "..";
import { BotQueryParams } from "../../../shared/MenuList/MenuItem/types";
import { DEXTabs, getBotLink } from "../../../shared/MenuList/utils";

type DEXMenuItem<T extends DEXTabs = DEXTabs> = T extends DEXTabs ? BotContentMenuItem<T> : never;

const BOT_TYPE = "DEX";

const stats = {
  abilityName: DEXAbilities.StatsView,
  label: "Stats",
  value: DEXRoutes.Stats,
  icon: NavigationTabsIcons.Stats,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: DEXRoutes.Stats, ...pathParam }),
} as DEXMenuItem;

const settings = {
  abilityName: DEXAbilities.SettingsView,
  label: "Settings",
  value: DEXRoutes.Settings,
  icon: NavigationTabsIcons.Settings,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: DEXRoutes.Settings, ...pathParam }),
} as DEXMenuItem;

const funding = {
  abilityName: DEXAbilities.FundingView,
  label: "Funding",
  value: DEXRoutes.Funding,
  icon: NavigationTabsIcons.Funding,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: DEXRoutes.Funding, ...pathParam }),
} as DEXMenuItem;

export const DEXMenuItems: DEXMenuItem[] = [stats, settings, funding];
