import { useLayoutEffect } from "react";

export const useClosePopup = (
  el: React.RefObject<HTMLDivElement>,
  setShow: (value: React.SetStateAction<boolean>) => void
) => {
  useLayoutEffect(() => {
    const cb = (e: MouseEvent) => {
      if (!e.composedPath().find((elem) => elem === el.current)) {
        setShow(false);
      }
    };

    document.addEventListener("click", cb);

    return () => {
      document.removeEventListener("click", cb);
    };
  });
};
