import { entries } from "src/helpers/utils";
import {
  AccountApi,
  BaseBotAccountName,
  BotAccountName,
  BotAccountsMap,
  isLiquidityAccountName,
  isVolumeAccountName,
} from "src/modules/accounts";

export const sortAccounts = (a: string, b: string) => {
  const firstCheck = priorityAccCheck(a);
  const secondCheck = priorityAccCheck(b);

  return secondCheck - firstCheck;
};

export const priorityAccCheck = (accName: string) => {
  if (isLiquidityAccountName(accName)) return 2;
  if (isVolumeAccountName(accName)) return 1;

  return 0;
};

export const filterBaseNames = (
  accountName: BotAccountName,
  baseNames: BaseBotAccountName[],
  exclude: boolean = true
) => {
  const nameMatch = baseNames.some((name) => accountName.startsWith(name));
  return exclude ? !nameMatch : nameMatch;
};

export const filterBaseNameAccounts = (
  accountsMap: BotAccountsMap,
  names: BaseBotAccountName[],
  exclude: boolean = true
): BotAccountsMap => {
  const accountsEntries = entries(accountsMap).filter(Boolean) as [BotAccountName, AccountApi][];

  const filteredAccountsEntries = accountsEntries.filter(([accountName]) =>
    filterBaseNames(accountName, names, exclude)
  );

  return Object.fromEntries(filteredAccountsEntries);
};
