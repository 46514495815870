import { makeAutoObservable } from "mobx";
import { Pair } from "src/api/dexScreener";
import { IDisposable } from "src/helpers/utils";
import { IObservableCache, ObservableTTLCache } from "src/state/shared/Cache";
import { DEXNEChainsInfoStore, IDEXNEChainsInfoProvider } from "../Info";

const PAIR_PRICE_TTL = 30 * 1000;

export interface IDEXNECacheProvider {
  screenerPairCache: IObservableCache<Pair>;
}

export interface IDEXNERootProvider extends IDEXNECacheProvider {
  chainsInfo: IDEXNEChainsInfoProvider;
}

export class DEXNERootProvider implements IDisposable, IDEXNERootProvider {
  private _chainsInfo: IDEXNEChainsInfoProvider & IDisposable;

  private _screenerPairCache: IObservableCache<Pair>;

  constructor() {
    makeAutoObservable(this);

    this._chainsInfo = new DEXNEChainsInfoStore();

    this._screenerPairCache = new ObservableTTLCache({
      ttl: PAIR_PRICE_TTL,
    });
  }

  get chainsInfo() {
    return this._chainsInfo;
  }

  get screenerPairCache() {
    return this._screenerPairCache;
  }

  destroy = () => {
    this._chainsInfo.destroy();
    this._screenerPairCache.clear();
  };
}
