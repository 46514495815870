import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { tryParseCurrencyAmount } from "src/state/shared/DEX/Swap";
import { PairPrice } from "../TradePairUSDPriceProvider";

export type ParseQuoteAmount = CurrencyAmount<Token> | string | number | undefined;

export type BasePairQuote = {
  usd?: CurrencyAmount<Token>;
  quote?: CurrencyAmount<Token>;
  raw?: CurrencyAmount<Token>;
};

interface ParseBaseQuoteParams {
  value: ParseQuoteAmount;
  token: Token | undefined;
  price: PairPrice["base"] | undefined;
}

export const parseBaseQuote = ({ value, token, price }: ParseBaseQuoteParams): BasePairQuote => {
  const rawBase =
    typeof value === "object" ? value : tryParseCurrencyAmount(value?.toString(), token);

  if (!rawBase) {
    return {};
  }

  if (!price) return { raw: rawBase };

  const baseUSDAmount = price.usd.quote(rawBase);

  const baseQuoteAmount = price.quote.quote(rawBase);

  return { usd: baseUSDAmount, quote: baseQuoteAmount, raw: rawBase };
};

export type QuotePairQuote = {
  usd?: CurrencyAmount<Token>;
  raw?: CurrencyAmount<Token>;
};

interface ParseQuoteQuoteParams {
  value: ParseQuoteAmount;
  token: Token | undefined;
  price: PairPrice["quote"] | undefined;
}

export const parseQuoteQuote = ({ value, token, price }: ParseQuoteQuoteParams): QuotePairQuote => {
  const rawQuote =
    typeof value === "object" ? value : tryParseCurrencyAmount(value?.toString(), token);

  if (!rawQuote) {
    return {};
  }

  if (!price) return { raw: rawQuote };

  const quoteUSDAmount = price.usd.quote(rawQuote);

  return { usd: quoteUSDAmount, raw: rawQuote };
};
