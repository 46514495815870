import { buildQueryUrl } from "src/helpers/url";
import { Mapper } from "src/helpers/utils";
import {
  IActionModuleCreate,
  IActionModuleInfo,
  IConditionModuleCreate,
  IConditionModuleInfo,
  IStrategyStore,
  IStrategyCreator,
} from "src/modules/expertSystem";
import { ApiResponse } from "src/modules/network";
import { ExpiredStrategyParam } from "src/state/expertSystem/strategies";
import ResponseHandler from "src/state/network/ResponseHandler";
import { FilterParams, PaginationMetaResponse, PaginationParams, SortParams } from "../types";
import { ApiModules, apiUrl } from "..";

export interface FiltersParams extends FilterParams<"type" | "status"> {}

export interface ModulesSortingParams extends SortParams<"name" | "created_at"> {}

interface GetModulesBaseRequestParams
  extends Partial<PaginationParams & FiltersParams & ModulesSortingParams> {}

export interface GetModulesRequestParams {
  pagination?: PaginationParams;
  filter?: FiltersParams;
  sort?: ModulesSortingParams;
}

const modulesRequestParamsToBaseParams: Mapper<
  GetModulesRequestParams,
  GetModulesBaseRequestParams
> = ({ pagination, filter, sort }) => ({
  ...pagination,
  ...filter,
  ...sort,
});

export type ConditionResponse = IConditionModuleInfo;

export type GetConditionsResponse = {
  items: ConditionResponse[];
  meta: PaginationMetaResponse;
};

interface GetConditionsRequestParams extends GetModulesRequestParams {}

export const getConditions = async (
  party: string,
  params?: GetConditionsRequestParams
): Promise<ApiResponse<GetConditionsResponse>> => {
  const queryParams = params ? modulesRequestParamsToBaseParams(params) : undefined;

  const url = buildQueryUrl(`${party}/conditions`, queryParams);

  return ResponseHandler.handler<GetConditionsResponse>({
    url: apiUrl(ApiModules.Parties, url),
    method: "GET",
  });
};

// export const getMockConditions = async (
//   party: string
// ): Promise<ApiResponse<GetConditionsResponse>> => {
//   return { isError: false, data: generateConditionModulesData() };
// };

type AddConditionRequest = IConditionModuleCreate;

interface AddConditionResponse {
  uuid: string;
}

export const addCondition = async (data: AddConditionRequest) =>
  ResponseHandler.handler<AddConditionResponse>({
    url: apiUrl(ApiModules.Empty, "/conditions"),
    method: "POST",
    data,
  });

interface DeleteConditionResponse {}

export const deleteCondition = async (uuid: string) =>
  ResponseHandler.handler<DeleteConditionResponse>({
    url: apiUrl(ApiModules.Empty, `/conditions/${uuid}`),
    method: "DELETE",
  });

export type ActionResponse = IActionModuleInfo;

export type GetActionsResponse = {
  items: ActionResponse[];
  meta: PaginationMetaResponse;
};

interface GetActionsRequestParams extends GetModulesRequestParams {}

export const getActions = async (
  party: string,
  params?: GetActionsRequestParams
): Promise<ApiResponse<GetActionsResponse>> => {
  const queryParams = params ? modulesRequestParamsToBaseParams(params) : undefined;

  const url = buildQueryUrl(`${party}/actions`, queryParams);

  return ResponseHandler.handler<GetActionsResponse>({
    url: apiUrl(ApiModules.Parties, url),
    method: "GET",
  });
};

// export const getMockActions = async (
//   party: string
// ): Promise<ApiResponse<GetActionsResponse>> => {
//   return { isError: false, data: generateActionModulesData() };
// };

type AddActionRequest = IActionModuleCreate;

interface AddActionResponse {
  uuid: string;
}

export const addAction = async (data: AddActionRequest) =>
  ResponseHandler.handler<AddActionResponse>({
    url: apiUrl(ApiModules.Empty, "/actions"),
    method: "POST",
    data,
  });

interface DeleteActionResponse {}

export const deleteAction = async (uuid: string) =>
  ResponseHandler.handler<DeleteActionResponse>({
    url: apiUrl(ApiModules.Empty, `/actions/${uuid}`),
    method: "DELETE",
  });

type AddStrategyRequest = IStrategyCreator;
interface AddStrategyResponse {
  uuid: string;
}

export const addStrategy = async (data: AddStrategyRequest) =>
  ResponseHandler.handler<AddStrategyResponse>({
    url: apiUrl(ApiModules.Empty, "/strategies"),
    method: "POST",
    data,
  });

export interface StrategiesSortingParams extends Omit<ModulesSortingParams, "sort_by"> {
  sort_by: "name" | "created_at" | "expired_at";
}

interface GetStrategiesBaseRequestParams
  extends Partial<PaginationParams & FiltersParams & StrategiesSortingParams> {}

export interface GetStrategiesRequestParams {
  pagination?: PaginationParams;
  filter?: FiltersParams;
  sort?: StrategiesSortingParams;
}

const strategiesRequestParamsToBaseParams: Mapper<
  GetStrategiesRequestParams,
  GetStrategiesBaseRequestParams
> = ({ pagination, filter, sort }) => ({
  ...pagination,
  ...filter,
  ...sort,
});

export type GetStrategiesResponse = {
  items: IStrategyStore[];
  meta: PaginationMetaResponse;
};

export const getStrategies = async (party: string, params?: GetStrategiesRequestParams) => {
  const queryParams = params ? strategiesRequestParamsToBaseParams(params) : undefined;

  const url = buildQueryUrl(`/${party}/strategies`, queryParams);

  return ResponseHandler.handler<GetStrategiesResponse>({
    url: apiUrl(ApiModules.Parties, url),
    method: "GET",
  });
};

export type GetStrategyResponse = IStrategyStore;

export const getStrategy = async (uuid: string) =>
  ResponseHandler.handler<GetStrategyResponse>({
    url: apiUrl(ApiModules.Empty, `strategies/${uuid}`),
    method: "GET",
  });

type StartStrategyResponse = {};

export const startStrategy = async (uuid: string) =>
  ResponseHandler.handler<StartStrategyResponse>({
    url: apiUrl(ApiModules.Empty, `/strategies/${uuid}/start`),
    method: "POST",
  });

type StopStrategyResponse = {};

export const stopStrategy = async (uuid: string) =>
  ResponseHandler.handler<StopStrategyResponse>({
    url: apiUrl(ApiModules.Empty, `/strategies/${uuid}/stop`),
    method: "POST",
  });

interface DeleteStrategyResponse {}

export const deleteStrategy = async (uuid: string) =>
  ResponseHandler.handler<DeleteStrategyResponse>({
    url: apiUrl(ApiModules.Empty, `/strategies/${uuid}`),
    method: "DELETE",
  });

interface ExtendStrategyResponse {}

export const extendStrategy = async (data: ExpiredStrategyParam, uuid: string) =>
  ResponseHandler.handler<ExtendStrategyResponse>({
    url: apiUrl(ApiModules.Empty, `/strategies/${uuid}/expired`),
    method: "PUT",
    data,
  });

export interface UpdateStrategyLaunchesLimitRequest {
  limit: number;
}

export const updateStrategyLaunchesLimit = async (
  data: UpdateStrategyLaunchesLimitRequest,
  uuid: string
) =>
  ResponseHandler.handler<ExtendStrategyResponse>({
    url: apiUrl(ApiModules.Empty, `/strategies/${uuid}/launches`),
    method: "PUT",
    data,
  });
