import { makeAutoObservable } from "mobx";
import { Pair } from "src/api/dexScreener";
import { IDisposable } from "src/helpers/utils";
import { IObservableCache, ObservableTTLCache } from "src/state/shared/Cache";

const PAIR_PRICE_TTL = 30 * 1000;
const NATIVE_PRICE_USD_TTL = 5 * 60 * 1000;

export interface IDEXV2BotsCacheProvider {
  get screenerPairCache(): IObservableCache<Pair>;
  get nativePriceUSDCache(): IObservableCache<string>;
}

export default class DEXV2BotsCacheProvider implements IDisposable, IDEXV2BotsCacheProvider {
  private _screenerPairCache: IObservableCache<Pair>;

  private _nativePriceUSDCache: IObservableCache<string>;

  constructor() {
    makeAutoObservable(this);

    this._screenerPairCache = new ObservableTTLCache({
      ttl: PAIR_PRICE_TTL,
    });

    this._nativePriceUSDCache = new ObservableTTLCache({
      ttl: NATIVE_PRICE_USD_TTL,
    });
  }

  get screenerPairCache() {
    return this._screenerPairCache;
  }

  get nativePriceUSDCache() {
    return this._nativePriceUSDCache;
  }

  destroy = () => {
    this._screenerPairCache.clear();
    this._nativePriceUSDCache.clear();
  };
}
