import { ComponentPropsWithoutRef } from "react";
import { ModeInfoItemData } from "..";
import { BaseModeInfoItem } from "./BaseModeInfoItem";
import { TimeModeInfoItem } from "./TimeModeInfoItem";

interface ModeInfoItemOwnProps {
  item: ModeInfoItemData;
}

export interface ModeInfoItemProps extends ModeInfoItemOwnProps, ComponentPropsWithoutRef<"div"> {}

const getModeInfoItem = ({ item, ...props }: ModeInfoItemProps) => {
  switch (typeof item.value) {
    case "object": {
      const { label, value } = item;
      return <TimeModeInfoItem label={label} {...value} {...props} />;
    }
    case "string": {
      const { label, value } = item;
      return <BaseModeInfoItem label={label} value={value} {...props} />;
    }
  }
};

export const ModeInfoItem = ({ item, ...props }: ModeInfoItemProps) => {
  const Item = getModeInfoItem({ item, ...props });
  return Item;
};
