import { setTextClipboard } from "src/helpers/clipboard";
import * as styles from "./style";

interface CopyStringProps extends React.HTMLAttributes<HTMLSpanElement> {
  copyValue: string | number;
}

export const CopyString = ({ copyValue, children, ...props }: CopyStringProps) => {
  const onCopy = () => setTextClipboard(String(copyValue));

  return (
    <styles.StyledSpan
      role="button"
      tabIndex={0}
      onClick={onCopy}
      onKeyDown={(e) => e.key === "Enter" && onCopy()}
      title="Copy"
      {...props}
    >
      {children}
    </styles.StyledSpan>
  );
};
