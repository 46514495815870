import { darken } from "polished";
import { ButtonContent } from "src/components/shared/Buttons/v2/Button/style";
import styled, { css } from "styled-components";
import { BotLinkButton, BotLinkButtonProps } from "../BotLinkButton";

const BackgroundColorMixin = ({ color }: Pick<BotLinkButtonProps, "color">) => css`
  ${({ theme }) => {
    const mainColor = color ?? theme.botColorStatus.gray;

    const enabledAlpha = mainColor;
    const hoverAlpha = darken(0.05, mainColor);
    const focusedAlpha = darken(0.1, mainColor);
    const activeAlpha = darken(0.15, mainColor);

    return css`
      background-color: ${enabledAlpha};

      :hover {
        background-color: ${hoverAlpha};
      }

      :active {
        background-color: ${activeAlpha};
      }

      :focus-visible {
        background-color: ${focusedAlpha};
      }
    `;
  }}
`;

export const StyledBotPairButton = styled(BotLinkButton)`
  height: 35px;

  font-weight: ${({ theme }) => theme.fontWeights["600"]};
  color: ${({ theme }) => theme.backgroundWhiteColor};

  ${BackgroundColorMixin}

  ${ButtonContent} {
    display: block;

    font-size: 1.5vmin;
    line-height: initial;

    word-break: break-word;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
