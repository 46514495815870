import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: 445px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;

  overflow: auto;

  ${ScrollSize}

  @media (max-width: 650px) {
    max-height: 120px;
  }
`;
