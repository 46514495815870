import styled from "styled-components";

export interface ChipContainerProps {
  color?: string;
}

export const ChipContainer = styled.div<ChipContainerProps>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: ${({ theme, color }) => color ?? theme.inputTextColor};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    height: 30px;
    width: 30px;
  }
`;

export const Text = styled.span`
  margin: 0;
  padding: 0;

  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: currentColor;
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
`;
