import styled from "styled-components";
import { Headline3 } from "../Typography";
import { ModalBackground, PanelContainer } from "../shared";

export const Background = styled(ModalBackground)`
  z-index: 9999;
`;

export const Container = styled(PanelContainer)`
  max-width: 573px;

  display: flex;
  flex-direction: column;

  gap: 60px;

  padding: 47px;

  @media screen and (max-width: 450px) {
    width: calc(100% - 20px);
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Headline3)`
  text-align: center;
`;

export const Message = styled.p`
  text-align: center;

  font-weight: ${({ theme }) => theme.fontWeights["500"]};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  color: ${({ theme }) => theme.labelColor};

  white-space: pre-line;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 27px;
`;
