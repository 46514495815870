import { createPortal } from "react-dom";
import Icons from "src/icons/Icons";
import { CEXBotWarning } from "src/modules/bots";
import { WarningTooltip } from "./WarningTooltip";
import * as styles from "./style";

interface BotWarningsProps {
  botId: string;
  warnings: CEXBotWarning[];
}

const WARNINGS_TOOLTIP = "warnings-tooltip";

export const BotWarnings = ({ botId, warnings }: BotWarningsProps) => {
  const tooltipId = `${WARNINGS_TOOLTIP}-${botId}`;

  if (!warnings?.length) return <div />;

  return (
    <styles.WarningsContainer>
      <styles.WarningsWrapper data-tooltip-id={tooltipId}>
        <styles.IconWrapper>
          <Icons.WarningFilled />
        </styles.IconWrapper>

        <styles.WarningsCount>{warnings.length}</styles.WarningsCount>
      </styles.WarningsWrapper>

      {createPortal(<WarningTooltip tooltipId={tooltipId} warnings={warnings} />, document.body)}
    </styles.WarningsContainer>
  );
};
