import { observer } from "mobx-react-lite";
import useElementSize from "src/hooks/useElementSize";
import { LabeledInput, LabeledInputProps } from "../../../Inputs";
import * as styles from "./style";

export interface InputSlotProps extends Omit<LabeledInputProps, "after"> {}

export interface SuggestionLabeledInputOwnProps<T extends HTMLElement> {
  getSuggestion: (setRef: (node: T | null) => void) => null | JSX.Element;
  suggestionGap?: number;
  input?: React.ComponentType<InputSlotProps>;
}

export interface SuggestionLabeledInputProps<T extends HTMLElement>
  extends InputSlotProps,
    SuggestionLabeledInputOwnProps<T> {}

export const SuggestionLabeledInput = observer(
  <T extends HTMLElement = HTMLDivElement>({
    getSuggestion,
    suggestionGap: gap = 0,
    input,
    ...props
  }: SuggestionLabeledInputProps<T>) => {
    const [setSuggestionRef, suggestionSize] = useElementSize<T>(200);

    const suggestionWidth = suggestionSize.width > 0 ? suggestionSize.width + gap : undefined;

    const suggestion = getSuggestion(setSuggestionRef);

    const CustomInput = input ?? LabeledInput;

    return (
      <styles.PaddedLabeledInput
        {...props}
        as={CustomInput}
        padding={{ right: suggestionWidth }}
        after={suggestion}
      />
    );
  }
);
