export enum ChainId {
  Ethereum = "1",
  BSC = "56",
  BSCTestnet = "97",
  opBNB = "204",
  Avalanche = "43114",
  Fantom = "250",
  Polygon = "137",
  Arbitrum = "42161",
  Base = "8453",
  zkSync = "324",
  Mantle = "5000",
  Core = "1116",
  Kava = "2222",
  Linea = "59144",
  LineaTestnet = "59140",
  Metis = "1088",
  Blast = "81457",
}

export const toChainId = (rawChainId: string | number) => `${rawChainId}` as ChainId;
