import { ChainsConfigResponse, GetChainsConfigsResponse } from "src/api/bots/DEXV2/chain";
import { ChainId, toChainId } from "src/config/chains";
import { Mapper, entries } from "src/helpers/utils";
import { ChainConfig, ChainConfigsMap } from "./types";

const chainsConfigRespToChainConfig: Mapper<ChainsConfigResponse, ChainConfig> = ({
  abbr,
  colour,
  pic,
  chain_id,
  address_helper,
  backend_helper,
  balance_helper,
  price_feed_addr,
}) => {
  const id = toChainId(chain_id);
  return {
    id,
    meta: {
      abbr,
      color: colour,
      icon: pic,
    },
    contracts: {
      addressHelper: address_helper,
      dexLens: backend_helper.map(({ version, addr }) => ({
        botVersion: version,
        address: addr,
      })),
      balanceHelper: balance_helper,
      nativePriceFeed: price_feed_addr ?? undefined,
    },
  };
};

export const chainsConfigRespToChainConfigs: Mapper<GetChainsConfigsResponse, ChainConfigsMap> = (
  resp
) =>
  resp.reduce((acc, chain) => {
    const chainConfig = chainsConfigRespToChainConfig(chain);
    acc[chainConfig.id] = chainConfig;
    return acc;
  }, {} as ChainConfigsMap);

export const filterEmptyChainConfigs = (chainConfigs: ChainConfigsMap): ChainConfigsMap => {
  const nonEmptyChainsEntries = entries(chainConfigs).filter((entry) => {
    if (!entry) return false;
    const [, chainConfig] = entry;
    return chainConfig && chainConfig.contracts.addressHelper && chainConfig.meta.icon;
  }) as [ChainId, ChainConfig][];

  return Object.fromEntries(nonEmptyChainsEntries);
};
