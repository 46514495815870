import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { DEXV2Routes, DEXV2Ability } from "src/app/constants";
import { getBotLink } from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { Loader } from "src/components/shared/Loader";
import { AllBotsContext } from "src/context/AllBots";
import { joinMarket } from "src/helpers/botName";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useBotsVirtualizer } from "src/components/AllBots/hooks/useBotsVirtualizer";
import { Container, Content, ContentScrollWrapper } from "../shared/shared";
import {
  DEXCommonBot,
  DEXCommonBotMob,
  DEXCommonBotProps,
  DEXCommonConfigProps,
} from "../shared/DEXCommon";
import { HeaderCell } from "../Header";
import { getDEXV2ModesProps } from "./utils";
import * as styles from "./style";

export const DEXV2_HEADER_INFO_CELLS: HeaderCell[] = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Chain", icon: "" },
  { title: "Limit", icon: "" },
  { title: "Volume", icon: "" },
  { title: "Counter", icon: "" },
];

export const DEXV2_BOT_TEMPLATE_COLUMNS =
  "minmax(150px, 1fr) minmax(120px, 1fr) minmax(150px, 1fr) repeat(3, minmax(90px, 1fr)) 70px auto";

const MOBILE_COMPACT_QUERY = getMobileMediaQuery("900px");

export const dexV2BotsConfigProps: DEXCommonConfigProps = {
  abilities: DEXV2Ability,
  routes: DEXV2Routes,
  botType: "DEX_V2",
  botTemplateColumnsStyle: DEXV2_BOT_TEMPLATE_COLUMNS,
};

export const DEXV2Bots = observer(() => {
  const { realtimeEnabled, dexV2BotsState: state } = useLateInitContext(AllBotsContext);

  const showMobile = useMediaQuery(getMobileMediaQuery("1150px"));

  const {
    scrollParentRef,
    virtualizer: botsVirtualizer,
    getVirtualRowStyles,
  } = useBotsVirtualizer({
    heights: { mobile: 85, desktop: 88 },
    isMobile: showMobile,
    size: state.bots.length,
  });

  const getBotInfoContainer = useCallback(() => scrollParentRef.current, [scrollParentRef]);

  useEffect(() => {
    if (realtimeEnabled) state.resumeBotsFetching();
    else state.suspendBotsFetching();

    return () => {
      if (realtimeEnabled) state.suspendBotsFetching();
    };
  }, [realtimeEnabled, state]);

  const bots = botsVirtualizer.virtualItems.map((virtualRow) => {
    const bot = state.bots[virtualRow.index];
    const {
      limitModeProps: limitMode,
      volumeModeProps: volumeMode,
      counterModeProps: counterMode,
      otherProps,
    } = getDEXV2ModesProps(bot);
    const {
      base,
      base_addr,
      quote,
      quote_addr,
      pair_addr: pairAddr,
      dex_version: dexVersion,
      bot_uuid: botUUID,
      ...botOtherProps
    } = otherProps;
    const { bot_uuid, name, exchange } = bot;

    const statsLink = getBotLink({
      botType: "DEX_V2",
      party: bot.party,
      uuid: bot.bot_uuid,
      botTab: DEXV2Routes.Stats,
    });

    const market = joinMarket({ quote, base, exchange });

    const credential = { market, name, UUID: bot_uuid, link: statsLink };

    const botProps: DEXCommonBotProps = {
      credential,
      favorite: true,
      limitMode,
      volumeMode,
      counterMode,
      pairAddr,
      dexVersion,
      botUUID,
      toggleIsWatched: state.toggleIsWatched,
      getInfoContainer: getBotInfoContainer,
      ...botOtherProps,
      ...dexV2BotsConfigProps,
      ...getVirtualRowStyles(virtualRow),
    };

    return showMobile ? (
      <DEXCommonBotMob key={bot_uuid} compactQuery={MOBILE_COMPACT_QUERY} {...botProps} />
    ) : (
      <DEXCommonBot key={bot_uuid} {...botProps} />
    );
  });
  return (
    <Container>
      {!showMobile ? (
        <styles.DEXV2HeaderMain
          favorite
          toggleWatchList={state.toggleWatchList}
          watchListEnabled={state.watchListEnabled}
          cells={DEXV2_HEADER_INFO_CELLS}
          $botTemplateColumnsStyle={DEXV2_BOT_TEMPLATE_COLUMNS}
        />
      ) : null}
      <Content ref={scrollParentRef}>
        <ContentScrollWrapper $height={botsVirtualizer.totalSize}>{bots}</ContentScrollWrapper>
      </Content>
      <Loader show={state.loading && state.firstLoad} />
    </Container>
  );
});
