import { CEXAbilities, CEXRoutes, PartyPagesAbilities } from "src/app/constants";
import { NavigationTabsIcons } from "src/icons/NavigationTabIcons";
import { BotContentMenuItem } from "..";
import { BotQueryParams } from "../../../shared/MenuList/MenuItem/types";
import { CEXTabs, getBotLink, getPartySectionLink } from "../../../shared/MenuList/utils";

type CEXMenuItem<T extends CEXTabs = CEXTabs> = T extends CEXTabs ? BotContentMenuItem<T> : never;

const BOT_TYPE = "CEX";

const apiKeys = {
  abilityName: CEXAbilities.ApiKeysView,
  label: "API",
  value: CEXRoutes.APIKeys,
  icon: NavigationTabsIcons.ApiKeys,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: CEXRoutes.APIKeys, ...pathParam }),
} as CEXMenuItem;

const stats = {
  abilityName: CEXAbilities.StatsView,
  label: "Stats",
  value: CEXRoutes.Stats,
  icon: NavigationTabsIcons.Stats,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: CEXRoutes.Stats, ...pathParam }),
} as CEXMenuItem;

const settings = {
  abilityName: CEXAbilities.SettingsView,
  label: "Settings",
  value: CEXRoutes.Settings,
  indicationName: "cexSettings",
  icon: NavigationTabsIcons.Settings,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: CEXRoutes.Settings, ...pathParam }),
} as CEXMenuItem;

const exchange = {
  abilityName: CEXAbilities.ExchangeView,
  label: "Exchange",
  value: CEXRoutes.Exchange,
  icon: NavigationTabsIcons.Exchange,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: CEXRoutes.Exchange, ...pathParam }),
} as CEXMenuItem;

const grid = {
  abilityName: CEXAbilities.GridView,
  label: "Grid",
  value: CEXRoutes.Grid,
  indicationName: "cexGrid",
  icon: NavigationTabsIcons.Grid,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: CEXRoutes.Grid, ...pathParam }),
} as CEXMenuItem;

const multigrid = {
  abilityName: PartyPagesAbilities.MultigridView,
  label: "Multi Grid",
  value: CEXRoutes.Multigrid,
  indicationName: "multigrid",
  icon: NavigationTabsIcons.MultiGrid,
  to: ({ party }: BotQueryParams) => getPartySectionLink({ partySection: "Multigrid", party }),
} as CEXMenuItem;

const floatGrid = {
  abilityName: CEXAbilities.ExchangeView,
  label: "Float Grid",
  value: CEXRoutes.FloatGrid,
  icon: NavigationTabsIcons.FloatGrid,
  to: (pathParam: BotQueryParams) =>
    getBotLink({ botType: BOT_TYPE, botTab: CEXRoutes.FloatGrid, ...pathParam }),
} as CEXMenuItem;

export const CEXMenuItems: CEXMenuItem[] = [stats, exchange, settings, grid, apiKeys, floatGrid];
export const AdditionalCEXMenuItems: CEXMenuItem[] = [multigrid];
