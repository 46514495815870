import { IDEXNEBotDTO } from "src/state/DEX_NE/bots";
import { buildQueryUrl } from "src/helpers/url";
import { ApiModules, apiUrl } from "src/api";
import responseHandler from "src/state/network/ResponseHandler";

export const getDEXNEStatus = async (botUuid?: string) =>
  responseHandler.handler<IDEXNEBotDTO>({
    url: apiUrl(ApiModules.DEXNEBot, `${botUuid}/status`),
    method: "GET",
  });

interface IGetDEXNEStatusesResponse {
  statuses: IDEXNEBotDTO[];
}

export const getDEXNEStatuses = async (party?: string) => {
  const url = buildQueryUrl("/status", { party });

  return responseHandler.handler<IGetDEXNEStatusesResponse>(
    {
      url: apiUrl(ApiModules.DEXNEBots, url),
      method: "GET",
    },
    "getDEXNEBotStatuses"
  );
};

type GetExchangesResponse = {
  exchanges: string[];
};

export const getDEXNEExchanges = async (party?: string) => {
  const url = buildQueryUrl("/exchanges/list", { party });

  return responseHandler.handler<GetExchangesResponse>(
    {
      url: apiUrl(ApiModules.DEXNE, url),
      method: "GET",
    },
    "getExchangesDEXNE"
  );
};

type GetBasesResponse = {
  bases: string[];
};

export const getDEXNEBases = async (party?: string) => {
  const url = buildQueryUrl("/base", { party });

  return responseHandler.handler<GetBasesResponse>(
    {
      url: apiUrl(ApiModules.DEXNEBots, url),
      method: "GET",
    },
    "getBasesDEXNE"
  );
};

export const stopDEXNEBot = async (botId: string) =>
  responseHandler.handler<{}>({
    url: apiUrl(ApiModules.DEXNEBot, `/${botId}/remove`),
    method: "POST",
  });

export const startDEXNEBot = async (botId: string) =>
  responseHandler.handler<{}>({
    url: apiUrl(ApiModules.DEXNEBot, `/${botId}/deploy`),
    method: "POST",
  });

export const getWatchList = async () =>
  responseHandler.handler<{ bot_ids: string[] }>({
    url: apiUrl(ApiModules.DEXNE, "/watchlist"),
    method: "GET",
  });

export const addWatchList = async (botId: string) =>
  responseHandler.handler({
    url: apiUrl(ApiModules.DEXNE, `/watchlist/${botId}`),
    method: "POST",
  });

export const deleteWatchList = async (botId: string) =>
  responseHandler.handler({
    url: apiUrl(ApiModules.DEXNE, `/watchlist/${botId}`),
    method: "DELETE",
  });
