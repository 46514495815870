import { comparer, makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { GetAllApiKeysResponse } from "src/api/bots/CEX/apiKeys";
import { stringToSelectorValue } from "src/helpers/forms/selectors";
import { entries } from "src/helpers/utils";
import {
  AccountApi,
  AccountType,
  BotAccountName,
  BotAccountsMap,
  AccountBinding as IAccountBinding,
  FULL_LIQUIDITY_ACCOUNT_NAMES,
  LiquidityAccountBinding,
  LiquidityAccountName,
  VolumeAccountBinding,
  VolumeAccountName,
  isLiquidityAccountName,
  isVolumeAccountName,
} from "src/modules/accounts";
import { SelectorValue } from "src/modules/shared";
import { StringSelectorValue } from "src/state/UserManager/Scopes/EditScopeStore";
import AccountBinding from "./AccountBinding";

export const EMPTY_ACCOUNT: AccountApi = {
  name: "",
  uuid: "",
};

const isVolumeBindings = (bindings: IAccountBinding[]): bindings is VolumeAccountBinding[] =>
  bindings.length > 0 && isVolumeAccountName(bindings[0].name);

type IAccountBindings<T extends AccountType = AccountType> = T extends "volume"
  ? VolumeAccountBinding[]
  : LiquidityAccountBinding[];

const apiVolumeBindingsToBindings = (
  bindings: IAccountBindings<"volume">
): IAccountBindings<"volume"> =>
  bindings.map(({ type, uuid, account }, index) => {
    const name = `mm${index + 1}` as const;
    return {
      name,
      type,
      uuid,
      account,
    };
  });

const apiLiquidityBindingToBindings = (
  bindings: IAccountBindings<"liquidity">
): IAccountBindings<"liquidity"> =>
  bindings.map(({ type, uuid, account, name }) => ({
    name,
    type,
    uuid,
    account,
  }));

const apiBindingsToBindings = (bindings: IAccountBindings) => {
  if (isVolumeBindings(bindings)) {
    return apiVolumeBindingsToBindings(bindings) as IAccountBinding[];
  }
  return apiLiquidityBindingToBindings(bindings) as IAccountBinding[];
};

export const allApiKeysResponseToBotAccountsMap = (
  response: GetAllApiKeysResponse
): BotAccountsMap => {
  const bindings = entries(response).flatMap((entry) => {
    if (!entry) return [];
    const [, bindings] = entry;
    if (!bindings) return [];
    return apiBindingsToBindings(bindings);
  });

  const accountsMap = Object.fromEntries(
    bindings
      .map(({ name, account }) => {
        if (!account) return null;
        return [name, account];
      })
      .filter(Boolean) as [BotAccountName, AccountApi][]
  );

  return accountsMap;
};

export const accountsMapToSelectorValue = (
  accounts: BotAccountsMap
): Required<StringSelectorValue>[] => {
  const accountsEntries = entries(accounts).filter(Boolean) as [
    BotAccountName,
    AccountApi | undefined,
  ][];

  return accountsEntries.map(([name, account]) => ({
    label: name,
    value: name,
    id: account?.uuid ?? "",
  }));
};

export type AccountBindingMap<T extends AccountType> = Partial<
  Record<BotAccountName<T>, AccountBinding<T>>
>;
export type LiquidityAccountBindingMap = AccountBindingMap<"liquidity">;
export type VolumeAccountBindingMap = AccountBindingMap<"volume">;

const bindingsToBindingMap = <T extends AccountType>(
  apiBindings: IAccountBindings<T>
): AccountBindingMap<T> => {
  const bindings = apiBindingsToBindings(apiBindings);
  const bindingMap = Object.fromEntries(
    bindings.map((binding) => {
      const accountBinding = new AccountBinding(binding);
      return [binding.name, accountBinding];
    })
  ) as AccountBindingMap<T>;
  return bindingMap;
};

export const accountToSelectorValue = ({ name, uuid }: AccountApi): SelectorValue => ({
  value: name,
  label: name,
  id: uuid,
});

const LIQUIDITY_ACCOUNTS_EXCLUSION_MAP: Record<LiquidityAccountName, LiquidityAccountName[]> = {
  info: [],
  spread: ["price", "wall"],
  wall: ["price", "spread"],
  price: ["spread", "wall"],
};

const VOLUME_ACCOUNTS_EXCLUSION_MAP: LiquidityAccountName[] = ["price", "spread", "wall"];

export type BindingsValidator = (
  accountName: BotAccountName,
  account: AccountApi | null
) => boolean;

export type BindingsValidatorNames = "REQUIRED" | "BINDING";

export type BindingsValidatorsMap = Record<BindingsValidatorNames, BindingsValidator>;

export interface IAccountsBindingsProvider {
  accountBindingSelectorOptions: (accountName: BotAccountName) => SelectorValue[];

  get exchangeAccounts(): AccountApi[];

  get liquidityNameSelectorOptions(): SelectorValue[];
}

export default class AccountsBindings implements IAccountsBindingsProvider {
  private _liquidityBindings: LiquidityAccountBindingMap = {};

  private _volumeBindings: VolumeAccountBindingMap = {};

  private _exchangeAccounts: AccountApi[] = [];

  constructor() {
    makeAutoObservable<this, "_getAccountsForBinding">(this, {
      accountBindingSelectorOptions: false,
      selectedAccountBinding: false,
      getAccountBinding: false,
      _getAccountsForBinding: false,
    });
  }

  setExchangeAccounts = (accounts: AccountApi[]) => {
    this._exchangeAccounts = accounts;
  };

  get exchangeAccounts() {
    return this._exchangeAccounts;
  }

  setVolumeBindings = (bindings: VolumeAccountBinding[]) => {
    const volumeBindings = bindingsToBindingMap<"volume">(bindings);

    this._volumeBindings = volumeBindings;
  };

  addVolumeBinding = (binding: AccountBinding<"volume">) => {
    const currentBindings = Object.values(this._volumeBindings).filter(
      Boolean
    ) as AccountBinding<"volume">[];
    currentBindings.push(binding);
    this.setVolumeBindings(currentBindings);
  };

  deleteVolumeBinding = (accountName: VolumeAccountName) => {
    const currentBindings = Object.values(this._volumeBindings).filter(
      Boolean
    ) as AccountBinding<"volume">[];
    const newBindings = currentBindings.filter((binding) => binding.name !== accountName);
    this.setVolumeBindings(newBindings);
  };

  setLiquidityBindings = (bindings: LiquidityAccountBinding[]) => {
    const liquidityBindings = bindingsToBindingMap<"liquidity">(bindings);

    this._liquidityBindings = liquidityBindings;
  };

  addLiquidityBinding = (binding: AccountBinding<"liquidity">) => {
    const currentBindings = Object.values(this._liquidityBindings).filter(
      Boolean
    ) as AccountBinding<"liquidity">[];
    currentBindings.push(binding);
    this.setLiquidityBindings(currentBindings);
  };

  deleteLiquidityBinding = (accountName: LiquidityAccountName) => {
    const currentBindings = Object.values(this._liquidityBindings).filter(
      Boolean
    ) as AccountBinding<"liquidity">[];
    const newBindings = currentBindings.filter((binding) => binding.name !== accountName);
    this.setLiquidityBindings(newBindings);
  };

  get liquidityBindings() {
    return this._liquidityBindings;
  }

  get liquidityAccountNames() {
    return Object.keys(this._liquidityBindings) as LiquidityAccountName[];
  }

  get liquidityNameSelectorOptions() {
    const allAccountNames = FULL_LIQUIDITY_ACCOUNT_NAMES;
    const currentAccountNames = this.liquidityAccountNames;
    const emptyAccountNames = allAccountNames.filter((name) => !currentAccountNames.includes(name));

    return emptyAccountNames.map(stringToSelectorValue);
  }

  get volumeBindings() {
    return this._volumeBindings;
  }

  get volumeAccountNames() {
    return Object.keys(this._volumeBindings) as VolumeAccountName[];
  }

  getAccountBinding = (name: BotAccountName) => {
    if (isVolumeAccountName(name)) {
      return this._volumeBindings[name];
    }
    if (isLiquidityAccountName(name)) {
      return this._liquidityBindings[name];
    }
  };

  private _clearBindings = () => {
    this._liquidityBindings = {};
    this._volumeBindings = {};
  };

  clear = () => {
    this._clearBindings();
    this.setExchangeAccounts([]);
  };

  clearAccountBinding = (name: BotAccountName) => {
    if (isVolumeAccountName(name)) {
      delete this._volumeBindings[name];
    }
    if (isLiquidityAccountName(name)) {
      delete this._liquidityBindings[name];
    }
  };

  saveAccountBinding = (name: BotAccountName) => {
    const binding = this.getAccountBinding(name);
    binding?.saveAccount();
  };

  private _getExcludedAccounts = (name: BotAccountName): BotAccountName[] => {
    if (isVolumeAccountName(name)) {
      // volume accounts cant intersect with liquidity accounts + with themselves
      const { volumeAccountNames } = this;
      const otherVolumeAccountNames = volumeAccountNames.filter(
        (accountName) => accountName !== name
      );
      return [...VOLUME_ACCOUNTS_EXCLUSION_MAP, ...otherVolumeAccountNames];
    }
    if (name === "info") {
      return LIQUIDITY_ACCOUNTS_EXCLUSION_MAP[name];
    }
    // liquidity accounts cant intersect with volume accounts
    const { volumeAccountNames } = this;
    return [...LIQUIDITY_ACCOUNTS_EXCLUSION_MAP[name], ...volumeAccountNames];
  };

  private _getAccountsForBinding = (
    accountName: BotAccountName,
    accounts: AccountApi[],
    useSavedAccounts: boolean
  ) => {
    const excludedAccountNames = this._getExcludedAccounts(accountName);

    const excludedAccountsSet: Set<string> = excludedAccountNames.reduce(
      (currentExcludedAccounts, accountName) => {
        const binding = this.getAccountBinding(accountName);
        const account = useSavedAccounts ? binding?.savedAccount : binding?.account;
        const accountId = account?.uuid;

        if (accountId) {
          currentExcludedAccounts.add(accountId);
        }

        return currentExcludedAccounts;
      },
      new Set<string>()
    );

    return accounts.filter((acc) => !excludedAccountsSet.has(acc.uuid));
  };

  accountBindingSelectorOptions = computedFn((accountName: BotAccountName): SelectorValue[] => {
    const accounts = this._getAccountsForBinding(accountName, this._exchangeAccounts, false);

    return accounts.map(accountToSelectorValue);
  });

  selectedAccountBinding = computedFn((accountName: BotAccountName): SelectorValue | null => {
    const binding = this.getAccountBinding(accountName);
    const account = binding?.account;

    if (!account) return null;

    return accountToSelectorValue(account);
  });

  private _updateSelectedAccount = (accountName: BotAccountName, account: AccountApi | null) => {
    const binding = this.getAccountBinding(accountName);
    binding?.setAccount(account);
  };

  onAccountBindingSelected = (accountName: BotAccountName) => (value: SelectorValue | null) => {
    if (!value) {
      this._updateSelectedAccount(accountName, null);
      return;
    }

    const accountId = String(value.id);

    const selectedAccount = this._exchangeAccounts.find(({ uuid }) => uuid === accountId);

    if (!selectedAccount) return;

    this._updateSelectedAccount(accountName, selectedAccount);
  };

  private _validateRequiredAccount: BindingsValidator = (_accountName, account) => {
    if (!account) return false;
    const isValid = !comparer.structural(account, EMPTY_ACCOUNT);

    return isValid;
  };

  private _validateAccountBinding: BindingsValidator = (accountName, account) => {
    // assume isValid if empty account passed
    if (!account) return true;

    // use saved accounts for bindings check since all keys may be bound in unsaved state
    const allowedAccounts = this._getAccountsForBinding(accountName, this._exchangeAccounts, true);

    const isValid = allowedAccounts.some(({ uuid }) => account.uuid === uuid);

    return isValid;
  };

  validators: BindingsValidatorsMap = {
    BINDING: this._validateAccountBinding,
    REQUIRED: this._validateRequiredAccount,
  };
}
