import styled, { css } from "styled-components";
import { InputStyle } from "../../Input";

export const PercentRangeInputWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const sliderThumb = css`
  height: 12px;
  width: 12px;

  cursor: pointer;
  border: 0;
  border-radius: ${({ theme }) => theme.radii["100%"]};
  background: ${({ theme }) => theme.primaryColor};
  -webkit-appearance: none;
`;

export const PERCENT_VAR = "--percent";

export const StyledRangeInput = styled(InputStyle.Input).attrs({
  type: "range",
})`
  height: 2px;

  padding: 0;

  background: ${({ theme: { bgGrayBlack, primaryColor } }) =>
    `linear-gradient(to right, ${primaryColor} 0%, ${primaryColor}  var(${PERCENT_VAR}), ${bgGrayBlack} var(${PERCENT_VAR}), ${bgGrayBlack} 100%)`};

  transition: background 450ms ease-in;
  -webkit-appearance: none;
  cursor: pointer;

  ::-webkit-slider-thumb {
    ${sliderThumb}
  }

  ::-moz-range-thumb {
    ${sliderThumb}
  }

  :focus {
    box-shadow: none;
  }
`;

export const StyledPercentInput = styled(InputStyle.Input)`
  max-width: 60px;

  text-align: center;

  padding: 9px 7px;
`;
