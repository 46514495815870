import { BigNumber, ethers } from "ethers";

export const isInfinity = (num: number) => num === Infinity || num === -Infinity;

export const isFiniteNumber = (num: number | string) => !isNaN(+num) && !isInfinity(+num);

/**
 * calculates the query step of a statistical data sample
 * @param startPoint starting point of time period
 * @param endPoint end point of time period
 * @returns optimal step with which data sampling is requested
 */
export const calcStep = (startPoint: number, endPoint: number) =>
  Math.ceil((endPoint - startPoint) / 11000 / 15) * 15;

export const toHexString = (num: number) => `0x${num.toString(16)}`;

export const hexToNum = (hex: string) => parseInt(hex, 16);

export const bigNumbersToNumbers = (numbers: BigNumber[]) =>
  numbers.map((number) => +ethers.utils.formatEther(number));

export const countDecimalsPlaces = (numberStr: string) => {
  const parts = numberStr.split(".");
  const fractionalPart = parts[1] ?? "";
  return fractionalPart.length;
};
