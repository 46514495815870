import { Component, ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

const LogoSVG = styled.svg`
  fill: ${({ theme }) => theme.svgColor};
`;

interface SVGProps extends ComponentPropsWithoutRef<"svg"> {}

export default class HeaderIcons extends Component {
  static Logo = (props: SVGProps) => (
    <LogoSVG viewBox="0 0 47 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.6958 12.4227C45.1087 12.3811 45.563 12.4434 46.0792 12.9212C46.6987 13.5029 47.0084 14.3338 46.9877 15.2063C46.616 22.8718 40.2978 29 32.5959 29C25.7408 29 20.0213 24.1598 18.5553 17.72C18.4727 17.346 18.6998 16.9928 19.0715 16.9305L21.1983 16.5773H8.25186C7.09556 16.5773 6.14575 15.601 6.18705 14.4377C6.22834 13.2952 7.1988 12.4227 8.33444 12.4227H15.8297C17.3164 12.4227 18.3488 12.111 18.5346 11.4255C18.7411 10.6361 18.9476 9.8467 19.2367 9.09885C19.3192 8.84957 19.5464 8.68337 19.7942 8.64183L21.8797 8.26791H16.4079C15.2929 8.26791 14.3017 7.39541 14.2812 6.27364C14.2605 5.08954 15.1896 4.13395 16.3459 4.13395H19.7942C21.5906 4.13395 22.4371 4.09241 23.5728 3.21991C26.0299 1.20487 29.1891 0 32.5959 0C36.4365 0 40.05 1.4957 42.7756 4.23782C43.6014 5.06877 43.5808 6.41905 42.7136 7.22923C41.9083 7.97707 40.6281 7.91475 39.8435 7.12535C37.9025 5.19341 35.3215 4.13395 32.5959 4.13395C27.6404 4.13395 23.4695 7.68625 22.5197 12.4018H44.4687C44.51 12.4018 44.5461 12.407 44.5822 12.4123C44.6183 12.4175 44.6545 12.4227 44.6958 12.4227ZM22.5197 16.5566C23.4695 21.2722 27.6198 24.8245 32.5959 24.8245C37.5515 24.8245 41.7018 21.2722 42.6723 16.5566H22.5197ZM2.07604 16.5567C3.21641 16.5567 4.14086 15.6266 4.14086 14.4794C4.14086 13.3321 3.21641 12.402 2.07604 12.402C0.935678 12.402 0.0112305 13.3321 0.0112305 14.4794C0.0112305 15.6266 0.935678 16.5567 2.07604 16.5567ZM16.4052 24.9903C17.5456 24.9903 18.47 24.0602 18.47 22.9129C18.47 21.7657 17.5456 20.8356 16.4052 20.8356C15.2648 20.8356 14.3404 21.7657 14.3404 22.9129C14.3404 24.0602 15.2648 24.9903 16.4052 24.9903Z"
      />
    </LogoSVG>
  );
}
