import { ComponentPropsWithRef, FC } from "react";
import { StyledProps } from "src/helpers/styled/types";
import Icons from "src/icons/Icons";
import styled from "styled-components";

export interface ExpandIconProps {
  open?: boolean;
}

export interface ExpandIconStyledProps extends StyledProps<ExpandIconProps> {}

export const ExpandIcon = styled(Icons.ExpandArrow)<ExpandIconStyledProps>`
  transform: ${({ $open }) => ($open ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
` as FC<ComponentPropsWithRef<"svg"> & ExpandIconStyledProps>;
