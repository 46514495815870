import { hexToRgb } from "src/helpers/colors";
import { StyledProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";
import { ButtonStyleProps } from "../Button/style";
import { BaseButton } from "../shared/style";

export interface IconButtonStyleProps extends Pick<ButtonStyleProps, "size" | "elevated"> {
  boxed?: boolean;
}
export interface IconButtonStyledProps extends StyledProps<IconButtonStyleProps> {}

const BackgroundColorMixin = () => css`
  ${({ theme }) => {
    const stateColors = theme.buttonLightBackgroundStateColors.primary;

    const { enabled, active, focused } = stateColors;

    const enabledAlpha = enabled;
    const activeAlpha = hexToRgb(active, 0.6);
    const focusedAlpha = hexToRgb(focused, 0.6);

    return css`
      background-color: transparent;

      :hover {
        background-color: ${enabledAlpha};
      }

      :active {
        background-color: ${activeAlpha};
      }

      :focus-visible {
        background-color: ${focusedAlpha};
      }
    `;
  }}
`;

const TextColorMixin = () => css`
  ${({ theme }) => {
    const invertTextColor = theme.backgroundWhiteColor;
    const textColor = theme.buttonBackgroundStateColors.primary.enabled;
    return css`
      color: ${textColor};

      :active,
      :focus-visible {
        color: ${invertTextColor};
      }
    `;
  }}
`;

const SizeMixin = ({ $size }: IconButtonStyledProps) => css`
  ${({ theme }) => {
    switch ($size) {
      case "l": {
        return css`
          min-height: 38px;
          min-width: 38px;
          padding: 10px;
          font-size: ${theme.fontSizes["18px"]};
        `;
      }
      case "m": {
        return css`
          min-height: 30px;
          min-width: 30px;
          padding: 7px;
          font-size: ${theme.fontSizes["16px"]};
        `;
      }
      case "s": {
        return css`
          min-width: 24px;
          min-height: 24px;
          padding: 5px;
          font-size: ${theme.fontSizes["14px"]};
          border-radius: 6px;
        `;
      }
      case "xs": {
        return css`
          min-height: 18px;
          min-width: 18px;
          padding: 3px;
          font-size: ${theme.fontSizes["12px"]};
          border-radius: 4px;
        `;
      }
    }
  }}
`;

const BoxedMixin = ({ $boxed }: IconButtonStyledProps) => {
  if (!$boxed) {
    return;
  }

  return css`
    ${() => css`
      min-height: initial;
      min-width: initial;
      padding: 0;

      &,
      :active,
      :hover,
      :focus-visible {
        background-color: transparent;
        color: ${({ theme }) => theme.textColor};
      }
    `}
  `;
};

const ElevationMixin = ({ $elevated }: Pick<IconButtonStyledProps, "$elevated">) => {
  const disabledElevation =
    !$elevated &&
    css`
      :hover,
      :active,
      :focus-visible {
        box-shadow: none;
      }
    `;

  const elevation = css`
    :hover,
    :active,
    :focus-visible {
      box-shadow: ${({ theme }) => theme.shadows.button};
    }
  `;

  return css`
    ${elevation}

    ${disabledElevation}
  `;
};

export const StyledIconButton = styled(BaseButton)<IconButtonStyledProps>`
  border-radius: 8px;

  ${BackgroundColorMixin}
  ${TextColorMixin}
  ${SizeMixin}
  ${BoxedMixin}
  ${ElevationMixin}
`;
