import { MainRoutes } from "src/app/constants";
import * as styles from "./style";

interface LogoProps {
  isMobile: boolean;
}

export const Logo = ({ isMobile }: LogoProps) => (
  <styles.LogoLink to={MainRoutes.MainPage}>
    <styles.Logo isMobile={isMobile} />
  </styles.LogoLink>
);
