import { ComponentPropsWithoutRef, memo, useMemo } from "react";
import { createPortal } from "react-dom";
import { DEXCommonBotModeStatus } from "src/modules/bots";
import { DefaultTheme, useTheme } from "styled-components";
import { Line } from "../../../shared";
import { ModeIcon, ModeIconProps } from "./ModeIcon";
import { ModeInfo, ModeInfoOwnProps, getTradeTimeText } from "./ModeInfo";
import { ModeInfoTooltip } from "./ModeInfoTooltip";
import * as styles from "./style";

export interface ModeChipProps
  extends Pick<ModeIconProps, "mode">,
    ModeInfoOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, keyof ModeInfoOwnProps> {
  status?: DEXCommonBotModeStatus;
  botId: string;
  iconSize?: number;
  isMobile?: boolean;
  showInfo?: boolean;
  infoAnchorEl?: HTMLElement | null;
}

const modeStatusToColor = (status: DEXCommonBotModeStatus, theme: DefaultTheme) => {
  switch (status) {
    case DEXCommonBotModeStatus.Error: {
      return theme.dangerColor;
    }
    case DEXCommonBotModeStatus.Running: {
      return theme.successColor;
    }
    case DEXCommonBotModeStatus.Stopped: {
      return theme.lightGray;
    }
  }
};

const TOOLTIP_ID = "mode-info-tip";

interface UseNextTradeParams {
  nextTrade?: number;
  isMobile?: boolean;
}

export const useNextTrade = ({ nextTrade, isMobile }: UseNextTradeParams) => {
  const nextTradeText = useMemo(
    () => getTradeTimeText(nextTrade, { formatType: "short" }),
    [nextTrade]
  );

  if (isMobile) return null;

  return nextTradeText ? (
    <styles.TradeTime>{nextTradeText}</styles.TradeTime>
  ) : (
    <styles.LineWrapper>
      <Line />
    </styles.LineWrapper>
  );
};

export const ModeChip = memo(
  ({
    mode,
    status = DEXCommonBotModeStatus.Stopped,
    nextTrade,
    lastTrade,
    message,
    botId,
    iconSize,
    showInfo = true,
    isMobile,
    infoAnchorEl,
    ...props
  }: ModeChipProps) => {
    const theme = useTheme();
    const modeColor = modeStatusToColor(status, theme);

    const tooltipID = `${TOOLTIP_ID}-${botId}-${mode}`;

    const NextTrade = useNextTrade({ nextTrade, isMobile });

    const infoTitle = isMobile ? mode : undefined;

    const tooltipAnchor = infoAnchorEl ?? document.body;

    return (
      <>
        <styles.ChipContainer data-tooltip-id={tooltipID} color={modeColor} {...props}>
          <ModeIcon mode={mode} mainColor={modeColor} size={iconSize} />
          {NextTrade}
        </styles.ChipContainer>
        {showInfo &&
          createPortal(
            <ModeInfoTooltip id={tooltipID} place="bottom">
              <ModeInfo
                nextTrade={nextTrade}
                lastTrade={lastTrade}
                message={message}
                title={infoTitle}
              />
            </ModeInfoTooltip>,
            tooltipAnchor
          )}
      </>
    );
  }
);
