import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  gap: 8px;
`;

export const ControlsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;
