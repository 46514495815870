import useLocalStore from "src/hooks/useLocalStore";
import { NavigationMenuStore } from "src/state/NavigationMenu";
import { createContextProvider } from "./utils/createContextProvider";

const NavigationMenuStoreCreator = () => useLocalStore(NavigationMenuStore);

const { Context: NavigationMenuContext, Provider: NavigationMenuProvider } =
  createContextProvider<NavigationMenuStore>({ stateCreator: NavigationMenuStoreCreator });

export { NavigationMenuContext, NavigationMenuProvider };
