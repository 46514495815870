import { observer } from "mobx-react-lite";
import { StatusConnect } from "src/components/shared/Indicators";
import { useAppState } from "src/state";
import { Clock } from "./Clock";
import { UserMenu, UserMenuProps } from "./UserMenu";
import * as styles from "./style";

export interface ProfileSectionProps extends Pick<UserMenuProps, "themeToggler"> {}

export const ProfileSection = observer(({ themeToggler }: ProfileSectionProps) => {
  const { userState, userConnectionState } = useAppState();

  return (
    <styles.MenuContainer>
      <styles.LoginWrapper>
        <styles.Login data-tooltip-id="main-menu-tooltip" data-tooltip-content="">
          {userState.login}
        </styles.Login>

        <styles.InfoRow>
          <StatusConnect
            type="ping"
            status={userConnectionState.connectStatus}
            delay={userConnectionState.serverDelay}
          />

          <Clock />
        </styles.InfoRow>
      </styles.LoginWrapper>

      <UserMenu
        themeToggler={themeToggler}
        onLogOut={userState.logOut}
        onUpdAbilities={userState.updAbilities}
      />
    </styles.MenuContainer>
  );
});
