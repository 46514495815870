import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { UserManagerStore } from "src/state/UserManager";
import EditPartyStore from "src/state/UserManager/EditParty/EditParty";
import TickersStore from "src/state/UserManager/EditParty/TickersStore";
import { createContextProvider } from "../utils/createContextProvider";

export const PartyContext = createLateInitContext<EditPartyStore>();

export const TickersContext = createLateInitContext<TickersStore>();

const UserManagerStoreCreator = () => useLocalStore(UserManagerStore);

const { Context: UserManagerContext, Provider: UserManagerProvider } =
  createContextProvider<UserManagerStore>({
    stateCreator: UserManagerStoreCreator,
  });

export { UserManagerContext, UserManagerProvider };
