import { DeleteHierarchy, NewHierarchy } from "src/modules/userManager";
import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from "..";

export const getHierarchies = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/hierarchies"),
    method: "GET",
  });

export const deleteHierarchy = async ({ hierarchy_id, user_name }: DeleteHierarchy) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/users/${user_name}/hierarchies/${hierarchy_id}`),
    method: "DELETE",
  });

export const addHierarchy = async ({ user_name, ...hierarchyData }: NewHierarchy) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/users/${user_name}/hierarchies`),
    method: "POST",
    data: hierarchyData,
  });
