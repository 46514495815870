import { DEXV2CreatingBot, DEXCommonExchangeVersion } from "src/modules/bots";
import { DEXV2CreatingModuleNames, DEXVersionOptions } from "../types";

export const DEXV2_CREATE_MODULES: readonly DEXV2CreatingModuleNames[] = [
  "base_data",
  "main_data",
  "limit_data",
  "volume_data",
  "counter_data",
] as const;

export const INITIAL_DEXV2_CREATING: DEXV2CreatingBot = {
  base_data: {
    chain_id: 0,
    party: "",
    bot_name: "",
    quote: "",
    base: "",
    exchange: "",
    withdrawer: "",
    transfer_oracle_wallets: [],
    swap_oracle_wallets: [],
    receiver_num: "",
  },
  main_data: {
    limit_executors_num: 3,
    volume_executors_num: 3,
    counter_executors_num: 3,
    tt_buy_fee: 0,
    tt_sell_fee: 0,
    gas_limit: 500000,
    pool_percent: 0,
    stable: "",
  },
  volume_data: {
    on: false,
    use_receiver: false,
    period: 5,
    min_trades: 3,
    max_trades: 5,
    buy_percent: 50,
    min_amount: 1,
    max_amount: 3,

    slippage: 0.5,
    gas_mult: 0,
    gas_price_limit: 0,
  },
  limit_data: {
    on: false,
    slippage: 0.5,
    gas_mult: 0,
    gas_price_limit: 0,
    data: [],
  },
  counter_data: {
    on: false,
    cumulative: true,
    black_listed_wallets: [],
    data: [],
    gas_mult: 0,
    gas_price_limit: 0,
    slippage: 0.5,
  },
};

export const DEX_VERSION_OPTIONS: DEXVersionOptions[] = [
  { value: DEXCommonExchangeVersion.V2, label: "v2" },
  { value: DEXCommonExchangeVersion.V3, label: "v3" },
];
