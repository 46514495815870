import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from "..";

export const getAbilities = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/abilities"),
    method: "GET",
  });

// export const deleteParty = async (party: string) => {
//   return ResponseHandler.handler(`/api/party/${party}`, "DELETE");
// };

// export const removeParty = async ({ scope_id, party }: DeleteParty) => {
//   return ResponseHandler.handler({
//     url: apiUrl(ApiModules.Auth, `/scopes/${scope_id}/parties/${party}`),
//     method: "DELETE",
//   });
// };

// export const getAPIKeys = async (party: string, uuid: string): Promise<any> => {
//   return ResponseHandler.handler(
//     `/api/parties/${party}/accounts/${uuid}/credentials`,
//     "GET"
//   );
// };
