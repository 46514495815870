import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface BaseModeInfoItemProps extends ComponentPropsWithoutRef<"div"> {
  label: string | JSX.Element;
  value: string | JSX.Element;
}

export const BaseModeInfoItem = ({ label, value, ...props }: BaseModeInfoItemProps) => (
  <styles.Row {...props}>
    <styles.Label>{label}</styles.Label>

    <styles.Value>{value}</styles.Value>
  </styles.Row>
);
