import { useEffect, useState } from "react";

export const useFindWidth = (screenResolution: number) => {
  const [show, setShow] = useState(window.innerWidth <= screenResolution);

  useEffect(() => {
    const resize = () => {
      setShow(window.innerWidth <= screenResolution);
    };
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [screenResolution]);

  return show;
};
