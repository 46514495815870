import { TextLineHeight } from "src/components/shared/shared";
import styled from "styled-components";

export interface ChipContainerProps {
  color?: string;
}

export const ChipContainer = styled.div<ChipContainerProps>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  color: ${({ color, theme }) => color ?? theme.inputTextColor};
`;

export const TradeTime = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
`;

export const LineWrapper = styled.div`
  max-width: 80px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }) => theme.fontSizes["14px"]};

  ${TextLineHeight}
`;
