import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 78px;
  display: flex;
  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const Favorites = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns:
    minmax(120px, 210px) minmax(70px, 125px) minmax(80px, 210px)
    minmax(70px, 132px) minmax(125px, 410px) minmax(70px, 150px)
    35px minmax(95px, 1fr) auto;

  color: currentColor;
`;

export const CellContent = styled.div`
  max-height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  color: currentColor;
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > :first-child {
    margin-right: 5px;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    justify-content: space-around;

    > :first-child {
      margin-right: 0px;
    }
  }
`;
