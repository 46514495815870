import detectEthereumProvider from "@metamask/detect-provider";
import { useEffect, useState } from "react";
import { logError } from "src/helpers/network/logger";

async function _() {
  return await detectEthereumProvider();
}

export type MetaMaskEthereumProvider = NonNullable<Awaited<ReturnType<typeof _>>>;

export const useMetaMaskProvider = () => {
  const [provider, setProvider] = useState<MetaMaskEthereumProvider | null>(null);
  useEffect(() => {
    const retrieveProvider = async () => {
      const provider = await detectEthereumProvider();

      if (provider) {
        if (provider !== window.ethereum) {
          logError("Do you have multiple wallets installed?", {
            dev: { log: true },
          });
        } else {
          setProvider(provider);
        }
      } else {
        logError("Please install MetaMask!", {
          dev: { log: true },
        });
      }
    };
    retrieveProvider();
  }, []);
  return provider;
};
