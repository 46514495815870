import { GetChainsQuotersResponse } from "src/api/bots/DEXV2/chain";
import { toChainId } from "src/config/chains";
import { Mapper } from "src/helpers/utils";
import { ChainRouterQuoters } from "./types";

export const chainsQuotersRespToChainRouterQuoters: Mapper<
  GetChainsQuotersResponse,
  ChainRouterQuoters
> = (resp) => {
  const chainQuoters = resp.reduce((acc, { chain_id, dex_addr, quoter_addr }) => {
    const chainId = toChainId(chain_id);
    const routerAddress = dex_addr;
    const quoterAddress = quoter_addr;

    const chainQuoters = acc[chainId] ?? {};
    chainQuoters[routerAddress] = quoterAddress;
    acc[chainId] = chainQuoters;

    return acc;
  }, {} as ChainRouterQuoters);
  return chainQuoters;
};
