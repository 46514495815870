import { makeAutoObservable } from "mobx";
import { DEXCommonBotsColorStatus } from "src/components/AllBots/Bots/shared/DEXCommon";
import { IDEXNEBotsProvider } from "./DEXNEBotsStore";
import { IListDEXNEBot } from "./types";

export interface IDEXNEBotStatusFilter {
  get count(): number;
  get isEnabled(): boolean;
  toggleEnabled: () => void;
  filter: (bot: IListDEXNEBot) => boolean;
}

export class DEXNEBotStatusFilterStore implements IDEXNEBotStatusFilter {
  private _enabled = false;

  private _botsProvider: IDEXNEBotsProvider;

  private _status: DEXCommonBotsColorStatus;

  constructor(botsProvider: IDEXNEBotsProvider, status: DEXCommonBotsColorStatus) {
    makeAutoObservable(this);

    this._botsProvider = botsProvider;

    this._status = status;
  }

  get count() {
    return this._statusCount;
  }

  get isEnabled() {
    return this._enabled;
  }

  private get _bots() {
    return this._botsProvider.allBots;
  }

  private get _statusCount() {
    return this._bots.filter(this._filterStatus).length;
  }

  toggleEnabled = () => {
    this._enabled = !this._enabled;
  };

  filter = (bot: IListDEXNEBot) => this._enabled && this._filterStatus(bot);

  private _filterStatus = ({ colorStatus }: IListDEXNEBot) => colorStatus === this._status;
}
