import { Redirect, RouteComponentProps } from "react-router-dom";
import { createRedirectParamUrl } from "src/components/Auth/utils/redirect";
import { AuthRoutes } from "../constants";

interface LoginRedirectProps extends RouteComponentProps {}

export const LoginRedirect = ({ location }: LoginRedirectProps) => {
  const redirectUrl = createRedirectParamUrl(AuthRoutes.LoginPage, location);

  return <Redirect to={redirectUrl} />;
};
