import { logError } from "../network/logger";

export type PrimaryColor = "blue" | "orange";

export const getPrimaryColor = (): PrimaryColor => {
  const savedPrimaryColor = localStorage.getItem("primaryColor");
  return (savedPrimaryColor || "orange") as PrimaryColor;
};

export const setPrimaryColor = (color: PrimaryColor) => {
  try {
    localStorage.setItem("primaryColor", color);
  } catch (err) {
    logError(err);
  }
};
