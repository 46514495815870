import styled, { css } from "styled-components";
import { Subtitle } from "../../Typography/Subtitle";

export type StyledLabelProps = {
  error: boolean;
};

export const Label = styled(Subtitle)<StyledLabelProps>`
  cursor: pointer;

  ${({ theme, error }) => {
    if (error) {
      return css`
        color: ${theme.errorLabelColor};
      `;
    }
  }}
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  white-space: nowrap;
`;
