import { Tooltip, ITooltip as TooltipProps } from "react-tooltip";
import styled, { css } from "styled-components";
import { MenuItem } from "./MenuItem";

export const MenuContainer = css`
  width: 170px !important;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0;
  font-size: 1em;
  border: none !important;

  .react-tooltip-arrow {
    display: none !important;
  }
`;

export const Container = styled(Tooltip).attrs<{}, TooltipProps>({
  id: "main-menu-tooltip",
  place: "bottom",
  delayHide: 300,
  clickable: true,
})`
  ${MenuContainer}
`;

export const Navigation = styled.nav`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

export const MenuList = styled.ul`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  ${({ theme }) => css`
    background-color: ${theme.contentBackgroundColor};
  `}
`;

export const Logout = styled(MenuItem).attrs({ to: "#" })`
  :hover {
    border-left: 2px solid;
    border-color: ${({ theme }) => theme.dangerColor};
  }
`;

export const Update = styled(Logout)``;

export const Separator = styled.hr`
  width: 100%;
  height: 0.6em;
  margin: 0;
  padding: 0;
  border: none;

  ${({ theme }) => css`
    background-color: ${theme.contentBackgroundColor};
  `}
`;

export const MenuLink = styled.a`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.primaryColor};
`;
