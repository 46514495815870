import { useEffect } from "react";
import { SubscribableStore } from "src/helpers/utils";

/**
 * Subscribes to store using side-effects and unsubscribes automatically on unmount
 * @param store - store with side-effects that needs disposal (reactions, etc.)
 */
export const useSubscribableStore = (store: SubscribableStore) => {
  useEffect(() => {
    store.subscribe();
    return () => {
      store.unsubscribe();
    };
  }, [store]);
};
