import { SelectorValue } from "src/modules/shared";
import * as styles from "./style";

export interface SelectItemProps {
  item: SelectorValue;
  removeClick: (value: string) => void;
  editable?: boolean | undefined;
}

export const SelectItem = ({
  editable = true,
  item: { value, label },
  removeClick,
}: SelectItemProps) => (
  <styles.Item>
    {label}
    {editable ? (
      <styles.RemoveBtn type="button" onClick={() => removeClick(String(value))} />
    ) : null}
  </styles.Item>
);
