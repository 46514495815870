import { ApiModules, apiUrl } from "src/api";
import { BotBinding } from "src/modules/settings";
import { ICurrentCEXBindings } from "src/state/CEX/CEXSettings/bindingsBots";
import { CEXBindingModule } from "src/state/CEX/botModules";
import responseHandler from "src/state/network/ResponseHandler";

export type CEXBindingModulesResponse = CEXBindingModule[];

export const getCEXBindings = async (party: string) =>
  responseHandler.handler<CEXBindingModulesResponse>({
    url: apiUrl(ApiModules.Parties, `${party}/bindings`),
    method: "GET",
  });

export type CEXCurrentBindingsResponse = ICurrentCEXBindings;

export const getCEXCurrentBindings = async (botUUID: string) =>
  responseHandler.handler<CEXCurrentBindingsResponse>({
    url: apiUrl(ApiModules.Bot, `${botUUID}/current-bindings`),
    method: "GET",
  });

export interface SetCEXCurrentBindingsRequestProps {
  data: ICurrentCEXBindings;
  botUUID: string;
}
export interface SetCEXCurrentBindingsResponse {}

export const setCEXCurrentBindings = async ({ data, botUUID }: SetCEXCurrentBindingsRequestProps) =>
  responseHandler.handler<SetCEXCurrentBindingsResponse>({
    url: apiUrl(ApiModules.Bot, `${botUUID}/current-bindings`),
    method: "PUT",
    data,
  });

export interface CreateCEXModuleRequestProps {
  data: CEXBindingModule;
  party: string;
}
export interface CreateCEXModuleResponse {}

export const createCEXBindingModule = async ({ data, party }: CreateCEXModuleRequestProps) =>
  responseHandler.handler({
    url: apiUrl(ApiModules.Parties, `${party}/binding`),
    method: "POST",
    data,
  });

export interface EditCEXModuleRequestProps extends CreateCEXModuleRequestProps {}
export interface EditCEXModuleResponse {}

export const editCEXBindingModule = async ({ data, party }: EditCEXModuleRequestProps) =>
  responseHandler.handler<EditCEXModuleResponse>({
    url: apiUrl(ApiModules.Parties, `${party}/bindings/${data.uuid}`),
    method: "PUT",
    data,
  });

export interface DeleteCEXModuleRequestProps {
  party: string;
  type: BotBinding["kind"];
  uuid: string;
}
export interface DeleteCEXModuleResponse {}

export const deleteCEXBindingModule = async ({ party, type, uuid }: DeleteCEXModuleRequestProps) =>
  responseHandler.handler<DeleteCEXModuleResponse>({
    url: apiUrl(ApiModules.Parties, `${party}/bindings/${type}/${uuid}`),
    method: "DELETE",
  });

export interface CEXSwapRoutes {
  exchanges: string[];
}

export const getCEXSwapRoutes = async () =>
  responseHandler.handler<CEXSwapRoutes>({
    url: "/exchanges/dex",
    method: "GET",
  });
