import { Headline2 } from "src/components/shared/Typography";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const DemoCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;

  padding: 30px;

  ${CardSurface}
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const Title = styled(Headline2)``;
