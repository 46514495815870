import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { noOp } from "src/helpers/utils";
import { ContentDirection, OnMenuItemChange, SwitchMenu, SwitchMenuTypeProps } from "../SwitchMenu";
import { MenuItemData } from "../SwitchMenu/MenuItem";
import * as styles from "./style";

export type TabsContentDirection = {
  contentDirection?: ContentDirection;
};
export interface TabsProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "onChange">,
    Partial<SwitchMenuTypeProps>,
    TabsContentDirection {
  defaultKey: string;
  menuItems: MenuItemData[];
  onClickActive?: OnMenuItemChange;
  setFunc?: (onItemChange: OnMenuItemChange | undefined) => void;
  onChange?: OnMenuItemChange;
  allFilter?: boolean;
  children: (JSX.Element | null)[];
  afterSwitch?: JSX.Element;
}

export const Tabs = observer(
  ({
    children,
    defaultKey,
    menuItems,
    onChange = noOp,
    onClickActive = noOp,
    setFunc,
    allFilter = false,
    type = "segmented",
    contentDirection = "column",
    afterSwitch,
    ...props
  }: TabsProps) => {
    const [currentKey, setCurrentKey] = useState(defaultKey);

    useEffect(() => {
      setFunc?.(setCurrentKey);

      return () => {
        setFunc?.(undefined);
      };
    }, [setFunc]);

    const onChangeKey: OnMenuItemChange = (value) => {
      if (value === currentKey) {
        setCurrentKey("all");
      } else setCurrentKey(value);
      onChange(value);
    };

    return (
      <styles.Container {...props}>
        <styles.Panel>
          <SwitchMenu
            menuItems={menuItems}
            selectedItem={currentKey}
            onChange={onChangeKey}
            direction="row"
            onClickActive={allFilter ? onChangeKey : onClickActive}
            type={type}
          />
          {afterSwitch}
        </styles.Panel>
        <styles.Content contentDirection={contentDirection}>
          {children?.filter((child) => (currentKey === "all" ? true : child?.key === currentKey))}
        </styles.Content>
      </styles.Container>
    );
  }
);
