/**
 *
 * @returns obj with browser outer sizes
 */
export const getOuterSizeWindow = () => {
  const sizes = {
    height: Math.max(document.documentElement.clientHeight || 0, window.outerHeight || 0),
    width: Math.max(document.documentElement.clientWidth || 0, window.outerWidth || 0),
  };

  return sizes;
};
