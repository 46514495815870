import { FormValidation } from "src/helpers/forms/types";
import { MAX_PRECISION_DECIMALS, MIN_PRECISION_DECIMALS } from "src/helpers/rounding";
import { CEXSettings } from "src/state/shared/CEX/types/settings";
import {
  graterThan,
  graterThanKey,
  greaterThanNumberKey,
  isInteger,
  isNumber,
  notEqual,
  required,
  shortThan,
  smallerThan,
  smallerThanKey,
  smallerThanNumberKey,
} from "src/validation-schemas";

const PRECISION_VALIDATION = [
  required(),
  isInteger(),
  smallerThan(MAX_PRECISION_DECIMALS, `Value cannot be greater than ${MAX_PRECISION_DECIMALS}`),
  graterThan(MIN_PRECISION_DECIMALS, `Value cannot be less than ${MIN_PRECISION_DECIMALS}`),
];

export const SHARED_SETTINGS_VALIDATION_SCHEME: FormValidation<CEXSettings> = {
  name: shortThan(50),
  "settings.period.value": required(),
  "settings.pair.minExchangeAmount": [required(), isNumber()],
  "settings.pair.dontCheckAmount": [required(), isNumber()],
  "settings.pair.dontTradePriceMax": [
    required(),
    isNumber(),
    graterThan(0, "The value must be positive"),
    greaterThanNumberKey("settings.pair.dontTradePriceMin", "DTPMax must be greater than DTPMin"),
  ],
  "settings.pair.dontTradePriceMin": [
    required(),
    isNumber(),
    graterThan(0, "The value must be positive"),
    smallerThanNumberKey("settings.pair.dontTradePriceMax", "DTPMin should be less than DTPMax"),
  ],
  "settings.decimals.amount": PRECISION_VALIDATION,
  "settings.decimals.price": PRECISION_VALIDATION,
  "settings.trade.minTrades": [
    required(),
    isInteger(),
    graterThan(0, "The value must be positive"),
    notEqual(0, "Value cannot be 0"),
    smallerThanKey("settings.trade.maxTrades", "TradesMin should be less than TradesMax"),
  ],
  "settings.trade.maxTrades": [
    required(),
    isInteger(),
    graterThan(0, "The value must be positive"),
    notEqual(0, "Value cannot be 0"),
    graterThanKey("settings.trade.minTrades", "TradesMax must be greater than TradesMin"),
  ],
  "settings.trade.buyBeforeSellMin": [
    required(),
    isInteger(),
    graterThan(0, "The value must be positive"),
    smallerThanKey("settings.trade.buyBeforeSellMax", "BBsMin should be less than BBsMax"),
  ],
  "settings.trade.buyBeforeSellMax": [
    required(),
    isInteger(),
    graterThan(0, "The value must be positive"),
    graterThanKey("settings.trade.buyBeforeSellMin", "BBsMax must be greater than BBsMin"),
  ],
  "settings.trade.buyPercent": required(),
  "settings.volume.tradePerDayMinUSD": [required(), graterThan(0, "The value must be positive")],
  "settings.volume.modifyTradePerDayMinUSD": [
    required(),
    graterThan(0, "The value must be positive"),
  ],
  "settings.volume.modifyDeltaBalanceQuoteUSD": [
    required(),
    graterThan(0, "The value must be positive"),
  ],
  "settings.volume.modifyDeltaBalanceBase": [
    required(),
    graterThan(0, "The value must be positive"),
  ],
};
