import { Currency, CurrencyAmount, Fraction, Percent, Price, sqrt } from "@uniswap/sdk-core";
import { BigNumber, ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { formatPNLPrice } from "src/components/shared/PNLV2/shared/utilts";
import { countDecimalsPlaces } from "src/helpers/math";
import { LogLevel, logDev } from "src/helpers/network/logger";
import { calcRoundingValue, toRounding } from "src/helpers/rounding";
import { Nullish } from "src/helpers/utils";
import { ALLOWED_PRICE_IMPACT_MEDIUM } from "./constants";

/**
 * Parses a CurrencyAmount from the passed string.
 * Returns the CurrencyAmount, or undefined if parsing fails.
 */

export function tryParseCurrencyAmount<T extends Currency>(
  value?: string,
  currency?: T
): CurrencyAmount<T> | undefined {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== "0") {
      return CurrencyAmount.fromRawAmount(currency, typedValueParsed);
    }
  } catch (error) {
    // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    logDev([`Failed to parse input amount: "${value}"`, error], {
      level: LogLevel.Debug,
    });
  }
  return undefined;
}

export function tryParsePrice<TQuote extends Currency, TBase extends Currency>(
  value?: string,
  quoteCurrency?: TQuote,
  baseCurrency?: TBase
): Price<TBase, TQuote> | undefined {
  if (!value || !quoteCurrency || !baseCurrency) {
    return undefined;
  }
  try {
    const decimalsCount = countDecimalsPlaces(value);
    const typedPriceParsed = parseUnits(value, decimalsCount).toString();
    if (typedPriceParsed === "0") {
      return undefined;
    }
    const priceDecimalsScale = BigNumber.from(10).pow(decimalsCount).toString();
    const priceFraction = new Fraction(typedPriceParsed, priceDecimalsScale);

    const unitPrice = new Price({
      baseAmount: CurrencyAmount.fromRawAmount(baseCurrency, 1),
      quoteAmount: CurrencyAmount.fromRawAmount(quoteCurrency, 1),
    });

    // price (quote(y)/base(x)) decimals scalar (dx/dy)
    const priceScalar = unitPrice.scalar;

    // string price from Price class: p = y/x * dx/dy (decimals adjusted) =>
    // Price class from string price: y/x = p*dy/dx
    const priceRawFraction = priceFraction.multiply(priceScalar.invert());

    return new Price(
      baseCurrency,
      quoteCurrency,
      priceRawFraction.denominator,
      priceRawFraction.numerator
    );
  } catch (error) {
    logDev([`Failed to parse price: "${value}"`, error], {
      level: LogLevel.Debug,
    });
  }
  return undefined;
}

export function parsePercent(percent: string | undefined): Percent | undefined {
  if (!percent) return undefined;
  if (Number.isNaN(percent)) return undefined;
  const numerator = Math.floor(Number(percent) * 100);
  return new Percent(numerator, 10000);
}

export function formatNumber(input: Nullish<number>, placeholder = "-") {
  if (input === null || input === undefined) {
    return placeholder;
  }

  const fractionDigits = Math.min(calcRoundingValue(input), 6);
  const roundedInput = toRounding(input, fractionDigits);
  return roundedInput;
}

export const formatCurrencyAmount = (
  amount: Nullish<CurrencyAmount<Currency>>,
  showSymbol = false,
  placeholder = "-"
) => {
  if (amount === null || amount === undefined) {
    return placeholder;
  }
  const formattedAmount = formatNumber(parseFloat(amount.toFixed()), placeholder);

  const symbol = amount?.currency.symbol ?? "";
  if (!showSymbol || !symbol) {
    return formattedAmount;
  }

  return `${formattedAmount} ${symbol}`;
};

export interface FormatFiatAmountOptions {
  tickerPosition?: "start" | "end";
}

export const formatFiatAmount = (
  amount: Nullish<CurrencyAmount<Currency>>,
  { tickerPosition = "start" }: FormatFiatAmountOptions = {}
) => {
  const formattedAmount = formatCurrencyAmount(amount);
  const fiatTicker = "$";

  const fiatAmountTicker =
    tickerPosition === "start" ? [fiatTicker, formattedAmount] : [formattedAmount, fiatTicker];

  return fiatAmountTicker.join(" ");
};

interface IFormatCurrencyPriceOptions {
  price: Nullish<Price<Currency, Currency>>;
  quoteSymbol: boolean;
  customSymbol?: string;
  placeholder: string;
}

const formatCurrencyPrice = ({
  price,
  quoteSymbol,
  customSymbol,
  placeholder,
}: IFormatCurrencyPriceOptions) => {
  if (price === null || price === undefined) {
    return placeholder;
  }
  const formattedPrice = formatPNLPrice(price.toSignificant());

  const currencySymbol = price.quoteCurrency.symbol ?? "";
  const symbol = customSymbol ?? currencySymbol;

  if (!quoteSymbol || !symbol) return formattedPrice;

  return `${formattedPrice} ${symbol}`;
};

export const formatFiatPrice = (
  price: Nullish<Price<Currency, Currency>>,
  quoteSymbol = false,
  placeholder = "-"
) => formatCurrencyPrice({ price, quoteSymbol, customSymbol: "$", placeholder });

export const formatPrice = (
  price: Nullish<Price<Currency, Currency>>,
  quoteSymbol = false,
  placeholder = "-"
) => formatCurrencyPrice({ price, quoteSymbol, placeholder });

export const sqrtFraction = (fraction: Fraction) => {
  const sqrtNumerator = sqrt(fraction.numerator);
  const sqrtDenominator = sqrt(fraction.denominator);
  return new Fraction(sqrtNumerator, sqrtDenominator);
};

/**
 * Formats the price impact value directly without sign correction.
 */
export const formatPriceImpactValue = (impact: Percent | undefined) => {
  if (!impact) return "-";
  return `${impact.toFixed(3)}%`;
};

export type FormatPriceImpact = {
  impact: string;
  sign?: number;
};
/**
 * Formats the price impact value adjusting for sign from sdk.
 */
export const formatPriceImpact = (priceImpact: Percent | undefined): FormatPriceImpact => {
  if (!priceImpact) return { impact: "-" };

  // reverse sign of price impact to display, because we store opposite sign internally
  const invertedPriceImpact = priceImpact.multiply(-1);
  const priceImpactSign = fractionSign(invertedPriceImpact);
  return {
    impact: formatPriceImpactValue(invertedPriceImpact),
    sign: priceImpactSign,
  };
};

export function getPriceImpactWarning(priceImpact: Percent): boolean | undefined {
  const absPriceImpactFraction = fractionAbs(priceImpact);
  const absPriceImpact = new Fraction(
    absPriceImpactFraction.numerator,
    absPriceImpactFraction.denominator
  );
  logDev(["getPriceImpactWarning", absPriceImpact, priceImpact, ALLOWED_PRICE_IMPACT_MEDIUM]);
  if (absPriceImpact.greaterThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return true;
}

export function fractionSign(fraction: Fraction) {
  const numerator = BigNumber.from(fraction.numerator.toString());
  const denominator = BigNumber.from(fraction.denominator.toString());
  const fractionMul = numerator.mul(denominator);
  if (fractionMul.isNegative()) {
    return -1;
  }
  if (fractionMul.isZero()) {
    return 0;
  }
  return 1;
}

export function fractionAbs(fraction: Fraction) {
  // we can't use fractionSign to calculate fraction abs, since no sign is stored per fraction
  // there are separate numerator/denominator signs => comparisons wont work
  const numeratorAbs = BigNumber.from(fraction.numerator.toString()).abs();
  const denominatorAbs = BigNumber.from(fraction.denominator.toString()).abs();
  return new Fraction(numeratorAbs.toString(), denominatorAbs.toString());
}

export function fractionToPercent(fraction: Fraction) {
  const percent = new Percent(fraction.numerator, fraction.denominator);
  return percent;
}

export function percentAbs(percent: Percent) {
  const absFraction = fractionAbs(percent);
  return fractionToPercent(absFraction);
}

export const unitCurrencyAmount = <T extends Currency>(currency: T) => {
  const rawUnitAmount = ethers.utils.parseUnits("1", currency.decimals);

  return CurrencyAmount.fromRawAmount(currency, rawUnitAmount.toString());
};

export const zeroCurrencyAmount = <T extends Currency>(currency: T) =>
  CurrencyAmount.fromRawAmount(currency, "0");
