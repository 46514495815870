import { Tooltip, ITooltip as TooltipProps } from "react-tooltip";
import styled from "styled-components";
import { MenuContainer, MenuList } from "../style";

export const SubMenuList = styled(MenuList)``;

export interface SubMenuContainerProps extends Pick<TooltipProps, "id"> {}

export const SubMenuContainer = styled(Tooltip).attrs<SubMenuContainerProps, TooltipProps>(
  ({ id }) => ({
    id,
    place: "left",
    delayHide: 100,
    clickable: true,
  })
)<TooltipProps>`
  ${MenuContainer}

  width: auto !important;
  min-width: 80px;
  padding: 0 !important;
`;
