import { Role } from "src/modules/userManager";
import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from "..";

export const getRoles = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/roles?abilities=true"),
    method: "GET",
  });

export const addRole = async (role: Role) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/roles"),
    method: "POST",
    data: role,
  });

export const deleteRole = async (roleName: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/roles/${roleName}`),
    method: "DELETE",
  });
