import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import TeamsStore from "src/state/UserManager/Teams/TeamsStore";

export const TeamsContext = createLateInitContext<TeamsStore>();

type TeamsProviderProps = ChildrenProps;

export const TeamsProvider = ({ children }: TeamsProviderProps) => {
  const scopesStore: TeamsStore = useLocalStore(TeamsStore);

  return <TeamsContext.Provider value={scopesStore}>{children}</TeamsContext.Provider>;
};
