import { makeAutoObservable } from "mobx";
import { History } from "history";
import { prepareQueryObj } from "./utils";
import {
  ISearchParamsProps,
  ISearchParamsState,
  QueryParams,
  QueryStringObj,
  UrlParamsSchema,
} from "./types";

const STORAGE_KEY = "searchParams";

export class UrlSearchParamsStore<T extends QueryParams> {
  private _query: URLSearchParams;

  private _history: History;

  private _mainState: ISearchParamsState<T>;

  private _validationSchema: UrlParamsSchema<T>;

  private _localStorageName?: string;

  constructor(
    mainState: ISearchParamsState<T>,
    { query, history, validationSchema, localStorageName }: ISearchParamsProps<T>
  ) {
    makeAutoObservable(this);

    this._query = query;
    this._history = history;
    this._mainState = mainState;
    this._validationSchema = validationSchema;
    this._localStorageName = localStorageName;

    this._initializeStateFromStorageOrUrl();
    this._parseQuery();
  }

  setAllQueryUrl = (newQuery: Partial<T>) => {
    const urlQuery = prepareQueryObj(newQuery);

    this._setHistoryQuery(urlQuery);
  };

  setPartialQueryUrl = (newQuery: Partial<T>) => {
    const urlQuery = prepareQueryObj(newQuery);

    this._setHistoryQuery(urlQuery, false);
  };

  private _setHistoryQuery = (queryObj: QueryStringObj, clearParams: boolean = true) => {
    if (clearParams) this._setNewQuery();

    Object.entries(queryObj).forEach(([key, value]) => this._query.set(key, value));

    this._query.sort();
    this._pushSearchToHistory(this._query.toString());
  };

  private _pushSearchToHistory = (search: string) => {
    if (this._localStorageName) this._setToLocalStorage(this._localStorageName, search);

    this._history.replace({ search });
  };

  private _parseQuery = () => {
    const parsedObj = Object.fromEntries(Array.from(this._query.entries()));

    const validatedParams = this._validationSchema.validateSync(parsedObj, {
      stripUnknown: true,
      abortEarly: false,
    });

    this._mainState.setInitialQueries(validatedParams as Partial<T>);
  };

  private _setNewQuery = (search?: string) => {
    this._query = new URLSearchParams(search);
  };

  private _initializeStateFromStorageOrUrl = () => {
    if (!this._localStorageName) return;

    const storageQuery = this._getFromLocalStorage(this._localStorageName);

    if (storageQuery && !this._query.size) this._setNewQuery(storageQuery);
    else this._setToLocalStorage(this._localStorageName, this._query.toString());
  };

  private _getFromLocalStorage = (key: string) => {
    const currentStorage = localStorage.getItem(STORAGE_KEY);

    if (!currentStorage || !key) return;

    const parsedStorage = JSON.parse(currentStorage) as Record<string, string>;

    return parsedStorage[key];
  };

  private _setToLocalStorage = (key: string, value: string) => {
    const currentStorage = localStorage.getItem(STORAGE_KEY);
    const parsedStorage = currentStorage ? JSON.parse(currentStorage) : {};
    const serializedValue = JSON.stringify({ ...parsedStorage, [key]: value });

    localStorage.setItem(STORAGE_KEY, serializedValue);
  };
}
