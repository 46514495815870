import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { useQueryUrlParams } from "src/hooks/useQueryUrlParams";
import { useSubscribableStore } from "src/hooks/useSubscribableStore";
import { CEXDashboardV2Store, IDashboardV2State } from "src/state/CEX/CEXDashboard/v2";

const useDashboardState = () => {
  const searchParamsProps = useQueryUrlParams();

  return useLocalStore(CEXDashboardV2Store, searchParamsProps);
};

export const DashboardContext = createLateInitContext<CEXDashboardV2Store>();

type DashboardProviderProps = ChildrenProps;

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const state = useDashboardState();

  useSubscribableStore(state);

  return <DashboardContext.Provider value={state}>{children}</DashboardContext.Provider>;
};
