import { observer } from "mobx-react-lite";
import useLocalStore from "src/hooks/useLocalStore";
import { AllBotsContext } from "src/context/AllBots";
import { useAppState } from "src/state";
import { AllBotsStore } from "src/state/AllBots";
import { AllBots } from "./AllBots";

interface PartyAllBotsProps {
  party: string;
}

export const PartyAllBots = observer(({ party }: PartyAllBotsProps) => {
  const { chainsInfoState, DEXNERootState } = useAppState();

  const state = useLocalStore(AllBotsStore, chainsInfoState, DEXNERootState, party);

  return (
    <AllBotsContext.Provider value={state}>
      <AllBots />
    </AllBotsContext.Provider>
  );
});
