import { BotType, getRoutePath } from "../shared/MenuList/utils";

interface NavPath {
  path: string;
  botType: BotType;
}

export const BOT_NAV_PARAMS: NavPath[] = [
  { path: getRoutePath({ layer: "bot", params: { botType: "CEX" } }), botType: "CEX" },
  { path: getRoutePath({ layer: "bot", params: { botType: "DEX" } }), botType: "DEX" },
  { path: getRoutePath({ layer: "bot", params: { botType: "DEX_V2" } }), botType: "DEX_V2" },
  { path: getRoutePath({ layer: "bot", params: { botType: "DEX_NE" } }), botType: "DEX_NE" },
];
