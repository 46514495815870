import { Currency, NativeCurrency, Token } from "@uniswap/sdk-core";

export class AbstractNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }

  get wrapped() {
    throw new Error("Can't wrap abstract native");
    // eslint-disable-next-line no-unreachable
    return new Token(this.chainId, "", 18);
  }

  constructor() {
    const chainId = -100;
    super(chainId, 18, "NATIVE", "Chain Native");
  }
}
