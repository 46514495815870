import { Account, DeleteParty, NewParty } from "src/modules/userManager";
import { INewTickerGroup, ITickerGroup } from "src/state/UserManager/EditParty/TickersStore";
import ResponseHandler from "src/state/network/ResponseHandler";
import {
  EditGroupInfo,
  EditSantimentInfo,
} from "src/state/UserManager/EditParty/Info/PartyInfoStore";
import { ApiModules, apiUrl } from "..";

export const getParties = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, ""),
    method: "GET",
  });

export const addParties = async (data: NewParty) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, ""),
    method: "POST",
    data,
  });

export const removeParty = async ({ scope_id, party }: DeleteParty) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/scopes/${scope_id}/parties/${party}`),
    method: "DELETE",
  });

export const getPartyBots = async (party: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `/${party}/bots`),
    method: "GET",
  });

export const getPartySwapBots = async (party: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `/${party}/swap-bots`),
    method: "GET",
  });

export interface DEXV2PartyBot {
  base_ticker: string;
  exchange: string;
  name: string;
  quote_ticker: string;
  uuid: string;
}
export type GetPartyDEXV2BotsResponse = DEXV2PartyBot[];

export type DEXNEPartyBotDTO = {
  base_ticker: string;
  bot_id: string;
  bot_name: string;
  chain_name: string;
  dex_name: string;
  quote_ticker: string;
};

export const getPartyDEXV2Bots = async (party: string) =>
  ResponseHandler.handler<GetPartyDEXV2BotsResponse>({
    url: apiUrl(ApiModules.Empty, `dex/${party}/bots`),
    method: "GET",
  });

export const getPartyDEXNEBots = async (party: string) =>
  ResponseHandler.handler<{ bots: DEXNEPartyBotDTO[] }>({
    url: apiUrl(ApiModules.DEXNE, `party/${party}/bots`),
    method: "GET",
  });

export const getPartyAccounts = async (party: string, isCredentials = true) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `${party}/accounts?credentials=${isCredentials}`),
    method: "GET",
  });

export type GetShortPartyAccountsResponse = Record<string, Account[]>;

export const getShortPartyAccounts = async (party: string) =>
  ResponseHandler.handler<GetShortPartyAccountsResponse>({
    url: apiUrl(ApiModules.Parties, `${party}/accounts?credentials`),
    method: "GET",
  });

export const moveBotNewParty = async (botUUID: string, party: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `/${botUUID}/party`),
    method: "PUT",
    data: {
      party,
    },
  });

export const moveSwapBotNewParty = async (botUUID: string, party: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `/${botUUID}/party`),
    method: "PUT",
    data: {
      party,
    },
  });

export const moveDEXV2BotNewParty = async (bot_uuid: string, party: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.DEXV2Bot, `/${bot_uuid}/move/${party}`),
    method: "PATCH",
    data: {
      party,
    },
  });

export const moveDEXNEBotNewParty = async (botUuid: string, party: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.DEXNEBot, `/${botUuid}/move/${party}`),
    method: "PATCH",
  });

export interface GetPartyInfoResponse {
  id: number;
  admin: string;
  admin_group: string;
  client_group: string;
  party: string;
  scopes: number[];
  slug: string;
  tg_chat_id: number;
  ticker: string;
}

export const getPartyInfo = async (party: string) =>
  ResponseHandler.handler<GetPartyInfoResponse>({
    url: apiUrl(ApiModules.Auth, `/parties/${party}`),
    method: "GET",
  });

interface ChangePartyGroupInfoProps {
  party: string;
  groupInfo: EditGroupInfo;
}

export const changePartyGroupInfo = async ({ party, groupInfo }: ChangePartyGroupInfoProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/parties/${party}`),
    method: "PUT",
    data: groupInfo,
  });

export const changePartySantimentInfo = async (party: string, santimentInfo: EditSantimentInfo) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/parties/${party}/config`),
    method: "PUT",
    data: santimentInfo,
  });

export const getPartyTickerGroups = async (party: string) =>
  ResponseHandler.handler<ITickerGroup[]>({
    url: apiUrl(ApiModules.Parties, `${party}/tickers`),
    method: "GET",
  });

export const addTickerGroup = async (party: string, data: INewTickerGroup) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `${party}/tickers`),
    method: "POST",
    data,
  });

export const editTickerGroup = async (tickerUUID: string, data: INewTickerGroup) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Tickers, `${tickerUUID}`),
    method: "PUT",
    data,
  });
export const deleteTickerGroup = async (tickerUUID: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Tickers, `${tickerUUID}`),
    method: "DELETE",
  });
