import { getAbilities } from "src/helpers/getToken";
import { checkParty } from "src/helpers/checkParty";
import { LayerType } from "../..";
import { IMenuItem } from "./MenuList/MenuItem/types";

export const filterWithAbilitiesMenu = (menuItems: IMenuItem<LayerType>[], party: string) => {
  const abilities = getAbilities();
  return menuItems.filter(({ abilityName }) =>
    abilityName ? checkParty(abilities, abilityName, party) : true
  );
};
