import { lazy } from "react";
import * as Sentry from "@sentry/react";
import { FAILED_IMPORT_MSGS } from "./constants";

/**
 * Simple wrapper around React.lazy that supports named imports.
 * Copy from react-lazily: https://github.com/JLarky/react-lazily
 */
export const lazily = <T extends {}, U extends keyof T>(
  loader: (x?: string) => Promise<T | undefined | null>
) =>
  new Proxy({} as unknown as T, {
    get: (target, componentName: string | symbol) => {
      if (typeof componentName === "string") {
        return lazy(() =>
          loader(componentName)
            .then((x) => {
              if (x && x[componentName as U]) {
                return {
                  default: x[componentName as U] as any as React.ComponentType<any>,
                };
              }
              throw new Error(`Component ${componentName} not found in module.`);
            })
            .catch((error) => {
              Sentry.captureException(error);
              const isFailedFetch = checkFailedImport(error?.message ?? "");
              if (isFailedFetch) window.location.reload();
              throw error;
            })
        );
      }
    },
  });

function checkFailedImport(errMsg: string) {
  return Boolean(FAILED_IMPORT_MSGS.find((msg) => errMsg.includes(msg)));
}
