import styled from "styled-components";
import { BotLinkButton } from "../../shared/BotLinkButton";
import { BotPairButton } from "../../shared/BotPairButton";
import {
  Comment,
  Exchange,
  LockTimeText,
  Mark,
  Message,
  MobFrame,
  Time,
} from "../../shared/shared";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 3px;
`;

export const PairMob = styled(BotPairButton)`
  height: 25px;

  grid-area: botName;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledFrame = styled(MobFrame)`
  flex-direction: row;
`;

export const FirstRow = styled.div`
  width: 100%;
  height: 50%;
  display: grid;
  grid-template-columns:
    minmax(95px, 1fr)
    minmax(50px, 100px) minmax(40px, 1fr)
    minmax(50px, 1fr) minmax(80px, 1fr);
  gap: 5px;

  grid-template-areas: "botName exchange downTime lockTime btns";

  margin-bottom: 5px;
`;

export const SecondRow = styled.div`
  width: 100%;
  height: 50%;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: calc(50% - 17px) calc(50% - 17px) 35px;
  grid-template-rows: 100%;
  gap: 5px;

  grid-template-areas: "comment message";
`;

export const WrapperForBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: btns;

  & > a:first-of-type {
    margin-right: 5px;
  }
`;

export const MobExchangeLink = styled(BotLinkButton)`
  height: 27px;
  max-width: 140px;

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["9px"]};
  }
`;

export const MobExchange = styled(Exchange)`
  grid-area: exchange;
  font-size: ${({ theme }) => theme.fontSizes["16px"]};

  @media (max-width: 875px) {
    font-size: ${({ theme }) => theme.fontSizes["14px"]};
  }

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const MobTime = styled(Time)`
  width: calc(100% - 30px);
  font-size: ${({ theme }) => theme.fontSizes["18px"]};
  text-align: center;

  @media (max-width: 875px) {
    font-size: ${({ theme }) => theme.fontSizes["14px"]};
  }

  @media (max-width: 450px) {
    width: 100%;

    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const MobComment = styled(Comment)`
  text-align: center;
  padding-left: 20px;

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const MobLockTime = styled(LockTimeText)`
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
  text-align: center;
  width: max-content;
  margin-left: "3px";

  @media (max-width: 875px) {
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const MobMessage = styled(Message)`
  max-width: 100%;

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const MobMark = styled(Mark)`
  width: 27px;
  height: 27px;
  flex: 0 0 auto;

  & > svg {
    width: 15px;
  }
`;
