import { ApiModules, apiUrl } from "src/api";
import { reqRefreshToken } from "src/api/auth";
import { RequestConfig } from "src/state/network/ResponseHandler";
import { getRefreshToken, setAbilities, setAccessToken, setRefreshToken } from "../getToken";
import { logError } from "../network/logger";

export const updateRefreshTokenStorage = (data: any) => {
  const { accessToken, refreshToken, abilities } = data;

  if (accessToken) setAccessToken(accessToken);

  if (refreshToken) setRefreshToken(refreshToken);

  if (abilities) setAbilities(abilities);
};

export const refreshTokenRequestConfig = (token: string): RequestConfig => ({
  url: apiUrl(ApiModules.Empty, "/refresh-token"),
  method: "POST",
  data: { refreshToken: token },
  auth: false,
});

/**
 * method for refresh tokens and user abilities
 */
export const refreshToken = async () => {
  try {
    const { isError, data } = await reqRefreshToken(getRefreshToken());

    if (!isError) {
      updateRefreshTokenStorage(data);
    }
  } catch (err) {
    logError(err);
  }
};
