import { AsProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";

export const ActiveAnimaMixin = css`
  transition:
    background-color 0.15s ease-out,
    color 0.15s ease-out,
    opacity 0.15s ease-out;
`;

export const BaseButton = styled.button.attrs<AsProps>(({ as, type }) => {
  const isButton = as === undefined || as === "button";
  return (
    isButton && {
      type: type ?? "button",
    }
  );
})`
  position: relative;
  box-sizing: border-box;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;

  outline: 0;
  border: 0;
  border-radius: 0;

  cursor: pointer;
  user-select: none;
  text-decoration: none;

  ${ActiveAnimaMixin};

  :disabled,
  &[disabled] {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
`;
