import { ElementType } from "react";
import { BooleanString } from "src/modules/shared/global";

export * from "./types";

export type UnionMap<Numbers extends number | string> = {
  [k in Numbers]: k;
};

export type TupleToUnion<T extends readonly any[]> = T[number];

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export type SetRequired<T, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>;
export type SetRequiredU<T, K extends keyof T> = Required<PickUnion<T, K>> & OmitUnion<T, K>;

export type Extends<T, U extends T> = U;

export type ExcludeStrict<Type, ExcludedUnion extends Type> = Exclude<Type, ExcludedUnion>;

export type ExtractStrict<Type, ExtractedUnion extends Partial<Type>> = Extract<
  Type,
  ExtractedUnion
>;

export type OmitStrict<T, K extends keyof T> = T extends any ? Pick<T, Exclude<keyof T, K>> : never;

export type OmitUnion<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export type OmitEndingWith<T, U extends string> = {
  [K in keyof T as K extends `${string}${U}` ? never : K]: T[K];
};

export type PickUnion<T, K extends keyof T> = T extends any ? Pick<T, K> : never;

export type Simplify<T> = { [KeyType in keyof T]: T[KeyType] } & {};

export type Nominal<T, Name extends string> = T & {
  [Symbol.species]: Name;
};

export type Nullable<T> = T | null;
export type Nullish<T> = Nullable<T> | undefined;

export type CommonKeys<A, B> = keyof A & keyof B;

export type KeysOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: any;
};

export type NoInfer<T> = [T][T extends any ? 0 : never];

export type Satisfies<U, T extends U> = T;

export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export interface ClassConstructor<
  Class extends abstract new (...args: any) => any,
  InstanceInterface = never,
> {
  new (...args: ConstructorParameters<Class>): InstanceType<Class> & InstanceInterface;
}

export type WhenReactionPromise = Promise<void> & {
  cancel(): void;
};
export interface IDisposable {
  destroy: () => void;
}

export interface ISubscribable {
  subscribe: () => void;
}

export type ChildrenProps = {
  children?: React.ReactNode;
};

export type ElementRef<T extends ElementType> = React.ComponentPropsWithRef<T>["ref"];

export interface OwnRefProps<T extends ElementType> {
  ownRef?: ElementRef<T>;
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export type EntriesUnion<T, KeyName extends string = "key", ValueName extends string = "value"> = {
  [K in keyof T]: Simplify<{ [P in KeyName]: K } & { [V in ValueName]: T[K] }>;
}[keyof T];

export type ObjectKeys<T extends object> = `${Exclude<keyof T, symbol>}`;

export type RenameKeys<T, R extends Partial<Record<keyof T, string>>> = {
  [K in keyof T as K extends keyof R ? (R[K] extends string ? R[K] : never) : K]: K extends keyof T
    ? T[K]
    : never;
};

export type ArrayElement<T> = T extends (infer U)[] ? U : never;

export interface SubscribableStore {
  subscribe: () => void;
  unsubscribe: () => void;
}

/**
 * Converts readonly to typed object with keys = values = items in tuple
 * @param numbers - readonly array
 * @returns object with key = value
 */
export const readonlyArrToObj = <Numbers extends readonly (number | string)[]>(
  numbers: Numbers
) => {
  const numbersObj = numbers.reduce((obj, val) => {
    // eslint-disable-next-line no-param-reassign
    obj[val] = val;
    return obj;
  }, {} as any) as UnionMap<TupleToUnion<Numbers>>;
  return numbersObj;
};

export const arrToObj = <T, K extends keyof any>(
  source: T[],
  getKey: (item: T) => K
): Record<K, T> =>
  source.reduce(
    (obj, val) => {
      // eslint-disable-next-line no-param-reassign
      obj[getKey(val)] = val;
      return obj;
    },
    {} as Record<K, T>
  );

/**
 * Checks whether string is undefined or empty
 * @param str - string to test against
 * @returns true if string is not empty and defined, else false
 */
export const isEmptyOrUndefined = (str: string | undefined): str is undefined | "" =>
  str === undefined || str === "";

export const isNull = (value: unknown): value is null => value === null;

export const noOp = () => {};

export const noOpAsync = () => Promise.resolve();

export const entries = <T extends object>(obj: T): Entries<T> => Object.entries(obj) as any;

export const keys = Object.keys as <Type extends object>(value: Type) => Array<ObjectKeys<Type>>;

export const filterCallback = (value: string, filter: string) =>
  value.toLowerCase().includes(filter.toLowerCase());

export const objProp = <T extends Object>(key: PropertyKey, obj: T): key is keyof T => key in obj;

export const objToArr = <T extends object>(obj: T) => {
  const arr = [];

  for (const key of Object.keys(obj) as Array<keyof T>) {
    arr.push({ label: key, value: obj[key] });
  }

  return arr;
};

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export function isReactComponent<TProps>(
  component: unknown
): component is React.ComponentType<TProps> {
  return (
    isClassComponent(component) || typeof component === "function" || isExoticComponent(component)
  );
}

function isClassComponent(component: any) {
  return (
    typeof component === "function" &&
    (() => {
      const proto = Object.getPrototypeOf(component);
      return proto.prototype && proto.prototype.isReactComponent;
    })()
  );
}

function isExoticComponent(component: any) {
  return (
    typeof component === "object" &&
    typeof component.$$typeof === "symbol" &&
    ["react.memo", "react.forward_ref"].includes(component.$$typeof.description)
  );
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const stringToBoolean = (value: BooleanString) => value === "true";
