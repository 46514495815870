import { makeAutoObservable } from "mobx";

export interface ISimpleInvalidationEmitter {
  get invalidations(): number;
  invalidate: () => void;
}

export class SimpleInvalidationStore implements ISimpleInvalidationEmitter {
  private _invalidationCounter = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get invalidations() {
    return this._invalidationCounter;
  }

  invalidate() {
    this._invalidationCounter += 1;
  }
}
