import { EmptyListText as BaseEmptyListText } from "src/components/shared/shared";
import styled from "styled-components";

export const PartiesList = styled.ul`
  overflow-y: auto;

  padding: 0;
  padding-right: 5px;
  margin: 0;

  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 6px;

  @media (max-width: 650px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 6px;
    column-gap: 12px;
  }

  list-style: none;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

export const EmptyListText = styled(BaseEmptyListText)``;
