import { FC } from "react";
import { Link } from "react-router-dom";
import { LinkButtonProps } from "src/components/shared/Buttons/v2/LinkButton";
import * as styles from "./style";

export interface BotLinkButtonProps
  extends Omit<LinkButtonProps<typeof Link>, "color" | "variant" | "as"> {
  color?: styles.BotLinkButtonStyleProps["$accentColor"];
}

export const BotLinkButton = ({ color, ...props }: BotLinkButtonProps) => (
  <styles.StyledBotLinkButton<FC<LinkButtonProps<typeof Link> & styles.BotLinkButtonStyleProps>>
    forwardedAs={Link}
    $accentColor={color}
    color="primary"
    size="m"
    stretched
    elevated={false}
    {...props}
  />
);
