import { NewScope, Scope } from "src/modules/userManager";
import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from "..";

export const getScopes = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/scopes"),
    method: "GET",
  });

export const addScope = async (data: NewScope) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/scopes"),
    method: "POST",
    data,
  });

export const changeScope = async (data: Scope) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/scopes/${data.scope_id}`),
    method: "PUT",
    data,
  });

export const deleteScope = async (scope_id: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/scopes/${scope_id}`),
    method: "DELETE",
  });
