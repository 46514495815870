import { SetOrderFromOrderBookProps } from "../types";
import { SelectedOrders } from "./types";

export function createEmptyOrderProps(): SetOrderFromOrderBookProps {
  return {
    amount: "",
    market: {
      decimals: { amount: "", price: "" },
      market: { base: "", exchange: "", quote: "" },
    },
    price: "",
  };
}

export function createEmptySelectOrders(): SelectedOrders {
  return {
    buy: null,
    sell: null,
  };
}
