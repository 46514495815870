import { IArrayInputFormProvider } from "src/state/DEXV2/shared/ArrayInputStore";
import { ArrayFormBinderStore } from "../FormBinder";
import { IArrayFormBinder } from "../FormBinder/types";

export class DEXNEWalletsInputFormAdapter implements IArrayInputFormProvider<string> {
  private _binder: IArrayFormBinder<string>;

  constructor(binder: IArrayFormBinder<string>) {
    this._binder = binder;
  }

  get formArray() {
    return this._binder.values;
  }

  setFormArray = (arr: string[]) => {
    this._binder.setArray(arr);
  };
}
