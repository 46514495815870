import { makeAutoObservable } from "mobx";
import {
  DEXCommonBotsColorStatus,
  ListDEXCommonBot,
} from "src/components/AllBots/Bots/shared/DEXCommon";
import { IDEXV2BotsProvider } from "./DEXV2Bots";

export interface IDEXV2BotStatusFilter {
  get count(): number;
  get isEnabled(): boolean;
  toggleEnabled: () => void;
  filter: (bot: ListDEXCommonBot) => boolean;
}

export default class DEXV2BotStatusFilterStore implements IDEXV2BotStatusFilter {
  private _enabled = false;

  private _botsProvider: IDEXV2BotsProvider;

  private _status: DEXCommonBotsColorStatus;

  constructor(botsProvider: IDEXV2BotsProvider, status: DEXCommonBotsColorStatus) {
    makeAutoObservable(this);

    this._botsProvider = botsProvider;

    this._status = status;
  }

  private get _bots() {
    return this._botsProvider.allBots;
  }

  get isEnabled() {
    return this._enabled;
  }

  toggleEnabled = () => {
    this._enabled = !this._enabled;
  };

  private _filterStatus = ({ colorStatus }: ListDEXCommonBot) => colorStatus === this._status;

  filter = (bot: ListDEXCommonBot) => this._enabled && this._filterStatus(bot);

  private get _statusCount() {
    return this._bots.filter(this._filterStatus).length;
  }

  get count() {
    return this._statusCount;
  }
}
