import useLocalStore from "src/hooks/useLocalStore";
import BotModulesStore from "src/state/CEX/botModules";
import { createContextProvider } from "../../utils/createContextProvider";

const CreateBotModulesStore = () => useLocalStore(BotModulesStore);

const { Context: BotModulesContext, Provider: BotModulesProvider } =
  createContextProvider<BotModulesStore>({
    stateCreator: CreateBotModulesStore,
  });

export { BotModulesContext, BotModulesProvider };
