import responseHandler from "src/state/network/ResponseHandler";
import {
  ArbitrageCreatorDTO,
  ArbitrageExchangeModuleDTO,
  ArbitrageGeneralSettingsDTO,
  ArbitrageStatus,
  CreatedArbitrageExchangeModuleDTO,
} from "./types";
import { ApiModules, apiUrl } from "..";

export const getPartyArbitrage = async (party: string) =>
  responseHandler.handler<string[]>({
    url: apiUrl(ApiModules.Parties, `${party}/${ApiModules.CEXTaker}`),
    method: "GET",
  });

export const createArbitrage = (data: ArbitrageCreatorDTO) =>
  responseHandler.handler<string>({
    url: apiUrl(ApiModules.CEXTaker, ""),
    method: "POST",
    data,
  });

export const getArbitrageSettings = async (uuid: string) =>
  responseHandler.handler<ArbitrageGeneralSettingsDTO>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/settings`),
    method: "GET",
  });

export const changeArbitrageSettings = async (uuid: string, data: ArbitrageGeneralSettingsDTO) =>
  responseHandler.handler<ArbitrageGeneralSettingsDTO>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/settings`),
    method: "PUT",
    data,
  });

export const getArbitrageStatus = async (uuid: string) =>
  responseHandler.handler<ArbitrageStatus>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/status`),
    method: "GET",
  });

export const startArbitrage = async (uuid: string) =>
  responseHandler.handler<string>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/start`),
    method: "POST",
  });

export const stopArbitrage = async (uuid: string) =>
  responseHandler.handler<string>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/stop`),
    method: "POST",
  });

export const getExchangeModules = async (uuid: string) =>
  responseHandler.handler<CreatedArbitrageExchangeModuleDTO[]>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/exchanges`),
    method: "GET",
  });

export const addExchangeModule = async (uuid: string, module: ArbitrageExchangeModuleDTO) =>
  responseHandler.handler<string>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/exchange`),
    method: "POST",
    data: module,
  });

export const editExchangeModule = async (
  uuid: string,
  id: string,
  settings: ArbitrageExchangeModuleDTO
) =>
  responseHandler.handler<string>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/exchange/${id}`),
    method: "PUT",
    data: settings,
  });

export const stopExchangeModule = async (uuid: string, id: string) =>
  responseHandler.handler<string>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/exchange/${id}/stop`),
    method: "POST",
  });

export const startExchangeModule = async (uuid: string, id: string) =>
  responseHandler.handler<string>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/exchange/${id}/start`),
    method: "POST",
  });

export const deleteModule = async (uuid: string, id: string) =>
  responseHandler.handler<string>({
    url: apiUrl(ApiModules.CEXTaker, `${uuid}/exchange/${id}`),
    method: "DELETE",
  });
