import { observer } from "mobx-react-lite";
import { useCallback, useContext, useRef } from "react";
import { ThemeContext } from "styled-components";
import Icons from "src/icons/Icons";
import { getBotLink } from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { PermissionWrapper } from "src/components/shared/PermissionWrapper";
import { noOp } from "src/helpers/utils";
import { ChainInfoChip } from "src/components/BotsContent/shared/Chain/ChainInfoChip";
import { BotPairButton } from "../../BotPairButton";
import { BotContainer, IconWrapper, Line, Name } from "../../shared";
import { copyBotCredential } from "../../utils";
import { ModeChip } from "../shared/ModeChip";
import { BotMark } from "../../BotMark";
import { DEXCommonBotProps } from "../types";
import { useBaseModeChipProps } from "../hooks";
import * as styles from "./style";

export const DEXCommonBot = observer(
  ({
    abilities,
    routes,
    botType,
    botTemplateColumnsStyle,

    favorite = false,
    toggleIsWatched,
    isWatched,
    name,
    botUUID,
    pair,
    exchange,
    dexVersion,
    link,
    colorStatus,
    showStartBtn,
    isStopped,
    onStartClick = noOp,
    party,
    chainMeta,

    limitMode,
    volumeMode,
    counterMode,

    showModesInfo = true,
    getInfoContainer,
    credential,
    afterContent,
    ...props
  }: DEXCommonBotProps) => {
    const { botColorStatus: colors, botBgColorStatus: bgColors } = useContext(ThemeContext);

    const containerRef = useRef<HTMLDivElement>(null);

    const showStartButton = showStartBtn;

    const baseModeChipProps = useBaseModeChipProps({
      getInfoContainer,
      showModesInfo,
      botUUID,
      containerRef,
    });

    const toggleWatched = useCallback(() => {
      toggleIsWatched?.(botUUID);
    }, [botUUID, toggleIsWatched]);

    const botLinkParams = { party, uuid: botUUID };

    const { limitLastTrade, limitMessage, limitNextTrade, limitStatus } = limitMode || {};
    const { volumeLastTrade, volumeMessage, volumeNextTrade, volumeStatus } = volumeMode || {};
    const { counterMessage, counterStatus, counterLastTrade } = counterMode || {};

    return (
      <BotContainer {...props} ref={containerRef}>
        {favorite ? (
          <styles.Favorites>
            <IconWrapper onClick={toggleWatched}>
              {isWatched ? Icons.startStatusTrue() : Icons.startStatusFalse()}
            </IconWrapper>
          </styles.Favorites>
        ) : null}

        <styles.DEXFrame color={colors[colorStatus]}>
          <styles.Content
            style={{ backgroundColor: bgColors[colorStatus] }}
            $botColumns={botTemplateColumnsStyle}
          >
            <styles.PairCellContent>
              <BotPairButton
                to={getBotLink({ botType, botTab: routes.Stats, ...botLinkParams })}
                color={colors[colorStatus]}
              >
                {pair?.split("_").join(" _ ")}
              </BotPairButton>

              <Name>{name}</Name>
            </styles.PairCellContent>

            <styles.ExchangeCellContent>
              <styles.DEXCommonExchange href={link || "#"} target="_blank">
                {exchange}
              </styles.DEXCommonExchange>
              <styles.DEXCommonVersion>{dexVersion}</styles.DEXCommonVersion>
            </styles.ExchangeCellContent>

            {chainMeta !== undefined ? (
              <styles.CellContent>
                {chainMeta ? <ChainInfoChip {...chainMeta} /> : <Line />}
              </styles.CellContent>
            ) : null}

            {limitMode && (
              <styles.ModeCellContent>
                <ModeChip
                  mode="limit"
                  nextTrade={limitNextTrade}
                  lastTrade={limitLastTrade}
                  message={limitMessage}
                  status={limitStatus}
                  {...baseModeChipProps}
                />
              </styles.ModeCellContent>
            )}

            {volumeMode && (
              <styles.ModeCellContent>
                <ModeChip
                  mode="volume"
                  nextTrade={volumeNextTrade}
                  lastTrade={volumeLastTrade}
                  message={volumeMessage}
                  status={volumeStatus}
                  {...baseModeChipProps}
                />
              </styles.ModeCellContent>
            )}

            {counterMode && (
              <styles.ModeCellContent>
                <ModeChip
                  mode="counter"
                  message={counterMessage}
                  status={counterStatus}
                  lastTrade={counterLastTrade}
                  {...baseModeChipProps}
                />
              </styles.ModeCellContent>
            )}

            <styles.ButtonCellContent>
              {showStartButton ? (
                <PermissionWrapper abilityName={abilities.BotManage}>
                  <styles.StartButton
                    started={!isStopped}
                    onClick={onStartClick}
                    color={colors[colorStatus]}
                  />
                </PermissionWrapper>
              ) : (
                <BotMark
                  onClick={() => credential && copyBotCredential(credential)}
                  disabled={!credential}
                  color={colors[colorStatus]}
                />
              )}
            </styles.ButtonCellContent>

            {afterContent && <styles.CellContent>{afterContent}</styles.CellContent>}
          </styles.Content>
        </styles.DEXFrame>
      </BotContainer>
    );
  }
);
