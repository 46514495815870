import { getRedirectUrlParam } from "./createRedirectParamUrl";
import { validateRedirectUrl } from "./validateRedirectUrl";

/**
 * Retrieves and validates the redirect URL from the query parameters.
 *
 * @param queryParams - The URLSearchParams object containing the query parameters.
 * @returns The validated redirect URL.
 */
export const getRedirectUrl = (queryParams: URLSearchParams) => {
  const redirectUrl = getRedirectUrlParam(queryParams);

  const validatedRedirectUrl = validateRedirectUrl(redirectUrl);

  return validatedRedirectUrl;
};
