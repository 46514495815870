import { makeAutoObservable, toJS } from "mobx";
import { IDisposable } from "src/helpers/utils";
import {
  addExchangeModule,
  deleteModule,
  editExchangeModule,
  getExchangeModules,
  startExchangeModule,
  stopExchangeModule,
} from "src/api/algoArbitrage";
import { logError } from "src/helpers/network/logger";
import { showSuccessMsg } from "src/helpers/message";
import windowConsent from "src/state/WindowConsent";
import { ArbitrageExchangeModuleDTO } from "src/api/algoArbitrage/types";
import { CreatedArbitrageExchangeModule, CreatedArbitrageExchangeModuleView } from "./type";
import { exchangeModulesDTOMapper } from "./mapper";
import { checkModuleActive, showWarningWhenSwitching } from "./utils";
import { IExchangeModuleCreator } from "../../exchangeModuleModal/types";
import { ActiveArbitrageStore } from "..";
import { AlgoArbitrageModules } from "../constants";
import { ArbitrageExchangeModule } from "../../types";
import { exchangeModuleMapper } from "../../mappers";

export class ExchangeModuleListStore implements IExchangeModuleCreator, IDisposable {
  private _mainState: ActiveArbitrageStore;

  private _modules: CreatedArbitrageExchangeModule[] = [];

  private _isLoading = false;

  private _isEditShow = false;

  private _editableModuleID: string | null = null;

  constructor(state: ActiveArbitrageStore) {
    makeAutoObservable(this);

    this._mainState = state;

    this._mainState.addUpdater(AlgoArbitrageModules.ExchangeModules, this.loadData);
  }

  get accountsState() {
    return this._mainState.accountsState;
  }

  get modulesView(): CreatedArbitrageExchangeModuleView[] {
    const modulesWithNames = this._modules.map((module) => {
      const account = this.accountsState.accountsMap[module.settings.accountUUID];
      return {
        accountName: account.name,
        ...module,
      };
    });

    return toJS(modulesWithNames);
  }

  get isLoading() {
    return this._isLoading;
  }

  get isEditShow() {
    return this._isEditShow;
  }

  get editableModule() {
    if (!this._editableModuleID) return undefined;

    const moduleFound = this._findModule(this._editableModuleID);

    if (moduleFound) return moduleFound.settings;

    return undefined;
  }

  private get _arbitrageUUID() {
    return this._mainState.arbitrageUUID;
  }

  loadData = () => {
    if (!this._mainState.arbitrageUUID) return;
    this._getModules(this._mainState.arbitrageUUID);
  };

  addExchModule = async (module: ArbitrageExchangeModule, closeModal: () => void) => {
    const mappedModule = exchangeModuleMapper(module);

    let isSuccess = false;

    if (this.isEditShow) {
      isSuccess = await this._editModule(mappedModule);
    } else {
      isSuccess = await this._addModule(mappedModule);
    }
    if (isSuccess) closeModal();
  };

  toggleModuleHandler = (uuid: string) => {
    const moduleFound = this._findModule(uuid);

    if (!moduleFound) return;

    const isActiveModule = checkModuleActive(moduleFound);

    if (isActiveModule) {
      showWarningWhenSwitching(moduleFound, "stop", this._stopModule);
    } else {
      showWarningWhenSwitching(moduleFound, "start", this._startModule);
    }
  };

  removeStrategyHandler = (uuid: string) => {
    windowConsent.showWindow(
      "",
      "Are you sure you want to remove module?",
      this._removeModule,
      uuid
    );
  };

  setEditModule = (uuid: string) => {
    this._isEditShow = true;

    this._editableModuleID = uuid;
  };

  onEditModuleClose = () => {
    this._isEditShow = false;

    this._editableModuleID = null;
  };

  private _setLoading = (value: boolean) => {
    this._isLoading = value;
  };

  private _findModule = (uuid: string) => this._modules.find((el) => el.id === uuid);

  private _setModules = (modules: CreatedArbitrageExchangeModule[]) => {
    this._modules = modules;
  };

  private _getModules = async (uuid: string) => {
    this._setLoading(true);

    try {
      const { isError, data } = await getExchangeModules(uuid);

      if (!isError) {
        const modules = exchangeModulesDTOMapper(data);

        this._setModules(modules);
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setLoading(false);
    }
  };

  private _addModule = async (module: ArbitrageExchangeModuleDTO) => {
    if (!this._arbitrageUUID) return false;

    try {
      const { isError } = await addExchangeModule(this._arbitrageUUID, module);

      if (!isError) {
        showSuccessMsg("Module created successfully");
        this.loadData();
      }
      return !isError;
    } catch (error) {
      logError(error);
      return false;
    }
  };

  private _editModule = async (module: ArbitrageExchangeModuleDTO) => {
    if (!this._arbitrageUUID || !this._editableModuleID) return false;

    try {
      const { isError } = await editExchangeModule(
        this._arbitrageUUID,
        this._editableModuleID,
        module
      );

      if (!isError) {
        showSuccessMsg("Module edited successfully");
        this.loadData();
      }

      return !isError;
    } catch (error) {
      logError(error);
      return false;
    }
  };

  private _stopModule = async ({ id }: CreatedArbitrageExchangeModule) => {
    if (!this._arbitrageUUID) return;

    this._setLoading(true);
    try {
      const { isError } = await stopExchangeModule(this._arbitrageUUID, id);

      if (!isError) {
        showSuccessMsg("Module stopped successfully");
        this.loadData();
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setLoading(false);
    }
  };

  private _startModule = async ({ id }: CreatedArbitrageExchangeModule) => {
    if (!this._arbitrageUUID) return;

    this._setLoading(true);

    try {
      const { isError } = await startExchangeModule(this._arbitrageUUID, id);

      if (!isError) {
        showSuccessMsg("Module  started successfully");
        this.loadData();
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setLoading(false);
    }
  };

  private _removeModule = async (id: string) => {
    if (!this._arbitrageUUID) return;

    this._setLoading(true);

    try {
      const { isError } = await deleteModule(this._arbitrageUUID, id);

      if (!isError) {
        showSuccessMsg("Module  was successfully removed");
        this.loadData();
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
