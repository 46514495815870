import { Price, Token } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { Pair } from "src/api/dexScreener";
import { makeLoggable } from "src/helpers/logger";
import { logDev } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { IBotTradePairProvider } from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { IObservableCache } from "src/state/shared/Cache";
import { CacheOptions, WaitOptions } from "src/state/shared/Cache/types";
import { AbstractStableCoin, tryParsePrice } from "src/state/shared/DEX/Swap";
import {
  IScreenerNetworkProvider,
  ISwapPairAddressProvider,
} from "src/state/shared/DEX/providers/types";
import { IScreenerPairProvider, ScreenerPairProvider } from "./ScreenerPairProvider";

export interface PairRawPrice {
  baseUsd: string;
  baseQuote: string;
}

export interface PairPrice {
  quote: { usd: Price<Token, Token> };
  base: { usd: Price<Token, Token>; quote: Price<Token, Token> };
}

export interface GetTradePairPriceOptions extends CacheOptions, WaitOptions {}

export interface ITradePairPriceProvider extends IDisposable {
  get pairPrice(): PairPrice | undefined;
  get pairData(): Pair | undefined;
  get canQuery(): boolean;
  getTradePairPrice: (options?: GetTradePairPriceOptions) => Promise<void>;
}

export interface ITradePairPriceParams {
  networkProvider: IScreenerNetworkProvider;
  tradePairProvider: IBotTradePairProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  pairCacheStore: IObservableCache<Pair>;
}

export class TradePairPriceProvider implements ITradePairPriceProvider {
  private _tradePairProvider: IBotTradePairProvider;

  private _screenerPairProvider: IScreenerPairProvider & IDisposable;

  constructor({
    networkProvider,
    tradePairProvider,
    pairAddressProvider,
    pairCacheStore,
  }: ITradePairPriceParams) {
    makeAutoObservable(this);

    this._tradePairProvider = tradePairProvider;

    this._screenerPairProvider = new ScreenerPairProvider({
      networkProvider,
      pairAddressProvider,
      pairCacheStore,
    });

    makeLoggable(this, { pairPrice: true });
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  get pairData() {
    return this._screenerPairProvider.pair;
  }

  private _getBaseUsdPrice = (pair: Pair) =>
    // priceUsd = base in USD
    pair.priceUsd;

  private _getBaseQuotePrice = (pair: Pair) => {
    // priceNative = base in quote
    const { priceNative: priceBaseQuote, liquidity } = pair;

    if (priceBaseQuote) {
      return priceBaseQuote;
    }
    // try to get base in quote based on liquidity if no priceNative present
    // can't determine base in quote price
    if (!liquidity) {
      return;
    }
    const { base, quote } = liquidity;
    const midPriceBaseQuote = quote / base;
    // using sufficiently large constant to not lose precision on low value tokens
    return midPriceBaseQuote.toFixed(30);
  };

  private get _pairRawPrice() {
    const { pair } = this._screenerPairProvider;
    if (!pair) return null;

    const { priceUsd, priceNative, liquidity, volume } = pair;
    logDev(["_getPairUsdPrice", priceUsd, priceNative, liquidity, volume]);

    const baseUsdPrice = this._getBaseUsdPrice(pair);
    const baseQuotePrice = this._getBaseQuotePrice(pair);
    if (!baseUsdPrice || !baseQuotePrice) return;

    return { baseUsd: baseUsdPrice, baseQuote: baseQuotePrice };
  }

  get pairPrice() {
    const tradePair = this._tradePair;
    const rawPrice = this._pairRawPrice;
    if (!tradePair || !rawPrice) {
      return undefined;
    }

    const { base: baseToken, quote: quoteToken } = tradePair;

    const { baseQuote: baseQuotePrice, baseUsd: baseUsdPrice } = rawPrice;

    const priceStableCoin = new AbstractStableCoin(baseToken.chainId);

    // usd/base price
    const baseTokenUsdPrice = tryParsePrice(baseUsdPrice, priceStableCoin, baseToken);

    // quote/base price
    const baseTokenQuotePrice = tryParsePrice(baseQuotePrice, quoteToken, baseToken);

    if (!baseTokenUsdPrice || !baseTokenQuotePrice) {
      return undefined;
    }

    // quote in usd price = base/quote*usd/base
    const quoteTokenUsdPrice = baseTokenQuotePrice.invert().multiply(baseTokenUsdPrice);

    return {
      quote: { usd: quoteTokenUsdPrice },
      base: { quote: baseTokenQuotePrice, usd: baseTokenUsdPrice },
    };
  }

  get canQuery() {
    return this._screenerPairProvider.canQuery;
  }

  getTradePairPrice = async (options: GetTradePairPriceOptions = {}) => {
    await this._screenerPairProvider.getPair(options);
  };

  destroy = () => {
    this._screenerPairProvider.destroy();
  };
}
