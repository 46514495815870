import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { AllBotsContext } from "src/context/AllBots";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { MainFilter } from "./MainFilter";
import { MainFilterFallback } from "./MainFilter/style";
import { PartiesFilter } from "./PartiesFilter";
import { PartiesFilterFallback } from "./PartiesFilter/style";
import * as styles from "./style";

export const Aside = observer(() => {
  const { isPartyMode } = useLateInitContext(AllBotsContext);

  return (
    <styles.FiltersContainer>
      <ErrorBoundary fallback={<MainFilterFallback />}>
        <MainFilter />
      </ErrorBoundary>

      {!isPartyMode ? (
        <ErrorBoundary fallback={<PartiesFilterFallback />}>
          <PartiesFilter />
        </ErrorBoundary>
      ) : null}
    </styles.FiltersContainer>
  );
});
