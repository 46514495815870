type VariableName = (typeof CSS_VARS)[keyof typeof CSS_VARS];

export const CSS_VARS = {
  SIDE_MENU_SPACE: "--side-menu-space",
} as const;

export const setCssVariable = (variableName: VariableName, value: string) => {
  document.documentElement.style.setProperty(variableName, value);
};

export const getCssVariableValue = (variableName: VariableName): string | null =>
  getComputedStyle(document.documentElement).getPropertyValue(variableName);
