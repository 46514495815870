import { makeAutoObservable } from "mobx";
import {
  OnDateTimeRangeChange,
  OnDateTimeRangeSync,
} from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { DurationWithMonth, getUTCRangeFromDuration } from "src/helpers/dateUtils";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";

const DEFAULT_RANGE: DurationWithMonth = {};

export interface IUseRangePicker {
  loadData: () => void;
}

export type OnRefreshRange = () => boolean;
export interface IRangePickerState {
  get range(): DateTimeRange;
  get start(): number | null;
  get end(): number | null;
  setRange: OnDateTimeRangeChange;
  setStateRange: (period: DateTimeRange) => void;
  refreshRange: OnRefreshRange;
  updDataWithDefaultRange: () => void;
}

export class RangePickerStore implements IRangePickerState, IDisposable {
  private _range!: DateTimeRange;

  private readonly _mainState: IUseRangePicker;

  private _defaultDuration: DurationWithMonth;

  private _syncRange?: OnDateTimeRangeSync;

  constructor(
    state: IUseRangePicker,
    defaultRange: DurationWithMonth = DEFAULT_RANGE,
    defaultSyncRange?: OnDateTimeRangeSync
  ) {
    this._mainState = state;

    this._defaultDuration = defaultRange;

    this._setDefaultRange();
    this._setSyncRange(defaultSyncRange);

    makeAutoObservable(this);

    makeLoggable(this, { range: true });
  }

  get range() {
    return this._range;
  }

  private _setRange = (range: DateTimeRange) => {
    this._range = range;
  };

  private _setSyncRange = (syncRange?: () => DateTimeRange) => {
    this._syncRange = syncRange;
  };

  get start() {
    if (this.range[0]) return this.range[0].unix();

    return null;
  }

  get end() {
    if (this.range[1]) return this.range[1].unix();

    return null;
  }

  private get _defaultRange(): DateTimeRange {
    const range = getUTCRangeFromDuration(this._defaultDuration);

    return range;
  }

  private _setDefaultRange = () => {
    const range = this._defaultRange;
    this._setRange(range);
  };

  setRange: OnDateTimeRangeChange = (period, syncRange) => {
    this._setRange(period);
    this._setSyncRange(syncRange);

    if (this.range[0] && this.range[1]) this._mainState.loadData();
  };

  setStateRange = (period: DateTimeRange) => {
    this._setRange(period);
  };

  /**
   * Refreshes the range of the RangePicker without causing mainState load
   * @returns True if the range was refreshed, false otherwise.
   */
  refreshRange = () => {
    if (this._syncRange) {
      const newRange = this._syncRange();
      this._setRange(newRange);
      return true;
    }
    return false;
  };

  updDataWithDefaultRange = () => {
    this._setDefaultRange();
  };

  destroy = () => {};
}
