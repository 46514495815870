import { ComponentPropsWithoutRef, memo } from "react";
import { separation } from "src/helpers/separation";
import { CopyString } from "../CopyString";
import * as styles from "./style";

interface SeparatorNumProps extends ComponentPropsWithoutRef<"span"> {
  hasCopy?: boolean;
  value: string | number;
}

export const SeparatorNum = memo(({ value, hasCopy, ...props }: SeparatorNumProps) => {
  const SeparatorView = <styles.Wrapper {...props}>{separation(value)}</styles.Wrapper>;

  if (hasCopy) return <CopyString copyValue={value}>{SeparatorView}</CopyString>;

  return SeparatorView;
});
