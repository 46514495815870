import useLocalStore from "src/hooks/useLocalStore";
import { CreateBotStore } from "src/state/CEX/CreateBot";
import { createContextProvider } from "../utils/createContextProvider";

const CreateBotStoreCreator = () => useLocalStore(CreateBotStore);

const { Context: CreateBotContext, Provider: CreateBotProvider } =
  createContextProvider<CreateBotStore>({
    stateCreator: CreateBotStoreCreator,
  });

export { CreateBotContext, CreateBotProvider };
