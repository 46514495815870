import { Button } from "src/components/shared/Buttons/v2/Button";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const SampleRangeButton = styled(Button)`
  align-self: flex-start;
`;

export const SyncRangeButton = styled(Button)`
  align-self: flex-start;
`;
