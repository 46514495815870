import { useCallback, useEffect, useState } from "react";
import useMediaQuery from "src/hooks/useMediaQuery";

const DEFAULT_MOBILE_QUERY = "(max-width: 650px)";

export const useShowContent = (defaultShow = false, mobileQuery = DEFAULT_MOBILE_QUERY) => {
  const isMobile = useMediaQuery(mobileQuery);

  const [showContent, setShowContent] = useState(isMobile ? false : defaultShow);

  const toggleShow = useCallback(() => {
    setShowContent((prevShow) => !prevShow);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setShowContent(false);
    }
  }, [isMobile]);

  return [showContent, toggleShow, setShowContent] as const;
};
