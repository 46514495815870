const HEX_COLOR_REGEX = /^#?([A-Fa-f0-9]{6})$/;

export const isValidHexColor = (str: string) => HEX_COLOR_REGEX.test(str);

/**
 * converts hex color string to rgb(a) string
 * @param hex - hex color string
 * @param alpha - optional alpha value
 * @returns rgb(a) string
 */
export const hexToRgb = (hex: string, alpha?: number) => {
  if (!isValidHexColor(hex)) return hex;

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha !== undefined) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};
