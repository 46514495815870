import { ErrorMsg } from "src/components/shared/shared";
import Icons from "src/icons/Icons";
import * as styles from "./style";

export type LabelError = {
  hint: string;
  id: string;
};

export type LabelErrorIconProps = {
  error: LabelError;
};

export const LabelErrorIcon = ({ error: { hint, id } }: LabelErrorIconProps) => {
  const tooltipId = `tooltip-${id}`;
  return (
    <styles.WrapperForIcon
      data-tooltip-content={hint}
      data-tooltip-id={tooltipId}
      data-iscapture="true"
    >
      <Icons.WarningSign />
      <ErrorMsg id={tooltipId} variant="error" place="right" />
    </styles.WrapperForIcon>
  );
};
