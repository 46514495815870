import { isDefined } from "src/helpers/utils";
import Icons from "src/icons/Icons";
import * as styles from "./style";

interface IndicatorProps {
  statusColor?: string | null;
}

export const Indicator = ({ statusColor }: IndicatorProps) => {
  if (!isDefined(statusColor)) return null;

  return (
    <styles.IndicatePlate color={statusColor}>
      <styles.InfoIconWrapper>
        <Icons.Info />
      </styles.InfoIconWrapper>
    </styles.IndicatePlate>
  );
};
