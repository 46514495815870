import { Redirect, RouteComponentProps } from "react-router-dom";
import { getRedirectUrl } from "src/components/Auth/utils/redirect";
import { MainRoutes } from "../constants";

interface MainRedirectProps extends RouteComponentProps {}

export const MainRedirect = ({ location }: MainRedirectProps) => {
  const query = new URLSearchParams(location.search);
  const paramRedirectUrl = getRedirectUrl(query);

  const redirectUrl = paramRedirectUrl ?? MainRoutes.MainPage;

  return <Redirect to={redirectUrl} />;
};
