import { GetSupportedChainsResponse, SupportedChain } from "src/api/bots/DEXV2/chain";
import { toChainId } from "src/config/chains";
import { trimStartEndSlashes } from "src/helpers/url";
import { Mapper } from "src/helpers/utils";
import { ChainInfo } from "./types";

const supportedChainToChainInfo: Mapper<SupportedChain, ChainInfo> = ({
  chain_id,
  rpc,
  scanner,
  symb,
  dexscreener_alias,
  chain_name,
}) => {
  const scannerBaseUrl = trimStartEndSlashes(scanner);
  return {
    id: toChainId(chain_id),
    rpc,
    txScanner: `${scannerBaseUrl}/tx`,
    addressScanner: `${scannerBaseUrl}/address`,
    dexscreenerName: dexscreener_alias,
    name: chain_name,
    native: symb,
  };
};

export const supportedChainsRespToChainInfos: Mapper<GetSupportedChainsResponse, ChainInfo[]> = (
  resp
) => {
  const { chains } = resp;
  return chains.map(supportedChainToChainInfo);
};
