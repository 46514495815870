import styled from "styled-components";

export const SelectPanel = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;

  overflow-y: auto;
  margin: 5px 0px;

  & > div:not(:last-of-type) {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
`;
