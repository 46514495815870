import React, { ComponentPropsWithoutRef, useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { observer } from "mobx-react-lite";
import { Tooltip } from "react-tooltip";
import { nanoid } from "nanoid";
import { StatusIndicator } from "./StatusIndicator";
import * as styles from "./style";

export enum ConnectStatus {
  noConnection = 0,
  bad = 1,
  normal = 2,
  good = 3,
  great = 4,
}

export type ConnectStatusKeys = keyof typeof ConnectStatus;

interface StatusParams {
  label: string;
  color: string;
}

export type IConnectStatusMap = Record<ConnectStatus, StatusParams>;

export interface StatusConnectProps extends ComponentPropsWithoutRef<"div"> {
  type: "ping" | "data";
  status?: ConnectStatusKeys;
  delay?: string;
}

export const StatusConnect = observer(
  ({ type = "ping", status = "noConnection", delay, ...props }: StatusConnectProps) => {
    const theme = useContext(ThemeContext);

    const statusMap: IConnectStatusMap = useMemo(
      () => ({
        0: {
          label: type === "ping" ? "No connection" : "No data",
          color: "#CDCED1",
        },
        1: {
          label: "Bad",
          color: theme.dangerColor,
        },
        2: { label: "Normal", color: theme.botColorStatus.yellow },
        3: { label: "Good", color: theme.successColor },
        4: { label: "Great", color: theme.successColor },
      }),
      [type, theme]
    );

    const statusParam = statusMap[ConnectStatus[status]];

    const id = useMemo(() => nanoid(4), []);
    const tooltipId = `tooltip-${id}`;

    return (
      <styles.Container
        data-tooltip-content={delay}
        data-tooltip-id={tooltipId}
        data-iscapture="true"
        $color={statusParam.color}
        {...props}
      >
        <styles.StatusTitle>{statusParam.label}</styles.StatusTitle>

        <StatusIndicator statusInd={ConnectStatus[status]} />

        {delay ? <Tooltip id={tooltipId} variant="info" place="left" /> : null}
      </styles.Container>
    );
  }
);
