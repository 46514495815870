import { useCallback, useEffect, useState } from "react";

const useMediaQuery = (query: string): boolean => {
  const getMatches = (query: string): boolean => window.matchMedia(query).matches;

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  const handleChange = useCallback(() => {
    setMatches(getMatches(query));
  }, [query]);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // deprecated addListener/removeListener still supported on Safari 14
    // instead of new addEventListener
    if (matchMedia.addListener) {
      matchMedia.addListener(handleChange);
    } else {
      matchMedia.addEventListener("change", handleChange);
    }

    return () => {
      if (matchMedia.removeListener) {
        matchMedia.removeListener(handleChange);
      } else {
        matchMedia.removeEventListener("change", handleChange);
      }
    };
  }, [handleChange, query]);

  return matches;
};

export default useMediaQuery;
