import styled from "styled-components";

export const StyledSpan = styled.span`
  display: block;
  color: ${({ theme }) => theme.textColor};
  border-radius: ${({ theme }) => theme.radii["2px"]};
  background-color: ${({ theme }) => theme.copyTextBackground.main};
  border: ${({ theme }) => `0.5px solid ${theme.dashboard.borderSecondary}`};
  padding: 0 4px;
  cursor: pointer;

  transition:
    background-color 0.2s ease-out,
    border 0.2s ease-out;

  :hover {
    background-color: ${({ theme }) => theme.copyTextBackground.hover};
    border: ${({ theme }) => `0.5px solid ${theme.dashboard.borderSecondaryHover}`};
  }

  :active {
    background-color: ${({ theme }) => theme.copyTextBackground.active};
  }
`;
