import { makeAutoObservable, toJS } from "mobx";
import { getMarketsInfo } from "src/api/arbitrage";
import { IMarketInfo } from "src/api/arbitrage/types";
import { logError } from "src/helpers/network/logger";
import {
  getPrimitiveSelectorList,
  getSelectorList,
  PrimitiveSelectorValue,
} from "src/helpers/forms/selectors";
import { SelectorProps } from "src/components/shared/Forms/Selectors";
import { SelectPanelProps } from "src/components/shared/Forms/Selectors/SelectionPanel/SelectPanel";
import { joinMarket } from "src/helpers/botName";
import { MarketMap } from "../types";
import { marketInfoListToMap, validationPartyMarketListMap } from "./utils";
import { PartyMarketListMap } from "./types";

export interface IUseMarketList {
  party: string;
}

const ARBITRAGE_STORAGE_KEY = "arbitrageMarkets";

export class MarketsProvider {
  private _marketInfoMap: MarketMap | null = null;

  private _selectMarkets: IMarketInfo[] = [];

  private _mainState: IUseMarketList;

  constructor(state: IUseMarketList) {
    makeAutoObservable(this);

    this._mainState = state;

    window.addEventListener("beforeunload", this._setMarketsToLocalStorage);
  }

  get marketOptions() {
    return getPrimitiveSelectorList(this._marketList);
  }

  get marketInfoMap() {
    return this._marketInfoMap;
  }

  get selectMarkets() {
    return this._selectMarkets;
  }

  private get _party() {
    return this._mainState.party;
  }

  private get _marketList(): string[] {
    if (!this._marketInfoMap) return [];

    return Array.from(this._marketInfoMap.keys());
  }

  private get _selectMarketsOptions() {
    return getSelectorList(this._selectMarkets.map(({ market }) => joinMarket(market)));
  }

  loadData = async () => {
    await this._getPartyMarkets();
  };

  selectorProps = (): Pick<
    SelectorProps<PrimitiveSelectorValue<string>, true, any>,
    "options" | "value" | "onChange"
  > => ({
    options: this.marketOptions,
    value: this._selectMarketsOptions,
    onChange: this._onMarketsSelected,
  });

  selectProps = (): Pick<SelectPanelProps, "selectItems" | "removeClick"> => ({
    selectItems: this._selectMarketsOptions,
    removeClick: this._removeSelectedExchange,
  });

  getMarketsFromLocalStorage = () => {
    const partyMap = this._getPartyMapFromLocalStorage();

    if (partyMap) {
      const marketList = partyMap[this._party];

      if (marketList) this._setMarkets(marketList);
    }
  };

  private _setMarkets = (data: IMarketInfo[]) => {
    this._selectMarkets = data;
  };

  private _setMarketInfoMap = (markets: IMarketInfo[]) => {
    this._marketInfoMap = marketInfoListToMap(markets);
  };

  private _onMarketsSelected = (values: readonly PrimitiveSelectorValue<string>[]) => {
    if (!this.marketInfoMap) return;

    const marketList = values.map(({ value }) => String(value));

    const newMarkets: IMarketInfo[] = [];

    marketList.forEach((el) => {
      const market = (this.marketInfoMap as MarketMap).get(el);

      if (market) newMarkets.push(market);
    });

    this._selectMarkets = newMarkets;
  };

  private _removeSelectedExchange = (value: string) => {
    const newMarkets = this._selectMarkets.filter(({ market }) => joinMarket(market) !== value);
    this._selectMarkets = newMarkets;
  };

  private _getPartyMapFromLocalStorage = () => {
    const storedItems = localStorage.getItem(ARBITRAGE_STORAGE_KEY);

    if (storedItems) {
      const partyMap = JSON.parse(storedItems) as PartyMarketListMap;
      const isValid = validationPartyMarketListMap(partyMap);

      if (isValid) return partyMap;
    }

    return {};
  };

  private _setMarketsToLocalStorage = () => {
    const partyMap = this._getPartyMapFromLocalStorage();

    partyMap[this._party] = toJS(this._selectMarkets);

    const serializedMap = JSON.stringify(partyMap);

    localStorage.setItem(ARBITRAGE_STORAGE_KEY, serializedMap);
  };

  private _getPartyMarkets = async () => {
    try {
      const { isError, data } = await getMarketsInfo(this._party);

      if (!isError) {
        this._setMarketInfoMap(data);
      }
    } catch (error) {
      logError(error);
    }
  };
}
