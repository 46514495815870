import { RouteProps, matchPath } from "react-router-dom";
import { AuthRoutes, MainRoutes } from "src/app/constants";
import { Nullish } from "src/helpers/utils";

const RESTRICTED_REDIRECTS: RouteProps[] = [
  { path: MainRoutes.Root, exact: true },
  { path: AuthRoutes.LoginPage },
  { path: AuthRoutes.ConfirmPage },
];

const urlRoutesMatch = (url: string, routes: string[] | RouteProps[]) => {
  const isMatch = routes.some((route) => matchPath(url, route));
  return isMatch;
};

/**
 * Validates a redirect URL by checking if it matches any restricted routes.
 * @param redirectUrl - The redirect URL to validate.
 * @returns The valid redirect URL if it doesn't match any restricted routes, otherwise null.
 */
export const validateRedirectUrl = (redirectUrl: Nullish<string>) => {
  if (!redirectUrl) {
    return null;
  }

  const isValidRedirect = !urlRoutesMatch(redirectUrl, RESTRICTED_REDIRECTS);

  return isValidRedirect ? redirectUrl : null;
};
