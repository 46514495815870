import { Button } from "src/components/shared/Buttons/v2/Button";
import styled from "styled-components";
import { BotButtonMixin } from "../BotLinkButton/style";

export const StyledMark = styled(Button)`
  ${BotButtonMixin}

  color: currentColor;

  padding-left: 0;
  padding-right: 0;
  flex-shrink: 0;

  :active {
    color: #fff;
  }

  cursor: pointer;
`;
