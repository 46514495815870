import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const Wrapper = styled.div`
  width: 15px;
  height: 15px;
`;
