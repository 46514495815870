import { createContextProvider } from "src/context/utils/createContextProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { DEXNECreateStore } from "src/state/DEX_NE/Create";
import { useDEXNEChainsInfo } from "../Info";

const useCreateStore = () => {
  const chainsInfoState = useDEXNEChainsInfo();
  const store = useLocalStore(DEXNECreateStore, { chainsInfoProvider: chainsInfoState });
  return store;
};

export const DEXNECreateContext = createContextProvider({
  stateCreator: useCreateStore,
});

export const useDEXNECreateState = () => {
  const state = useLateInitContext(DEXNECreateContext.Context);
  return state;
};
