import {
  getExponentValue,
  roundToExp,
  roundToNDecimals,
  roundToSignificant,
} from "src/helpers/rounding";
import { numFormatter } from "src/helpers/separation";
import { formatWithSign } from "src/helpers/string";
import { Nullish, isDefined } from "src/helpers/utils";

/**
 * Rounds the PNL value to a specified number of decimals.
 * If the absolute value of the PNL is greater than or equal to 1, it is rounded to 2 decimals.
 * Otherwise, it is rounded to 3 significant figures.
 * @param value - The PNL value to be rounded.
 * @returns The rounded PNL value.
 */
const roundPNLValue = (value: string | number) => {
  const numValue = +value;

  if (Math.abs(numValue) >= 1) {
    const roundedValue = roundToNDecimals(numValue, 2);
    return roundedValue;
  }

  const roundedValue = +roundToSignificant(numValue, 3);
  return roundedValue;
};

/**
 * Rounds the PNL value to exponential notation if the exponent is greater than 3.
 * Applies extra significant rounding with 3 digits to preserve the format x.xxe+yy.
 * @param value - The PNL value to be rounded.
 * @returns The rounded PNL value in exponential notation or as a string.
 */
const roundPNLExpValue = (value: number) => {
  if (value === 0) return "0";

  const exponent = getExponentValue(value);

  if (Math.abs(exponent) > 3) {
    const roundedValue = +roundToSignificant(value, 3);
    return roundToExp(roundedValue);
  }

  return value.toString();
};

const formatPNLSymbol = (value: string, showSymbol: boolean) => (showSymbol ? `${value} $` : value);

const formatPNLSign = (roundedValue: number, formattedValue: string, showSign: boolean) =>
  showSign ? formatWithSign(roundedValue, formattedValue) : formattedValue;

/**
 * Formats the PNL delta value.
 * If the value is greater than 10,000, it uses the `numFormatter` logic.
 * If the value is less than 1, it uses the `roundPNLExpValue` logic.
 * Otherwise, it returns the value as a string.
 *
 * @param value - The PNL delta value to be formatted.
 * @returns The formatted PNL delta value.
 */
const formatPNLDeltaValue = (value: number) => {
  if (Math.abs(value) > 10_000) {
    const formattedValue = numFormatter(value);
    return formattedValue;
  }
  if (Math.abs(value) < 1) {
    const formattedValue = roundPNLExpValue(value);
    return formattedValue;
  }

  return value.toString();
};

export const formatPNLDelta = (
  value: Nullish<number | string>,
  showSymbol = false,
  showSign = true
) => {
  if (!isDefined(value)) {
    return "-";
  }

  const roundedValue = roundPNLValue(+value);

  const formattedValue = formatPNLDeltaValue(roundedValue);

  const valueWithSign = formatPNLSign(roundedValue, formattedValue, showSign);

  return formatPNLSymbol(valueWithSign, showSymbol);
};

/**
 * Formats the PNL price value using roundPNLExpValue logic.
 *
 * @param value - The value to be formatted.
 * @returns The formatted value.
 */
const formatPNLPriceValue = (value: number) => {
  const expValue = roundPNLExpValue(value);
  return expValue;
};

export const formatPNLPrice = (
  value: Nullish<number | string>,
  showSymbol = false,
  showSign = false
) => {
  if (!isDefined(value)) {
    return "-";
  }

  const roundedValue = roundPNLValue(value);

  const formattedValue = formatPNLPriceValue(roundedValue);

  const valueWithSign = formatPNLSign(roundedValue, formattedValue, showSign);

  return formatPNLSymbol(valueWithSign, showSymbol);
};
