import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from "..";

export const addToWatchList = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.WatchList, `/${bot_uuid}`),
    method: "POST",
  });

export const removeFromWatchList = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.WatchList, `/${bot_uuid}`),
    method: "DELETE",
  });

export const addDEXWatchList = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `/${bot_uuid}/watchlist`),
    method: "POST",
  });

export const removeDEXWatchList = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `/${bot_uuid}/watchlist`),
    method: "DELETE",
  });
