import { makeAutoObservable } from "mobx";
import { LabelError } from "src/components/shared/Forms/Label/LabelErrorIcon";
import { FormValidation } from "src/helpers/forms/types";
import { required, validateData } from "src/validation-schemas";
import CreateStrategyStore from "./CreateStrategyStore";

const ERRORS_MSGS = {
  allKeys: "The logical expression must contain the keys of all selected condition modules",
  validBrackets: "Brackets are placed incorrectly",
  spacing: "All expression members must be separated by spaces (including parentheses)",
  grammar: "Logical expression contains invalid characters",
};

const validateExpressionKeys = (conditionKeys: string[]) => (expressionStr: string) => {
  const regexXKeys = /x\d+/g;

  const keys = expressionStr.match(regexXKeys);

  if (keys) {
    for (const key of keys) {
      const isFindKey = conditionKeys.includes(key);

      if (!isFindKey) return `Invalid condition key ${key}`;
    }
  }

  const baseKeysSet = new Set(conditionKeys);
  const expressionKeysSet = new Set(keys);

  for (const value of baseKeysSet) {
    if (!expressionKeysSet.has(value)) {
      return ERRORS_MSGS.allKeys;
    }
  }

  return "";
};

const validateBrackets = () => (expression: string) => {
  const brackets = "()";
  const stack = [];

  for (const bracket of expression) {
    const bracketsIndex = brackets.indexOf(bracket);

    if (bracketsIndex === -1) {
      continue;
    }

    if (bracketsIndex % 2 === 0) {
      stack.push(bracketsIndex + 1);
    } else if (stack.pop() !== bracketsIndex) {
      return ERRORS_MSGS.validBrackets;
    }
  }

  if (stack.length !== 0) return ERRORS_MSGS.validBrackets;

  return "";
};

const validateSpacing = () => (v: string) => {
  const tokens = v.split(" ");
  const regexSpacingBetween = /\(|\)|\bor\b|\band\b|\d+|x\d+/g;

  for (const token of tokens) {
    const match = token.match(regexSpacingBetween);

    if (!match || match.length > 1) return ERRORS_MSGS.spacing;
  }

  return "";
};

const validateExpressionGrammar = () => (v: string) => {
  const regexGrammar = /^(\(|\)|or|and|x\d*|\s)*$/;
  const valid = regexGrammar.test(v);

  if (!valid) return ERRORS_MSGS.grammar;

  return "";
};

export class ExpressionAreaStore {
  private _mainState: CreateStrategyStore;

  private _expression = "";

  private _validationScheme: FormValidation<{ expression: string }> = {
    expression: [required()],
  };

  private _errors: any = {};

  constructor(state: CreateStrategyStore) {
    this._mainState = state;

    makeAutoObservable(this);
  }

  get expressionError(): LabelError | undefined {
    if (this._errors?.expression) return { hint: this._errors?.expression, id: "" };

    return undefined;
  }

  get expression() {
    return this._expression;
  }

  private get conditionKeys() {
    return this._mainState.conditionKeys;
  }

  private _setExpression = (value: string) => {
    this._expression = value.toLowerCase();
  };

  autogenerateExpression = (conditionKey: string) => {
    const conditionCount = this.conditionKeys.length;

    if (conditionCount !== 1) {
      this._expression += " and ";
    }

    this._expression += conditionKey;
  };

  expressionHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this._setExpression(e.target.value);
  };

  validation = () => {
    this._validationScheme.expression = [
      required(),
      validateExpressionKeys(this.conditionKeys),
      validateBrackets(),
      validateSpacing(),
      validateExpressionGrammar(),
    ];

    return validateData(
      this._validationScheme,
      { expression: this.expression },
      this._errors,
      undefined
    );
  };
}
