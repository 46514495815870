import { toast } from "src/components/shared/Toaster";

/**
 * success message trigger
 * @param message - message to display on the client
 * @param duration - message display time
 */
export const showSuccessMsg = (message: string, duration: number | undefined = 2000) => {
  toast.success(message, { duration });
};

/**
 * error message trigger
 * @param message - message to display on the client
 * @param duration - message display time
 */
export const showErrorMsg = (message: string, duration?: number) => {
  toast.error(message, { duration });
};
