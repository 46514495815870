import { useEffect } from "react";
import { useAppState } from "src/state";

export const useConnectPartyAbilities = (party: string) => {
  const { abilitiesState } = useAppState();
  const { setParty, resetParty } = abilitiesState;

  useEffect(() => {
    setParty(party);

    return () => {
      resetParty();
    };
  }, [setParty, resetParty, party]);
};
