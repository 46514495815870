import { DEXCommonBotsColorStatus } from "src/components/AllBots/Bots/shared/DEXCommon";
import { DEXCommonBotMode, DEXCommonExchangeVersion } from "src/modules/bots";
import { IChainMeta } from "src/modules/chain";
import { ChainInfo } from "../Info/Chains/types";
import { DEXNEExchangeInfosMap } from "../Info/Exchanges/types";

export type DEXNEBotMode = Exclude<DEXCommonBotMode, "counter">;

// type DEXNEModeInfoDTO<T extends DEXNEBotMode> = {
//   [k in `${T}_descr`]: DEXCommonBotModeStatus;
// } & {
//   [k in `${T}_bot_last_trade_status`]: string;
// } & {
//   [k in `${T}_bot_next_trade_date_unix`]: number;
// } & {
//   [k in `${T}_bot_last_trade_date_unix`]: number;
// };

// export type DEXNELimitInfoDTO = DEXNEModeInfoDTO<"limit">;

// export type DEXNEVolumeInfoDTO = DEXNEModeInfoDTO<"volume">;

export const enum DEXNEBotStatuses {
  Stopped = "stopped",
  Stale = "paused",
  Running = "active",
  Error = "alert",
  Warning = "warning",
}

export const enum DEXNEBotModeStatuses {
  Stopped = "stopped",
  Running = "active",
  Error = "alert",
}

export interface DEXNEModeInfoDTO {
  last_trade: number;
  message: string;
  next_trade: number;
  status: DEXNEBotModeStatuses;
}

export interface IDEXNEBotDTO {
  base_ticker: string;
  base_address: string;
  quote_ticker: string;
  quote_address: string;
  deployed: boolean;
  dex_name: string;
  dex_link: string;
  pool_address: string;
  party: string;
  bot_id: string;
  bot_name: string;
  chain_name: string;
  status: DEXNEBotStatuses;
  dex_version: DEXCommonExchangeVersion;
  limit_strategy_status: DEXNEModeInfoDTO;
  volume_strategy_status: DEXNEModeInfoDTO;
  counter_strategy_status: DEXNEModeInfoDTO;
}

export type IDEXNEModeInfo = {
  status: DEXNEBotModeStatuses;
  message: string;
  nextTrade: number;
  lastTrade: number;
};

export interface IDEXNEBot {
  base: string;
  baseAddr: string;
  quote: string;
  quoteAddr: string;
  pairAddr: string;
  exchange: string;
  link: string;
  party: string;
  botUUID: string;
  name: string;
  chainName: string;
  isStopped: boolean;
  status: DEXNEBotStatuses;
  dexVersion: DEXCommonExchangeVersion;

  limit: IDEXNEModeInfo;
  volume: IDEXNEModeInfo;
  counter: IDEXNEModeInfo;
}

export interface IListDEXNEBot extends IDEXNEBot {
  colorStatus: DEXCommonBotsColorStatus;
  pair: string;
  chainMeta?: IChainMeta | null;
  isWatched: boolean;
}

export interface IDEXNEChainInfoProvider {
  get chainInfo(): ChainInfo | null;
  get chainMeta(): IChainMeta | null;
  get chainExchanges(): DEXNEExchangeInfosMap | null;
}
