import { makeAutoObservable } from "mobx";
import { deleteElem } from "src/helpers/array";
import { getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
  Validator,
} from "src/helpers/forms/types";
import { validateData } from "src/validation-schemas";

export type InputType = string | number;
type InputValue<T extends InputType> = T | "";

interface ArrayInputForm<T extends InputType> {
  value: InputValue<T>;
}

type ArrayInputFormKeys = FormDataKeys<ArrayInputForm<"">>;

export interface IArrayInputFormProvider<T extends InputType> {
  get formArray(): T[];
  setFormArray: (arr: T[]) => void;
}

const arrayInputValidatorSymbol: unique symbol = Symbol("arrayInputValidator");

export type _ArrayInputFormValidator<T extends InputType> = (value: T, array: T[]) => string;

type ArrayInputFormValidator<T extends InputType> = _ArrayInputFormValidator<T> & {
  [arrayInputValidatorSymbol]: true;
};

export const createArrayInputValidator = <T extends InputType>(
  validator: _ArrayInputFormValidator<T>
): ArrayInputFormValidator<T> =>
  Object.assign(validator, {
    [arrayInputValidatorSymbol]: true as const,
  });

const isArrayInputFormValidator = <T extends InputType>(
  validator: Validator | ArrayInputFormValidator<T>
): validator is ArrayInputFormValidator<T> =>
  (validator as ArrayInputFormValidator<T>)[arrayInputValidatorSymbol] === true;

export type ArrayInputFormValidators<T extends InputType> = (
  | Validator
  | ArrayInputFormValidator<T>
)[];

export interface IArrayInputState<T extends InputType> {
  get inputErrors(): FormErrors<ArrayInputForm<T>>["value"];

  get inputValue(): InputValue<T>;

  getInputValueHandler: FormFieldHandler;

  addToArray: () => boolean;

  deleteFromArray: (value: T) => boolean;
}

export default class ArrayInputStore<T extends InputType> implements IArrayInputState<T> {
  private _form: ArrayInputForm<T>;

  private _formHandlers: FormHandlers<ArrayInputForm<T>> = {};

  private _formErrors: FormErrors<ArrayInputForm<T>> = {};

  private _formValidation: FormValidation<ArrayInputForm<T>>;

  private _arrayProvider: IArrayInputFormProvider<T>;

  private _defaultValue: T | "";

  constructor(
    arrayProvider: IArrayInputFormProvider<T>,
    validation: ArrayInputFormValidators<T> = [],
    defaultValue?: T
  ) {
    makeAutoObservable(this);

    this._arrayProvider = arrayProvider;

    this._defaultValue = defaultValue ?? "";

    this._form = {
      value: this._defaultValue,
    };

    this._formValidation = {
      value: this._transformValidators(validation),
    };
  }

  private _transformValidators = (validation: ArrayInputFormValidators<T>) => {
    const validators = validation.map((validator) => {
      if (isArrayInputFormValidator(validator)) {
        return ((value: T) => {
          const array = this._arrayProvider.formArray;
          return validator(value, array);
        }) as Validator;
      }
      return validator;
    });
    return validators;
  };

  private _resetInputValue = () => {
    this._form.value = this._defaultValue;
  };

  private _getFormHandler = (key: ArrayInputFormKeys): FormFieldHandler => {
    if (!this._formHandlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this._form, path);

      this._formHandlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (key) {
          default: {
            targetData[endKey] = getChangeEventValue(e);
          }
        }
      };
    }
    return this._formHandlers[key]!;
  };

  private _validateForm = () =>
    validateData(this._formValidation, this._form, this._formErrors, undefined);

  get inputErrors() {
    return this._formErrors.value;
  }

  get inputValue() {
    return this._form.value;
  }

  getInputValueHandler: FormFieldHandler = (e) => this._getFormHandler("value")(e);

  addToArray = () => {
    const isValid = this._validateForm();
    if (isValid) {
      const array = this._arrayProvider.formArray;
      this._arrayProvider.setFormArray([...array, this._form.value as T]);

      this._resetInputValue();
    }
    return isValid;
  };

  deleteFromArray = (value: T) => {
    const array = this._arrayProvider.formArray;
    const copy = array.slice();
    deleteElem(copy as string[] | number[], value);
    this._arrayProvider.setFormArray(copy);
    return true;
  };
}
