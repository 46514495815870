import { makeAutoObservable } from "mobx";
import { getSupportedExchanges } from "src/api/bots/DEXV2/chain";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { supportedExchangesRespToChainsExchanges } from "./mappers";
import { ChainExchangesMap } from "./types";

export interface IChainExchangesProvider {
  get chainExchanges(): ChainExchangesMap;
  getChainExchanges: () => Promise<void>;
}

export class ChainExchangesStore implements IChainExchangesProvider, IDisposable {
  private _chainExchanges: ChainExchangesMap | null = null;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { chainExchanges: true });
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get chainExchanges(): ChainExchangesMap {
    return this._chainExchanges ?? {};
  }

  private _setChainExchanges = (exchanges: ChainExchangesMap) => {
    this._chainExchanges = exchanges;
  };

  getChainExchanges = async () => {
    await this._getExchanges();
  };

  private _getExchanges = async () => {
    if (this._loading || this._chainExchanges) return;

    this._setLoading(true);

    try {
      const { isError, data } = await getSupportedExchanges();
      if (!isError) {
        const chainExchanges = supportedExchangesRespToChainsExchanges(data);
        this._setChainExchanges(chainExchanges);
      }
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
