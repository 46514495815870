import styled from "styled-components";

export const Label = styled.label`
  white-space: nowrap;

  color: ${({ theme }) => theme.textColor};

  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;

export const Value = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  word-break: break-word;
`;

export const Row = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
