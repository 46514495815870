export enum FundingMenuValue {
  AGGREGATED = "AGGREGATED",
  TRANSFERS = "TRANSFERS",
  MANUAL = "MANUAL",
  AUTO = "AUTO",
}

export type FundingType = "withdraw" | "deposit";

export interface IFundingCommon {
  id: number;
  account_uuid: string;
  account_name: string;
  amount: string;
  comment?: string;
  skip: boolean;
  currency: string;
  exchange: string;
  party: string;
  time: number;
  type: FundingType;
}

export interface AggregatedFunding
  extends Pick<
    IFundingCommon,
    "account_uuid" | "account_name" | "amount" | "currency" | "exchange" | "party" | "time" | "type"
  > {}

export interface TransfersFunding
  extends Pick<
    IFundingCommon,
    | "id"
    | "account_uuid"
    | "account_name"
    | "amount"
    | "comment"
    | "skip"
    | "currency"
    | "exchange"
    | "party"
    | "time"
    | "type"
  > {}

export interface ManualFunding
  extends Pick<
    IFundingCommon,
    | "id"
    | "account_uuid"
    | "account_name"
    | "amount"
    | "comment"
    | "skip"
    | "currency"
    | "exchange"
    | "party"
    | "time"
    | "type"
  > {}

export interface AutoFunding
  extends Pick<
    IFundingCommon,
    | "id"
    | "account_uuid"
    | "account_name"
    | "amount"
    | "comment"
    | "skip"
    | "currency"
    | "exchange"
    | "party"
    | "time"
    | "type"
  > {}

export interface NewManualFunding
  extends Pick<
    IFundingCommon,
    "account_uuid" | "comment" | "currency" | "exchange" | "type" | "party"
  > {
  amount: number | "";
  time: number | "";
}

export interface NewFormManualFunding extends Omit<NewManualFunding, "party"> {}

export interface UpdateFunding extends Partial<Pick<IFundingCommon, "skip" | "comment">> {}

export interface PartyFundingRange {
  party: string;
  from: number;
  to: number;
}
