import styled from "styled-components";

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LoginWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const Login = styled.h3`
  width: 150px;

  margin: 0px;

  color: ${({ theme }) => theme.textColor};
  text-align: end;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights[600]};
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  > * {
    width: auto;
  }
`;
