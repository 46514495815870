import { Link } from "react-router-dom";
import styled from "styled-components";
import { LogoIcon } from "./LogoIcon";

interface LogoProps {
  isMobile: boolean;
}

export const Logo = styled(LogoIcon)<LogoProps>`
  height: 40px;
  width: ${({ isMobile }) => (isMobile ? "47px" : "67px")};

  cursor: pointer;
  box-sizing: border-box;
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;
