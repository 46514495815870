import { Route, Switch } from "react-router-dom";
import { NavigationMenuProvider } from "src/context/NavigationMenu";
import { BOT_NAV_PARAMS } from "./BotsNavigation";
import { BotNavigationList } from "./BotsNavigation/BotNavigationList";
import { MAIN_MENU_ITEMS, useGetMainCurrentTab, useGetMainPathParams } from "./MainPagesNavigation";
import { MenuItem } from "./shared/MenuList/MenuItem";
import { MenuList } from "./shared/MenuList/style";
import { PARTY_SECTION_PATH } from "./shared/MenuList/utils";
import { PartyMenuList } from "./PartyNavigation/PartyMenuList";

export const MenuContent = () => (
  <NavigationMenuProvider>
    <Switch>
      {BOT_NAV_PARAMS.map((el) => (
        <Route key={el.path} path={el.path} exact>
          <BotNavigationList key={el.path} botType={el.botType} />
        </Route>
      ))}

      <Route path={PARTY_SECTION_PATH} exact>
        <PartyMenuList />
      </Route>

      <Route path="/:section">
        <MenuList>
          {MAIN_MENU_ITEMS.map(({ value, ...other }) => (
            <MenuItem<"main">
              key={value}
              useGetCurrentTab={useGetMainCurrentTab}
              useGetPathParams={useGetMainPathParams}
              item={{ value, ...other }}
            />
          ))}
        </MenuList>
      </Route>
    </Switch>
  </NavigationMenuProvider>
);
