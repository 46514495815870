import React from "react";
import * as styles from "./style";

export interface StatusIndicatorProps {
  statusInd: number;
}

export const StatusIndicator = ({ statusInd }: StatusIndicatorProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
    <styles.SVGGroup connectStatus={statusInd} clipPath="url(#clip0_4618_47414)">
      <path d="M1.70165 8H0.698351C0.513182 8.00013 0.335638 8.06612 0.204704 8.18349C0.073769 8.30085 0.000146111 8.46 0 8.62598V11.374C0.000146111 11.54 0.073769 11.6991 0.204704 11.8165C0.335638 11.9339 0.513182 11.9999 0.698351 12H1.70165C1.8867 11.9995 2.06401 11.9334 2.19486 11.8161C2.32571 11.6988 2.39946 11.5399 2.4 11.374V8.62598C2.39946 8.46011 2.32571 8.30117 2.19486 8.18388C2.06401 8.0666 1.8867 8.00049 1.70165 8Z" />
      <path d="M4.90184 5.6001H3.89855C3.7135 5.60058 3.53618 5.66581 3.40533 5.78156C3.27448 5.89731 3.20074 6.05416 3.2002 6.21786V11.3823C3.20074 11.546 3.27449 11.7029 3.40534 11.8186C3.53619 11.9344 3.7135 11.9996 3.89855 12.0001H4.90184C5.08701 12 5.26455 11.9348 5.39549 11.819C5.52642 11.7032 5.60005 11.5461 5.6002 11.3823V6.21786C5.60005 6.05406 5.52643 5.897 5.39549 5.78118C5.26456 5.66535 5.08701 5.60023 4.90184 5.6001Z" />
      <path d="M8.10155 3.2002H7.09825C7.00651 3.20009 6.91565 3.21551 6.83086 3.24557C6.74608 3.27563 6.66904 3.31974 6.60417 3.37538C6.5393 3.43101 6.48786 3.49708 6.45281 3.56979C6.41776 3.6425 6.39978 3.72043 6.3999 3.79911V11.4013C6.40005 11.5601 6.47368 11.7123 6.60461 11.8246C6.73554 11.9369 6.91309 12.0001 7.09825 12.0002H8.10155C8.2866 11.9997 8.46391 11.9365 8.59476 11.8243C8.72561 11.712 8.79936 11.56 8.7999 11.4013V3.79911C8.79976 3.6403 8.72613 3.48804 8.5952 3.37575C8.46426 3.26346 8.28672 3.20032 8.10155 3.2002Z" />
      <path d="M11.3017 0H10.2984C10.1134 0.000499175 9.93609 0.0683888 9.80524 0.18884C9.67439 0.309291 9.60064 0.472514 9.6001 0.642857V11.3571C9.60064 11.5275 9.67439 11.6907 9.80524 11.8112C9.93609 11.9316 10.1134 11.9995 10.2984 12H11.3017C11.4869 11.9999 11.6645 11.9321 11.7954 11.8116C11.9263 11.691 12 11.5276 12.0001 11.3571V0.642857C12 0.472402 11.9263 0.308965 11.7954 0.188435C11.6645 0.0679048 11.4869 0.0001328 11.3017 0Z" />
    </styles.SVGGroup>
  </svg>
);
