import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { CEXRoutes } from "src/app/constants";
import { getBotLink } from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { Loader } from "src/components/shared/Loader";
import { AllBotsContext } from "src/context/AllBots";
import { useFindWidth } from "src/hooks/useFindWidth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useBotsVirtualizer } from "../../hooks/useBotsVirtualizer";
import { Header } from "../Header";
import { Container, Content, ContentScrollWrapper } from "../shared/shared";
import { CEXBot } from "./CEXBot";
import { CEXBotMob } from "./CEXBotMob";

const HEADER = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Downtime", icon: "" },
  { title: "Side", icon: "" },
  { title: "Message", icon: "" },
  { title: "Comment", icon: "" },
];

export const CEXBots = observer(() => {
  const { realtimeEnabled, cexBotsState: state } = useLateInitContext(AllBotsContext);

  const showMobVers = useFindWidth(1050);

  const {
    scrollParentRef,
    virtualizer: botsVirtualizer,
    getVirtualRowStyles,
  } = useBotsVirtualizer({
    heights: { mobile: 85, desktop: 88 },
    isMobile: showMobVers,
    size: state.bots.length,
  });

  useEffect(() => {
    if (realtimeEnabled) {
      state.resumeBotsFetching();
    } else {
      state.suspendBotsFetching();
    }

    return () => {
      if (realtimeEnabled) {
        state.suspendBotsFetching();
      }
    };
  }, [state, realtimeEnabled, state.resumeBotsFetching, state.suspendBotsFetching]);

  const bots = botsVirtualizer.virtualItems.map((virtualRow) => {
    const bot = state.bots[virtualRow.index];

    return showMobVers ? (
      <CEXBotMob
        favorite
        toggleIsWatched={state.toggleIsWatched}
        key={bot.bot_uuid}
        {...bot}
        {...getVirtualRowStyles(virtualRow)}
      />
    ) : (
      <CEXBot
        favorite
        toggleIsWatched={state.toggleIsWatched}
        key={bot.bot_uuid}
        {...bot}
        {...getVirtualRowStyles(virtualRow)}
      />
    );
  });

  return (
    <Container>
      {!showMobVers ? (
        <Header
          favorite
          toggleWatchList={state.toggleWatchList}
          watchListEnabled={state.watchListEnabled}
          cells={HEADER}
        />
      ) : null}
      <Content ref={scrollParentRef}>
        <ContentScrollWrapper $height={botsVirtualizer.totalSize}>{bots}</ContentScrollWrapper>
      </Content>
      <Loader show={state.showLoader && state.firstLoad} />
    </Container>
  );
});
