import styled from "styled-components";
import { BaseModeInfoItem } from "../BaseModeInfoItem";
import { Value } from "../BaseModeInfoItem/style";

export const StyledModeInfoItem = styled(BaseModeInfoItem)`
  ${Value} {
    i {
      font-style: italic;
    }
    strong {
      font-weight: ${({ theme }) => theme.fontWeights[500]};
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;
