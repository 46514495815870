import { CreatedArbitrageExchangeModuleDTO } from "src/api/algoArbitrage/types";
import { Mapper } from "src/helpers/utils";
import { CreatedArbitrageExchangeModule } from "./type";

export const exchangeModuleDTOMapper: Mapper<
  CreatedArbitrageExchangeModuleDTO,
  CreatedArbitrageExchangeModule
> = ({
  settings: {
    account_uuid,
    amount_decimals,
    price_decimals,
    taker_fees,
    min_exchange_amount,
    ...restSettings
  },
  ...rest
}) => ({
  settings: {
    accountUUID: account_uuid,
    amountDecimals: amount_decimals,
    priceDecimals: price_decimals,
    takerFees: +taker_fees,
    minExchangeAmount: +min_exchange_amount,
    ...restSettings,
  },
  ...rest,
});

export const exchangeModulesDTOMapper: Mapper<
  CreatedArbitrageExchangeModuleDTO[],
  CreatedArbitrageExchangeModule[]
> = (modules) => modules.map(exchangeModuleDTOMapper);
