import { extend } from "dayjs";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";
import timezonePlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";

extend(duration);
extend(relativeTime);
extend(utcPlugin);
extend(timezonePlugin);
extend(isBetween);
extend(isSameOrBefore);
extend(isSameOrAfter);

export type DayjsDuration = duration.Duration;
