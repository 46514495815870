import { IDisposable, Nullable } from "src/helpers/utils";
import { makeAutoObservable } from "mobx";
import { ArbitrageStatus } from "src/api/algoArbitrage/types";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { getArbitrageStatus, startArbitrage, stopArbitrage } from "src/api/algoArbitrage";
import { UpdaterStore } from "src/state/shared/updater/UpdaterStore";
import { AlgoArbitrageStore } from "..";
import { AlgoArbitrageModules } from "./constants";

export class ActiveArbitrageStore implements IDisposable {
  private _mainState: AlgoArbitrageStore;

  private _updaterState = new UpdaterStore<AlgoArbitrageModules>();

  private _isStatusLoading = false;

  private _arbitrageStatus: Nullable<ArbitrageStatus> = null;

  constructor(state: AlgoArbitrageStore) {
    makeAutoObservable(this);

    this._mainState = state;

    this._updaterState.addUpdater(AlgoArbitrageModules.ArbitrageStatus, this.loadStatus);
  }

  get isStatusLoading() {
    return this._isStatusLoading;
  }

  get arbitrageStatus(): ArbitrageStatus {
    if (!this._arbitrageStatus) return "stopped";

    return this._arbitrageStatus;
  }

  get arbitrageUUID() {
    return this._mainState.arbitrageUUID;
  }

  get accountsState() {
    return this._mainState.accountsState;
  }

  get updateFetchData() {
    return this._updaterState.updateFetchData;
  }

  get addUpdater() {
    return this._updaterState.addUpdater;
  }

  loadStatus = () => {
    if (this.arbitrageUUID) {
      this._getArbitrageStatus(this.arbitrageUUID);
    }
  };

  stopArbitrageHandler = () => {
    if (this.arbitrageUUID) {
      this._stopArbitrageHandler(this.arbitrageUUID);
    }
  };

  startArbitrageHandler = () => {
    if (this.arbitrageUUID) {
      this._startArbitrageHandler(this.arbitrageUUID);
    }
  };

  private _setIsStatusLoading = (value: boolean) => {
    this._isStatusLoading = value;
  };

  private _setArbitrageStatus = (status: ArbitrageStatus) => {
    this._arbitrageStatus = status;
  };

  private _getArbitrageStatus = async (uuid: string) => {
    this._setIsStatusLoading(true);

    try {
      const { isError, data } = await getArbitrageStatus(uuid);

      if (!isError) {
        this._setArbitrageStatus(data);
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setIsStatusLoading(false);
    }
  };

  private _stopArbitrageHandler = async (uuid: string) => {
    this._setIsStatusLoading(true);

    try {
      const { isError } = await stopArbitrage(uuid);

      if (!isError) {
        showSuccessMsg("Arbitrage stopped successfully");
        this.updateFetchData();
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setIsStatusLoading(false);
    }
  };

  private _startArbitrageHandler = async (uuid: string) => {
    this._setIsStatusLoading(true);

    try {
      const { isError } = await startArbitrage(uuid);

      if (!isError) {
        showSuccessMsg("Arbitrage started successfully");
        this.updateFetchData();
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setIsStatusLoading(false);
    }
  };

  destroy = () => {};
}
