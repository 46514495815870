import { useEffect } from "react";
import { useAppState } from "src/state";

export const useDEXNEChainsInfo = () => {
  const {
    DEXNERootState: { chainsInfo },
  } = useAppState();

  useEffect(() => {
    chainsInfo.getChainsInfo();
  }, [chainsInfo]);

  return chainsInfo;
};
