import { observer } from "mobx-react-lite";
import {
  FormField,
  FormFieldControlProps,
  FormFieldOuterProps,
  FormFieldOwnProps,
} from "../../../FormField";
import { PercentRangeInput, PercentRangeInputOwnProps } from "../PercentRangeInput";

export type LabeledPercentRangeOwnProps = Omit<PercentRangeInputOwnProps, "id" | "error">;

export interface LabeledPercentRangeInputProps
  extends LabeledPercentRangeOwnProps,
    Pick<FormFieldOwnProps, "label" | "errorHint">,
    Omit<FormFieldOuterProps, keyof LabeledPercentRangeOwnProps> {
  disabled?: boolean;
}

export const LabeledPercentRangeInput = observer(
  ({ value, min, max, step, onChange, disabled, ...props }: LabeledPercentRangeInputProps) => {
    const FieldControl = ({ isError, id }: FormFieldControlProps) => (
      <PercentRangeInput
        id={id}
        error={isError}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        disabled={disabled}
      />
    );

    return <FormField control={FieldControl} gap={6} {...props} />;
  }
);
