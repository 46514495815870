import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 3px;

  @media (max-width: 950px) {
    padding: 10px 5px 0;
  }

  @media (max-width: 650px) {
    height: 100%;
    padding: 0 10px;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

export interface ContentScrollWrapperProps {
  $height?: number;
}

export const ContentScrollWrapper = styled.div<ContentScrollWrapperProps>`
  position: relative;

  width: 100%;
  height: ${({ $height: height }) => (height ? `${height}px` : "auto")};

  flex: 1 0 auto;
`;

export const BotContainer = styled.div`
  position: relative;
  width: 100%;
  height: 88px;
  display: flex;
  flex: 0 0 auto;

  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryColor};
`;

export const Frame = styled.div`
  width: 100%;
  /* height: 74px; */
  color: ${(props) => props.color || "#B66047"};
  border: 3px solid;
  border-color: ${(props) => props.color || "#B66047"};
  border-radius: ${({ theme }) => theme.radii["6px"]};
`;

export const MobBotContainer = styled(BotContainer)`
  height: 85px;
`;

export const MobFrame = styled(Frame)`
  height: 85px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  z-index: 1;
`;

export const Favorite = styled(IconWrapper)`
  position: absolute;
  width: 22px;
  height: 22px;
  z-index: 2;
  bottom: 10px;
  left: 13px;
  background-color: inherit;
  border-radius: ${({ theme }) => theme.radii["50%"]};
`;

export const Pair = styled(Link)`
  height: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  white-space: nowrap;
  color: #ffffff;
  text-decoration: none;
  border-radius: ${({ theme }) => theme.radii["4px"]};
`;

export const PairText = styled.span`
  display: block;

  font-size: 1.5vmin;
  line-height: initial;

  word-break: break-word;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const LockTime = styled.div`
  grid-area: lockTime;

  height: 24px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    margin-right: 3px;
  }
`;

export const DownTime = styled(LockTime)`
  grid-area: downTime;
`;

export const Time = styled.span`
  grid-area: time;

  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["18px"]};

  @media (max-width: 1230px) {
    font-size: ${({ theme }) => theme.fontSizes["14px"]};
  }
`;

export const LockTimeText = styled(Time)`
  display: flex;
  color: ${({ theme }) => theme.lockTime};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};

  & > svg {
    margin-right: 5px;
    fill: currentColor;

    @media (max-width: 450px) {
      width: 5px;
    }
  }
`;

export const Name = styled(LockTimeText)`
  justify-content: center;

  word-break: break-word;
  overflow: auto;
  text-align: center;

  font-size: ${({ theme }) => theme.fontSizes["10px"]};

  ::-webkit-scrollbar {
    width: 3px;
  }

  @media (max-width: 950px) {
    display: block;

    font-size: ${({ theme }) => theme.fontSizes["8px"]};

    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Exchange = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.bigHeader};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};

  @media (max-width: 1230px) {
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }
`;

export const Side = styled(Link)`
  height: 40px;
  width: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  background: ${({ theme }) => theme.bgGrayBlack};
  border-radius: ${({ theme }) => theme.radii["6px"]};
  color: ${({ theme }) => theme.bigHeader};

  @media (max-width: 1230px) {
    font-size: ${({ theme }) => theme.fontSizes["14px"]};
  }
`;

export const Message = styled.p`
  max-height: 100%;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 3px;
  margin: 0px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
  grid-area: message;

  color: ${({ theme }) => theme.inputTextColor};

  ::-webkit-scrollbar {
    width: 3px;
  }

  @media (max-width: 1230px) {
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }
`;

export const Comment = styled(Message)`
  grid-area: comment;
`;

export interface LineProps {
  $orientation?: "vertical" | "horizontal";
  $width?: number;
  $heightPx?: number;
}

const LineOrientationMixin = css<LineProps>`
  ${({ $orientation = "horizontal", $width, $heightPx }) => {
    const widthPercent = $width ? `${$width}%` : "";
    const height = $heightPx ? `${$heightPx}px` : "";
    const defaultWidth = "calc(100% - 20px)";
    const defaultHeight = "2.5px";

    switch ($orientation) {
      case "horizontal": {
        return css`
          width: ${widthPercent || defaultWidth};
          height: ${height || defaultHeight};
        `;
      }
      case "vertical": {
        return css`
          height: ${widthPercent || defaultWidth};
          width: ${height || defaultHeight};
        `;
      }
    }
  }}
`;

export const Line = styled.div<LineProps>`
  ${LineOrientationMixin}
  background-color: currentColor;
  border-radius: ${({ theme }) => theme.radii["2px"]};
`;

export const MobLine = styled(Line).attrs({ $width: 50, $heightPx: 2 })`
  margin: 0 auto;
`;

export const Mark = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  border-radius: ${({ theme }) => theme.radii["6px"]};

  & > svg {
    fill: currentColor;
  }
`;
