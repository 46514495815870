import { observer } from "mobx-react-lite";
import React, { ComponentPropsWithoutRef } from "react";
import loaderBlack from "../../../icons/loader.gif";
import loaderLight from "../../../icons/loaderLight.gif";
import * as styles from "./style";
import { useDarkMode } from "../../../hooks/UseDarkMode";

interface LoaderProps extends ComponentPropsWithoutRef<"div"> {
  show: boolean | undefined;
}

export const Loader = observer(({ show, ...props }: LoaderProps) => {
  const { theme } = useDarkMode();

  return (
    <styles.Container style={show ? { display: "flex" } : { display: "none" }} {...props}>
      {theme === "light" ? (
        <styles.LoaderAnim src={loaderBlack} />
      ) : (
        <styles.LoaderAnim src={loaderLight} />
      )}
    </styles.Container>
  );
});
