import { makeAutoObservable, toJS } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { ChainsStore, IChainsProvider } from "./Chains";
import { ChainExchangesStore, IChainExchangesProvider } from "./Exchanges";

export interface IDEXNEChainsInfoProvider
  extends Pick<IChainsProvider, "chains" | "chainMetaMap">,
    Pick<IChainExchangesProvider, "chainExchanges"> {
  getChainsInfo: () => Promise<void>;
}

export class DEXNEChainsInfoStore implements IDEXNEChainsInfoProvider, IDisposable {
  private _chainsState: IChainsProvider;

  private _chainExchangesState: IChainExchangesProvider;

  private _disposers: IDisposable[];

  constructor() {
    makeAutoObservable(this);

    const chainsState = new ChainsStore();

    const chainExchangesState = new ChainExchangesStore();

    this._disposers = [chainsState, chainExchangesState];

    this._chainsState = chainsState;
    this._chainExchangesState = chainExchangesState;

    makeLoggable(this, { chainExchanges: true, chains: true });
  }

  get chains() {
    return toJS(this._chainsState.chains);
  }

  get chainExchanges() {
    return toJS(this._chainExchangesState.chainExchanges);
  }

  get chainMetaMap() {
    return toJS(this._chainsState.chainMetaMap);
  }

  private _getChains = async () => {
    await this._chainsState.getChains();
  };

  private _getExchanges = async () => {
    await this._chainExchangesState.getChainExchanges();
  };

  getChainsInfo = async () => {
    try {
      await Promise.all([this._getChains(), this._getExchanges()]);
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {
    this._disposers.forEach((disposer) => disposer.destroy());
  };
}
