import { Optional } from "src/helpers/utils";

export interface NewParty
  extends Optional<PartyInfo, "tg_chat_id" | "client_group" | "slug" | "ticker"> {
  scopes: number[];
  party: string;
}

export interface DeleteParty {
  scope_id: number;
  party: string;
}

export interface User extends Pick<NewUser, "group_type" | "tg_handler" | "group_name"> {
  name: string;
  email: string;
  is_active: boolean;
}

export interface NewUser {
  name: string;
  email: string;
  is_active: true;
  hierarchy: {
    hierarchy_id?: number;
    scope_id: number | "";
    role: string;
  };
  password: string;
  group_type: GroupType;
  group_name: string;
  tg_handler: string;
}

export const GROUP_TYPES = ["admin", "client", "other"] as const;

export type GroupType = (typeof GROUP_TYPES)[number];

export interface NewUserGroup {
  name: string;
  type: GroupType;
  head: string;
}

export type TeamMember = {
  name: string;
  parties: string[];
  head: boolean;
};

export type Team = {
  name: string;
  head: string;
  headIsMember: boolean;
  index: number;
  members: TeamMember[];
};

export type RequestMembers = Record<string, string[]>;

export type RequestTeam = {
  name: string;
  head: string;
  users: RequestMembers;
};

export interface UserGroup extends NewUserGroup {
  users: string[];
  id: number;
}

export interface PartyInfo {
  tg_chat_id: number;
  client_group: string;
  admin_group: string;
  admin: string;
  slug: string;
  ticker: string;
}

export interface Role {
  name: string;
  abilities: string[];
}

export interface Scope {
  scope_id: number;
  name: string;
  parent_id: number;
  parties: string[];
  full_name: string;
}

export interface NewScope {
  name: string;
  parent_id: number;
}

export interface BaseHierarchy {
  hierarchy_id: number;
  role: string;
  scope_id: number;
}

export type NewHierarchy = {
  role: string;
  scope_id: number;
  user_name: string;
};

export type DeleteHierarchy = {
  hierarchy_id: number;
  user_name: string;
};

export interface Hierarchie {
  user: {
    name: string;
    email: string;
    is_active: boolean;
  };
  scope: {
    full_name: string;
  };
  hierarchy: BaseHierarchy;
}

export interface Account {
  uuid: string; // optional
  name: string;
  active_id: number;
  meta: {
    [key: string]: string;
  };
  exchange: string;
  credentials: Credential[]; // optional
}

export interface Credential {
  id: number; // optional
  name: string;
  data: {
    KEY: string;
    SECRET: string;
    ACCOUNT_ID: string;
  };
  meta: {
    [key: string]: string;
  };
  info: CredentialInfo; // optional
}

export interface CredentialInfo {
  created_at: string;
  updated_at: string;
}

export interface NewAccount {
  name: string;
  exchange: string;
  credentials: NewCredential;
  meta: { [key: string]: string };
}

export interface NewCredential {
  name: string;
  is_active?: boolean;
  data: {
    KEY: string;
    SECRET: string;
    ACCOUNT_ID: string;
  };
  meta: {
    [key: string]: string;
  };
}

export interface RequestAccount extends Omit<NewAccount, "credentials"> {
  credentials: NewCredential[];
}
