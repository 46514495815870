import { Tooltip as BaseTooltip, ITooltip as TooltipProps } from "react-tooltip";
import { CardSurface } from "src/components/shared/shared";
import styled, { css } from "styled-components";

// extra classname for increasing specificity
export const INFO_TOOLTIP_CLASSNAME = "info-tooltip";

export const TooltipCard = css`
  width: 220px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 10px;

  ${CardSurface};
`;

export const InfoTooltip = styled(BaseTooltip).attrs<{}, TooltipProps>({
  className: INFO_TOOLTIP_CLASSNAME,
  noArrow: true,
  float: true,
  opacity: 1,
})`
  &.${INFO_TOOLTIP_CLASSNAME} {
    ${TooltipCard}
  }
`;
