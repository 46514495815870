// source code from https://github.com/bolstycjw/chartjs-adapter-dayjs-4/blob/master/src/index.ts
import { _adapters } from "chart.js";

import dayjs, { extend, QUnitType } from "dayjs";

import type { TimeUnit } from "chart.js";

// Needed to handle the custom parsing
import CustomParseFormat from "dayjs/plugin/customParseFormat.js";

// Needed to handle quarter format
import AdvancedFormat from "dayjs/plugin/advancedFormat.js";

// Needed to handle adding/subtracting quarter
import QuarterOfYear from "dayjs/plugin/quarterOfYear.js";

// Needed to handle localization format
import LocalizedFormat from "dayjs/plugin/localizedFormat.js";

import isoWeek from "dayjs/plugin/isoWeek.js";

extend(AdvancedFormat);

extend(QuarterOfYear);

extend(LocalizedFormat);

extend(CustomParseFormat);

extend(isoWeek);

const FORMATS = {
  datetime: "MMM D, YYYY, h:mm:ss a",
  millisecond: "h:mm:ss.SSS a",
  second: "h:mm:ss a",
  minute: "h:mm a",
  hour: "hA",
  day: "MMM D",
  week: "ll",
  month: "MMM YYYY",
  quarter: "[Q]Q - YYYY",
  year: "YYYY",
};

export interface DayjsAdapterOptions {
  utc?: boolean;
}

const getDayjsConstructor = (options: unknown) => {
  const useUtc = (options as DayjsAdapterOptions).utc;
  const dayJS = useUtc ? dayjs.utc : dayjs;
  return dayJS;
};

_adapters._date.override({
  formats: () => FORMATS,
  parse(value: any, format?: TimeUnit) {
    const valueType = typeof value;
    const dayJS = getDayjsConstructor(this.options);

    if (value === null || valueType === "undefined") {
      return null;
    }

    if (valueType === "string" && typeof format === "string") {
      return dayJS(value, format).isValid() ? dayJS(value, format).valueOf() : null;
    }
    if (!(value instanceof dayjs)) {
      return dayJS(value).isValid() ? dayJS(value).valueOf() : null;
    }
    return null;
  },
  format(time: any, format: TimeUnit): string {
    const dayJS = getDayjsConstructor(this.options);
    return dayJS(time).format(format);
  },
  add(time: any, amount: number, unit: QUnitType & TimeUnit) {
    return dayjs(time).add(amount, unit).valueOf();
  },
  diff(max: any, min: any, unit: TimeUnit) {
    return dayjs(max).diff(dayjs(min), unit);
  },
  startOf(time: any, unit: (TimeUnit & QUnitType) | "isoWeek", weekday?: number) {
    if (unit === "isoWeek") {
      // Ensure that weekday has a valid format
      // const formattedWeekday

      const validatedWeekday: number =
        typeof weekday === "number" && weekday > 0 && weekday < 7 ? weekday : 1;

      return dayjs(time).isoWeekday(validatedWeekday).startOf("day").valueOf();
    }

    return dayjs(time).startOf(unit).valueOf();
  },
  endOf(time: any, unit: TimeUnit & QUnitType) {
    return dayjs(time).endOf(unit).valueOf();
  },
});
