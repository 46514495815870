import { ChainInfoIcon } from "../ChainInfoIcon";
import * as styles from "./style";

export interface ChainInfoChipOwnProps {
  abbr?: string;
  icon?: string;
  color?: string;
}

export interface ChainInfoChipProps
  extends ChainInfoChipOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof ChainInfoChipOwnProps> {}

export const ChainInfoChip = ({ abbr, icon, ...props }: ChainInfoChipProps) => (
  <styles.ChipContainer {...props}>
    {icon && <ChainInfoIcon icon={icon} />}
    {abbr && <styles.Text>{abbr}</styles.Text>}
  </styles.ChipContainer>
);
