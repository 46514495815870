import { makeAutoObservable, runInAction } from "mobx";
import { UseFieldArrayAppend, UseFormTrigger } from "react-hook-form";
import { toast } from "src/components/shared/Toaster";
import { addStrategy, getActions, getConditions } from "src/api/expertSystem";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import {
  ActionModulesSoftMap,
  ActionTypes,
  ConditionModuleSoftParam,
  ConditionSettingsValueSoftParams,
  ConditionTypes,
  IActionModuleInfo,
  IActionStrategyCreatorModule,
  IConditionModuleInfo,
  IConditionStrategyCreatorModule,
  IStrategyCreator,
  StrategyCreatorKeys,
  StrategyModuleTypes,
  isActionType,
  isConditionType,
  isModuleWithAccounts,
} from "src/modules/expertSystem";
import AccountListStore from "src/state/shared/AccountListStore";
import AccountReceiver from "src/state/shared/AccountReceiver";
import StrategyListStore from ".";
import { ExpressionAreaStore } from "./ExpressionAreaStore";

const STRATEGY_CREATING_STEPS_TABS = ["Settings", "Conditions", "Actions"] as const;

const EMPTY_CONDITION_MODULE: ConditionModuleSoftParam = { key: "", value: "", compare: "" };

const EMPTY_SETTINGS_VALUE_MODULE: ConditionSettingsValueSoftParams = {
  key: "",
  value: "",
  compare: "eq",
};

const EMPTY_ACTION_SOFT_PARAMS_MAP: ActionModulesSoftMap = {
  alert_tg: { text: "" },
  trade: { amount: "", price: "", side: "" },
  change_settings: {
    settings: "",
  },
};

export const EMPTY_STRATEGY_CREATOR: IStrategyCreator = {
  conditions: [],
  actions: [],
  cooldown: "",
  execution_order: "",
  expired_at: "",
  expression: "",
  launches_limit: "",
  name: "",
  party: "",
  status: "stopped",
};

export type StrategyCreatingTabs = (typeof STRATEGY_CREATING_STEPS_TABS)[number];

const STRATEGY_CREATING_MAP_VALIDATION: Record<StrategyCreatingTabs, StrategyCreatorKeys[]> = {
  Settings: ["name", "expired_at", "launches_limit", "cooldown", "execution_order"],
  Conditions: ["conditions"],
  Actions: [],
};

const getConditionModuleSoftParam = (type: ConditionTypes) => {
  if (type === "settings_value") return EMPTY_SETTINGS_VALUE_MODULE;

  return EMPTY_CONDITION_MODULE;
};

class CreateStrategyStore {
  private _mainState: StrategyListStore;

  private _conditions: IConditionModuleInfo[] = [];

  private _actions: IActionModuleInfo[] = [];

  private _conditionMap: Map<ConditionTypes, IConditionModuleInfo[]> = new Map();

  private _actionsMap: Map<ActionTypes, IActionModuleInfo[]> = new Map();

  private _strategyConditions: IConditionStrategyCreatorModule[] = [];

  private _strategyActions: IActionStrategyCreatorModule[] = [];

  private _conditionCounter = 0;

  private _isLoading = false;

  private _expression = "";

  private _accountsReceiverState: AccountReceiver;

  private _accountListState: AccountListStore;

  private _childStates;

  constructor(state: StrategyListStore) {
    makeAutoObservable(this);

    this._mainState = state;

    this._accountsReceiverState = new AccountReceiver(this);

    this._accountListState = new AccountListStore({
      mainState: this._accountsReceiverState,
      exchState: this,
    });

    this._childStates = {
      expressionAreaState: new ExpressionAreaStore(this),
    };
  }

  get party() {
    return this._party;
  }

  // only for _accountListState
  // need to think about how to simplify the connection AccountListStore
  get exchange() {
    return "";
  }

  get conditionTypeList() {
    return Array.from(this._conditionMap.keys()).sort();
  }

  get actionTypeList() {
    return Array.from(this._actionsMap.keys()).sort();
  }

  get strategyConditions() {
    return this._strategyConditions;
  }

  get expressionAreaState() {
    return this._childStates.expressionAreaState;
  }

  get conditionKeys() {
    const keys: string[] = [];

    this._strategyConditions.forEach((el) => {
      keys.push(el.soft_params.key);
    });

    return keys;
  }

  get expression() {
    return this._expression;
  }

  get isLoading() {
    return this._isLoading;
  }

  private get _party() {
    return this._mainState.party;
  }

  getConditionList = (type: ConditionTypes) => this._conditionMap.get(type) || [];

  getActionList = (type: ActionTypes) => this._actionsMap.get(type) || [];

  private _getAccounts = async () => {
    this._accountsReceiverState.loadAccounts();
  };

  loadData = () => {
    this._getConditions();
    this._getActions();
    this._getAccounts();
  };

  nextStepHandler = async (
    step: StrategyCreatingTabs,
    triggerValidation: UseFormTrigger<IStrategyCreator>,
    nextStep: () => void
  ) => {
    const valid = await triggerValidation(STRATEGY_CREATING_MAP_VALIDATION[step]);

    if (step === "Conditions") {
      const conditionsValid = this._conditionValidate();

      if (!conditionsValid) return;
    }

    if (valid) nextStep();
  };

  addConditionModuleHandler =
    (append: UseFieldArrayAppend<IStrategyCreator, "conditions">) =>
    (uuid: string, type: StrategyModuleTypes) => {
      // check type strategy module for add only condition module
      const isCondition = isConditionType(type);

      if (!isCondition) return;

      const module: IConditionStrategyCreatorModule = {
        uuid,
        type,
        soft_params: getConditionModuleSoftParam(type),
      };

      const moduleKey = this._getConditionModuleKey();

      module.soft_params.key = moduleKey;

      // collect condition modules
      runInAction(() => this._strategyConditions.push(module));
      // add condition module for react-hook-form
      append(module);
      // add module key for autogenerate expression
      this.expressionAreaState.autogenerateExpression(moduleKey);
    };

  addActionModuleHandler =
    (append: UseFieldArrayAppend<IStrategyCreator, "actions">) =>
    (uuid: string, type: StrategyModuleTypes) => {
      // check type strategy module for add only condition module
      const isAction = isActionType(type);

      if (!isAction) return;

      const module: IActionStrategyCreatorModule = {
        uuid,
        type,
        soft_params: EMPTY_ACTION_SOFT_PARAMS_MAP[type],
      };

      // collect condition modules
      runInAction(() => this._strategyActions.push(module));
      // add condition module for react-hook-form
      append(module);
    };

  removeConditionHandler = (key: string) => {
    this._strategyConditions = this._strategyConditions.filter((el) => el.soft_params.key !== key);
  };

  removeActionHandler = (index: number) => {
    this._strategyActions.splice(index, 1);
  };

  getConditionAddedModule = (uuid: string) => {
    let counter = 0;

    this._strategyConditions.forEach((el) => {
      if (el.uuid === uuid) counter += 1;
    });

    return counter;
  };

  getActionAddedModule = (uuid: string) => {
    let counter = 0;

    this._strategyActions.forEach((el) => {
      if (el.uuid === uuid) counter += 1;
    });

    return counter;
  };

  getConditionHardParamsProps = (uuid: string) => {
    const module = this._conditions.find((el) => el.uuid === uuid);
    const paramsProps = { name: "", hardParams: {} };

    if (module) {
      const isAccounts = isModuleWithAccounts(module);

      let hardParams = {};

      if (isAccounts) {
        const accountNames = this._accountListState.getAccountNames(module.hard_params.accounts);

        hardParams = { ...module.hard_params, accounts: accountNames.join(", ") };
      } else {
        hardParams = (module as IConditionModuleInfo).hard_params;
      }

      paramsProps.name = module.name;
      paramsProps.hardParams = hardParams;
    }

    return paramsProps;
  };

  getActionHardParamsProps = (uuid: string) => {
    const module = this._actions.find((el) => el.uuid === uuid);
    const paramsProps = { name: "", hardParams: {} };

    if (module) {
      paramsProps.name = module.name;
      paramsProps.hardParams = { ...module.hard_params };
    }

    return paramsProps;
  };

  expressionHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this._setExpression(e.target.value);
  };

  private _setExpression = (value: string) => {
    this._expression = value;
  };

  submitHandler = (closeModal: () => void) => (strategy: IStrategyCreator) => {
    const actionsValid = this._actionValidate();

    if (!actionsValid) return;

    const requestStrategy = this._getRequestStrategyCreate(strategy);

    this._createStrategy(requestStrategy, closeModal);
  };

  private _getRequestStrategyCreate = (strategy: IStrategyCreator) => ({
    ...strategy,
    party: this._party,
    expression: this.expressionAreaState.expression,
  });

  private _validateEmptyConditions = () => {
    const valid = this._strategyConditions.length > 0;

    if (!valid) {
      toast.error("The strategy must contain at least one condition");
    }
    return valid;
  };

  private _conditionValidate = () =>
    this._validateEmptyConditions() && this.expressionAreaState.validation();

  private _validateEmptyActions = () => this._strategyActions.length > 0;

  private _actionValidate = () => {
    const valid = this._validateEmptyActions();

    if (!valid) {
      toast.error("The strategy must contain at least one action");
    }

    return valid;
  };

  private _getConditionModuleKey = () => {
    const key = `x${this._conditionCounter}`;

    this._conditionCounter += 1;

    return key;
  };

  private _generateConditionsMap = () => {
    this._conditions.forEach((el) => {
      const conditions = this._conditionMap.get(el.type) || [];

      conditions.push(el);
      this._conditionMap.set(el.type, conditions);
    });
  };

  private _generateActionsMap = () => {
    this._actions.forEach((el) => {
      const actions = this._actionsMap.get(el.type) || [];

      actions.push(el);
      this._actionsMap.set(el.type, actions);
    });
  };

  private _getConditions = async () => {
    try {
      const { isError, data } = await getConditions(this._party);

      if (!isError) {
        const { items: conditions } = data;

        runInAction(() => {
          this._conditions = conditions;
        });

        this._generateConditionsMap();
      }
    } catch (error) {
      runInAction(() => {
        this._conditions = [];
      });
    }
  };

  private _getActions = async () => {
    try {
      const { isError, data } = await getActions(this._party);

      if (!isError) {
        const { items: actions } = data;

        runInAction(() => {
          this._actions = actions;
        });

        this._generateActionsMap();
      }
    } catch (error) {
      runInAction(() => {
        this._conditions = [];
      });
    }
  };

  private _createStrategy = async (strategy: IStrategyCreator, closeModal: () => void) => {
    runInAction(() => {
      this._isLoading = true;
    });

    try {
      const { isError } = await addStrategy(strategy);

      if (!isError) {
        showSuccessMsg("The strategy has been successfully created");

        this._mainState.refreshStrategies();

        closeModal();
      }
    } catch (error) {
      logError(error);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  destroy = () => {};
}

export default CreateStrategyStore;
