import { ComponentPropsWithoutRef, memo } from "react";
import DEXCommonBotIcons from "src/icons/DEXCommonBotIcons";
import { DEXCommonBotMode } from "src/modules/bots";
import * as styles from "./style";

export interface ModeIconProps
  extends ComponentPropsWithoutRef<"div">,
    styles.ModeIconWrapperProps {
  mode: DEXCommonBotMode;
}

export const getModeIcon = (mode: DEXCommonBotMode) => {
  switch (mode) {
    case "counter": {
      return DEXCommonBotIcons.counter();
    }
    case "limit": {
      return DEXCommonBotIcons.limit();
    }
    case "volume": {
      return DEXCommonBotIcons.volume();
    }
  }
};

export const ModeIcon = memo(({ mode, ...props }: ModeIconProps) => {
  const icon = getModeIcon(mode);

  return <styles.ModeIconWrapper {...props}>{icon}</styles.ModeIconWrapper>;
});
