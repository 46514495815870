import styled from "styled-components";

interface Props {
  border?: boolean;
  direction?: string;
}

export const Container = styled.div`
  width: 100%;
`;

export const Label = styled.label<Props>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 2px 0px;

  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.textColor};
  background: inherit;
  border-radius: ${({ theme }) => theme.radii["4px"]};
  font-family: inherit;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["14px"]};

  @media (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["13px"]};
  }
`;

export const RadioButton = styled.input.attrs({ type: "radio" })<Props>`
  position: absolute;
  display: none;

  :checked + ${Label} {
    font-weight: ${({ theme }) => theme.fontWeights[600]};
    color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;
