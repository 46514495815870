import styled from "styled-components";
import { Link } from "react-router-dom";
import { hexToRgb } from "src/helpers/colors";

interface MenuItemProps {
  isSelected: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: ${({ isSelected, theme }) => (isSelected ? theme.primaryColor : theme.svgColor)};
  text-align: center;
`;

export const NavLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  color: inherit;
  font-size: 1.5em;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  padding: 0 0.2em;
  box-sizing: border-box;
`;

export const IconWrapper = styled.div<MenuItemProps>`
  width: 56px;
  height: 32px;
  border-radius: 100px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? hexToRgb(theme.primaryColor, 0.1) : "transparent"};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.span<MenuItemProps>`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  font-weight: ${({ isSelected, theme }) =>
    isSelected ? theme.fontWeights.bold : theme.fontWeights.normal};
  color: ${({ isSelected, theme }) => (isSelected ? theme.primaryColor : theme.textColor)};
`;
