import windowConsent from "src/state/WindowConsent";
import { CreatedArbitrageExchangeModule } from "./type";

export const showWarningWhenSwitching = (
  strategy: CreatedArbitrageExchangeModule,
  action: "stop" | "start",
  toggleCb: (strategy: CreatedArbitrageExchangeModule) => Promise<void>
) => {
  windowConsent.showWindow("", `Are you sure you want to ${action} module?`, toggleCb, strategy);
};

export const checkModuleActive = (module: CreatedArbitrageExchangeModule) =>
  module.status === "active" || module.status === "requested";
