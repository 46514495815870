import { makeAutoObservable } from "mobx";
import { logError } from "src/helpers/network/logger";
import { getClients } from "src/api/bots/CEX/terminal";
import { OnMenuItemChange } from "src/components/shared/SwitchMenu";
import { IStoresUpdater } from "src/state/shared/updater/types";
import { UpdaterStore } from "src/state/shared/updater/UpdaterStore";
import { ArbitrageStore } from "..";
import {
  ArbitrageScopesMap,
  CalcTotalsCbsMap,
  IArbitrageScope,
  IBuySellTotalFuncs,
  SocketSupportMap,
} from "./types";
import { exchangeInfoToMap } from "./utils";
import { StreamProvider } from "./providers/StreamProvider";
import { ArbitrageTerminalModules } from "../types";

export class OrderBooksPanelStore implements IStoresUpdater {
  setCurrentKey: OnMenuItemChange | undefined;

  private _mainState: ArbitrageStore;

  private _isLoading = false;

  private _streamState: StreamProvider;

  /** key - market (quote_base_exchange) */
  private _updaterState = new UpdaterStore<string>();

  private _socketSupportsMap: SocketSupportMap | null = null;

  private _arbitrageScopesMap: ArbitrageScopesMap = new Map();

  private _calcBuySellTotals: CalcTotalsCbsMap = new Map();

  constructor(mainState: ArbitrageStore) {
    makeAutoObservable(this);

    this._mainState = mainState;
    this._streamState = new StreamProvider();

    this._mainState.addUpdater(ArbitrageTerminalModules.OrderBookPanel, this.updateFetchData);
  }

  get isLoading() {
    return this._isLoading;
  }

  get streamState() {
    return this._streamState;
  }

  get accountsMap() {
    return this._mainState.exchangeAccountsMap;
  }

  get updateFetchData() {
    return this._updaterState.updateFetchData;
  }

  get addUpdater() {
    return this._updaterState.addUpdater;
  }

  get removeUpdater() {
    return this._updaterState.removeUpdater;
  }

  get minSell() {
    if (!this._minSellList.length) return null;

    return Math.min(...this._minSellList);
  }

  get maxBuy() {
    if (!this._maxBuyList.length) return null;

    return Math.max(...this._maxBuyList);
  }

  get selectMarkets() {
    return this._mainState.selectMarkets;
  }

  get calcTotalsMap() {
    return this._calcBuySellTotals;
  }

  get orderSetter() {
    return this._mainState.arbitrageModeState.orderSetter;
  }

  private get _minSellList() {
    const list = [...this._arbitrageScopesMap.values()].map(({ minSell }) => minSell);

    return list.filter((el) => el !== null && el > 0) as number[];
  }

  private get _maxBuyList() {
    const list = [...this._arbitrageScopesMap.values()].map(({ maxBuy }) => maxBuy);

    return list.filter((el) => el !== null && el > 0) as number[];
  }

  loadData = async () => {
    await this._getWebsocketSupport();
  };

  checkSocketSupport = (exchange: string) => {
    const isSupport = this._socketSupportsMap?.get(exchange);

    if (isSupport !== undefined) return isSupport;

    return false;
  };

  setArbitrageScope = (marketStr: string, scope: IArbitrageScope) => {
    this._arbitrageScopesMap.set(marketStr, scope);
  };

  removeArbitrageScope = (marketStr: string) => {
    this._arbitrageScopesMap.delete(marketStr);
  };

  setCalcTotalsFuncs = (marketStr: string, funcs: IBuySellTotalFuncs) => {
    this._calcBuySellTotals.set(marketStr, funcs);
  };

  removeCalcTotalsFuncs = (marketStr: string) => {
    this._calcBuySellTotals.delete(marketStr);
  };

  setFuncCurrentKey = (setFunc: OnMenuItemChange | undefined) => {
    this.setCurrentKey = setFunc;
  };

  private _setSocketSupportMap = (map: SocketSupportMap) => {
    this._socketSupportsMap = map;
  };

  private _getWebsocketSupport = async () => {
    try {
      const { data, isError } = await getClients();

      if (!isError) {
        const exchanges = data.data;
        this._setSocketSupportMap(exchangeInfoToMap(exchanges));
      }
    } catch (error) {
      logError(error);
    }
  };
}
