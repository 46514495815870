import { useRouteMatch } from "react-router-dom";
import { CEXRoutes, DEXRoutes, DEXV2Routes } from "src/app/constants";
import { PartyQueryParams } from "src/components/NavigationMenu/MenuContent/PartyNavigation";
import {
  BotType,
  getBotLink,
  getPartySectionLink,
  isBotType,
} from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { BotLayerQueryParams } from "src/components/NavigationMenu/hooks";
import { BOT_ROUTE } from "src/components/NavigationMenu/consts";
import { getCrumb, getPathNames, getReduceString } from "./utils";

export interface ICrumbItem {
  name: string;
  to: string;
}

const PARTY_ROUTE = "/party/:party/:section";

const getDefaultBotSection = (botType: BotType) => {
  switch (botType) {
    case "CEX":
      return CEXRoutes.Exchange;
    case "DEX":
      return DEXRoutes.Stats;
    case "DEX_V2":
      return DEXV2Routes.Stats;

    default:
      return undefined;
  }
};

const useGetBotCrumb = () => {
  const botRoute = useRouteMatch<BotLayerQueryParams>({ path: BOT_ROUTE });

  if (!botRoute) return [];

  const { botType, uuid, section, party } = botRoute.params;

  const defaultSection = getDefaultBotSection(botType);

  const botCrumb = getCrumb(
    [botType, uuid],
    getBotLink({ party, botType, uuid, botTab: defaultSection })
  );

  const sectionCrumb = getCrumb([section], "");

  return [botCrumb, sectionCrumb];
};

const useGetPartyCrumb = () => {
  const partyRoute = useRouteMatch<PartyQueryParams>({ path: PARTY_ROUTE });

  if (!partyRoute) return [];

  const { party, section } = partyRoute.params;

  const partyCrumb = getCrumb(
    ["party", party],
    getPartySectionLink({ party, partySection: "Bots" })
  );

  if (isBotType(section)) {
    return [partyCrumb];
  }

  const sectionCrumb = getCrumb([section], "");

  return [partyCrumb, sectionCrumb];
};

const useGetMainCrumbs = () => {
  const mainRoute = useRouteMatch<{ section: string }>("/:section");

  if (!mainRoute) return [];

  const { section } = mainRoute.params;

  if (section === "party") return [];

  const { url } = mainRoute;
  const pathNames = getPathNames(url);

  return pathNames.map((el, index) => ({
    name: el,
    to: getReduceString(pathNames.slice(0, index), "/"),
  }));
};

export const useGetBreadCrumbs = (): ICrumbItem[] => {
  const mainCrumbs = useGetMainCrumbs();
  const partyCrumbs = useGetPartyCrumb();
  const botCrumbs = useGetBotCrumb();

  return [...mainCrumbs, ...partyCrumbs, ...botCrumbs];
};
