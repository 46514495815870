import { PropertyKeyKeys, PropertyKeyValues } from "src/modules/shared";

/**
 *
 * @param arr - array of simple data types
 * @param v - the element to be removed from the array
 */
export const deleteElem = (arr: string[] | number[], v: string | number) => {
  const foundIndex = arr.findIndex((el) => el === v);

  if (foundIndex !== -1) {
    arr.splice(foundIndex, 1);
  }
};

/**
 *
 * @param arr - array of objects to be mapped
 * @param key - the key by which the mapping will be performed
 * @returns mapped object by the specified key
 */
export const arrayToMapObj = <T, K extends PropertyKeyKeys<T>>(arr: T[], key: K) => {
  type TPropKeyValues = PropertyKeyValues<T>;

  return Object.fromEntries(arr.map((el) => [el[key], el])) as Record<TPropKeyValues[K], T>;
};

export const filterDuplicates = <T>(arr: T[]) => {
  const set = new Set<T>();
  arr.forEach((item) => set.add(item));
  return Array.from(set);
};

export const sumArray = (arr: number[]) => {
  let sum = 0;
  for (let i = 0; i < arr.length; i += 1) {
    sum += arr[i];
  }
  return sum;
};

export const shuffle = <T>(arr: T[]) =>
  arr
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

export const isEmptyArray = (value: any) => Array.isArray(value) && !value.length;
