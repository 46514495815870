import { ChildrenProps } from "src/helpers/utils";
import { ChainConnectionProvider } from "./ChainConnectionContext";
import { DEXV2BotProvider } from "./DEXV2Bots/DEXV2Bot";
import { DEXV2BotQuotesProvider } from "./DEXV2Bots/DEXV2BotQuotes";
import { BotWalletConnectionProvider } from "./DEXV2Stats/Vaults/BotWalletConnection";

type DEXV2BotProvidersProps = ChildrenProps;

export const DEXV2BotProviders = ({ children }: DEXV2BotProvidersProps) => (
  <DEXV2BotProvider>
    <DEXV2BotQuotesProvider>
      <ChainConnectionProvider>
        <BotWalletConnectionProvider>{children}</BotWalletConnectionProvider>
      </ChainConnectionProvider>
    </DEXV2BotQuotesProvider>
  </DEXV2BotProvider>
);
