import { makeAutoObservable } from "mobx";
import { IDisposable, noOp } from "src/helpers/utils";
import { UseFieldArrayAppend } from "react-hook-form";
import { createArbitrage } from "src/api/algoArbitrage";
import { logError } from "src/helpers/network/logger";
import { ArbitrageCreatorDTO } from "src/api/algoArbitrage/types";
import { IExchangeModuleCreator } from "../exchangeModuleModal/types";
import { AlgoArbitrageStore } from "..";
import { ArbitrageCreateForm, ArbitrageExchangeModule } from "../types";
import { arbitrageSettingsMapper, exchangeModulesMapper } from "../mappers";

type AppendCb = UseFieldArrayAppend<ArbitrageCreateForm, "exchanges">;
export class CreateArbitrageStore implements IExchangeModuleCreator, IDisposable {
  private _mainState: AlgoArbitrageStore;

  private _appendInForm: AppendCb = noOp;

  private _isLoading = false;

  constructor(state: AlgoArbitrageStore) {
    makeAutoObservable(this);

    this._mainState = state;
  }

  get accountsState() {
    return this._mainState.accountsState;
  }

  get isLoading() {
    return this._isLoading;
  }

  private get _party() {
    return this._mainState.party;
  }

  getAccountName = (accUUID: string) => {
    const { name } = this.accountsState.accountsMap[accUUID];

    return name ?? "";
  };

  setAppendInFormCb = (cb: AppendCb) => {
    this._appendInForm = cb;
  };

  addExchModule = async (module: ArbitrageExchangeModule, closeModal: () => void) => {
    this._appendInForm(module);

    closeModal();
  };

  createHandler = async ({ settings, exchanges }: ArbitrageCreateForm) => {
    const requestData: ArbitrageCreatorDTO = {
      party: this._party,
      exchanges: exchangeModulesMapper(exchanges),
      settings: arbitrageSettingsMapper(settings),
    };

    this._setLoading(true);

    try {
      const { isError, data } = await createArbitrage(requestData);

      if (!isError) this._mainState.setArbitrageUUID(data);
    } catch (error) {
      logError(error);
    } finally {
      this._setLoading(false);
    }
  };

  private _setLoading = (value: boolean) => {
    this._isLoading = value;
  };

  destroy = () => {};
}
