import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-height: 200px;
`;

export const ShortcutsList = styled.ul`
  max-height: 85px;
  overflow-y: auto;

  margin: 0;
  padding: 0;
  padding-right: 5px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;

  list-style: none;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

export const ShortcutsItem = styled.li`
  width: 100%;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  padding: 6px 8px;
  display: flex;

  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.dashboard.textDefault};
  font-weight: ${({ theme }) => theme.fontWeights.normal};

  border-radius: ${({ theme }) => theme.radii["4px"]};
  border: 1px solid ${({ theme }) => theme.dashboard.borderDefault};
  background: ${({ theme }) => theme.dashboard.backgroundBaseField};

  :active {
    background-color: ${({ theme }) => darken(0.05, theme.dashboard.backgroundBaseField)};
  }

  cursor: pointer;
`;
