import { ApiModules, apiUrl } from "src/api";
import { buildQueryUrl } from "src/helpers/url";
import ResponseHandler from "src/state/network/ResponseHandler";

type ExchangeBot = {
  avg_work: number;
  botUUID: string;
  pair: string;
};

type Exchange = {
  list: ExchangeBot[];
  avg_active: true;
  avg_exchange_number: 0;
  current_active: true;
};

type PartyExchanges = {
  admin_user: string;
  admin_user_group: string;
  avg_exchange_number: number;
  current_exchange_number: number;
  exchanges: Record<string, Exchange>;
  total_uniq_exchange_number: number;
};

export type GetPartyExchangesResponse = Record<string, PartyExchanges>;

export interface GetPartyExchangesRequestParams {
  start: string;
  stop: string;
}

export const getPartyExchanges = async (params?: GetPartyExchangesRequestParams) => {
  const url = buildQueryUrl("/party-exchanges", params);
  return ResponseHandler.handler<GetPartyExchangesResponse>({
    url: apiUrl(ApiModules.Empty, url),
    method: "GET",
  });
};
