import styled from "styled-components";

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  padding: 5px;
  height: 28px;

  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  color: ${({ theme }) => theme.inputTextColor};

  :hover {
    background: ${({ theme }) => theme.inputHoverBgColor};
  }

  :last-of-type {
    border-radius: 0px 0px 4px 4px;
  }

  :first-of-type {
    border-radius: 4px 4px 0px 0px;
  }
`;
