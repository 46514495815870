import { useEffect } from "react";
import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { useMetaMaskProvider } from "src/hooks/useMetaMaskProvider";
import ChainConnectionStore from "src/state/chain/ChainConnectionStore";

export const ChainConnectionContext = createLateInitContext<ChainConnectionStore>();

interface ChainConnectionContextProps extends ChildrenProps {
  listenToMetamaskEvents?: boolean;
}

export const ChainConnectionProvider = ({
  listenToMetamaskEvents,
  children,
}: ChainConnectionContextProps) => {
  const metaMaskProvider = useMetaMaskProvider();

  const chainContextStore: ChainConnectionStore = useLocalStore(
    ChainConnectionStore,
    listenToMetamaskEvents
  );

  useEffect(() => {
    if (!metaMaskProvider) return;
    chainContextStore.init(metaMaskProvider);
  }, [chainContextStore, metaMaskProvider]);

  return (
    <ChainConnectionContext.Provider value={chainContextStore}>
      {children}
    </ChainConnectionContext.Provider>
  );
};
