import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { IQueryHistory } from "../shared/UrlSearchParams/types";
import { UrlSearchParamsStore } from "../shared/UrlSearchParams";
import { UrlParams, searchParamsValidationSchema } from "./validationSchemes";

export type TabsType = "STRATEGIES" | "MODULES";

export class ExpertSystemStore {
  private _party = "";

  private _currentTab: TabsType = "STRATEGIES";

  private _urlSearchParamsState: UrlSearchParamsStore<UrlParams>;

  private _querySearchParamsReaction?: IReactionDisposer;

  constructor(searchParamsProps: IQueryHistory) {
    makeAutoObservable(this);

    this._urlSearchParamsState = new UrlSearchParamsStore(this, {
      ...searchParamsProps,
      validationSchema: searchParamsValidationSchema,
    });
  }

  get party() {
    return this._party;
  }

  get currentTab() {
    return this._currentTab;
  }

  private get _querySearchParams(): UrlParams {
    return { currentTab: this.currentTab };
  }

  setParty = (party: string) => {
    this._party = party;
  };

  switchCurrentTab = (tab: TabsType) => {
    this._currentTab = tab;
  };

  updateQueryUrl = () => {
    this._urlSearchParamsState.setAllQueryUrl(this._querySearchParams);
  };

  setInitialQueries = (queryObj: Partial<UrlParams>) => {
    const { currentTab } = queryObj;

    if (currentTab) this.switchCurrentTab(currentTab);
  };

  destroy = () => {};

  subscribe = () => {
    this._querySearchParamsReaction = reaction(
      () => this._querySearchParams,
      (query) => this._urlSearchParamsState.setAllQueryUrl(query),
      { fireImmediately: true }
    );
  };

  unsubscribe = () => this._querySearchParamsReaction?.();
}
