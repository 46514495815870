import { makeAutoObservable } from "mobx";
import { DebouncedFunc } from "lodash";
import { IExchangeOrderBook } from "src/api/bots/CEX/terminal/types";
import { joinMarket, joinPair } from "src/helpers/botName";
import WebSocketStore, { IStreamConfig } from "src/state/network/WebSocketHandler";
import { IDisposable } from "src/helpers/utils";
import { IMarket } from "src/api/arbitrage/types";
import { ConnectStatusKeys } from "src/components/shared/Indicators";
import { getStreamConfig } from "../utils";
import { calcDelayExchangeResponse, getStatusConnection } from "./utils";

type SubscribeOrderBookCallback = DebouncedFunc<(data: IExchangeOrderBook) => void>;

interface ISubscriptionHandler {
  callback: SubscribeOrderBookCallback;
  delay: string;
  statusConnection: ConnectStatusKeys;
}

/** key - market (quote_base_exchange) */
type OrderbookSubscriptionMap = Map<string, ISubscriptionHandler>;

export class StreamProvider implements IDisposable {
  channelToSubscription: OrderbookSubscriptionMap = new Map();

  private _webSocketState: WebSocketStore;

  constructor() {
    makeAutoObservable(this);

    this._webSocketState = new WebSocketStore({
      subCb: [this.updateProcessing],
    });
  }

  private get _data() {
    return this._webSocketState.data;
  }

  private get _lockedConnect() {
    return this._webSocketState.socketStatus === 1 || this._webSocketState.socketStatus === 2;
  }

  updateProcessing = () => {
    if (!this._data || this._data.type !== "orderbooks") return;

    const { data, pair, exchange, tsExchange, tsGateway } = this._data.result;

    const marketChannel = `${pair}_${exchange}`;

    const subscriptionItem = this.channelToSubscription.get(marketChannel);

    if (!subscriptionItem) return;

    const delay = calcDelayExchangeResponse(tsExchange, tsGateway);

    subscriptionItem.callback(data);
    subscriptionItem.delay = `Exchange delay ${delay} ms`;
    subscriptionItem.statusConnection = getStatusConnection(delay);
  };

  subscribeOnStream = (
    { quote, base, exchange }: IMarket,
    onRealtimeCallback: SubscribeOrderBookCallback
  ) => {
    const marketChannel = joinMarket({ quote, base, exchange });

    const subscriptionItem = this.channelToSubscription.has(marketChannel);

    if (!subscriptionItem) {
      const subHandler: ISubscriptionHandler = {
        callback: onRealtimeCallback,
        delay: "",
        statusConnection: "noConnection",
      };

      this.channelToSubscription.set(marketChannel, subHandler);

      const pair = joinPair(quote, base);

      const config = getStreamConfig("subscribe", pair, exchange);

      if (this._webSocketState.isOpened) {
        this._subscribeMarket(config);
      } else {
        this._setupWebSocket(config);
      }
    }
  };

  unsubscribeFromStream = ({ quote, base, exchange }: IMarket) => {
    // Find a subscription with key === market
    const marketChannel = joinMarket({ quote, base, exchange });

    const subscriptionItem = this.channelToSubscription.has(marketChannel);

    if (!subscriptionItem) return;

    this.channelToSubscription.delete(marketChannel);

    const pair = joinPair(quote, base);

    const unsubscribeConfig = getStreamConfig("unsubscribe", pair, exchange);

    // unsubscribe from stream if websocket connection is open
    if (this._webSocketState.socketStatus === 1) {
      this._webSocketState.sendMsgOnStream(unsubscribeConfig);
    }

    // TODO: its correct? Maybe close the connection just to destroy the store??

    // close connection if it is the last handler
    if (this.channelToSubscription.size === 0) {
      this._unbindProcessing();
    }
  };

  private _setupWebSocket = (config: IStreamConfig) => {
    if (this._lockedConnect) return;

    this._webSocketState.setupWebSocket({
      url: "order-books",
      config,
    });
  };

  private _subscribeMarket = (config: IStreamConfig) => {
    // sending configuration to subscribe to the data stream
    if (this._webSocketState.socketStatus === 1) {
      this._webSocketState.sendMsgOnStream(config);
    }
  };

  private _unbindProcessing = () => {
    this._webSocketState.closeWebSocket();
  };

  destroy = () => {
    this._unbindProcessing();
  };
}
