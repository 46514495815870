import { rgba } from "polished";
import styled from "styled-components";

export const Item = styled.div`
  min-width: 55px;
  height: 19px;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  padding: 3px 5px;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.primaryColor};
  background: ${({ theme }) => rgba(theme.primaryColor, 0.1)};
  border: 1px solid ${({ theme }) => rgba(theme.primaryColor, 0.33)};
  border-radius: ${({ theme }) => theme.radii["2px"]};
`;

export const RemoveBtn = styled.button`
  width: 15px;
  height: 15px;
  position: relative;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radii["2px"]};

  ::before,
  ::after {
    position: absolute;
    content: "";
    width: 1.3px;
    height: 10px;
    background: ${({ theme }) => theme.primaryColor};
    left: 7px;
    top: 2.5px;
    -ms-transform: rotate(140deg);
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg);
    transform: rotate(47deg);
  }

  ::after {
    right: 9px;
    -ms-transform: rotate(230deg);
    -webkit-transform: rotate(230deg);
    transform: rotate(230deg);
    transform: rotate(-47deg);
  }

  :hover {
    background: inherit;
  }
`;
