import { DEXCommonLimitInfo, DEXCommonVolumeInfo, DEXCommonCounterInfo } from "src/modules/bots";
import { ListDEXCommonBot } from "../shared/DEXCommon";

export const getDEXV2ModesProps = (bot: ListDEXCommonBot) => {
  const {
    limitLastTrade,
    limitMessage,
    limitNextTrade,
    limitStatus,

    volumeLastTrade,
    volumeMessage,
    volumeNextTrade,
    volumeStatus,

    counterMessage,
    counterStatus,
    counterLastTrade,

    ...otherProps
  } = bot;

  const limitModeProps: DEXCommonLimitInfo = {
    limitLastTrade,
    limitMessage,
    limitNextTrade,
    limitStatus,
  };
  const volumeModeProps: DEXCommonVolumeInfo = {
    volumeLastTrade,
    volumeMessage,
    volumeNextTrade,
    volumeStatus,
  };
  const counterModeProps: DEXCommonCounterInfo = {
    counterMessage,
    counterStatus,
    counterLastTrade,
  };

  return { limitModeProps, volumeModeProps, counterModeProps, otherProps };
};
