import { useEffect, useMemo } from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { NavigationMenuContext } from "src/context/NavigationMenu";
import { IMenuItem, IndicationName } from "./MenuList/MenuItem/types";
import { LayerType } from "../..";

interface UseLoadIndicationProps {
  party?: string;
  uuid?: string;
  currentTab: string;
  menuList: IMenuItem<LayerType>[];
}

const getAllIndicationInCurrentMenu = (menuList: IMenuItem<LayerType>[]) =>
  menuList.map((item) => item.indicationName) as IndicationName[];

export const useLoadIndication = ({
  party,
  uuid,
  currentTab,
  menuList,
}: UseLoadIndicationProps) => {
  const state = useLateInitContext(NavigationMenuContext);

  const filteredItems = menuList.filter(({ indicationName }) => indicationName);

  const allIndicationInCurrentMenu = useMemo(
    () => getAllIndicationInCurrentMenu(filteredItems),
    [filteredItems]
  );

  useEffect(() => {
    state.loadIndication({ party, uuid, currentTab, allIndicationInCurrentMenu });
  }, [state, party, uuid, currentTab, allIndicationInCurrentMenu]);
};
