import { RefObject } from "react";
import { ModeChipProps } from "./shared/ModeChip";
import { DEXCommonBotProps } from "./types";

interface UseBaseModeChipProps
  extends Pick<DEXCommonBotProps, "getInfoContainer" | "showModesInfo" | "botUUID"> {
  containerRef?: RefObject<HTMLDivElement>;
}

export const useBaseModeChipProps = ({
  getInfoContainer,
  showModesInfo,
  botUUID,
  containerRef,
}: UseBaseModeChipProps) => {
  const baseModeChipProps: Omit<ModeChipProps, "mode"> = {
    botId: botUUID,
    showInfo: showModesInfo,
    infoAnchorEl: getInfoContainer?.() ?? containerRef?.current,
  };

  return baseModeChipProps;
};
