import { makeAutoObservable } from "mobx";
import { AccountApi, AccountType, BaseAccountBinding, BotAccountName } from "src/modules/accounts";

export default class AccountBinding<T extends AccountType> {
  private _name: BotAccountName<T>;

  private _uuid: string;

  private _type: T;

  private _savedAccount: AccountApi | null = null;

  private _account: AccountApi | null = null;

  private _loading = false;

  private _error = "";

  constructor({ name, uuid, type, account }: BaseAccountBinding<T>) {
    makeAutoObservable(this);

    this._name = name;
    this._uuid = uuid;
    this._type = type;

    this.setSavedAccount(account);
  }

  setSavedAccount = (account: AccountApi | null) => {
    this._savedAccount = account;
    this._account = account;
  };

  get savedAccount() {
    return this._savedAccount;
  }

  setAccount = (account: AccountApi | null) => {
    this._account = account;
  };

  get account() {
    return this._account;
  }

  saveAccount = () => {
    const currentAccount = this._account;
    if (!currentAccount) return;

    this._savedAccount = currentAccount;
  };

  resetAccount = () => {
    this._account = this._savedAccount;
  };

  get name() {
    return this._name;
  }

  get uuid() {
    return this._uuid;
  }

  get type() {
    return this._type;
  }

  get loading(): boolean {
    return this._loading;
  }

  setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get error(): string {
    return this._error;
  }

  setError = (error: string) => {
    this._error = error;
  };
}
