import { SwitchMenu } from "src/components/shared/SwitchMenu";
import { Label } from "src/components/shared/SwitchMenu/MenuItem/style";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Toggle = styled.div`
  width: 100%;
  height: 29px;
  display: flex;
  justify-content: space-between;
`;

export const ToggleFavoriteWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const BotsSwitching = styled(SwitchMenu)`
  @media (max-width: 950px) {
    ${Label} {
      font-size: 12px;
    }
  }
`;

export const FilterPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 0px;
`;

export const CellFilter = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > div {
    margin: 0px;
  }

  & > svg {
    height: 18px;
    width: 18px;
  }
`;

export const Count = styled.span`
  font-family: "Roboto";
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
  /* color: #1a1a1a; */
  color: ${({ theme }) => theme.textColor};
`;
