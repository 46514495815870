import styled from "styled-components";
import { Header } from "../Header";

interface HeaderMainProps {
  $botTemplateColumnsStyle: string;
}

export const DEXV2HeaderMain = styled(Header)<HeaderMainProps>`
  grid-template-columns: 40px ${({ $botTemplateColumnsStyle }) => $botTemplateColumnsStyle};
`;
