import { Link } from "react-router-dom";
import styled from "styled-components";

export const CrumbLink = styled(Link)`
  color: currentColor;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;
