import { entries } from "src/helpers/utils";
import { isEmptyArray } from "src/helpers/array";
import { QueryParams, QueryStringObj } from "./types";

export const prepareQueryObj = (queryParams: QueryParams) => {
  const preparedQuery: QueryStringObj = {};

  for (const [key, value] of entries(queryParams)) {
    if (value !== null && value !== undefined && !isEmptyArray(value))
      preparedQuery[key] = String(value);
  }

  return preparedQuery;
};
