import { ComponentPropsWithRef } from "react";
import { SelectorValue } from "src/modules/shared";
import { SelectItem, SelectItemProps } from "./SelectItem";
import * as styles from "./style";

export interface SelectPanelProps
  extends ComponentPropsWithRef<"div">,
    Pick<SelectItemProps, "editable" | "removeClick"> {
  readonly selectItems?: SelectorValue[];
}

export const SelectPanel = ({
  editable = true,
  selectItems = [],
  removeClick = () => {},
  ...props
}: SelectPanelProps) => (
  <styles.SelectPanel {...props}>
    {selectItems.map((item) => (
      <SelectItem key={item.value} item={item} removeClick={removeClick} editable={editable} />
    ))}
  </styles.SelectPanel>
);
