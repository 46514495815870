import { Route, Switch } from "react-router-dom";
import { CalendarStory } from "src/components/stories/CalendarStory";
import { enableStories } from "src/environment/env";
import { lazily } from "src/helpers/lazily";

const { ButtonsStory } = lazily(() => import("src/components/stories/ButtonsStory"));
const { OldButtonsStory } = lazily(() => import("src/components/stories/OldButtonsStory"));
const { PNLV2Story } = lazily(() => import("src/components/stories/PNLV2Story"));
const { Stories } = lazily(() => import("src/components/stories"));
const { ToastsStory } = lazily(() => import("src/components/stories/ToastsStory"));
const { GraphStory } = lazily(() => import("src/components/stories/GraphStory"));

const useShowStories = () => {
  const showStories = enableStories;
  return showStories === "true";
};

export const useStoryRouter = () => {
  const showStories = useShowStories();

  if (!showStories) {
    return null;
  }

  // extra wrapper to use story switch router as a part parent router
  // can't male it work without inlining
  const StoryRoute = (
    <Route path="/stories">
      <StoryRouter />
    </Route>
  );

  return StoryRoute;
};

export const StoryRouter = () => (
  <Switch>
    <Route path="/stories/buttons">
      <ButtonsStory />
    </Route>
    <Route path="/stories/old-buttons">
      <OldButtonsStory />
    </Route>
    <Route path="/stories/pnlv2">
      <PNLV2Story />
    </Route>
    <Route path="/stories/toasts">
      <ToastsStory />
    </Route>
    <Route path="/stories/graph">
      <GraphStory />
    </Route>
    <Route path="/stories/calendar">
      <CalendarStory />
    </Route>
    <Route exact path="/stories/">
      <Stories />
    </Route>
  </Switch>
);
