import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";
import { Link } from "react-router-dom";
import { OwnRefProps } from "src/helpers/utils";
import { ButtonOwnProps } from "../Button";
import * as styles from "./style";

type LinkElement = "a" | typeof Link;
export type LinkButtonProps<T extends LinkElement> = Omit<
  ComponentPropsWithoutRef<T>,
  keyof ButtonOwnProps
> &
  OwnRefProps<T> &
  Omit<ButtonOwnProps, "as"> & {
    as?: T;
  };

const LinkButtonInner = <T extends LinkElement = "a">(
  { as, ...props }: LinkButtonProps<T>,
  ref: ForwardedRef<HTMLAnchorElement>
) => {
  const forwardedAs = as ?? "a";
  return <styles.StyledLinkButton<LinkElement> forwardedAs={forwardedAs} ref={ref} {...props} />;
};

export const LinkButton = forwardRef(LinkButtonInner) as <T extends LinkElement = "a">(
  props: LinkButtonProps<T> & { ref?: ForwardedRef<HTMLAnchorElement> }
) => ReturnType<typeof LinkButtonInner>;
