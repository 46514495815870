import { IBotPair } from "src/state/CEX/shared/types";

export type BotNameParts = {
  quote: string;
  base: string;
  exchange: string;
};

/**
 * Converts bot name formatted as USDT_BTC_binance to corresponding parts
 * @param name - bot name, i.e. USDT_BTC_binance
 * @returns BotNameParts or `undefined` if bot name is malformed
 */
export const botNameToParts = (name: string): BotNameParts | undefined => {
  if (!name) return;

  const nameParts = name.split("_");
  if (nameParts.length < 3) return;

  const [quote, base, ...exchangeParts] = nameParts;
  return {
    quote,
    base,
    exchange: exchangeParts.join("_"),
  };
};

export type BotPairParts = {
  quote: string;
  base: string;
};

export const botPairToParts = (pair: string): BotPairParts | undefined => {
  if (!pair) return;

  const pairParts = pair.split("_");
  if (pairParts.length < 2) return;

  const [quote, base] = pairParts;
  return {
    quote,
    base,
  };
};

/**
 *Converts bot parameters to USDT_BTC_binance format
 * @param quote
 * @param base
 * @param exchange
 * @returns market
 */
export const joinMarket = ({
  quote,
  base,
  exchange,
}: {
  quote: string;
  base: string;
  exchange: string;
}) => {
  if (quote && base && exchange) return `${quote}_${base}_${exchange}`;

  return "";
};

/**
 * Join market with bot name
 * @param market - bot market (quote_base_exchange)
 * @param name - bot name set by the user
 * @returns market (name) string
 */
export const joinMarketBotName = (market: string, name: string) =>
  `${market} ${name ? `(${name})` : ""}`;

/** @deprecated use joinPairV2 */
export const joinPair = (quote: string, base: string) => {
  if (quote && base) return `${quote}_${base}`;

  return "";
};

export const joinPairV2 = ({ quote, base }: IBotPair) => {
  if (quote && base) return `${quote}_${base}`;

  return "";
};
