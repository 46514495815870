import { useParams } from "react-router-dom";
import { BotQueryParams } from "src/components/NavigationMenu/MenuContent/shared/MenuList/MenuItem/types";
import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { useAppState } from "src/state";
import { DEXNEBotStore } from "src/state/DEX_NE/bots";
import { useDEXNEChainsInfo } from "../Info";

export const DEXNEBotContext = createLateInitContext<DEXNEBotStore>();

type DEXNEBotProviderProps = ChildrenProps;

export const DEXNEBotProvider = ({ children }: DEXNEBotProviderProps) => {
  const { uuid, party } = useParams<BotQueryParams>();

  const { DEXNERootState } = useAppState();

  const chainsInfoState = useDEXNEChainsInfo();

  const botsStore = useLocalStore(DEXNEBotStore, {
    botUUID: uuid,
    party,
    chainsInfoProvider: chainsInfoState,
    cacheProvider: DEXNERootState,
  });

  return <DEXNEBotContext.Provider value={botsStore}>{children}</DEXNEBotContext.Provider>;
};
