import { useState, useCallback } from "react";

export interface UsePopupStateActions {
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
}

export type UsePopupStateResponse = [boolean, UsePopupStateActions];

export const usePopupState = (defaultValue: boolean = false): UsePopupStateResponse => {
  const [open, setOpen] = useState(defaultValue);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);
  return [open, { onOpen, onClose, onToggle }];
};
