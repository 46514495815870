import { IStreamConfig, SubscriptionType } from "src/state/network/WebSocketHandler";
import { IClientExchangeInfo, IOrderBookOrder } from "src/api/bots/CEX/terminal/types";
import { SocketSupportMap } from "./types";

export const getStreamConfig = (
  type: SubscriptionType,
  pair: string,
  exchange: string
): IStreamConfig => ({
  type,
  payload: {
    pair,
    exchange,
  },
});

export const adjustOrderBookDepth = (arr: IOrderBookOrder[], depth: number) => {
  const deltaLength = arr.length - depth;

  if (deltaLength > 0) {
    return arr.slice(0, depth);
  }
  if (deltaLength < 0) {
    for (let i = 0; i > deltaLength; i -= 1) {
      arr.push({ price: "", amount: "" });
    }
  }

  return arr;
};

export const exchangeInfoToMap = (exchanges: IClientExchangeInfo[]): SocketSupportMap => {
  const map = new Map();

  exchanges.forEach(({ id, websocket }) => {
    map.set(id, websocket);
  });

  return map;
};
