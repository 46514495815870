import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, { useMemo } from "react";
import { Label, labelErrorFromHint } from "../Label";
import * as styles from "./style";

export interface FormFieldControlProps {
  id: string;
  isError: boolean;
}

export interface FormFieldOwnProps {
  control: (props: FormFieldControlProps) => JSX.Element;
  label?: string;
  errorHint?: string;
  labelAfter?: JSX.Element;
  fieldId?: string;
}

export interface FormFieldOuterProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, keyof FormFieldOwnProps> {}

export interface FormFieldProps
  extends FormFieldOwnProps,
    styles.FormFieldContainerProps,
    FormFieldOuterProps {}

export const FormField = observer(
  ({ control, label, errorHint, labelAfter, fieldId, ...props }: FormFieldProps) => {
    const id = useMemo(() => (!fieldId ? nanoid() : fieldId), [fieldId]);

    const labelError = labelErrorFromHint(id, errorHint);
    const isError = Boolean(errorHint);

    return (
      <styles.Container {...props}>
        {label || labelAfter ? (
          <Label htmlFor={id} error={labelError} after={labelAfter}>
            {label}
          </Label>
        ) : null}
        {control({ id, isError })}
      </styles.Container>
    );
  }
);
