import { useParams } from "react-router-dom";
import { MainPagesAbilities, MainRoutes } from "src/app/constants";
import { NavigationTabsIcons } from "src/icons/NavigationTabIcons";
import { IMenuItem } from "../shared/MenuList/MenuItem/types";

interface MainSectionParams {
  section: string;
}

export const MAIN_MENU_ITEMS: IMenuItem<"main">[] = [
  {
    icon: () => NavigationTabsIcons.AllBots(),
    to: () => MainRoutes.AllBotsPage,
    value: "all-bots",
    label: "All Bots",
  },
  {
    abilityName: MainPagesAbilities.MarketCheckView,
    icon: () => NavigationTabsIcons.MarketCheck(),
    to: () => MainRoutes.MarketCheckPage,
    value: "market-check",
    label: "Market Check",
  },
  {
    abilityName: MainPagesAbilities.AccessView,
    icon: () => NavigationTabsIcons.Access(),
    to: () => MainRoutes.AccessPage,
    value: "access",
    label: "Access",
  },
  {
    abilityName: MainPagesAbilities.TeamsView,
    icon: () => NavigationTabsIcons.ManageGroups(),
    to: () => MainRoutes.TeamsPage,
    value: "teams",
    label: "Manage Groups",
  },
  {
    abilityName: MainPagesAbilities.AccountingView,
    icon: () => NavigationTabsIcons.Accounting(),
    to: () => MainRoutes.AccountingPage,
    value: "accounting",
    label: "Accounting",
  },
];

export const useGetMainCurrentTab = () => {
  const { section } = useParams<MainSectionParams>();

  return section;
};

export const useGetMainPathParams = () => undefined;
