import { observer } from "mobx-react-lite";
import { NavigationMenuContext } from "src/context/NavigationMenu";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { INDICATE_TOOLTIP_ID } from "src/components/NavigationMenu/consts";
import { Indicator } from "./Indicator";
import { IndicationName } from "./types";
import * as styles from "./style";

interface ItemProps {
  value: string;
  indicationName?: IndicationName;
  isActiveTab: boolean;
  linkPath: string;
  label: string;
  icon?: () => JSX.Element;
}

export const Item = observer(
  ({ value, indicationName, isActiveTab, linkPath, label, icon }: ItemProps) => {
    const state = useLateInitContext(NavigationMenuContext);
    const indication = state.getIndication(indicationName);

    return (
      <styles.MenuItem
        key={value}
        isSelected={isActiveTab}
        data-tooltip-content={indication?.message}
        data-tooltip-id={INDICATE_TOOLTIP_ID}
      >
        <Indicator statusColor={indication?.statusColor} />

        <styles.NavLink to={linkPath} replace={isActiveTab}>
          <styles.IconWrapper isSelected={isActiveTab}>{icon ? icon() : null}</styles.IconWrapper>

          <styles.Label isSelected={isActiveTab}>{label}</styles.Label>
        </styles.NavLink>
      </styles.MenuItem>
    );
  }
);
