import { NewUser } from "src/modules/userManager";
import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from "..";

export const getUsers = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/users"),
    method: "GET",
  });

export const toggleUser = async (userName: string, is_active: boolean) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/users/${userName}/active`),
    method: "PUT",
    data: {
      is_active,
    },
  });

export const addUser = async (user: NewUser) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/users"),
    method: "POST",
    data: user,
  });

export const changePassword = (userName: string, password: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/users/${userName}/password`),
    method: "PUT",
    data: {
      password,
    },
  });

export const changeEmail = (userName: string, email: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/users/${userName}/email`),
    method: "PUT",
    data: {
      email,
    },
  });

export const changeTgHandler = async (userName: string, tg_handler: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/users/${userName}/tg_handler`),
    method: "PUT",
    data: {
      tg_handler,
    },
  });
