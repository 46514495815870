import { Subtitle } from "src/components/shared/Typography";
import styled from "styled-components";

export const MenuList = styled.ul`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  padding: 12px 0;
  margin: 0;
`;

export const AdditionalSection = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  padding-top: 8px;
  gap: 15px;

  border-top: 1px solid rgba(145, 158, 171, 0.2);
`;

export const SectionTitle = styled(Subtitle)``;
