import { selectorValueToString, stringToSelectorValue } from "src/helpers/forms/selectors";
import { stringToBoolean } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import { BooleanString } from "src/modules/shared/global";

export const mapAndJoin = (arr: SelectorValue[]) =>
  arr.length ? arr.map(selectorValueToString).join(",") : null;

export const splitAndMap = (str: string) => str.split(",").map(stringToSelectorValue);

export const shouldToggleStatus = (currentStatus: Boolean, booleanStringStatus?: BooleanString) =>
  booleanStringStatus && stringToBoolean(booleanStringStatus) !== currentStatus;
