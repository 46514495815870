import { Simplify } from "src/helpers/utils";
import { MultiGridStatus } from "src/state/CEX/shared/types";
import { CEXBotStatus } from "../bots";

export const CONDITION_MODULE_TYPES = [
  "price_abs",
  "volume_abs",
  "spread_abs",
  "time",
  "bot_status",
  "price_change",
  "volume_change",
  "volatility_abs",
  "balance_abs",
  "balance_change",
  "balance_delta",
  "depth_abs",
  "price_exchange_pnl",
  "price_account_pnl",
  // "pnl_abs",
  // "multigrinder_status",
  "settings_value",
  "multigrinder_status",
] as const;

export const CONDITION_MODULE_GROUPS_MAP = {
  abs_modules: ["price_abs", "volume_abs", "spread_abs"] as const,
  timeframe_modules: ["price_change", "volume_change", "volatility_abs"] as const,
  time: ["time"] as const,
  bot_status: ["bot_status"] as const,
  balance_abs: ["balance_abs"] as const,
  balance_change_modules: ["balance_change", "balance_delta"] as const,
  depth_abs: ["depth_abs"] as const,
  // price_exchange_pnl: ["price_exchange_pnl"] as const,
  // price_account_pnl: ["price_account_pnl"] as const,
  // pnl_abs: ["pnl_abs"] as const,
  settings_value: ["settings_value"] as const,
  multigrinder_status: ["multigrinder_status"] as const,
};

export type ConditionModuleGroupeMap = typeof CONDITION_MODULE_GROUPS_MAP;

export type ConditionModuleGroupsNames = keyof ConditionModuleGroupeMap;

export type ConditionModuleGroup<T extends ConditionModuleGroupsNames> =
  ConditionModuleGroupeMap[T][number];

export const CONDITION_MODULE_GROUPS = Object.keys(
  CONDITION_MODULE_GROUPS_MAP
) as ConditionModuleGroupsNames[];

export type ConditionTypes = (typeof CONDITION_MODULE_TYPES)[number];

export const ACTIONS_MODULE_TYPES = ["alert_tg", "trade", "change_settings"] as const;

export const STRATEGY_EXECUTION_ORDER_TYPES = ["sync", "async"] as const;

export const STRATEGY_STATUSES = ["active", "stopped", "expired", "failed", "done"] as const;

export const MODULE_COMPARE_VALUES = ["lte", "lt", "eq", "ne", "gt", "gte"] as const;

export const DEPTH_MODES = ["all", "our", "org"] as const;

export const SIDES = ["buy", "sell"] as const;

type SideType = (typeof SIDES)[number];

export type ConditionCompareType = (typeof MODULE_COMPARE_VALUES)[number];

export type ActionTypes = (typeof ACTIONS_MODULE_TYPES)[number];

export type DepthModeType = (typeof DEPTH_MODES)[number];

export interface AbsModuleParams {
  exchange: string;
  pair: string;
}

export interface SpreadAbsParams extends AbsModuleParams {}

export interface BotStatusParams {
  bot_uuid: string;
  status: CEXBotStatus | "";
}

export interface PriceChangeParams extends AbsModuleParams {
  time: string;
}

export interface VolumeChangeParams extends PriceChangeParams {}

export interface VolatilityAbsParams extends PriceChangeParams {}

interface BalanceModuleMainParams {
  accounts: string[];
  currency: string;
}
export interface BalanceAbsParams extends BalanceModuleMainParams {}

export interface BalanceChange extends BalanceModuleMainParams {
  timeframe: string;
}

export interface BalanceDelta extends BalanceChange {}

export interface DepthAbs {
  exchange: string;
  pair: string;
  percent: string;
  mode: DepthModeType | "";
}

export interface PnLAbs {}

export interface MultigrinderStatus {
  multigrinder_uuid: string;
  status: MultiGridStatus | "";
}

export interface PriceExchangePnl {
  exchanges: string[];
  side: SideType | "";
  timeframe: string;
}

export interface PriceAccountPnl {
  accounts: string[];
  side: SideType | "";
  timeframe: string;
}

export interface ChangeSettings {
  bot_uuid: string;
}

export interface TradeParams {
  exchange: string;
  pair: string;
  account_uuid: string;
}

export interface AlertTGParams {
  username: string;
}

export type StrategyModuleCategory = "condition" | "action";

export type StrategyModuleTypes = ConditionTypes | ActionTypes;

export type StrategyCategoryModuleTypes<T extends StrategyModuleCategory> = T extends "condition"
  ? ConditionTypes
  : T extends "action"
  ? ActionTypes
  : never;

export type ConditionModuleMap = {
  price_abs: AbsModuleParams;
  volume_abs: AbsModuleParams;
  spread_abs: SpreadAbsParams;
  time: {};
  bot_status: BotStatusParams;
  price_change: PriceChangeParams;
  volume_change: VolumeChangeParams;
  volatility_abs: VolatilityAbsParams;
  balance_abs: BalanceAbsParams;
  balance_delta: BalanceDelta;
  balance_change: BalanceChange;
  depth_abs: DepthAbs;
  price_exchange_pnl: PriceExchangePnl;
  price_account_pnl: PriceAccountPnl;
  settings_value: ChangeSettings;
  multigrinder_status: MultigrinderStatus;
};

export type ActionModulesMap = {
  trade: TradeParams;
  alert_tg: AlertTGParams;
  change_settings: ChangeSettings;
};

export type StrategyModulesHardParams =
  | ConditionModuleMap[keyof ConditionModuleMap]
  | ActionModulesMap[keyof ActionModulesMap];

export interface ConditionModuleSoftParam {
  key: string;
  compare: ConditionCompareType | "";
  value: string;
}

export interface TradeModuleSoftParams {
  side: SideType | "";
  price: number | "";
  amount: number | "";
}

export interface AlertTgSoftParams {
  text: string;
}

export interface ChangeSettingsSoftParams {
  settings: string;
}

export interface ConditionSettingsValueSoftParams extends ConditionModuleSoftParam {
  compare: "eq";
}

// TYPES
// decimal - "1000.123" (signed float)
// timestamp - "1704042000" (unsigned integer)
// timeframe - "1h30s" (formatted duration)

// CONDITION SOFT PARAMETERS
// Time time [timestamp] - "1704042000"
// Bot Status bot_status [timeframe] - "1h30s"
// Multigrinder Status multigrinder_status [timeframe] - "1h30s"
// Price Abs price_abs [decimal] - "1000.123"
// Price Change price_change [decimal] - "1000.123"
// BalanceAbs balance_abs [decimal] - "1000.123"
// BalanceChange balance_change [decimal] - "1000.123"
// BalanceDelta balance_delta [decimal] - "1000.123"
// VolumeAbs volume_abs [decimal] - "1000.123"
// VolumeChange volume_change [decimal] - "1000.123"
// VolatilityAbs volatility_abs [decimal] - "1000.123"
// DepthAbs depth_abs [decimal] - "1000.123"
// Price Exchange PNL price_exchange_pnl [decimal] - "1000.123"
// Price Account PNL price_account_pnl [decimal] - "1000.123"

export type ConditionModuleSoftMap = {
  price_abs: ConditionModuleSoftParam;
  volume_abs: ConditionModuleSoftParam;
  spread_abs: ConditionModuleSoftParam;
  time: ConditionModuleSoftParam;
  bot_status: ConditionModuleSoftParam;
  price_change: ConditionModuleSoftParam;
  volume_change: ConditionModuleSoftParam;
  volatility_abs: ConditionModuleSoftParam;
  balance_abs: ConditionModuleSoftParam;
  balance_delta: ConditionModuleSoftParam;
  balance_change: ConditionModuleSoftParam;
  depth_abs: ConditionModuleSoftParam;
  price_exchange_pnl: ConditionModuleSoftParam;
  price_account_pnl: ConditionModuleSoftParam;
  settings_value: ConditionSettingsValueSoftParams;
  multigrinder_status: ConditionModuleSoftParam;
};

export type ActionModulesSoftMap = {
  trade: TradeModuleSoftParams;
  alert_tg: AlertTgSoftParams;
  change_settings: ChangeSettingsSoftParams;
};

type ModuleHardParams<T extends StrategyModuleTypes> = T extends ConditionTypes
  ? ConditionModuleMap[T]
  : T extends ActionTypes
  ? ActionModulesMap[T]
  : never;

type ModuleSoftParams<T extends StrategyModuleTypes> = T extends ConditionTypes
  ? ConditionModuleSoftMap[T]
  : T extends ActionTypes
  ? ActionModulesSoftMap[T]
  : never;

type BaseActionCreatedModuleParams<Types extends StrategyModuleTypes> = {
  uuid: string;
  type: Types;
  name: string;
  hard_params: ModuleHardParams<Types>;
  soft_params: ModuleSoftParams<Types>;
};

type BaseConditionCreatedModuleParams<Types extends StrategyModuleTypes> = {
  uuid: string;
  type: Types;
  name: string;
  current: string | null;
  updated_at: number | null;
  hard_params: ModuleHardParams<Types>;
  soft_params: ModuleSoftParams<Types>;
};

export type DistributeStrategyActionCreatedModule<T extends ActionTypes> =
  T extends StrategyModuleTypes ? BaseActionCreatedModuleParams<T> : never;

export type DistributeStrategyConditionCreatedModule<T extends ConditionTypes> =
  T extends StrategyModuleTypes ? BaseConditionCreatedModuleParams<T> : never;

export type ConditionCreatedModule = DistributeStrategyConditionCreatedModule<ConditionTypes>;

export type ActionCreatedModule = DistributeStrategyActionCreatedModule<ActionTypes>;

export type StrategyCreatedModule = ConditionCreatedModule | ActionCreatedModule;

export type ExecutionOrderType = (typeof STRATEGY_EXECUTION_ORDER_TYPES)[number];

export type StrategyStatus = (typeof STRATEGY_STATUSES)[number];

export interface IStrategyStore {
  uuid: string;
  name: string;
  launches_count: number;
  launches_limit: number;
  launches_completed: number;
  cooldown: number;
  execution_order: ExecutionOrderType;
  status: StrategyStatus;
  expression: string;
  expired_at: number;
  created_at: number;
  conditions: ConditionCreatedModule[];
  actions: ActionCreatedModule[];
  error_msg?: string;
}

export type StrategyKeys = keyof IStrategyStore;

// INFO MODULES

type BaseModuleInfo<T extends StrategyModuleTypes, P> = {
  uuid: string;
  name: string;
  unique_name: string;
  current: string;
  linked: boolean;
  linked_active: boolean;
  created_at: number;
  updated_at: number;
  type: T;
  hard_params: ModuleHardParams<T>;
} & P;

type DistributiveModuleInfo<T extends StrategyModuleTypes, P> = T extends StrategyModuleTypes
  ? BaseModuleInfo<T, P>
  : never;

export type IConditionModuleInfo = Simplify<DistributiveModuleInfo<ConditionTypes, {}>>;

export type IActionModuleInfo = Simplify<DistributiveModuleInfo<ActionTypes, { enabled: boolean }>>;

export type IStrategyModuleInfo = IConditionModuleInfo | IActionModuleInfo;

export type IStrategyCategoryModuleInfo<T extends StrategyModuleCategory> = T extends "condition"
  ? IConditionModuleInfo
  : T extends "action"
  ? IActionModuleInfo
  : never;

// CREATE MODULES

type BaseModuleCreate<T extends StrategyModuleTypes, P> = Pick<
  BaseModuleInfo<T, P>,
  "name" | "hard_params" | "type"
> & {
  party: string;
};

type DistributiveModuleCreate<T extends StrategyModuleTypes, P> = T extends StrategyModuleTypes
  ? BaseModuleCreate<T, P>
  : never;

export type IConditionModuleCreate = Simplify<DistributiveModuleCreate<ConditionTypes, {}>>;

export type IActionModuleCreate = Simplify<
  DistributiveModuleCreate<ActionTypes, { enabled: boolean }>
>;

export type IStrategyModuleCreate = IConditionModuleCreate | IActionModuleCreate;

export type IStrategyModuleCreateType<T extends StrategyModuleTypes> = Extract<
  IStrategyModuleCreate,
  { type: T }
>;

export type StrategyModuleGroupCreateType<T extends ConditionModuleGroupsNames> =
  IStrategyModuleCreateType<ConditionModuleGroup<T>>;

// STRATEGIES

export interface IConditionStrategyCreatorModule {
  uuid: string;
  type: ConditionTypes;
  soft_params: ConditionModuleSoftParam | ConditionSettingsValueSoftParams;
}

export interface IActionStrategyCreatorModule {
  uuid: string;
  type: ActionTypes;
  soft_params: TradeModuleSoftParams | AlertTgSoftParams | ChangeSettingsSoftParams;
}

export interface IStrategyCreator {
  conditions: IConditionStrategyCreatorModule[];
  cooldown: number | "";
  execution_order: ExecutionOrderType | "";
  expired_at: number | "";
  expression: string;
  launches_limit: number | "";
  name: string;
  party: string;
  actions: IActionStrategyCreatorModule[];
  status: StrategyStatus;
}

export type StrategyCreatorKeys = keyof IStrategyCreator;

export const isConditionType = (type: StrategyModuleTypes): type is ConditionTypes =>
  CONDITION_MODULE_TYPES.includes(type as ConditionTypes);

export const isActionType = (type: StrategyModuleTypes): type is ActionTypes =>
  ACTIONS_MODULE_TYPES.includes(type as ActionTypes);

const MODULES_WITH_ACCOUNTS = [
  "balance_abs",
  "balance_change",
  "balance_delta",
  "price_account_pnl",
] as const;

export type ModuleWithAccounts = (typeof MODULES_WITH_ACCOUNTS)[number];

export const isModuleWithAccounts = (
  module: IConditionModuleInfo
): module is Simplify<DistributiveModuleInfo<ModuleWithAccounts, {}>> =>
  MODULES_WITH_ACCOUNTS.includes(module.type as ModuleWithAccounts);
