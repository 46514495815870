import { IStringSelectorDataProvider } from "./StringSelectorStore";

export default class SelectorProvider implements IStringSelectorDataProvider {
  private _getData: () => string[];

  constructor(getData: () => string[]) {
    this._getData = getData;
  }

  get data(): string[] {
    return this._getData();
  }
}
