import { GetSupportedExchangesResponse, SupportedExchange } from "src/api/bots/DEX_NE/chains";
import { Mapper, entries } from "src/helpers/utils";
import { DEXNEChainExchangesMap, DEXNEExchangeInfo, DEXNEExchangeInfosMap } from "./types";

const supportedExchangeRespToExchangeInfo = (
  chain: string,
  name: string,
  { version, pool_percent }: SupportedExchange
): DEXNEExchangeInfo => ({
  name,
  chain,
  dexVersion: version,
  poolPercents: pool_percent,
});

const supportedExchangesToExchangeInfos = (
  chain: string,
  exchanges: SupportedExchange[]
): DEXNEExchangeInfosMap => {
  const dexExchanges: DEXNEExchangeInfosMap = Object.fromEntries(
    exchanges.map((exchange) => [
      exchange.name,
      supportedExchangeRespToExchangeInfo(chain, exchange.name, exchange),
    ])
  );
  return dexExchanges;
};

export const supportedExchangesRespToChainsExchanges: Mapper<
  GetSupportedExchangesResponse,
  DEXNEChainExchangesMap
> = (chainExchanges) => {
  const chainDexMapsEntries = entries(chainExchanges.data).map(([chain, exchanges]) => {
    const exchangesInfo = supportedExchangesToExchangeInfos(chain, exchanges.exchanges);
    return [chain, exchangesInfo] as const;
  });
  const chainDexMaps = Object.fromEntries(chainDexMapsEntries);

  return chainDexMaps;
};
