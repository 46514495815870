import { object, number } from "yup";
import { QueryParams, UrlParamsSchema } from "src/state/shared/UrlSearchParams/types";

export interface UrlParams extends QueryParams {
  from: number | null;
  to: number | null;
}

export const searchParamsValidationSchema: UrlParamsSchema<UrlParams> = object({
  from: number().positive().integer(),
  to: number().positive().integer(),
});
