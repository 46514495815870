import { makeAutoObservable } from "mobx";
import { logError } from "src/helpers/network/logger";
import { ADDRESS_ZERO, AbstractToken } from "src/state/shared/DEX/Swap";
import { TradeToken } from "src/state/shared/DEX/entities/TradeToken";
import { IBotTickersProvider } from "src/state/shared/DEX/providers/types";
import { TradePair } from "../../shared/DEX/entities/TradePair";
import { ITradePairProviderDEXNE } from "../Swap/Widget/SwapInfo/types";

interface IDEXNETradePairParams {
  tickersProvider: IBotTickersProvider;
}

/** Provider returning static 18 decimals tokens since no tokens info is available on dex ne */
export class DEXNETradePairProviderStore implements ITradePairProviderDEXNE {
  private _tradePair: TradePair | null = null;

  private _tickersProvider: IBotTickersProvider;

  private _typeTokenToSell: "quote" | "base";

  constructor({ tickersProvider }: IDEXNETradePairParams) {
    makeAutoObservable(this);
    this._tickersProvider = tickersProvider;
    this._typeTokenToSell = "quote";
  }

  private _setTradePair = (base: TradeToken, quote: TradeToken) => {
    this._tradePair = new TradePair(base, quote);
  };

  get typeTokenToSell() {
    return this._typeTokenToSell;
  }

  get tradePair() {
    return this._tradePair;
  }

  private get _tickers() {
    return this._tickersProvider.tickers ?? { base: "BASE", quote: "QUOTE" };
  }

  private get _addresses() {
    return this._tickersProvider.addresses;
  }

  switchTypeTokenToSell = () => {
    if (this._typeTokenToSell === "base") {
      this._typeTokenToSell = "quote";
    } else {
      this._typeTokenToSell = "base";
    }
  };

  getTradePair = async () => {
    try {
      const { base, quote } = this._tickers;

      if (this._addresses) {
        const { base: baseAddress, quote: quoteAddress } = this._addresses;

        const baseToken = new AbstractToken({
          name: baseAddress,
          symbol: base,
          address: `${ADDRESS_ZERO.slice(0, -1)}1`,
        });

        const quoteToken = new AbstractToken({
          name: quoteAddress,
          symbol: quote,
          address: `${ADDRESS_ZERO.slice(0, -1)}2`,
        });

        this._setTradePair(
          TradeToken.fromToken(baseToken, "base"),
          TradeToken.fromToken(quoteToken, "quote")
        );
      }
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}
