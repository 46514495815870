const WHEN_TIMEOUT_ERROR_MESSAGE = "WHEN_TIMEOUT";

const WHEN_CANCELLED_ERROR_MESSAGE = "WHEN_CANCELLED";

export type WhenTimeoutError = Error;

export type WhenCancelledError = Error;

export const isWhenTimeoutError = (error: unknown): error is WhenTimeoutError =>
  (error as any)?.message === WHEN_TIMEOUT_ERROR_MESSAGE;

export const isWhenCancelledError = (error: unknown): error is WhenCancelledError =>
  (error as any)?.message === WHEN_CANCELLED_ERROR_MESSAGE;
