import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import useLocalStore from "src/hooks/useLocalStore";
import { useTimezone } from "src/hooks/useTimezone";
import ClockStore from "src/state/time/ClockStore";
import * as styles from "./style";

const useClockState = () => {
  const timezoneState = useTimezone();
  const state = useLocalStore(ClockStore, { timezone: timezoneState });
  return state;
};

const UTC_INFO_TEXT = "All times displayed and stored on this platform are in UTC!";

export interface ClockProps extends ComponentPropsWithoutRef<"div"> {}

export const Clock = observer((props: ClockProps) => {
  const { time, utcOffset } = useClockState();

  return (
    <styles.Container {...props}>
      <styles.ClockText>{time} (UTC)</styles.ClockText>
      <styles.UTCInfoPrompt text={UTC_INFO_TEXT} />
      <styles.UTCOffsetText>{utcOffset}</styles.UTCOffsetText>
    </styles.Container>
  );
});
