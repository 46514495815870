import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { Row } from "@tanstack/react-table";
import { IAccountCred } from "src/api/bots/CEX/terminal/types";
import { joinPair } from "src/helpers/botName";
import { logError } from "src/helpers/network/logger";
import {
  cancelLimitOrdersByAcc,
  cancelOrder,
  LimitOrderRequestProps,
  fetchExtendedLimitOrders,
} from "src/api/bots/CEX/terminal";
import { SubscribableStore } from "src/helpers/utils";
import windowConsent from "src/state/WindowConsent";
import { TooltipOrdersCounters } from "src/components/shared/ui/cex/orders/HeadTooltip";
import { ArbitrageStore } from "..";
import { ArbitrageTerminalModules } from "../types";
import { IExtendedLimitOrderStatus, IExtendedLimitOrderStatusView } from "./types";
import { getRequestPropsFromOrder, placedLimitOrdersMap } from "./mappers";

export class PlacedOrdersStore implements SubscribableStore {
  private _mainState: ArbitrageStore;

  private _orders: IExtendedLimitOrderStatus[] = [];

  private _selectedOrders: IExtendedLimitOrderStatusView[] = [];

  private _selectMarketsReaction?: IReactionDisposer;

  private _isLoading = false;

  constructor(state: ArbitrageStore) {
    makeAutoObservable(this);

    this._mainState = state;
    this._mainState.addUpdater(ArbitrageTerminalModules.OrdersTable, this.loadData);
  }

  get accountsScope(): IAccountCred[] {
    const selectAccounts: IAccountCred[] = [];

    this._selectMarkets.forEach(({ market: { exchange, quote, base } }) => {
      const accounts = this._exchangeAccountsMap[exchange];
      const pair = joinPair(quote, base);

      if (accounts) {
        accounts.forEach(({ uuid }) => {
          selectAccounts.push({ exchange, account_uuid: uuid, pair });
        });
      }
    });

    return selectAccounts;
  }

  get orders(): IExtendedLimitOrderStatusView[] {
    return this._orders.map(({ accountUUID, pair: { quote, base }, ...rest }) => ({
      accountName: this._accountsMap[accountUUID].name,
      accountUUID,
      pair: joinPair(quote, base),
      ...rest,
    }));
  }

  get tooltipInfo(): TooltipOrdersCounters {
    return {
      allOrdersCount: this._allOrdersCounter,
      buyOrdersCount: this._buyOrdersCounter,
      sellOrdersCount: this._sellOrdersCounter,
    };
  }

  get cancelActive() {
    return !!this._selectedOrders.length;
  }

  get isLoading() {
    return this._isLoading;
  }

  private get _selectMarkets() {
    return this._mainState.selectMarkets;
  }

  private get _exchangeAccountsMap() {
    return this._mainState.exchangeAccountsMap;
  }

  private get _accountsMap() {
    return this._mainState.accountsMap;
  }

  private get _allOrdersCounter() {
    return this._orders.length;
  }

  private get _buyOrdersCounter() {
    return this._orders.filter(({ side }) => side === "buy").length;
  }

  private get _sellOrdersCounter() {
    return this._orders.filter(({ side }) => side === "sell").length;
  }

  private get _cancelPropsList(): LimitOrderRequestProps[] {
    return this._selectedOrders.map(getRequestPropsFromOrder);
  }

  private get _selectedOrdersCount() {
    return this._selectedOrders.length;
  }

  private get _updateAllData() {
    return this._mainState.updateFetchData;
  }

  loadData = () => {
    if (!this.accountsScope.length) {
      this._resetOrders();
    } else this._getOrders();
  };

  subscribe = () => {
    this._selectMarketsReaction = reaction(
      () => this._selectMarkets,
      () => {
        this.loadData();
      }
    );
  };

  unsubscribe = () => {
    this._selectMarketsReaction?.();
  };

  setSelectedOrders = (tableRowOrders: Row<IExtendedLimitOrderStatusView>[]) => {
    const selectedOrders = tableRowOrders.map(({ original }) => original);

    this._selectedOrders = selectedOrders;
  };

  cancelOrders = () => {
    if (this._selectedOrdersCount > 1) {
      windowConsent.showWindow(
        "",
        `Are you sure you want to cancel ${
          this._selectedOrdersCount === this._orders.length ? "all" : this._selectedOrdersCount
        } orders?`,
        this._cancelOrders,
        this._cancelPropsList
      );
    } else {
      this._cancelOrders(this._cancelPropsList);
    }
  };

  cancelOneOrder = async (order: IExtendedLimitOrderStatusView, onClose?: () => void) => {
    const cancelProps = getRequestPropsFromOrder(order);

    const isError = await this._cancelOneOrder(cancelProps);

    if (!isError) onClose?.();
  };

  private _setOrders = (data: IExtendedLimitOrderStatus[]) => {
    this._orders = data;
  };

  private _setIsLoading = (value: boolean) => {
    this._isLoading = value;
  };

  private _resetOrders = () => {
    this._orders = [];
    this._selectedOrders = [];
  };

  private _getOrders = async () => {
    this._setIsLoading(true);

    try {
      const { isError, data } = await fetchExtendedLimitOrders(this.accountsScope);

      if (!isError) {
        const mappedData = placedLimitOrdersMap(data.data);

        this._setOrders(mappedData);
      } else this._resetOrders();
    } catch (error) {
      this._resetOrders();
      logError(error);
    } finally {
      this._setIsLoading(false);
    }
  };

  private _cancelOrders = async (data: LimitOrderRequestProps[]) => {
    this._setIsLoading(true);

    try {
      const { isError } = await cancelLimitOrdersByAcc(data);

      if (!isError) {
        this._updateAllData();
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setIsLoading(false);
    }
  };

  private _cancelOneOrder = async (data: LimitOrderRequestProps) => {
    this._setIsLoading(true);

    try {
      const { isError } = await cancelOrder(data);

      if (!isError) {
        this._updateAllData();
      }

      return isError;
    } catch (error) {
      logError(error);
    } finally {
      this._setIsLoading(false);
    }
  };
}
