import { LayerType } from "src/components/NavigationMenu";
import { PermissionWrapper } from "src/components/shared/PermissionWrapper";
import { IMenuItem, LayerPathParams } from "./types";
import { Item } from "./Item";

export interface MenuItemProps<T extends LayerType> {
  item: IMenuItem<T>;
  useGetCurrentTab: () => string | null;
  useGetPathParams: () => LayerPathParams<T>;
}

export const MenuItem = <T extends LayerType>({
  item,
  useGetCurrentTab,
  useGetPathParams,
}: MenuItemProps<T>) => {
  const { label, value, indicationName, to, icon, abilityName } = item;

  const currentTab = useGetCurrentTab();

  const pathParam = useGetPathParams();

  const linkPath = to(pathParam);

  const isActiveTab = currentTab === value;

  const itemProps = { value, indicationName, isActiveTab, linkPath, label, icon };

  return (
    <>
      {abilityName ? (
        <PermissionWrapper abilityName={abilityName}>
          <Item {...itemProps} />
        </PermissionWrapper>
      ) : (
        <Item {...itemProps} />
      )}
    </>
  );
};
