import { makeAutoObservable } from "mobx";
import { getChainsConfig } from "src/api/bots/DEXV2/chain";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable, entries } from "src/helpers/utils";
import { ChainMap } from "../types";
import { chainsConfigRespToChainConfigs, filterEmptyChainConfigs } from "./mappers";
import {
  ChainConfig,
  ChainConfigsMap,
  IAddressHelperMapProvider,
  IBalanceHelperMapProvider,
  IChainMetaProvider,
  IDEXLensProvider,
  INativePriceFeedProvider,
} from "./types";

/**
 * Remap chain configs map based on mapFn
 */
const getConfigByFn = <T>(configsMap: ChainConfigsMap, mapFn: (chainConfig: ChainConfig) => T) =>
  entries(configsMap).reduce((acc, entry) => {
    if (!entry) return acc;
    const [chainId, chainConfig] = entry;
    if (!chainConfig) return acc;

    const newConfig = mapFn(chainConfig);
    acc[chainId] = newConfig;
    return acc;
  }, {} as ChainMap<T>);

export interface IChainsConfigsProvider
  extends IChainMetaProvider,
    INativePriceFeedProvider,
    IDEXLensProvider,
    IBalanceHelperMapProvider,
    IAddressHelperMapProvider {
  get chainConfigs(): ChainConfigsMap;
  getChainConfigs: () => Promise<void>;
}

export class ChainConfigsStore implements IChainsConfigsProvider, IDisposable {
  private _chainConfigs: ChainConfigsMap | null = null;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { chainConfigs: true });
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get chainConfigs(): ChainConfigsMap {
    const chainConfigs = this._chainConfigs;
    if (!chainConfigs) {
      return {};
    }

    return filterEmptyChainConfigs(chainConfigs);
  }

  get chainMetaMap() {
    return getConfigByFn(this.chainConfigs, (chainConfig) => chainConfig.meta);
  }

  get nativePriceFeedMap() {
    return getConfigByFn(this.chainConfigs, (chainConfig) => chainConfig.contracts.nativePriceFeed);
  }

  get dexLensMap() {
    return getConfigByFn(this.chainConfigs, (chainConfig) => chainConfig.contracts.dexLens);
  }

  get balanceHelperMap() {
    return getConfigByFn(this.chainConfigs, (chainConfig) => chainConfig.contracts.balanceHelper);
  }

  get addressHelperMap() {
    return getConfigByFn(this.chainConfigs, (chainConfig) => chainConfig.contracts.addressHelper);
  }

  private _setChainConfigs = (configs: ChainConfigsMap) => {
    this._chainConfigs = configs;
  };

  getChainConfigs = async () => {
    await this._getChainsConfig();
  };

  private _getChainsConfig = async () => {
    if (this._loading || this._chainConfigs) return;

    this._setLoading(true);
    try {
      const { isError, data } = await getChainsConfig();
      if (!isError) {
        const chainConfigs = chainsConfigRespToChainConfigs(data);
        this._setChainConfigs(chainConfigs);
      }
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
