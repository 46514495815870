import { observer } from "mobx-react-lite";
import React, { KeyboardEventHandler, RefObject, useCallback, WheelEventHandler } from "react";
import * as styles from "./style";

export interface InputOwnProps
  extends styles.StyledInputProps,
    Pick<
      React.ComponentPropsWithoutRef<"input">,
      | "type"
      | "value"
      | "onChange"
      | "readOnly"
      | "id"
      | "step"
      | "onBlur"
      | "onFocus"
      | "pattern"
      | "inputMode"
    > {
  after?: null | JSX.Element;
  inputRef?: null | RefObject<HTMLInputElement>;
  preventControlsEdit?: boolean;
}

export interface InputProps
  extends InputOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof InputOwnProps> {}

const KEY_UP = "ArrowUp";
const KEY_DOWN = "ArrowDown";

export const Input = observer(
  ({
    error = false,
    after = null,
    inputRef = null,
    preventControlsEdit = true,
    type,
    value,
    onChange,
    onBlur,
    onFocus,
    pattern,
    inputMode,
    readOnly,
    id,
    step,
    placeholder,
    ...props
  }: InputProps) => {
    const onPreventScroll: WheelEventHandler<HTMLElement> = useCallback((e) => {
      e.currentTarget.blur();
    }, []);

    const onPreventArrows: KeyboardEventHandler<HTMLElement> = useCallback((e) => {
      if (e.key === KEY_UP || e.key === KEY_DOWN) {
        e.preventDefault();
      }
    }, []);

    const preventControlsProps = preventControlsEdit
      ? { onWheel: onPreventScroll, onKeyDown: onPreventArrows }
      : {};

    return (
      <styles.InputWrapper {...props}>
        <styles.Input
          id={id}
          ref={inputRef}
          error={error}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          readOnly={readOnly}
          step={step}
          placeholder={placeholder}
          pattern={pattern}
          inputMode={inputMode}
          {...preventControlsProps}
        />
        {after}
      </styles.InputWrapper>
    );
  }
);
