import { DEXCommonBotsColorStatus } from "src/components/AllBots/Bots/shared/DEXCommon";
import { DEXNEBotStatuses } from "./types";

// interface RawModeInfo {
//   status?: GetBotModeStatus;
//   nextTrade?: number;
//   lastTrade?: number;
//   message?: string;
// }

// type DEXNEBotModeMap = {
//   limit: DEXCommonLimitInfo;
//   volume: DEXCommonVolumeInfo;
// };

// const parseRawTradeTime = (time: number) =>
//   // ignore negative trade unix times from api
//   Math.max(time, 0);

// const parseRawModeInfo = ({
//   status = "stopped",
//   nextTrade = 0,
//   lastTrade = 0,
//   message = "",
// }: RawModeInfo): ModeInfo => ({
//   status,
//   message,
//   nextTrade,
//   lastTrade,
// });

// const botModeStatusRespToStatus = (status: GetBotModeStatus): DEXCommonBotModeStatus => {
//   switch (status) {
//     case "error": {
//       return DEXCommonBotModeStatus.Error;
//     }
//     case "running": {
//       return DEXCommonBotModeStatus.Running;
//     }
//     case "stopped": {
//       return DEXCommonBotModeStatus.Stopped;
//     }
//   }
// };

// export const getLimitModeInfo = ({
//   status: modeStatus,
//   nextTrade,
//   lastTrade,
//   message,
// }: ModeInfo): DEXCommonLimitInfo => {
//   const status = botModeStatusRespToStatus(modeStatus);
//   return {
//     limitStatus: status,
//     limitNextTrade: parseRawTradeTime(nextTrade),
//     limitLastTrade: parseRawTradeTime(lastTrade),
//     limitMessage: message,
//   };
// };

// export const getVolumeModeInfo = ({
//   status: modeStatus,
//   nextTrade,
//   lastTrade,
//   message,
// }: ModeInfo): DEXCommonVolumeInfo => {
//   const status = botModeStatusRespToStatus(modeStatus);
//   return {
//     volumeStatus: status,
//     volumeNextTrade: parseRawTradeTime(nextTrade),
//     volumeLastTrade: parseRawTradeTime(lastTrade),
//     volumeMessage: message,
//   };
// };

// export const getModeInfo = <M extends DEXNEBotMode>(
//   rawInfo: RawModeInfo,
//   mode: M
// ): DEXNEBotModeMap[M] => {
//   const info = parseRawModeInfo(rawInfo);

//   let modeInfo: DEXNEBotModeMap[DEXNEBotMode];
//   switch (mode) {
//     case "limit": {
//       modeInfo = getLimitModeInfo(info);
//       break;
//     }
//     case "volume": {
//       modeInfo = getVolumeModeInfo(info);
//       break;
//     }
//     default: {
//       throw Error("unknown mode type");
//     }
//   }

//   return modeInfo as DEXNEBotModeMap[M];
// };

// export const botTotalStatusRespToStatus = (status: GetBotTotalStatus): DEXCommonBotStatus => {
//   switch (status) {
//     case "warning": {
//       return DEXCommonBotStatus.Warning;
//     }
//     case "error": {
//       return DEXCommonBotStatus.Error;
//     }
//     case "running": {
//       return DEXCommonBotStatus.Running;
//     }
//     case "stopped": {
//       return DEXCommonBotStatus.Stopped;
//     }
//     case "stale": {
//       return DEXCommonBotStatus.Stale;
//     }
//   }
// };

export const getBotColorStatus = (status: DEXNEBotStatuses) => {
  switch (status) {
    case DEXNEBotStatuses.Stopped: {
      return DEXCommonBotsColorStatus.Gray;
    }
    case DEXNEBotStatuses.Error: {
      return DEXCommonBotsColorStatus.Red;
    }
    case DEXNEBotStatuses.Warning: {
      return DEXCommonBotsColorStatus.Yellow;
    }
    case DEXNEBotStatuses.Stale: {
      return DEXCommonBotsColorStatus.Blue;
    }
    case DEXNEBotStatuses.Running: {
      return DEXCommonBotsColorStatus.Green;
    }
    default: {
      return DEXCommonBotsColorStatus.Gray;
    }
  }
};
