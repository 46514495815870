import { throttle } from "lodash";
import { comparer } from "mobx";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";

export interface Size {
  width: number;
  height: number;
}

const INITIAL_SIZE: Size = {
  width: 0,
  height: 0,
};

const isEqual = (prevVal: Size, newVal: Size) => comparer.structural(prevVal, newVal);

const useElementSize = <T extends HTMLElement = HTMLDivElement>(
  throttleResizeMs: number = 150
): [(node: T | null) => void, Size] => {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>(INITIAL_SIZE);

  const handleSize = useCallback(() => {
    setSize((prevSize) => {
      const newSize = {
        width: ref?.offsetWidth || 0,
        height: ref?.offsetHeight || 0,
      };
      // deep compare to avoid unnecessary re-renders
      if (!isEqual(prevSize, newSize)) {
        return newSize;
      }
      return prevSize;
    });
  }, [ref]);

  const throttledHandleSize = useMemo(
    () => throttle(handleSize, throttleResizeMs),
    [handleSize, throttleResizeMs]
  );

  useEffect(() => {
    window.addEventListener("resize", throttledHandleSize);

    return () => {
      throttledHandleSize.cancel();
      window.removeEventListener("resize", throttledHandleSize);
    };
  }, [throttledHandleSize]);

  useLayoutEffect(() => {
    if (!ref) return;

    const resizeObserver = new ResizeObserver(throttledHandleSize);
    resizeObserver.observe(ref);

    throttledHandleSize();

    return () => {
      throttledHandleSize.cancel();
      resizeObserver.disconnect();
    };
  }, [ref, throttledHandleSize]);

  useLayoutEffect(() => {
    handleSize();
  }, [handleSize]);

  return [setRef, size];
};

export default useElementSize;
