import { Tooltip } from "react-tooltip";
import styled from "styled-components";

export const WarningsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WarningsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.botColorStatus.yellow};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WarningsCount = styled.div`
  color: ${({ theme }) => theme.botColorStatus.yellow};

  font-size: 12px;
  line-height: 20px;
  font-weight: ${({ theme }) => theme.fontWeights[400]};
`;

export const StyledTooltip = styled(Tooltip)`
  max-width: 280px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  padding: 6px 8px;
`;

export const Divider = styled.div`
  margin: 8px 0;
  border-bottom: 1px solid #e0e0e0;
`;

export const WarningRow = styled.div`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  word-break: break-word;
`;
