export type DataOrder = "Columns" | "Rows";

const transpose = <T>(matrix: T[][]) =>
  Array.from(matrix[0].keys()).map((colInd) => matrix.map((row) => row[colInd]));

const joiner = (data: string[][], separator = ",", enclosingCharacter = '"') =>
  data
    .map((row) =>
      row.map((column) => `${enclosingCharacter}${column}${enclosingCharacter}`).join(separator)
    )
    .join("\n");

const arrays2csv = (data: string[][]) => joiner(data);

export const toCSV = (data: string[][], headers?: string[], dataOrder: DataOrder = "Rows") => {
  const dataRows = dataOrder === "Columns" ? transpose(data) : data;
  return arrays2csv(headers ? [headers, ...dataRows] : dataRows);
};
