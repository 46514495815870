import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { BotsType } from "src/modules/bots";
import { BooleanString } from "src/modules/shared/global";
import { CEXBotsStore } from "../CEX/CEXBots";
import { DEXBotsStore } from "../DEX/DEXBots";
import { DEXV2BotsStore } from "../DEXV2/DEXV2Bots/DEXV2Bots";
import { DEXNEBotsStore } from "../DEX_NE/bots";
import { IChainsInfoProvider } from "../chain/ChainsInfo";
import { IQueryHistory, ISearchParamsState } from "../shared/UrlSearchParams/types";
import { UrlSearchParamsStore } from "../shared/UrlSearchParams";
import { IDEXNERootProvider } from "../DEX_NE/Providers/RootProvider";
import { searchParamsValidationSchema } from "./validationSchemes";
import { UrlParams } from "./types";
import { mapAndJoin, shouldToggleStatus, splitAndMap } from "./utils";

const STORAGE_KEY = "currentTab";

export class AllBotsStore implements ISearchParamsState<UrlParams> {
  private _realtimeEnabled = true;

  private _currentTab: BotsType = "CEX";

  private _party = "";

  private _cexBotsState: CEXBotsStore;

  private _dexBotsState: DEXBotsStore;

  private _dexV2BotsState: DEXV2BotsStore;

  private _dexNEBotsState: DEXNEBotsStore;

  private _urlSearchParamsState?: UrlSearchParamsStore<UrlParams>;

  private _querySearchParamsReaction?: IReactionDisposer;

  private _switchCurrentTabReaction?: IReactionDisposer;

  constructor(chainsInfo: IChainsInfoProvider, neRootProvider: IDEXNERootProvider, party?: string) {
    if (party) this._setParty(party);

    this._cexBotsState = new CEXBotsStore(party);
    this._dexBotsState = new DEXBotsStore(party);
    this._dexV2BotsState = new DEXV2BotsStore(chainsInfo, party);
    this._dexNEBotsState = new DEXNEBotsStore(neRootProvider, party);

    makeAutoObservable(this);
  }

  get realtimeEnabled() {
    return this._realtimeEnabled;
  }

  switchRealtime = () => {
    this._realtimeEnabled = !this._realtimeEnabled;
  };

  get currentTab() {
    return this._currentTab;
  }

  get isPartyMode() {
    return Boolean(this._party);
  }

  get botsState() {
    const stateByTab = {
      CEX: this._cexBotsState,
      DEX: this._dexBotsState,
      DEXV2: this._dexV2BotsState,
      DEXNE: this._dexNEBotsState,
    };

    return stateByTab[this.currentTab];
  }

  get dexV2BotsState() {
    return this._dexV2BotsState;
  }

  get dexNEBotsState() {
    return this._dexNEBotsState;
  }

  get dexBotsState() {
    return this._dexBotsState;
  }

  get cexBotsState() {
    return this._cexBotsState;
  }

  private get _querySearchParams(): UrlParams {
    const {
      redStatus,
      yellowStatus,
      blueStatus,
      recentlyAddedEnabled,
      selectedParties,
      selectedExchanges,
      selectedBase,
      chainsEnabled,
      dexVersionsEnabled,
    } = this.botsState;

    const redStatusStr = String(redStatus) as BooleanString;
    const yellowStatusStr = String(yellowStatus) as BooleanString;
    const blueStatusStr = String(blueStatus) as BooleanString;
    const recentlyAddedEnabledStr = String(recentlyAddedEnabled) as BooleanString;
    const selectedPartiesStr = mapAndJoin(selectedParties);
    const selectedExchangesStr = mapAndJoin(selectedExchanges);
    const selectedBaseStr = mapAndJoin(selectedBase);
    const selectedChainsStr = chainsEnabled ? mapAndJoin(this.botsState.selectedChains) : null;
    const selectedVersionsStr = dexVersionsEnabled
      ? mapAndJoin(this.botsState.selectedVersions)
      : null;

    return {
      currentTab: this.currentTab,
      redStatus: redStatusStr,
      yellowStatus: yellowStatusStr,
      blueStatus: blueStatusStr,
      recentlyAddedEnabled: recentlyAddedEnabledStr,
      selectedParties: selectedPartiesStr,
      selectedExchanges: selectedExchangesStr,
      selectedBase: selectedBaseStr,
      selectedChains: selectedChainsStr,
      selectedVersions: selectedVersionsStr,
    };
  }

  switchCurrentTab = (value: BotsType) => {
    this._currentTab = value;
    localStorage.setItem(STORAGE_KEY, this.currentTab);
  };

  setUrlParamsState = (searchParamsProps: IQueryHistory) => {
    const localStorageName = !this.isPartyMode ? "allBots" : undefined;

    this._urlSearchParamsState = new UrlSearchParamsStore(this, {
      ...searchParamsProps,
      validationSchema: searchParamsValidationSchema,
      localStorageName,
    });

    this._querySearchParamsReaction?.();
    this._querySearchParamsReaction = reaction(
      () => this._querySearchParams,
      (query) => this._urlSearchParamsState?.setAllQueryUrl(query)
    );

    this._urlSearchParamsState.setAllQueryUrl(this._querySearchParams);
  };

  setInitialQueries = (queryObj: Partial<UrlParams>) => {
    const {
      currentTab,
      redStatus,
      yellowStatus,
      blueStatus,
      recentlyAddedEnabled,
      selectedParties,
      selectedExchanges,
      selectedBase,
      selectedChains,
      selectedVersions,
    } = queryObj;

    if (currentTab) this.switchCurrentTab(currentTab);

    if (!currentTab && this.isPartyMode) {
      const tab = localStorage.getItem(STORAGE_KEY);

      if (tab) this.switchCurrentTab(tab as BotsType);
    }

    this._setInitialStatuses({ redStatus, yellowStatus, blueStatus, recentlyAddedEnabled });
    this._setInitialSelectors({
      selectedParties,
      selectedExchanges,
      selectedBase,
      selectedChains,
      selectedVersions,
    });
  };

  private _setInitialStatuses = ({
    redStatus,
    yellowStatus,
    blueStatus,
    recentlyAddedEnabled,
  }: Partial<UrlParams>) => {
    const {
      redStatus: currentRedStatus,
      yellowStatus: currentYellowStatus,
      blueStatus: currentBlueStatus,
      recentlyAddedEnabled: currentRecentlyAddedEnabled,
      toggleRedStatus,
      toggleYellowStatus,
      toggleBlueStatus,
      toggleRecentlyAddedEnabled,
    } = this.botsState;

    if (shouldToggleStatus(currentRedStatus, redStatus)) toggleRedStatus();

    if (shouldToggleStatus(currentYellowStatus, yellowStatus)) toggleYellowStatus();

    if (shouldToggleStatus(currentBlueStatus, blueStatus)) toggleBlueStatus();

    if (shouldToggleStatus(currentRecentlyAddedEnabled, recentlyAddedEnabled))
      toggleRecentlyAddedEnabled();
  };

  private _setInitialSelectors = ({
    selectedParties,
    selectedExchanges,
    selectedBase,
    selectedChains,
    selectedVersions,
  }: Partial<UrlParams>) => {
    const {
      setSelectedParties,
      setSelectedExchanges,
      setSelectedBases,
      chainsEnabled,
      dexVersionsEnabled,
    } = this.botsState;

    if (selectedParties) setSelectedParties(splitAndMap(selectedParties));

    if (selectedExchanges) setSelectedExchanges(splitAndMap(selectedExchanges));

    if (selectedBase) setSelectedBases(splitAndMap(selectedBase));

    if (selectedChains && chainsEnabled)
      this.botsState.setSelectedChains(splitAndMap(selectedChains));

    if (selectedVersions && dexVersionsEnabled)
      this.botsState.setSelectedVersions(splitAndMap(selectedVersions));
  };

  private _setParty = (party: string) => {
    this._party = party;
  };

  destroy = () => {};

  subscribe = () => {
    this._switchCurrentTabReaction = reaction(
      () => this._currentTab,
      () => {
        if (!this.realtimeEnabled) {
          this.botsState.resumeBotsFetching();
          this.botsState.suspendBotsFetching();
        }
      }
    );
  };

  unsubscribe = () => {
    this._querySearchParamsReaction?.();
    this._switchCurrentTabReaction?.();
  };
}
