import { ColumnSort, SortingState, Updater } from "@tanstack/react-table";
import { makeAutoObservable, runInAction } from "mobx";
import { joinQueryValues } from "src/api";
import {
  FiltersParams,
  GetStrategiesRequestParams,
  StrategiesSortingParams,
  UpdateStrategyLaunchesLimitRequest,
  deleteStrategy,
  extendStrategy,
  getStrategies,
  startStrategy,
  stopStrategy,
  updateStrategyLaunchesLimit,
} from "src/api/expertSystem";
import { PaginationParams } from "src/api/types";
import { OnSortingChange } from "src/components/PartyPages/ExpertSystem/shared";
import { makeLoggable } from "src/helpers/logger";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { entries } from "src/helpers/utils";
import { IStrategyStore } from "src/modules/expertSystem";
import windowConsent from "src/state/WindowConsent";
import { ExpertSystemStore } from "..";
import { StrategyFiltersState } from "./Filters/StrategyFiltersStore";

interface ActionStrategyProps {
  uuid: string;
  name: string;
}

export interface ExpiredStrategyParam {
  expired_at: number | "";
}

export interface UpdateStrategyLaunchesLimitData {
  limit: number | "";
}

const STRATEGIES_SORTING_IDS = ["name", "created_at", "expired_at"] as const;

type StrategiesSortingId = (typeof STRATEGIES_SORTING_IDS)[number];

interface StrategiesColumnSort extends ColumnSort {
  id: StrategiesSortingId;
}

type StrategiesSortingState = StrategiesColumnSort[];

const INITIAL_SORTING_STATE: StrategiesSortingState = [{ id: "created_at", desc: true }];

class StrategyListStore {
  private _mainState: ExpertSystemStore;

  private _strategies: IStrategyStore[] = [];

  private _filtersState?: StrategyFiltersState;

  private _sortingState: StrategiesSortingState = INITIAL_SORTING_STATE;

  private _isLoading = false;

  private _currentPage = 0;

  private _pageSize: number = 10;

  private _pagesCount = 0;

  private _extendLoading = false;

  private _launchesLimitLoading = false;

  constructor(state: ExpertSystemStore) {
    makeAutoObservable(this);

    this._mainState = state;

    makeLoggable(this, { strategies: true });
  }

  get strategies() {
    return this._strategies;
  }

  get filtersState() {
    return this._filtersState;
  }

  get isLoading() {
    return this._isLoading;
  }

  get extendLoading() {
    return this._extendLoading;
  }

  get launchesLimitLoading() {
    return this._launchesLimitLoading;
  }

  get party() {
    return this._mainState.party;
  }

  get filtersCount() {
    const filtersState = this._filtersState;
    if (!filtersState) return 0;

    const totalCount = Object.values(filtersState).reduce(
      (count, values) => count + values.length,
      0
    );

    return totalCount;
  }

  get pagesCount() {
    return this._pagesCount;
  }

  get sortingState() {
    return this._sortingState;
  }

  get pageSize() {
    return this._pageSize;
  }

  get currentPage() {
    return this._currentPage;
  }

  private get _paginationQueryParams(): PaginationParams {
    const currentPage = this._currentPage + 1;
    const pageSize = this._pageSize;
    return {
      page: `${currentPage}`,
      limit: `${pageSize}`,
    };
  }

  private get _filtersQueryParams(): FiltersParams | undefined {
    const filtersState = this._filtersState;
    if (!filtersState) return undefined;

    for (const [filterKey, filterValue] of entries(filtersState)) {
      if (filterValue.length)
        return {
          filter_key: filterKey,
          filter_val: joinQueryValues(filterValue),
        };
    }

    return undefined;
  }

  private get _sortingQueryParams(): StrategiesSortingParams | undefined {
    const [typeSort] = this._sortingState;
    if (!typeSort) return undefined;
    const { id, desc } = typeSort;
    return {
      sort_by: id,
      sort_dir: desc ? "desc" : "asc",
    };
  }

  private get _queryParams(): GetStrategiesRequestParams {
    const paginationParams = this._paginationQueryParams;
    const filtersParams = this._filtersQueryParams;
    const sortingParams = this._sortingQueryParams;

    const queryParams: GetStrategiesRequestParams = {
      pagination: paginationParams,
      filter: filtersParams,
      sort: sortingParams,
    };

    return queryParams;
  }

  getStrategies = async (fromPage: number, pageSize?: number) => {
    await this._getStrategies(fromPage, pageSize);
  };

  refreshStrategies = async () => {
    await this._getStrategies();
  };

  setCurrentPage = (page: number) => {
    this._currentPage = page;
  };

  setPageSize = (pageSize: number) => {
    this._pageSize = pageSize;
  };

  setSortingState = async (updater: Updater<SortingState>) => {
    this._updateSortingState(updater);

    await this.refreshStrategies();
  };

  findStrategy = (uuid: string) => this._strategies.find((el) => el.uuid === uuid);

  updateFilters = async (state: StrategyFiltersState) => {
    this._setFiltersState(state);

    this._resetPagination();

    const isSuccess = await this._getStrategies();

    return isSuccess;
  };

  toggleStrategyHandler = (uuid: string) => {
    const strategyFound = this.findStrategy(uuid);

    if (!strategyFound) return;

    if (strategyFound.status === "active") {
      StrategyListStore._showWarningWhenSwitching(strategyFound, "stop", this._stopStrategy);
    } else {
      StrategyListStore._showWarningWhenSwitching(strategyFound, "start", this._startStrategy);
    }
  };

  removeStrategyHandler = (uuid: string) => {
    const strategyFound = this.findStrategy(uuid);

    if (!strategyFound) return;

    windowConsent.showWindow(
      "",
      `Are you sure you want to remove ${strategyFound?.name} strategy?`,
      this._removeStrategy,
      { uuid, name: strategyFound?.name }
    );
  };

  extendStrategyHandler =
    (uuid: string, closeModalCb: () => void) => (data: ExpiredStrategyParam) => {
      const strategyFound = this.findStrategy(uuid);
      if (!strategyFound) return;

      this._extendStrategy({ uuid, name: strategyFound.name }, data, closeModalCb);
    };

  updateLaunchesLimitHandler =
    (uuid: string, closeModalCb: () => void) => (data: UpdateStrategyLaunchesLimitData) => {
      const strategy = this.findStrategy(uuid);
      if (!strategy) return;

      this._updateStrategyLaunchesLimit({ uuid, name: strategy.name }, data, closeModalCb);
    };

  private _resetPagination = () => {
    this.setCurrentPage(0);
  };

  private _updateSortingState: OnSortingChange = (updater) => {
    if (typeof updater === "function") {
      const newState = updater(this._sortingState);
      this._setSortingState(newState);
    } else {
      this._setSortingState(updater);
    }
  };

  private _setSortingState = (sortingState: SortingState) => {
    this._sortingState = sortingState as StrategiesSortingState;
  };

  private _setFiltersState = (filtersState: StrategyFiltersState) => {
    this._filtersState = filtersState;
  };

  private _setLaunchesLimitLoading = (loading: boolean) => {
    this._launchesLimitLoading = loading;
  };

  private static _showWarningWhenSwitching = (
    strategy: IStrategyStore,
    action: "stop" | "start",
    toggleCb: (strategy: IStrategyStore) => Promise<void>
  ) => {
    windowConsent.showWindow(
      "",
      `Are you sure you want to ${action} ${strategy.name} strategy?`,
      toggleCb,
      strategy
    );
  };

  private _stopStrategy = async ({ uuid, name }: IStrategyStore) => {
    try {
      const { isError } = await stopStrategy(uuid);

      if (!isError) {
        showSuccessMsg(`Strategy ${name} stopped successfully`);
        await this.refreshStrategies();
      }
    } catch (error) {
      logError(error);
    }
  };

  private _startStrategy = async ({ uuid, name }: IStrategyStore) => {
    try {
      const { isError } = await startStrategy(uuid);

      if (!isError) {
        showSuccessMsg(`Strategy ${name} started successfully`);
        await this.refreshStrategies();
      }
    } catch (error) {
      logError(error);
    }
  };

  private _removeStrategy = async ({ uuid, name }: ActionStrategyProps) => {
    try {
      const { isError } = await deleteStrategy(uuid);

      if (!isError) {
        showSuccessMsg(`Strategy ${name} was successfully removed`);
        await this.refreshStrategies();
      }
    } catch (error) {
      logError(error);
    }
  };

  private _getStrategies = async (fromPage?: number, pageSize?: number) => {
    const { party } = this;
    if (!party) return;

    this._updatePaginationParams(fromPage, pageSize);

    runInAction(() => {
      this._isLoading = true;
    });

    try {
      const queryParams = this._queryParams;

      const { isError, data } = await getStrategies(party, queryParams);
      if (!isError) {
        const {
          items: strategies,
          meta: { pages },
        } = data;

        runInAction(() => {
          this._strategies = strategies;
        });

        runInAction(() => {
          this._pagesCount = pages;
        });

        return true;
      }
      this._resetStrategies();

      return false;
    } catch (error) {
      logError(error);
      this._resetStrategies();
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  };

  private _updatePaginationParams = (fromPage?: number, pageSize?: number) => {
    if (fromPage !== undefined) {
      this.setCurrentPage(fromPage);
    }

    if (pageSize) {
      this.setPageSize(pageSize);
    }
  };

  private _extendStrategy = async (
    params: ActionStrategyProps,
    data: ExpiredStrategyParam,
    closeModalCb: () => void
  ) => {
    runInAction(() => {
      this._extendLoading = true;
    });

    try {
      const { isError } = await extendStrategy(data, params.uuid);

      if (!isError) {
        showSuccessMsg(`Strategy ${params.name} has been successfully extended`);
        closeModalCb();

        await this.refreshStrategies();
      }
    } catch (error) {
      logError(error);
    } finally {
      runInAction(() => {
        this._extendLoading = false;
      });
    }
  };

  private _updateStrategyLaunchesLimit = async (
    params: ActionStrategyProps,
    data: UpdateStrategyLaunchesLimitData,
    closeModalCb: () => void
  ) => {
    this._setLaunchesLimitLoading(true);

    const requestData = data as UpdateStrategyLaunchesLimitRequest;

    try {
      const { isError } = await updateStrategyLaunchesLimit(requestData, params.uuid);

      if (!isError) {
        showSuccessMsg(`Strategy ${params.name} launches limit has been successfully updated`);
        closeModalCb();

        await this.refreshStrategies();
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setLaunchesLimitLoading(false);
    }
  };

  private _resetStrategies = () => {
    this._strategies = [];
  };

  destroy = () => {};
}

export default StrategyListStore;
