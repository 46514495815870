import { makeAutoObservable, when } from "mobx";
import { computedFn } from "mobx-utils";
import { cacheComparer, isWhenTimeoutError } from "src/helpers/mobx";
import { logDevError, logError, LogLevel } from "src/helpers/network/logger";
import { IDisposable, WhenReactionPromise } from "src/helpers/utils";
import { IObservableCache } from "src/state/shared/Cache";
import {
  CacheKeyFn,
  CacheQueryFetchOptions,
  ICacheQueryParams,
  ICacheQueryProvider,
  QueryFn,
} from "./types";

export class CacheQueryProvider<P extends object | null, T>
  implements ICacheQueryProvider<T>, IDisposable
{
  private _queryFn: QueryFn<P, T>;

  private _cacheKeyFn: CacheKeyFn<P>;

  private _cacheStore: IObservableCache<T>;

  private _loading = false;

  private _currentWaitPromise?: WhenReactionPromise;

  private _queryParamsProvider: Pick<ICacheQueryParams<P, T>, "queryParams">;

  constructor(params: ICacheQueryParams<P, T>) {
    makeAutoObservable<this, "_getCachedDataByKey" | "_currentWaitPromise">(this, {
      _getCachedDataByKey: false,
      _currentWaitPromise: false,
    });

    const { queryFn, cacheStore, cacheKeyFn } = params;

    this._queryFn = queryFn;
    this._cacheStore = cacheStore;
    this._cacheKeyFn = cacheKeyFn;
    this._queryParamsProvider = params;
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private get _queryParams() {
    return this._queryParamsProvider.queryParams;
  }

  private get _cacheKey() {
    const params = this._queryParams;
    if (!params) return null;
    return this._cacheKeyFn(params);
  }

  private _setCachedData = (data: T) => {
    const cacheKey = this._cacheKey;
    if (!cacheKey) return;

    this._cacheStore.set(cacheKey, data);
  };

  get _cachedData() {
    const cacheKey = this._cacheKey;
    if (!cacheKey) return undefined;

    return this._cacheStore.get(cacheKey);
  }

  private _getCachedDataByKey = computedFn(
    (cacheKey: string | null) => {
      if (!cacheKey) return undefined;
      return this._cacheStore.get(cacheKey);
    },
    {
      equals: cacheComparer<T>(),
    }
  );

  get data() {
    return this._getCachedDataByKey(this._cacheKey);
  }

  get canQuery() {
    return Boolean(this._queryParams) && Boolean(this._cacheKey);
  }

  private _fetchData = async () => {
    const queryParams = this._queryParams;
    if (!queryParams) return;

    const data = await this._queryFn(queryParams);

    return data;
  };

  private _fetchCachedData = async (useCache: boolean) => {
    if (useCache) {
      const cachedData = this._cachedData;

      if (cachedData) {
        return cachedData;
      }
    }

    const data = await this._fetchData();

    return data;
  };

  private _getData = async (useCache = true) => {
    if (!this.canQuery) return;

    const cachedData = await this._fetchCachedData(useCache);
    if (!cachedData) return;

    this._setCachedData(cachedData);
  };

  private async _getWaitData(options: CacheQueryFetchOptions) {
    try {
      const { waitTimeout, ...otherOptions } = options;
      if (waitTimeout) {
        const waitPromise = when(() => this.canQuery, { timeout: waitTimeout });
        this._currentWaitPromise = waitPromise;
        await waitPromise;
      }
      await this._getData(otherOptions.useCache);
    } catch (err) {
      // possible we can reach timeout waiting for deps to settle
      // log warning without showing error to ui
      if (isWhenTimeoutError(err)) {
        logDevError("CacheQueryProvider: timeout when waiting for data, no data will be fetched!", {
          level: LogLevel.Warning,
        });
      } else {
        throw err;
      }
    }
  }

  getData = async (options: CacheQueryFetchOptions = {}) => {
    if (this._loading) return;

    this._setLoading(true);
    try {
      await this._getWaitData(options);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._currentWaitPromise?.cancel();
  };
}
