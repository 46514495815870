import { Headline3 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled(Headline3)`
  align-self: center;

  font-size: ${({ theme }) => theme.fontSizes["14px"]};

  ::first-letter {
    text-transform: capitalize;
  }
`;
