import React from "react";
import { CEXBotWarning } from "src/modules/bots";
import { convertSnakeToTitleCase } from "src/helpers/string";
import { getFormattedTimeNotWork } from "../utils";
import * as styles from "./style";

interface WarningTooltipProps {
  tooltipId: string;
  warnings: CEXBotWarning[];
}

export const WarningTooltip = ({ tooltipId, warnings }: WarningTooltipProps) => (
  <styles.StyledTooltip id={tooltipId}>
    {warnings.map(({ type, msg, downtime }, index) => {
      const isLast = index === warnings.length - 1;
      const titleType = convertSnakeToTitleCase(type);

      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <styles.WarningRow>
            {titleType} - {msg} - {getFormattedTimeNotWork(downtime)}
          </styles.WarningRow>

          {!isLast && <styles.Divider />}
        </React.Fragment>
      );
    })}
  </styles.StyledTooltip>
);
