import { rgba } from "polished";
import styled from "styled-components";

export const VersionSpan = styled.span`
  width: 2em;
  height: 2em;

  padding: 0.25em;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }) => theme.fontSizes["14px"]};
  border: 1px solid ${({ theme }) => theme.labelColor};
  border-radius: ${({ theme }) => theme.radii["6px"]};
  background: ${({ theme }) => rgba(theme.labelColor, 0.2)};
  color: ${({ theme }) => theme.bigTextColor};
  text-transform: uppercase;
`;
