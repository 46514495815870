import { Headline4 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Text = styled(Headline4)`
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
`;
