import { NewUserGroup } from "src/modules/userManager";
import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from "..";

export const getUserGroups = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/groups"),
    method: "GET",
  });

export const addUserGroup = async (userGroup: NewUserGroup) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/groups"),
    method: "POST",
    data: userGroup,
  });

export const getUserGroup = async (name: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/groups/${name}`),
    method: "GET",
  });

export const changeUserGroup = async (name: string, head: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/groups/${name}`),
    method: "PUT",
    data: {
      head,
    },
  });

export const deleteUserGroup = async (name: string): Promise<any> =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/groups/${name}`),
    method: "DELETE",
  });

export const moveUserToUserGroup = async (userName: string, groupName: string): Promise<any> =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, `/users/${userName}/group`),
    method: "PUT",
    data: {
      group: groupName,
    },
  });

export const getTeams = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Auth, "/groups-info"),
    method: "GET",
  });
