import styled from "styled-components";
import { Frame } from "../../shared/shared";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const Favorites = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    cursor: pointer;
  }
`;

export const DEXFrame = styled(Frame)``;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(90px, 185px)) minmax(90px, 100%) minmax(95px, 1fr);
  color: currentColor;
`;

export const CellContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  color: currentColor;
  overflow-y: auto;

  /* :not(:last-of-type) {
    :before {
      position: absolute;
      top: 5px;
      right: 0;
      content: "";
      background: currentColor;
      height: 82%;
      width: 1px;
    }
  } */
`;
