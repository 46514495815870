import { Mapper } from "src/helpers/utils";
import { ChainMetaMap } from "../Info/Chains/types";
import { DEXNEBotStatuses, IDEXNEBot, IDEXNEBotDTO, IListDEXNEBot } from "./types";
import { getBotColorStatus } from "./utils";

export const botResponseToDEXNEBot: Mapper<IDEXNEBotDTO, IDEXNEBot> = ({
  base_address,
  base_ticker,
  quote_address,
  quote_ticker,
  bot_id: bot_uuid,
  chain_name,
  dex_version,
  dex_name,
  bot_name,
  dex_link,
  status,
  party,
  pool_address,

  volume_strategy_status,
  limit_strategy_status,
  counter_strategy_status,
}) => ({
  name: bot_name,
  base: base_ticker,
  quote: quote_ticker,
  baseAddr: base_address,
  botUUID: bot_uuid,
  chainName: chain_name,
  pairAddr: pool_address,
  quoteAddr: quote_address,
  dexVersion: dex_version,
  link: dex_link,
  isStopped: status === DEXNEBotStatuses.Stopped,
  exchange: dex_name,
  status,
  party,

  limit: {
    status: limit_strategy_status.status,
    message: limit_strategy_status.message,
    nextTrade: limit_strategy_status.next_trade,
    lastTrade: limit_strategy_status.last_trade,
  },

  volume: {
    status: volume_strategy_status.status,
    message: volume_strategy_status.message,
    nextTrade: volume_strategy_status.next_trade,
    lastTrade: volume_strategy_status.last_trade,
  },

  counter: {
    status: counter_strategy_status.status,
    message: counter_strategy_status.message,
    nextTrade: counter_strategy_status.next_trade,
    lastTrade: counter_strategy_status.last_trade,
  },
});

export const dexNEBotToListDEXNEBot = (
  bot: IDEXNEBot,
  chainMetaMap: ChainMetaMap
): IListDEXNEBot => {
  const { chainName, base, quote, status } = bot;
  const colorStatus = getBotColorStatus(status);

  return {
    ...bot,
    colorStatus,
    pair: `${quote}_${base}`,
    chainMeta: chainMetaMap[chainName] ?? null,
    isWatched: false,
  };
};
