import { unixToDateFormat } from "src/helpers/dateUtils";
import { getFormattedTimeNotWork } from "src/components/AllBots/Bots/shared/utils";

export const getLastUpdateMsg = (time: number) =>
  `Last update: ${unixToDateFormat(time, "FullDate")}`;

export const geCexSettingsMsg = (timeDontWork?: number, message?: string) => {
  const formattedTime = timeDontWork ? getFormattedTimeNotWork(timeDontWork) : "—";

  return `Downtime: ${formattedTime}\n\nMessage: ${message || "—"}`;
};
