import ResponseHandler, { RequestConfig } from "src/state/network/ResponseHandler";

const BASE_URL = "https://api.dexscreener.com";

const API_PREFIX = "/latest/dex";

type DEXScreenerUrlConfig = Pick<RequestConfig, "baseUrl" | "apiPrefix" | "validateDataScheme">;

const BASE_API_CONFIG: DEXScreenerUrlConfig = {
  baseUrl: BASE_URL,
  apiPrefix: API_PREFIX,
  validateDataScheme: false,
};

const dexScreenerRequestConfig = (
  config: Omit<RequestConfig, keyof DEXScreenerUrlConfig>
): RequestConfig => ({ ...config, ...BASE_API_CONFIG });

export interface Pair {
  chainId: string;
  dexId: string;
  url: string;
  pairAddress: string;
  baseToken: {
    address: string;
    name: string;
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceNative: string;
  priceUsd?: string;
  txns: {
    m5: {
      buys: number;
      sells: number;
    };
    h1: {
      buys: number;
      sells: number;
    };
    h6: {
      buys: number;
      sells: number;
    };
    h24: {
      buys: number;
      sells: number;
    };
  };
  volume: {
    m5: number;
    h1: number;
    h6: number;
    h24: number;
  };
  priceChange: {
    m5: number;
    h1: number;
    h6: number;
    h24: number;
  };
  liquidity?: {
    usd?: number;
    base: number;
    quote: number;
  };
  fdv?: number;
  pairCreatedAt?: number;
}

export interface PairsResponse {
  schemaVersion: string;
  /** @deprecated use pairs field instead */
  pair: Pair | null;
  pairs: Pair[] | null;
}

export const getPairs = async (chainId: string, pairAddresses: string | string[]) =>
  ResponseHandler.handler<PairsResponse>(
    dexScreenerRequestConfig({
      url: `pairs/${chainId}/${pairAddresses}`,
      method: "GET",
    })
  );
