import { Nominal } from "lightweight-charts";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "src/components/shared/Toaster";
import { API_V1_PREFIX } from "src/api";
import { apiUrl } from "src/environment/env";
import { refreshTokenRequestConfig, updateRefreshTokenStorage } from "src/helpers/auth";
import { getCurrentUnix, unixToISOString } from "src/helpers/dateUtils";
import { getRefreshToken, getToken, setAccessToken, setRefreshToken } from "src/helpers/getToken";
import { getRequestUrl } from "src/helpers/url";
import { getOuterSizeWindow } from "src/helpers/window";
import { ApiResponse, RequestMethods } from "src/modules/network";
import { ErrorInfo, ErrorStack } from "src/modules/shared";
import JSONParseError from "./JSONParseError";

const STORAGE_KEY = "errStorage";
const COUNTERS_RESET_INTERVAL_MS = 300000;

export type Authentication =
  | {
      token: string;
    }
  | false
  | undefined;

export interface RequestConfig {
  baseUrl?: string;
  apiPrefix?: string;
  url: string;
  method: RequestMethods;
  data?: any;
  auth?: Authentication;
  headers?: Record<string, string>;
  validateDataScheme?: boolean;
}

export interface ResponseData<Data> {
  response: Response;
  result: Data;
}

interface ErrorData extends ErrorInfo {
  toastId?: string | undefined;
}

export type ApiResponseError = Nominal<string, "ResponseError">;

class ResponseHandler {
  errors: ErrorStack[] = [];

  private _counters: Record<string, number> = {};

  private _timeoutHandler: ReturnType<typeof setTimeout> | null = null;

  constructor() {
    const storedItems = localStorage.getItem(STORAGE_KEY);
    if (storedItems?.length) {
      this.errors = JSON.parse(storedItems);
    }
    makeAutoObservable(this);
    window.addEventListener("beforeunload", () => {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(this.errors));
    });
  }

  // counter for repeated requests
  private _repeatHandler = (toastId: string) => {
    if (!this._timeoutHandler) {
      this._enableResetTimer();
    }

    if (this._counters[toastId]) {
      this._counters[toastId] += 1;
    } else {
      this._counters[toastId] = 1;
    }
  };

  private _enableResetTimer = () => {
    this._timeoutHandler = setTimeout(() => {
      this._resetCounters();
    }, COUNTERS_RESET_INTERVAL_MS);
  };

  private _resetCounters = () => {
    runInAction(() => {
      this._counters = {};
    });

    runInAction(() => {
      this._timeoutHandler = null;
    });
  };

  private _resetTimeout = () => {
    if (!this._timeoutHandler) return;

    clearTimeout(this._timeoutHandler);
    this._timeoutHandler = null;
  };

  private _getAuthToken = (auth?: Authentication) => {
    if (auth === undefined) return getToken();
    if (auth === false) return undefined;
    if (auth.token) return auth.token;
  };

  private _getAuthHeader = (auth?: Authentication) => {
    const token = this._getAuthToken(auth);
    if (!token) return undefined;
    return { Authorization: `Bearer: ${token}` };
  };

  private _getViewPortParam = () => {
    const sizes = getOuterSizeWindow();

    return {
      "Display-Width": `${sizes.width}`,
      "Display-Height": `${sizes.height}`,
    };
  };

  private _fetchApi = async <Data>({
    data,
    auth,
    baseUrl = apiUrl,
    apiPrefix = API_V1_PREFIX,
    url,
    method,
    headers,
  }: RequestConfig): Promise<ApiResponse<ResponseData<Data>, ErrorInfo>> => {
    let response: Response | undefined;

    const requestUrl = getRequestUrl({
      baseUrl: baseUrl ?? "",
      prefix: apiPrefix,
      url,
    });

    const authHeader = this._getAuthHeader(auth);

    const viewPortParams = this._getViewPortParam();

    try {
      const body = data ? JSON.stringify(data) : undefined;

      response = await fetch(requestUrl, {
        method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeader,
          ...headers,
          ...viewPortParams,
        },
        body,
      });

      const responseData = await this._parseJson<Data>(response);

      // fetch success => return response with parsed json data
      return { isError: false, data: { response, result: responseData } };
    } catch (err) {
      // error happened during fetch or json parse
      return {
        isError: true,
        data: {
          message: this._getErrorMessage(err),
          request: response?.url ?? requestUrl,
          status: response?.status ?? null,
          code: "",
        },
      };
    }
  };

  private _parseJson = async <Data>(response: Response) => {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      return json as Data;
    } catch (err) {
      throw new JSONParseError(text);
    }
  };

  private _getErrorMessage = (err: unknown) => {
    if (err instanceof Error) return err.message;
    if (typeof err === "string") return err;
    return "Something went wrong";
  };

  private _isDataError = (success?: boolean, isError?: boolean) => {
    if (isError !== undefined) {
      return isError;
    }

    if (success === undefined) {
      return true;
    }
    if (!success) {
      return true;
    }
    return false;
  };

  // toastId: the parameter is specified for repeated requests to avoid duplicate messages
  handler = async <Data = any>(
    req: RequestConfig,
    toastId?: string | undefined
  ): Promise<ApiResponse<Data, ApiResponseError>> => {
    let apiResponse: Response | undefined;

    try {
      const fetchResult = await this._fetchApi<Data>(req);

      // checking if fetch resulted in error (network, parse json, etc.)
      if (fetchResult.isError) {
        this._throwError({
          toastId,
          ...fetchResult.data,
        });
        return {
          isError: true,
          data: fetchResult.data.message as ApiResponseError,
        };
      }

      let responseData = fetchResult.data;

      // if original request status = 409 => need to refresh token
      if (responseData.response.status === 409) {
        const retryResponse = await this._refreshTokens<Data>(req, responseData.response);

        // checking if refresh token request resulted in error
        // (refresh request itself, or retry request)
        if (retryResponse.isError) {
          this._throwError({
            toastId,
            ...retryResponse.data,
          });
          return {
            isError: true,
            data: retryResponse.data.message as ApiResponseError,
          };
        }

        responseData = retryResponse.data;
      }

      // if resulting request (original or refreshed) status = 401
      // => can't refresh token, need to logout
      if (responseData.response.status === 401) {
        this._logOut();
      }

      if (req.validateDataScheme === false) {
        return { isError: false, data: responseData.result };
      }

      // parsing successful response data
      const { status, error, message, success, isError, data } = responseData.result as any;
      apiResponse = responseData.response;

      const isDataError = this._isDataError(success, isError);

      // check if error in response data object
      if (isDataError) {
        const errorMessage = this._getErrorMessage(error || message);

        this._throwError({
          code: status,
          message: errorMessage,
          toastId,
          status: apiResponse.status,
          request: apiResponse.url,
        });
        return { isError: true, data: errorMessage as ApiResponseError };
      }

      // everything ok and response data is valid
      return { isError: false, data };
    } catch (err: unknown) {
      // unknown error happened while fetching
      const errorMessage = this._getErrorMessage(err);

      this._throwError({
        code: "",
        message: errorMessage,
        toastId,
        status: apiResponse?.status ?? null,
        request: apiResponse?.url ?? "",
      });

      return { isError: true, data: errorMessage as ApiResponseError };
    }
  };

  private _getPathNameByRequest = (request: string) => {
    try {
      const pathName = new URL(request).pathname;
      return pathName;
    } catch {
      return "";
    }
  };

  private _throwError = (data: ErrorData) => {
    const { message, code, status, request, toastId } = data;
    // if the request has already worked 3 times, then we do not display anything
    // if (this.repeatCounter >= 3) return;
    if (toastId) {
      if (this._counters[toastId] >= 3) return;
    }

    // eslint-disable-next-line quotes
    const notSupp = message.includes('"not supported"');

    if (!notSupp) {
      const statusCodeMessage = `${code ? "Code" : "Status"}: ${code || status}`;
      const requestMessage = `Request: ${this._getPathNameByRequest(request)}`;
      toast.error(`${statusCodeMessage}\n\n${requestMessage}\n\nMessage:\n${message}`, {
        id: toastId,
      });
    }

    this._setError(data);
    if (toastId) this._repeatHandler(toastId);
  };

  private _setError = (data: ErrorInfo) => {
    if (this.errors.length === 100) {
      this.errors.shift();
    }

    this.errors.push({
      ...data,
      date: unixToISOString(getCurrentUnix()),
    });
  };

  private _refreshTokens = async <Data>(
    originalReq: RequestConfig,
    originalRes: Response
  ): Promise<ApiResponse<ResponseData<Data>, ErrorInfo>> => {
    try {
      const refreshRequestConfig = refreshTokenRequestConfig(getRefreshToken());
      const refreshResult = await this._fetchApi<any>(refreshRequestConfig);

      // error during refresh token request
      if (refreshResult.isError) {
        return {
          isError: true,
          data: {
            ...refreshResult.data,
            status: originalRes.status,
            request: originalRes.url,
          },
        };
      }

      // parsing refresh tokens response data
      const refreshResponse = refreshResult.data;

      const { status, error, message, success, isError, data } = refreshResponse.result;

      const isDataError = this._isDataError(success, isError);

      // check if valid response data => update tokens
      if (!isDataError) {
        updateRefreshTokenStorage(data);

        // make original request once again with same config
        const retryData = await this._fetchApi<Data>(originalReq);

        return retryData;
      }
      // logout user if refresh tokens failed
      this._logOut();

      const errMessage = this._getErrorMessage(error || message);

      return {
        isError: true,
        data: {
          message: errMessage,
          code: status,
          status: originalRes.status,
          request: originalRes.url,
        },
      };
    } catch (err) {
      // unknown error happened while refreshing tokens and retrying
      this._logOut();

      const errMessage = this._getErrorMessage(err);

      return {
        isError: true,
        data: {
          message: errMessage,
          code: "",
          status: originalRes.status,
          request: originalRes.url,
        },
      };
    }
  };

  private _logOut = () => {
    setAccessToken("");
    setRefreshToken("");

    this._resetTimeout();
  };
}

const responseHandler = new ResponseHandler();
export default responseHandler;
