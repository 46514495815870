import { ExpandIcon } from "src/components/shared/Buttons/v2/DropdownButton/style";
import { Headline3 } from "src/components/shared/Typography";
import styled from "styled-components";

export interface SelectorContainerProps {
  size?: "small" | "large";
}

export const SelectorContainer = styled.div<SelectorContainerProps>`
  width: ${({ size = "large" }) => (size === "small" ? "min-content" : "100%")};
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  padding: 9px 13px;

  font-size: 13px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  background-color: ${({ theme }) => theme.bgGrayBlack};

  color: ${({ theme }) => theme.inputTextColor};

  :focus {
    box-shadow: ${({ theme: { inputBorderColor } }) => `0 0 0 1px ${inputBorderColor}`};
  }
`;

export const Title = styled(Headline3)`
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
`;

export const DropdownIcon = styled(ExpandIcon)`
  transform: ${({ $open }) => ($open ? "rotate(0deg)" : "rotate(90deg)")};
  font-size: 14px;
  flex-shrink: 0;
`;
