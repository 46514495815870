import { BaseModeInfoItemProps } from "../BaseModeInfoItem";
import * as styles from "./style";

export interface TimeModeInfoItemProps extends Omit<BaseModeInfoItemProps, "value"> {
  time: string;
  elapsedTime: string;
}

export const TimeModeInfoItem = ({ time, elapsedTime, ...props }: TimeModeInfoItemProps) => {
  const timeText = (
    <>
      {time} <strong>UTC</strong>
      <br />
      <i>{elapsedTime}</i>
    </>
  );

  return <styles.StyledModeInfoItem value={timeText} {...props} />;
};
