import { useEffect, useRef } from "react";
import { Toaster, toast } from "sonner";
import { useThemeMode } from "src/hooks/useThemeMode";
import "./toastOverride.css";

const clickToastHandler = (e: MouseEvent) => {
  if (e.shiftKey && e.type === "click") {
    toast.dismiss();
  }
};

export const ToasterInit = () => {
  const theme = useThemeMode();

  const toastRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const toastCurrent = toastRef.current;
    if (!toastCurrent) return;

    toastCurrent.addEventListener("click", clickToastHandler, false);

    return () => {
      toastCurrent.removeEventListener("click", clickToastHandler, false);
    };
  }, []);

  return (
    <div ref={toastRef}>
      <Toaster theme={theme} position="top-right" visibleToasts={5} richColors />
    </div>
  );
};
