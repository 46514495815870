import { ApiModules, apiUrl } from "src/api";
import { IMultiGridStatus } from "src/state/CEX/multiGrid/ActiveMultiGrid";
import { INewExchangeModule, ModuleConfig } from "src/state/CEX/multiGrid/AddModuleModal";
import { IMultiGrinder, MultiGrinderSettings } from "src/state/CEX/multiGrid/MultiGridForm";
import { GridStateStep, IGridState } from "src/state/CEX/multiGrid/MultiGridState";
import ResponseHandler from "src/state/network/ResponseHandler";

interface ExchangeModuleProps {
  uuid: string;
  id: string;
}

interface CreateExchangeModuleProps {
  uuid: string;
  data: INewExchangeModule;
}

interface UpdExchangeModuleProps extends ExchangeModuleProps {
  data: ModuleConfig;
}

interface IGridStateResponse {
  count: number;
  state: IGridState;
}

export interface MultiGrinderStateProps {
  uuid: string;
  step: GridStateStep;
  offset?: number;
}

export const getMultiGridUUID = async (party: string) =>
  ResponseHandler.handler<string[]>({
    url: apiUrl(ApiModules.Parties, `${party}/multigrinders`),
    method: "GET",
  });

export const getMultiGridExist = async (uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/exist`),
    method: "GET",
  });

export const createMultiGrinder = async (data: IMultiGrinder) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, ""),
    method: "POST",
    data,
  });

export const getMultiGrinderStatus = async (uuid: string) =>
  ResponseHandler.handler<IMultiGridStatus>({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/status`),
    method: "GET",
  });

export const getMultiGrinderSettings = async (uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/settings`),
    method: "GET",
  });

export const updMultiGrinderSettings = async (uuid: string, settings: MultiGrinderSettings) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/settings`),
    method: "PUT",
    data: { settings },
  });

export const startMultiGrinder = async (uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/start`),
    method: "POST",
    data: {},
  });

export const stopMultiGrinder = async (uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/stop`),
    method: "POST",
    data: {},
  });

export const createExchangeModule = async ({ uuid, data }: CreateExchangeModuleProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/exchange`),
    method: "POST",
    data,
  });

export const getExchangesModules = async (uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/exchange`),
    method: "GET",
  });

export const updExchangeModule = async ({ uuid, id, data }: UpdExchangeModuleProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/exchange/${id}`),
    method: "PUT",
    data,
  });

export const startExchangeModule = async ({ uuid, id }: ExchangeModuleProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/exchange/${id}/start`),
    method: "POST",
    data: {},
  });

export const stopExchangeModule = async ({ uuid, id }: ExchangeModuleProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/exchange/${id}/stop`),
    method: "POST",
    data: {},
  });

export const removeExchangeModule = async ({ uuid, id }: ExchangeModuleProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/exchange/${id}`),
    method: "DELETE",
  });

export const getMultiGrinderState = async ({ uuid, offset, step }: MultiGrinderStateProps) => {
  const offsetParam = offset !== undefined ? `&offset=${offset}` : "";

  return ResponseHandler.handler<IGridStateResponse>({
    url: apiUrl(ApiModules.MultiGrinder, `${uuid}/state?step=${step}${offsetParam}`),
    method: "GET",
  });
};
