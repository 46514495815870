import { useCallback, useState } from "react";
import { setTextClipboard } from "src/helpers/clipboard";
import Icons from "src/icons/Icons";
import { TOAST_PROMPT_TEXT } from "../toastSettings";
import * as styles from "./style";

interface CopyToastProps {
  msg: string;
}

export const CopyToast = ({ msg }: CopyToastProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const title = isCopied ? "Copied" : "Copy";

  const copyText = useCallback(() => {
    setTextClipboard(msg, { isShowSuccessMsg: false, onSuccess: () => setIsCopied(true) });
  }, [msg]);

  return (
    <styles.Container>
      {TOAST_PROMPT_TEXT}
      <styles.Wrapper title={title} onClick={copyText}>
        {isCopied ? <Icons.Success fill="currentColor" /> : <Icons.Copy />}
      </styles.Wrapper>
    </styles.Container>
  );
};
