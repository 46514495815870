import { ChainConnectionContext } from "src/context/DEXV2/ChainConnectionContext";
import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import BotWalletConnectionStore from "src/state/DEXV2/DEXV2Stats/Vaults/BotWalletConnectionStore";
import { DEXV2BotContext } from "../../DEXV2Bots/DEXV2Bot";

export const BotWalletConnectionContext = createLateInitContext<BotWalletConnectionStore>();

type BotWalletConnectionProviderProps = ChildrenProps;

export const BotWalletConnectionProvider = ({ children }: BotWalletConnectionProviderProps) => {
  const chainConnectionState = useLateInitContext(ChainConnectionContext);
  const botState = useLateInitContext(DEXV2BotContext);

  const store = useLocalStore(BotWalletConnectionStore, botState, chainConnectionState);

  return (
    <BotWalletConnectionContext.Provider value={store}>
      {children}
    </BotWalletConnectionContext.Provider>
  );
};
