import { makeAutoObservable, runInAction } from "mobx";
import { getDEXBot } from "src/api/bots/DEX/bots";
import { DEXBot } from "src/modules/bots";

const EMPTY_DEX_BOT = {
  swap_bot_uuid: "",
  base: "",
  client: "",
  exchange: "",
  lastTrade: 0,
  link: "",
  message: "",
  nextTrade: 0,
  note: "",
  party: "",
  party_id: 0,
  quote: "",
  swap_bot_id: 0,
  visible: false,
  created_at: 0,
  updated_at: 0,
};

export class DEXBotStore {
  DEXBot: DEXBot = EMPTY_DEX_BOT;

  showLoader: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get bot_uuid() {
    return this.DEXBot.swap_bot_uuid;
  }

  get market() {
    return this.DEXBot.quote
      ? `${this.DEXBot.base}_${this.DEXBot.quote}_${this.DEXBot.exchange}`
      : "";
  }

  get party() {
    return this.DEXBot.party;
  }

  resetBot = () => {
    this.DEXBot = EMPTY_DEX_BOT;
  };

  setBotUUID = (uuid: string) => {
    this.resetBot();

    this.DEXBot.swap_bot_uuid = uuid;
  };

  setLoading = (load: boolean) => {
    this.showLoader = load;
  };

  async _fetchBot() {
    this.setLoading(true);

    try {
      const { data, isError } = await getDEXBot(this.bot_uuid);
      if (!isError) {
        runInAction(() => {
          this.DEXBot = data;
        });
      }
    } finally {
      this.setLoading(false);
    }
  }

  loadBot = (): void => {
    this._fetchBot();
  };
}
