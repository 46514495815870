import { History, LocationState } from "history";

type HistoryPushParameters<HistoryLocationState> = Parameters<
  History<HistoryLocationState>["push"]
>;

/**
 * Pushes new location or replaces current if same location pathName
 * @param history - history object
 * @param pushParams - location pathName and optional state
 */
export const pushOrReplaceHistory = <HistoryLocationState = LocationState>(
  history: History<HistoryLocationState>,
  ...pushParams: HistoryPushParameters<HistoryLocationState>
) => {
  const [location, state] = pushParams;
  if (history.location.pathname !== location) {
    history.push(location, state);
  } else {
    history.replace(location, state);
  }
};
