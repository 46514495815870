import { makeAutoObservable } from "mobx";
import { getAutoFunding, updateAutoFunding } from "src/api/shared/funding";
import { AutoFunding, PartyFundingRange, UpdateFunding } from "../types";
import { IBaseFundingStore } from "./IBaseFundingStore";

export class AutoFundingStore implements IBaseFundingStore<AutoFunding> {
  private _list: AutoFunding[] = [];

  get list() {
    return this._list;
  }

  set list(data: AutoFunding[]) {
    this._list = data;
  }

  constructor() {
    makeAutoObservable(this);
  }

  loadFunding = async (fundingParam: PartyFundingRange) => await getAutoFunding(fundingParam);

  getSelectFunding = () => {};

  updateFunding = async (id: number, data: UpdateFunding) => await updateAutoFunding(id, data);

  deleteFundingModal = () => {};

  deleteFundingAction = async () => {};
}
